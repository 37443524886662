import styled from "styled-components";

export const AuthPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 72px;
    width: 100%;
`
export const Content = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100vh;
    padding: 0px 48px;
    position: absolute;
    width: 100vw;
`
export const LeftCol = styled.div`
    display: flex;
    transition: 0.2s;
    width: 50%;

    .left-col-content {
        display: flex;
        flex-direction: column;
        width: 420px;

        .toggle-container {
            display: flex;
            gap: 32px;
            margin-bottom: 32px;

            .toggle-option {
                padding: 12px 0px;

                .underline {
                    opacity: 1.0;
                    position: relative; 
                }
            
                .underline::after {
                    background: #FFFFFF;
                    bottom: -10px;
                    content: '';
                    height: 2px;
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    transition: 0.5s;
                    width: 100%;
                }

                h3 {
                    color: #FFFFFF;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 16px;
                    margin: 0;
                    opacity: 0.5;
                }

                h3:hover {
                    opacity: 1;
                }
            }
        }
    }
`
export const RightCol = styled.div`
    width: 50%;
`
export const MainGradientBGContainer = styled.div`
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: -500;
`