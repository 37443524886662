import { useNavigate } from 'react-router-dom';

// styles
import { PanelMediumContainer } from './PanelMedium.styles.js';

export default function PanelMedium({ children, path=null }) {
  const navigate = useNavigate()

  const handleNavigate = () => {
    if (path !== null) {
      navigate(path)
    }
  }

  return (
    <PanelMediumContainer
      className='dropshadow panel-bg'
      onClick={handleNavigate}
      whileHover={{ scale: 1.015 }}
    >
        {children}
    </PanelMediumContainer>
  )
}
