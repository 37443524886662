export const CARD_ACTION_TYPES = {
    SET_CARD_DATA: 'card/SET_CARD_DATA',
    SET_CHECKLIST_DATA: 'card/SET_CHECKLIST_DATA',
    SET_PARALLELS_ROUTES: 'card/SET_PARALLELS_ROUTES',
    SET_QUEUED_CARDS: 'card/SET_QUEUED_CARDS',
    SET_QUEUED_CARDS_QTY: 'card/SET_QUEUED_CARDS_QTY',
    SET_QUEUED_CARDS_DISPLAY: 'card/SET_QUEUED_CARDS_DISPLAY',
    SET_QUEUED_CARDS_DISPLAY_QTY: 'card/SET_QUEUED_CARDS_DISPLAY_QTY',
    SET_CARD_COLLECTION: 'card/SET_CARD_COLLECTION',
    SET_CARD_COLLECTION_QTY: 'card/SET_CARD_COLLECTION_QTY',
    CLEAR_QUEUE: 'card/CLEAR_QUEUE'
};