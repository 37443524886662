// CHECKLIST: COMPLETE
// PARALLELS: COMPLETE

export const MLB_2024_TOPPS_SERIES2 = {
    "MLB": {
        "2024": {
            "Topps": {
                "Series 2": {
                    "Base Card Set": {
                        "Base": {
                            "351": {
                                "cardNumber": "351",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "352": {
                                "cardNumber": "352",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "353": {
                                "cardNumber": "353",
                                "name": ["Tyler Freeman"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "354": {
                                "cardNumber": "354",
                                "name": ["Javier Assad"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "355": {
                                "cardNumber": "355",
                                "name": ["Lucas Sims"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "356": {
                                "cardNumber": "356",
                                "name": ["José Alvarado"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "357": {
                                "cardNumber": "357",
                                "name": ["Rhys Hoskins"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "358": {
                                "cardNumber": "358",
                                "name": ["Patrick Bailey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "359": {
                                "cardNumber": "359",
                                "name": ["Luis Garcia"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "360": {
                                "cardNumber": "360",
                                "name": ["Steven Cruz"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "361": {
                                "cardNumber": "361",
                                "name": ["Myles Straw"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "362": {
                                "cardNumber": "362",
                                "name": ["James Outman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "363": {
                                "cardNumber": "363",
                                "name": ["Bryce Jarvis"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "364": {
                                "cardNumber": "364",
                                "name": ["Nick Pratto"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "365": {
                                "cardNumber": "365",
                                "name": ["Carson Spiers"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "366": {
                                "cardNumber": "366",
                                "name": ["Jason Heyward"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "367": {
                                "cardNumber": "367",
                                "name": ["Michael Soroka"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "368": {
                                "cardNumber": "368",
                                "name": ["Tylor Megill"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "369": {
                                "cardNumber": "369",
                                "name": ["Brenan Hanifee"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "370": {
                                "cardNumber": "370",
                                "name": ["Cedric Mullins"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "371": {
                                "cardNumber": "371",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "372": {
                                "cardNumber": "372",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "373": {
                                "cardNumber": "373",
                                "name": ["Akil Baddoo"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "374": {
                                "cardNumber": "374",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "375": {
                                "cardNumber": "375",
                                "name": ["Matt Vierling"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "376": {
                                "cardNumber": "376",
                                "name": ["Adam Duvall"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "377": {
                                "cardNumber": "377",
                                "name": ["Tanner Houck"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "378": {
                                "cardNumber": "378",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "379": {
                                "cardNumber": "379",
                                "name": ["Kyle Farmer"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "380": {
                                "cardNumber": "380",
                                "name": ["Sean Manaea"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "381": {
                                "cardNumber": "381",
                                "name": ["Michael Brantley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "382": {
                                "cardNumber": "382",
                                "name": ["Mitch Haniger"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "383": {
                                "cardNumber": "383",
                                "name": ["Francisco Alvarez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "384": {
                                "cardNumber": "384",
                                "name": ["Matthew Batten"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "385": {
                                "cardNumber": "385",
                                "name": ["Touki Toussaint"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "386": {
                                "cardNumber": "386",
                                "name": ["Will Benson"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "387": {
                                "cardNumber": "387",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "388": {
                                "cardNumber": "388",
                                "name": ["Patrick Sandoval"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "389": {
                                "cardNumber": "389",
                                "name": ["Miguel Rojas"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "390": {
                                "cardNumber": "390",
                                "name": ["Jack Suwinski"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "391": {
                                "cardNumber": "391",
                                "name": ["Forrest Wall"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "392": {
                                "cardNumber": "392",
                                "name": ["Isaac Paredes"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "393": {
                                "cardNumber": "393",
                                "name": ["Matt Chapman"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "394": {
                                "cardNumber": "394",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "395": {
                                "cardNumber": "395",
                                "name": ["Riley Adams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "396": {
                                "cardNumber": "396",
                                "name": ["Raisel Iglesias"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "397": {
                                "cardNumber": "397",
                                "name": ["Jacob Young"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "398": {
                                "cardNumber": "398",
                                "name": ["Enrique Hernandez"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "399": {
                                "cardNumber": "399",
                                "name": ["Jonathan Bowlan"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "400": {
                                "cardNumber": "400",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "401": {
                                "cardNumber": "401",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "402": {
                                "cardNumber": "402",
                                "name": ["Josiah Gray"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "403": {
                                "cardNumber": "403",
                                "name": ["Casey Schmitt"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "404": {
                                "cardNumber": "404",
                                "name": ["Ryan Noda"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "405": {
                                "cardNumber": "405",
                                "name": ["Erick Fedde"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "406": {
                                "cardNumber": "406",
                                "name": ["Andrew Saalfrank"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "407": {
                                "cardNumber": "407",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "408": {
                                "cardNumber": "408",
                                "name": ["Kyle Nicolas"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "409": {
                                "cardNumber": "409",
                                "name": ["Garrett Stubbs"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "410": {
                                "cardNumber": "410",
                                "name": ["José Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "411": {
                                "cardNumber": "411",
                                "name": ["Ryan Pepiot"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "412": {
                                "cardNumber": "412",
                                "name": ["Victor Robles"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "413": {
                                "cardNumber": "413",
                                "name": ["Bobby Witt Jr."],
                                "team": ["American League™"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "414": {
                                "cardNumber": "414",
                                "name": ["Eguy Rosario"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "415": {
                                "cardNumber": "415",
                                "name": ["Rob Refsnyder"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "416": {
                                "cardNumber": "416",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "417": {
                                "cardNumber": "417",
                                "name": ["Washington Nationals®"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "418": {
                                "cardNumber": "418",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "419": {
                                "cardNumber": "419",
                                "name": ["Chris Bassitt"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "420": {
                                "cardNumber": "420",
                                "name": ["Eloy Jiménez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "421": {
                                "cardNumber": "421",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "422": {
                                "cardNumber": "422",
                                "name": ["Austin Barnes"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "423": {
                                "cardNumber": "423",
                                "name": ["José Azocar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "424": {
                                "cardNumber": "424",
                                "name": ["Mookie Betts"],
                                "team": ["Major League Baseball™"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "425": {
                                "cardNumber": "425",
                                "name": ["Anthony Veneziano"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "426": {
                                "cardNumber": "426",
                                "name": ["John Schreiber"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "427": {
                                "cardNumber": "427",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "428": {
                                "cardNumber": "428",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "429": {
                                "cardNumber": "429",
                                "name": ["Michael King"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "430": {
                                "cardNumber": "430",
                                "name": ["Brandon Lowe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "431": {
                                "cardNumber": "431",
                                "name": ["Charlie Blackmon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "432": {
                                "cardNumber": "432",
                                "name": ["Hunter Goodman"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "433": {
                                "cardNumber": "433",
                                "name": ["Josh Hader"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "434": {
                                "cardNumber": "434",
                                "name": ["James McArthur"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "435": {
                                "cardNumber": "435",
                                "name": ["Shane Baz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "436": {
                                "cardNumber": "436",
                                "name": ["Jose Siri"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "437": {
                                "cardNumber": "437",
                                "name": ["Tanner Bibee"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Future Star"]
                            },
                            "438": {
                                "cardNumber": "438",
                                "name": ["Bryan Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "439": {
                                "cardNumber": "439",
                                "name": ["Cleveland Guardians™"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "440": {
                                "cardNumber": "440",
                                "name": ["Osleivis Basabe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "441": {
                                "cardNumber": "441",
                                "name": ["Dylan Moore"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "442": {
                                "cardNumber": "442",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "443": {
                                "cardNumber": "443",
                                "name": ["Joe Boyle"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "444": {
                                "cardNumber": "444",
                                "name": ["Jarred Kelenic"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "445": {
                                "cardNumber": "445",
                                "name": ["Max Kepler"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "446": {
                                "cardNumber": "446",
                                "name": ["David Fry"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "447": {
                                "cardNumber": "447",
                                "name": ["Garrett Mitchell"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Future Star"]
                            },
                            "448": {
                                "cardNumber": "448",
                                "name": ["James McCann"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "449": {
                                "cardNumber": "449",
                                "name": ["Triston McKenzie"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "450": {
                                "cardNumber": "450",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "451": {
                                "cardNumber": "451",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "452": {
                                "cardNumber": "452",
                                "name": ["Alexander Canario"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "453": {
                                "cardNumber": "453",
                                "name": ["San Francisco Giants®"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "454": {
                                "cardNumber": "454",
                                "name": ["Anthony Rendon"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "455": {
                                "cardNumber": "455",
                                "name": ["Tyrone Taylor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "456": {
                                "cardNumber": "456",
                                "name": ["Cristian Pache"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "457": {
                                "cardNumber": "457",
                                "name": ["John Means"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "458": {
                                "cardNumber": "458",
                                "name": ["Wade Meckler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "459": {
                                "cardNumber": "459",
                                "name": ["Oswald Peraza"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "460": {
                                "cardNumber": "460",
                                "name": ["Blake Snell"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "461": {
                                "cardNumber": "461",
                                "name": ["Everson Pereira"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "462": {
                                "cardNumber": "462",
                                "name": ["Ryan McMahon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "463": {
                                "cardNumber": "463",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "464": {
                                "cardNumber": "464",
                                "name": ["Chicago White Sox®"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "465": {
                                "cardNumber": "465",
                                "name": ["Edward Cabrera"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "466": {
                                "cardNumber": "466",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "467": {
                                "cardNumber": "467",
                                "name": ["Vaughn Grissom"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "468": {
                                "cardNumber": "468",
                                "name": ["Hunter Renfroe"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "469": {
                                "cardNumber": "469",
                                "name": ["Martín Maldonado"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "470": {
                                "cardNumber": "470",
                                "name": ["Jordyn Adams"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "471": {
                                "cardNumber": "471",
                                "name": ["Michael Busch"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "472": {
                                "cardNumber": "472",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "473": {
                                "cardNumber": "473",
                                "name": ["Brayan Rocchio"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "474": {
                                "cardNumber": "474",
                                "name": ["Reese McGuire"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "475": {
                                "cardNumber": "475",
                                "name": ["Elvis Andrus"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "476": {
                                "cardNumber": "476",
                                "name": ["Drew Rom"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "477": {
                                "cardNumber": "477",
                                "name": ["Garrett Whitlock"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "478": {
                                "cardNumber": "478",
                                "name": ["Ryan McKenna"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "479": {
                                "cardNumber": "479",
                                "name": ["Oswaldo Cabrera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "480": {
                                "cardNumber": "480",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "481": {
                                "cardNumber": "481",
                                "name": ["Freddie Freeman"],
                                "team": ["National League™"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "482": {
                                "cardNumber": "482",
                                "name": ["Jake McCarthy"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "483": {
                                "cardNumber": "483",
                                "name": ["Tristan Gray"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "484": {
                                "cardNumber": "484",
                                "name": ["Chris Sale"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "485": {
                                "cardNumber": "485",
                                "name": ["Spencer Steer"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "486": {
                                "cardNumber": "486",
                                "name": ["Mark Canha"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "487": {
                                "cardNumber": "487",
                                "name": ["Josh Rojas"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "488": {
                                "cardNumber": "488",
                                "name": ["Andrés Muñoz"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "489": {
                                "cardNumber": "489",
                                "name": ["Miles Mastrobuoni"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "490": {
                                "cardNumber": "490",
                                "name": ["St. Louis Cardinals®"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "491": {
                                "cardNumber": "491",
                                "name": ["Tommy Edman"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "492": {
                                "cardNumber": "492",
                                "name": ["J.D. Martinez"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "493": {
                                "cardNumber": "493",
                                "name": ["Darius Vines"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "494": {
                                "cardNumber": "494",
                                "name": ["Allan Winans"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "495": {
                                "cardNumber": "495",
                                "name": ["Jose Caballero"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "496": {
                                "cardNumber": "496",
                                "name": ["Alex Call"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "497": {
                                "cardNumber": "497",
                                "name": ["Alex Cobb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "498": {
                                "cardNumber": "498",
                                "name": ["Texas Rangers®"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "499": {
                                "cardNumber": "499",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "500": {
                                "cardNumber": "500",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "501": {
                                "cardNumber": "501",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "502": {
                                "cardNumber": "502",
                                "name": ["Jacob Lopez"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "503": {
                                "cardNumber": "503",
                                "name": ["Jackson Rutledge"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "504": {
                                "cardNumber": "504",
                                "name": ["Michael Conforto"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "505": {
                                "cardNumber": "505",
                                "name": ["Avisaíl García"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "506": {
                                "cardNumber": "506",
                                "name": ["Nick Loftin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "507": {
                                "cardNumber": "507",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "508": {
                                "cardNumber": "508",
                                "name": ["Javier Báez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "509": {
                                "cardNumber": "509",
                                "name": ["Andre Lipcius"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "510": {
                                "cardNumber": "510",
                                "name": ["Yusei Kikuchi"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "511": {
                                "cardNumber": "511",
                                "name": ["Zach McKinstry"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "512": {
                                "cardNumber": "512",
                                "name": ["Kyle Higashioka"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "513": {
                                "cardNumber": "513",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "514": {
                                "cardNumber": "514",
                                "name": ["Kansas City Royals®"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "515": {
                                "cardNumber": "515",
                                "name": ["Nick Madrigal"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "516": {
                                "cardNumber": "516",
                                "name": ["Parker Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "517": {
                                "cardNumber": "517",
                                "name": ["Lyon Richardson"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "518": {
                                "cardNumber": "518",
                                "name": ["Marcell Ozuna"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "519": {
                                "cardNumber": "519",
                                "name": ["Gavin Lux"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "520": {
                                "cardNumber": "520",
                                "name": ["Orion Kerkering"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "521": {
                                "cardNumber": "521",
                                "name": ["Blake Sabol"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "522": {
                                "cardNumber": "522",
                                "name": ["Brandon Williamson"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "523": {
                                "cardNumber": "523",
                                "name": ["Jackson Wolf"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "524": {
                                "cardNumber": "524",
                                "name": ["Jon Berti"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "525": {
                                "cardNumber": "525",
                                "name": ["Kyle Isbel"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "526": {
                                "cardNumber": "526",
                                "name": ["Colorado Rockies™"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "527": {
                                "cardNumber": "527",
                                "name": ["Edward Olivares"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "528": {
                                "cardNumber": "528",
                                "name": ["Matt Strahm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "529": {
                                "cardNumber": "529",
                                "name": ["Ian Happ"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "530": {
                                "cardNumber": "530",
                                "name": ["Drew Millas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "531": {
                                "cardNumber": "531",
                                "name": ["Estevan Florial"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "532": {
                                "cardNumber": "532",
                                "name": ["Romy Gonzalez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "533": {
                                "cardNumber": "533",
                                "name": ["Milwaukee Brewers™"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "534": {
                                "cardNumber": "534",
                                "name": ["T.J. Friedl"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "535": {
                                "cardNumber": "535",
                                "name": ["Pavin Smith"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "536": {
                                "cardNumber": "536",
                                "name": ["Kerry Carpenter"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "537": {
                                "cardNumber": "537",
                                "name": ["Owen Miller"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "538": {
                                "cardNumber": "538",
                                "name": ["Kody Funderburk"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "539": {
                                "cardNumber": "539",
                                "name": ["A.J. Minter"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "540": {
                                "cardNumber": "540",
                                "name": ["Mike Tauchman"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "541": {
                                "cardNumber": "541",
                                "name": ["Miguel Vargas"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "542": {
                                "cardNumber": "542",
                                "name": ["Liover Peguero"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "543": {
                                "cardNumber": "543",
                                "name": ["Bobby Dalbec"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "544": {
                                "cardNumber": "544",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "545": {
                                "cardNumber": "545",
                                "name": ["Zach Neto"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "546": {
                                "cardNumber": "546",
                                "name": ["Luke Little"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "547": {
                                "cardNumber": "547",
                                "name": ["Drew Smyly"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "548": {
                                "cardNumber": "548",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "549": {
                                "cardNumber": "549",
                                "name": ["Kyren Paris"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "550": {
                                "cardNumber": "550",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "551": {
                                "cardNumber": "551",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "552": {
                                "cardNumber": "552",
                                "name": ["José Urquidy"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "553": {
                                "cardNumber": "553",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "554": {
                                "cardNumber": "554",
                                "name": ["Yoán Moncada"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "555": {
                                "cardNumber": "555",
                                "name": ["Ezequiel Duran"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "556": {
                                "cardNumber": "556",
                                "name": ["Logan Gilbert"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "557": {
                                "cardNumber": "557",
                                "name": ["New York Mets®"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "558": {
                                "cardNumber": "558",
                                "name": ["Arizona Diamondbacks®"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "559": {
                                "cardNumber": "559",
                                "name": ["Taylor Walls"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "560": {
                                "cardNumber": "560",
                                "name": ["Tim Anderson"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "561": {
                                "cardNumber": "561",
                                "name": ["Dylan Carlson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "562": {
                                "cardNumber": "562",
                                "name": ["Edmundo Sosa"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "563": {
                                "cardNumber": "563",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "564": {
                                "cardNumber": "564",
                                "name": ["Jake Meyers"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "565": {
                                "cardNumber": "565",
                                "name": ["Ezequiel Tovar"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Future Star"]
                            },
                            "566": {
                                "cardNumber": "566",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "567": {
                                "cardNumber": "567",
                                "name": ["Kyle Hendricks"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "568": {
                                "cardNumber": "568",
                                "name": ["Trevor Larnach"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "569": {
                                "cardNumber": "569",
                                "name": ["Evan Phillips"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "570": {
                                "cardNumber": "570",
                                "name": ["Brice Turang"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "571": {
                                "cardNumber": "571",
                                "name": ["John McMillon"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "572": {
                                "cardNumber": "572",
                                "name": ["Kevin Ginkel"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "573": {
                                "cardNumber": "573",
                                "name": ["Irving Lopez"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "574": {
                                "cardNumber": "574",
                                "name": ["Brenton Doyle"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "575": {
                                "cardNumber": "575",
                                "name": ["Jonathan Ornelas"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "576": {
                                "cardNumber": "576",
                                "name": ["Tommy Kahnle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "577": {
                                "cardNumber": "577",
                                "name": ["Joey Wendle"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "578": {
                                "cardNumber": "578",
                                "name": ["Shane McClanahan"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "579": {
                                "cardNumber": "579",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "580": {
                                "cardNumber": "580",
                                "name": ["Jesús Sánchez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "581": {
                                "cardNumber": "581",
                                "name": ["Yan Gomes"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "582": {
                                "cardNumber": "582",
                                "name": ["Jeff McNeil"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "583": {
                                "cardNumber": "583",
                                "name": ["Kyle Hurt"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "584": {
                                "cardNumber": "584",
                                "name": ["Matthew Liberatore"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "585": {
                                "cardNumber": "585",
                                "name": ["Chase Silseth"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "586": {
                                "cardNumber": "586",
                                "name": ["Aledmys Díaz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "587": {
                                "cardNumber": "587",
                                "name": ["Liam Hendriks"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "588": {
                                "cardNumber": "588",
                                "name": ["Lance McCullers Jr."],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "589": {
                                "cardNumber": "589",
                                "name": ["Taj Bradley"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Future Star"]
                            },
                            "590": {
                                "cardNumber": "590",
                                "name": ["Craig Kimbrel"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "591": {
                                "cardNumber": "591",
                                "name": ["Nolan Gorman"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "592": {
                                "cardNumber": "592",
                                "name": ["Davis Daniel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "593": {
                                "cardNumber": "593",
                                "name": ["Carlos Rodón"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "594": {
                                "cardNumber": "594",
                                "name": ["Pablo Reyes"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "595": {
                                "cardNumber": "595",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "596": {
                                "cardNumber": "596",
                                "name": ["Edouard Julien"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "597": {
                                "cardNumber": "597",
                                "name": ["Ketel Marte"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "598": {
                                "cardNumber": "598",
                                "name": ["Justin Turner"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "599": {
                                "cardNumber": "599",
                                "name": ["Nick Allen"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "600": {
                                "cardNumber": "600",
                                "name": ["Max Scherzer"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "601": {
                                "cardNumber": "601",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "602": {
                                "cardNumber": "602",
                                "name": ["Tyler Fitzgerald"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "603": {
                                "cardNumber": "603",
                                "name": ["Joc Pederson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "604": {
                                "cardNumber": "604",
                                "name": ["Toronto Blue Jays®"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "605": {
                                "cardNumber": "605",
                                "name": ["Kenta Maeda"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "606": {
                                "cardNumber": "606",
                                "name": ["Brayan Bello"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "607": {
                                "cardNumber": "607",
                                "name": ["Victor Vodnik"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "608": {
                                "cardNumber": "608",
                                "name": ["Ross Stripling"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "609": {
                                "cardNumber": "609",
                                "name": ["DJ Stewart"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "610": {
                                "cardNumber": "610",
                                "name": ["Zack Greinke"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "611": {
                                "cardNumber": "611",
                                "name": ["Ty France"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "612": {
                                "cardNumber": "612",
                                "name": ["Tyler Cropley"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "613": {
                                "cardNumber": "613",
                                "name": ["Jordan Wicks"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "614": {
                                "cardNumber": "614",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "615": {
                                "cardNumber": "615",
                                "name": ["Yoendrys Gómez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "616": {
                                "cardNumber": "616",
                                "name": ["Sawyer Gipson-Long"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "617": {
                                "cardNumber": "617",
                                "name": ["Daniel Lynch"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "618": {
                                "cardNumber": "618",
                                "name": ["Germán Márquez"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "619": {
                                "cardNumber": "619",
                                "name": ["Teoscar Hernández"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "620": {
                                "cardNumber": "620",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "621": {
                                "cardNumber": "621",
                                "name": ["Jake Irvin"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "622": {
                                "cardNumber": "622",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "623": {
                                "cardNumber": "623",
                                "name": ["Dominic Fletcher"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "624": {
                                "cardNumber": "624",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "625": {
                                "cardNumber": "625",
                                "name": ["Paul DeJong"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "626": {
                                "cardNumber": "626",
                                "name": ["Michael Toglia"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "627": {
                                "cardNumber": "627",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "628": {
                                "cardNumber": "628",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "629": {
                                "cardNumber": "629",
                                "name": ["Gavin Sheets"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "630": {
                                "cardNumber": "630",
                                "name": ["Lance Lynn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "631": {
                                "cardNumber": "631",
                                "name": ["Marcus Stroman"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "632": {
                                "cardNumber": "632",
                                "name": ["Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "633": {
                                "cardNumber": "633",
                                "name": ["Luis Severino"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "634": {
                                "cardNumber": "634",
                                "name": ["Carlos Estévez"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "635": {
                                "cardNumber": "635",
                                "name": ["JT Brubaker"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "636": {
                                "cardNumber": "636",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "637": {
                                "cardNumber": "637",
                                "name": ["Blake Rutherford"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "638": {
                                "cardNumber": "638",
                                "name": ["Cal Quantrill"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "639": {
                                "cardNumber": "639",
                                "name": ["Harrison Bader"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "640": {
                                "cardNumber": "640",
                                "name": ["Jack Flaherty"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "641": {
                                "cardNumber": "641",
                                "name": ["DJ LeMahieu"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "642": {
                                "cardNumber": "642",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "643": {
                                "cardNumber": "643",
                                "name": ["Taijuan Walker"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "644": {
                                "cardNumber": "644",
                                "name": ["Brandon Drury"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "645": {
                                "cardNumber": "645",
                                "name": ["Ramon Laureano"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "646": {
                                "cardNumber": "646",
                                "name": ["Drew Waters"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "647": {
                                "cardNumber": "647",
                                "name": ["Paul Blackburn"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "648": {
                                "cardNumber": "648",
                                "name": ["Anthony Santander"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "649": {
                                "cardNumber": "649",
                                "name": ["Slade Cecconi"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "650": {
                                "cardNumber": "650",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "651": {
                                "cardNumber": "651",
                                "name": ["Pablo López"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "652": {
                                "cardNumber": "652",
                                "name": ["Graham Ashcraft"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "653": {
                                "cardNumber": "653",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "654": {
                                "cardNumber": "654",
                                "name": ["Christian Vázquez"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "655": {
                                "cardNumber": "655",
                                "name": ["Alejandro Kirk"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "656": {
                                "cardNumber": "656",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "657": {
                                "cardNumber": "657",
                                "name": ["Luis García"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "658": {
                                "cardNumber": "658",
                                "name": ["Weston Wilson"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "659": {
                                "cardNumber": "659",
                                "name": ["Ken Waldichuk"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "660": {
                                "cardNumber": "660",
                                "name": ["Joe Kelly"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "661": {
                                "cardNumber": "661",
                                "name": ["Whit Merrifield"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "662": {
                                "cardNumber": "662",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "663": {
                                "cardNumber": "663",
                                "name": ["Blake Perkins"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "664": {
                                "cardNumber": "664",
                                "name": ["Brandon Pfaadt"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "665": {
                                "cardNumber": "665",
                                "name": ["Andrew Heaney"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "666": {
                                "cardNumber": "666",
                                "name": ["Logan Porter"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "667": {
                                "cardNumber": "667",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "668": {
                                "cardNumber": "668",
                                "name": ["Colin Selby"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "669": {
                                "cardNumber": "669",
                                "name": ["Emmanuel Rivera"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "670": {
                                "cardNumber": "670",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "671": {
                                "cardNumber": "671",
                                "name": ["Wade Miley"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "672": {
                                "cardNumber": "672",
                                "name": ["Wilyer Abreu"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "673": {
                                "cardNumber": "673",
                                "name": ["Stuart Fairchild"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "674": {
                                "cardNumber": "674",
                                "name": ["Luis Urías"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "675": {
                                "cardNumber": "675",
                                "name": ["Alek Manoah"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "676": {
                                "cardNumber": "676",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "677": {
                                "cardNumber": "677",
                                "name": ["Lourdes Gurriel Jr."],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "678": {
                                "cardNumber": "678",
                                "name": ["José Tena"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "679": {
                                "cardNumber": "679",
                                "name": ["Bryce Miller"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Future Star"]
                            },
                            "680": {
                                "cardNumber": "680",
                                "name": ["Kelvin Caceres"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "681": {
                                "cardNumber": "681",
                                "name": ["Iván Herrera"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "682": {
                                "cardNumber": "682",
                                "name": ["Marco Gonzales"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "683": {
                                "cardNumber": "683",
                                "name": ["Dustin May"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "684": {
                                "cardNumber": "684",
                                "name": ["Tyler Stephenson"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "685": {
                                "cardNumber": "685",
                                "name": ["J.P. Martinez"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "686": {
                                "cardNumber": "686",
                                "name": ["Travis d'Arnaud"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "687": {
                                "cardNumber": "687",
                                "name": ["Nicky Lopez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "688": {
                                "cardNumber": "688",
                                "name": ["Oakland Athletics™"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "689": {
                                "cardNumber": "689",
                                "name": ["Josh Bell"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "690": {
                                "cardNumber": "690",
                                "name": ["Brett Baty"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Future Star"]
                            },
                            "691": {
                                "cardNumber": "691",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "692": {
                                "cardNumber": "692",
                                "name": ["Minnesota Twins®"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "693": {
                                "cardNumber": "693",
                                "name": ["Carlos Carrasco"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "694": {
                                "cardNumber": "694",
                                "name": ["Casey Mize"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "695": {
                                "cardNumber": "695",
                                "name": ["Kyle Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "696": {
                                "cardNumber": "696",
                                "name": ["Pittsburgh Pirates®"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "697": {
                                "cardNumber": "697",
                                "name": ["Jordan Diaz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "698": {
                                "cardNumber": "698",
                                "name": ["Gio Urshela"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "699": {
                                "cardNumber": "699",
                                "name": ["Sean Bouchard"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "700": {
                                "cardNumber": "700",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Insert": {
                        "Women in Baseball": {
                            "WIB-1": {
                                "cardNumber": "WIB-1",
                                "name": ["Veronica Alvarez"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "WIB-2": {
                                "cardNumber": "WIB-2",
                                "name": ["Jessica Mendoza"],
                                "team": ["Major League Baseball™"],
                                "cardAttributes": []
                            },
                            "WIB-3": {
                                "cardNumber": "WIB-3",
                                "name": ["Ronnie Gajownik"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "WIB-4": {
                                "cardNumber": "WIB-4",
                                "name": ["Alyssa Nakken"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "WIB-5": {
                                "cardNumber": "WIB-5",
                                "name": ["Rachel Folden"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            }
                        },
                        "City to City": {
                            "CTC-1": {
                                "cardNumber": "CTC-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CTC-2": {
                                "cardNumber": "CTC-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CTC-3": {
                                "cardNumber": "CTC-3",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "CTC-4": {
                                "cardNumber": "CTC-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CTC-5": {
                                "cardNumber": "CTC-5",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CTC-6": {
                                "cardNumber": "CTC-6",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-7": {
                                "cardNumber": "CTC-7",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-8": {
                                "cardNumber": "CTC-8",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CTC-9": {
                                "cardNumber": "CTC-9",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CTC-10": {
                                "cardNumber": "CTC-10",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CTC-11": {
                                "cardNumber": "CTC-11",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CTC-12": {
                                "cardNumber": "CTC-12",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CTC-13": {
                                "cardNumber": "CTC-13",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-14": {
                                "cardNumber": "CTC-14",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "CTC-15": {
                                "cardNumber": "CTC-15",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "CTC-16": {
                                "cardNumber": "CTC-16",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-17": {
                                "cardNumber": "CTC-17",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CTC-18": {
                                "cardNumber": "CTC-18",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CTC-19": {
                                "cardNumber": "CTC-19",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-20": {
                                "cardNumber": "CTC-20",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-21": {
                                "cardNumber": "CTC-21",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "CTC-22": {
                                "cardNumber": "CTC-22",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CTC-23": {
                                "cardNumber": "CTC-23",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "CTC-24": {
                                "cardNumber": "CTC-24",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "CTC-25": {
                                "cardNumber": "CTC-25",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-26": {
                                "cardNumber": "CTC-26",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-27": {
                                "cardNumber": "CTC-27",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTC-28": {
                                "cardNumber": "CTC-28",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "CTC-29": {
                                "cardNumber": "CTC-29",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "CTC-30": {
                                "cardNumber": "CTC-30",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            }
                        },
                        "Around the Horn": {
                            "ATH-1": {
                                "cardNumber": "ATH-1",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ATH-2": {
                                "cardNumber": "ATH-2",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ATH-3": {
                                "cardNumber": "ATH-3",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ATH-4": {
                                "cardNumber": "ATH-4",
                                "name": ["Brooks Robinson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ATH-5": {
                                "cardNumber": "ATH-5",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ATH-6": {
                                "cardNumber": "ATH-6",
                                "name": ["Eddie Murray"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ATH-7": {
                                "cardNumber": "ATH-7",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ATH-8": {
                                "cardNumber": "ATH-8",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ATH-9": {
                                "cardNumber": "ATH-9",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ATH-10": {
                                "cardNumber": "ATH-10",
                                "name": ["Max Muncy"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ATH-11": {
                                "cardNumber": "ATH-11",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ATH-12": {
                                "cardNumber": "ATH-12",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ATH-13": {
                                "cardNumber": "ATH-13",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ATH-14": {
                                "cardNumber": "ATH-14",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ATH-15": {
                                "cardNumber": "ATH-15",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ATH-16": {
                                "cardNumber": "ATH-16",
                                "name": ["David Wright"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ATH-17": {
                                "cardNumber": "ATH-17",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ATH-18": {
                                "cardNumber": "ATH-18",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ATH-19": {
                                "cardNumber": "ATH-19",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ATH-20": {
                                "cardNumber": "ATH-20",
                                "name": ["Matt McLain"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ATH-21": {
                                "cardNumber": "ATH-21",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ATH-22": {
                                "cardNumber": "ATH-22",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ATH-23": {
                                "cardNumber": "ATH-23",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ATH-24": {
                                "cardNumber": "ATH-24",
                                "name": ["Jeff Bagwell"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ATH-25": {
                                "cardNumber": "ATH-25",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ATH-26": {
                                "cardNumber": "ATH-26",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ATH-27": {
                                "cardNumber": "ATH-27",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ATH-28": {
                                "cardNumber": "ATH-28",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ATH-29": {
                                "cardNumber": "ATH-29",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ATH-30": {
                                "cardNumber": "ATH-30",
                                "name": ["Don Mattingly"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "Covering the Field": {
                            "CTF-1": {
                                "cardNumber": "CTF-1",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CTF-2": {
                                "cardNumber": "CTF-2",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CTF-3": {
                                "cardNumber": "CTF-3",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CTF-4": {
                                "cardNumber": "CTF-4",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "CTF-5": {
                                "cardNumber": "CTF-5",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CTF-6": {
                                "cardNumber": "CTF-6",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CTF-7": {
                                "cardNumber": "CTF-7",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CTF-8": {
                                "cardNumber": "CTF-8",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CTF-9": {
                                "cardNumber": "CTF-9",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "CTF-10": {
                                "cardNumber": "CTF-10",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "CTF-11": {
                                "cardNumber": "CTF-11",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CTF-12": {
                                "cardNumber": "CTF-12",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CTF-13": {
                                "cardNumber": "CTF-13",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CTF-14": {
                                "cardNumber": "CTF-14",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTF-15": {
                                "cardNumber": "CTF-15",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "CTF-16": {
                                "cardNumber": "CTF-16",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CTF-17": {
                                "cardNumber": "CTF-17",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CTF-18": {
                                "cardNumber": "CTF-18",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "CTF-19": {
                                "cardNumber": "CTF-19",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CTF-20": {
                                "cardNumber": "CTF-20",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CTF-21": {
                                "cardNumber": "CTF-21",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "CTF-22": {
                                "cardNumber": "CTF-22",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CTF-23": {
                                "cardNumber": "CTF-23",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CTF-24": {
                                "cardNumber": "CTF-24",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CTF-25": {
                                "cardNumber": "CTF-25",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            }
                        },
                        "Fantasy Favorites": {
                            "FF-1": {
                                "cardNumber": "FF-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "FF-2": {
                                "cardNumber": "FF-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "FF-3": {
                                "cardNumber": "FF-3",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "FF-4": {
                                "cardNumber": "FF-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "FF-5": {
                                "cardNumber": "FF-5",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FF-6": {
                                "cardNumber": "FF-6",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "FF-7": {
                                "cardNumber": "FF-7",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "FF-8": {
                                "cardNumber": "FF-8",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "FF-9": {
                                "cardNumber": "FF-9",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "FF-10": {
                                "cardNumber": "FF-10",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "FF-11": {
                                "cardNumber": "FF-11",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "FF-12": {
                                "cardNumber": "FF-12",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "FF-13": {
                                "cardNumber": "FF-13",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "FF-14": {
                                "cardNumber": "FF-14",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "FF-15": {
                                "cardNumber": "FF-15",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "FF-16": {
                                "cardNumber": "FF-16",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "FF-17": {
                                "cardNumber": "FF-17",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FF-18": {
                                "cardNumber": "FF-18",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "FF-19": {
                                "cardNumber": "FF-19",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FF-20": {
                                "cardNumber": "FF-20",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "Significant Statistics": {
                            "SS-1": {
                                "cardNumber": "SS-1",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-2": {
                                "cardNumber": "SS-2",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-3": {
                                "cardNumber": "SS-3",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SS-4": {
                                "cardNumber": "SS-4",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SS-5": {
                                "cardNumber": "SS-5",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SS-6": {
                                "cardNumber": "SS-6",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SS-7": {
                                "cardNumber": "SS-7",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SS-8": {
                                "cardNumber": "SS-8",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SS-9": {
                                "cardNumber": "SS-9",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-10": {
                                "cardNumber": "SS-10",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "SS-11": {
                                "cardNumber": "SS-11",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SS-12": {
                                "cardNumber": "SS-12",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "SS-13": {
                                "cardNumber": "SS-13",
                                "name": ["Brenton Doyle"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "SS-14": {
                                "cardNumber": "SS-14",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SS-15": {
                                "cardNumber": "SS-15",
                                "name": ["Brenton Doyle"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "SS-16": {
                                "cardNumber": "SS-16",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "SS-17": {
                                "cardNumber": "SS-17",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SS-18": {
                                "cardNumber": "SS-18",
                                "name": ["Patrick Bailey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "SS-19": {
                                "cardNumber": "SS-19",
                                "name": ["Jhoan Duran"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SS-20": {
                                "cardNumber": "SS-20",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SS-21": {
                                "cardNumber": "SS-21",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "SS-22": {
                                "cardNumber": "SS-22",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-23": {
                                "cardNumber": "SS-23",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "SS-24": {
                                "cardNumber": "SS-24",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SS-25": {
                                "cardNumber": "SS-25",
                                "name": ["Marcus Stroman"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            }
                        },
                        "Heavy Lumber": {
                            "HL-21": {
                                "cardNumber": "HL-21",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HL-22": {
                                "cardNumber": "HL-22",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HL-23": {
                                "cardNumber": "HL-23",
                                "name": ["Jeff Bagwell"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HL-24": {
                                "cardNumber": "HL-24",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "HL-25": {
                                "cardNumber": "HL-25",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "HL-26": {
                                "cardNumber": "HL-26",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "HL-27": {
                                "cardNumber": "HL-27",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HL-28": {
                                "cardNumber": "HL-28",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HL-29": {
                                "cardNumber": "HL-29",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "HL-30": {
                                "cardNumber": "HL-30",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "HL-31": {
                                "cardNumber": "HL-31",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HL-32": {
                                "cardNumber": "HL-32",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HL-33": {
                                "cardNumber": "HL-33",
                                "name": ["Mike Piazza"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "HL-34": {
                                "cardNumber": "HL-34",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HL-35": {
                                "cardNumber": "HL-35",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HL-36": {
                                "cardNumber": "HL-36",
                                "name": ["Ryne Sandberg"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "HL-37": {
                                "cardNumber": "HL-37",
                                "name": ["Mike Schmidt"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "HL-38": {
                                "cardNumber": "HL-38",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "HL-39": {
                                "cardNumber": "HL-39",
                                "name": ["Sammy Sosa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "HL-40": {
                                "cardNumber": "HL-40",
                                "name": ["Frank Thomas"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "Homefield Advantage": {
                            "HFA-11": {
                                "cardNumber": "HFA-11",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HFA-12": {
                                "cardNumber": "HFA-12",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-13": {
                                "cardNumber": "HFA-13",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-14": {
                                "cardNumber": "HFA-14",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-15": {
                                "cardNumber": "HFA-15",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-16": {
                                "cardNumber": "HFA-16",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-17": {
                                "cardNumber": "HFA-17",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "HFA-18": {
                                "cardNumber": "HFA-18",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-19": {
                                "cardNumber": "HFA-19",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "HFA-20": {
                                "cardNumber": "HFA-20",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Legendary Homefield Advantage": {
                            "LHA-6": {
                                "cardNumber": "LHA-6",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "LHA-7": {
                                "cardNumber": "LHA-7",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "LHA-8": {
                                "cardNumber": "LHA-8",
                                "name": ["Nolan Ryan"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "LHA-9": {
                                "cardNumber": "LHA-9",
                                "name": ["Wade Boggs"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "LHA-10": {
                                "cardNumber": "LHA-10",
                                "name": ["Ty Cobb"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            }
                        },
                        "Platinum Performers": {
                            "PP-1": {
                                "cardNumber": "PP-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "PP-2": {
                                "cardNumber": "PP-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "PP-3": {
                                "cardNumber": "PP-3",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-4": {
                                "cardNumber": "PP-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "PP-5": {
                                "cardNumber": "PP-5",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "PP-6": {
                                "cardNumber": "PP-6",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-7": {
                                "cardNumber": "PP-7",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-8": {
                                "cardNumber": "PP-8",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-9": {
                                "cardNumber": "PP-9",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-10": {
                                "cardNumber": "PP-10",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-11": {
                                "cardNumber": "PP-11",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "PP-12": {
                                "cardNumber": "PP-12",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "PP-13": {
                                "cardNumber": "PP-13",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "PP-14": {
                                "cardNumber": "PP-14",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "PP-15": {
                                "cardNumber": "PP-15",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-16": {
                                "cardNumber": "PP-16",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "PP-17": {
                                "cardNumber": "PP-17",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-18": {
                                "cardNumber": "PP-18",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "PP-19": {
                                "cardNumber": "PP-19",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "PP-20": {
                                "cardNumber": "PP-20",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "PP-21": {
                                "cardNumber": "PP-21",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-22": {
                                "cardNumber": "PP-22",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-23": {
                                "cardNumber": "PP-23",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "PP-24": {
                                "cardNumber": "PP-24",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "PP-25": {
                                "cardNumber": "PP-25",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "PP-26": {
                                "cardNumber": "PP-26",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-27": {
                                "cardNumber": "PP-27",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-28": {
                                "cardNumber": "PP-28",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "PP-29": {
                                "cardNumber": "PP-29",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "PP-30": {
                                "cardNumber": "PP-30",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps All Star Baseball": {
                            "89ASB-1": {
                                "cardNumber": "89ASB-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89ASB-2": {
                                "cardNumber": "89ASB-2",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-3": {
                                "cardNumber": "89ASB-3",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-4": {
                                "cardNumber": "89ASB-4",
                                "name": ["Willie Mays"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89ASB-5": {
                                "cardNumber": "89ASB-5",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASB-6": {
                                "cardNumber": "89ASB-6",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-7": {
                                "cardNumber": "89ASB-7",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-8": {
                                "cardNumber": "89ASB-8",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89ASB-9": {
                                "cardNumber": "89ASB-9",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "89ASB-10": {
                                "cardNumber": "89ASB-10",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASB-11": {
                                "cardNumber": "89ASB-11",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-12": {
                                "cardNumber": "89ASB-12",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-13": {
                                "cardNumber": "89ASB-13",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASB-14": {
                                "cardNumber": "89ASB-14",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-15": {
                                "cardNumber": "89ASB-15",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-16": {
                                "cardNumber": "89ASB-16",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASB-17": {
                                "cardNumber": "89ASB-17",
                                "name": ["Babe Ruth"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-18": {
                                "cardNumber": "89ASB-18",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89ASB-19": {
                                "cardNumber": "89ASB-19",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89ASB-20": {
                                "cardNumber": "89ASB-20",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89ASB-21": {
                                "cardNumber": "89ASB-21",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASB-22": {
                                "cardNumber": "89ASB-22",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASB-23": {
                                "cardNumber": "89ASB-23",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASB-24": {
                                "cardNumber": "89ASB-24",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASB-25": {
                                "cardNumber": "89ASB-25",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-26": {
                                "cardNumber": "89ASB-26",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89ASB-27": {
                                "cardNumber": "89ASB-27",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASB-28": {
                                "cardNumber": "89ASB-28",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-29": {
                                "cardNumber": "89ASB-29",
                                "name": ["Nolan Ryan"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASB-30": {
                                "cardNumber": "89ASB-30",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89ASB-31": {
                                "cardNumber": "89ASB-31",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASB-32": {
                                "cardNumber": "89ASB-32",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-33": {
                                "cardNumber": "89ASB-33",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89ASB-34": {
                                "cardNumber": "89ASB-34",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASB-35": {
                                "cardNumber": "89ASB-35",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89ASB-36": {
                                "cardNumber": "89ASB-36",
                                "name": ["Bo Jackson"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89ASB-37": {
                                "cardNumber": "89ASB-37",
                                "name": ["Mark McGwire"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89ASB-38": {
                                "cardNumber": "89ASB-38",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASB-39": {
                                "cardNumber": "89ASB-39",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89ASB-40": {
                                "cardNumber": "89ASB-40",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASB-41": {
                                "cardNumber": "89ASB-41",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-42": {
                                "cardNumber": "89ASB-42",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "89ASB-43": {
                                "cardNumber": "89ASB-43",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASB-44": {
                                "cardNumber": "89ASB-44",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "89ASB-45": {
                                "cardNumber": "89ASB-45",
                                "name": ["Satchel Paige"],
                                "team": ["St. Louis Browns™"],
                                "cardAttributes": []
                            },
                            "89ASB-46": {
                                "cardNumber": "89ASB-46",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASB-47": {
                                "cardNumber": "89ASB-47",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-48": {
                                "cardNumber": "89ASB-48",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-49": {
                                "cardNumber": "89ASB-49",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-50": {
                                "cardNumber": "89ASB-50",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball": {
                            "89B2-1": {
                                "cardNumber": "89B2-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89B2-2": {
                                "cardNumber": "89B2-2",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-3": {
                                "cardNumber": "89B2-3",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-4": {
                                "cardNumber": "89B2-4",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-5": {
                                "cardNumber": "89B2-5",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-6": {
                                "cardNumber": "89B2-6",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89B2-7": {
                                "cardNumber": "89B2-7",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-8": {
                                "cardNumber": "89B2-8",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89B2-9": {
                                "cardNumber": "89B2-9",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89B2-10": {
                                "cardNumber": "89B2-10",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89B2-11": {
                                "cardNumber": "89B2-11",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89B2-12": {
                                "cardNumber": "89B2-12",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89B2-13": {
                                "cardNumber": "89B2-13",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89B2-14": {
                                "cardNumber": "89B2-14",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-15": {
                                "cardNumber": "89B2-15",
                                "name": ["David Wright"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89B2-16": {
                                "cardNumber": "89B2-16",
                                "name": ["Ha-Seong Kim"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89B2-17": {
                                "cardNumber": "89B2-17",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89B2-18": {
                                "cardNumber": "89B2-18",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-19": {
                                "cardNumber": "89B2-19",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89B2-20": {
                                "cardNumber": "89B2-20",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89B2-21": {
                                "cardNumber": "89B2-21",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-22": {
                                "cardNumber": "89B2-22",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89B2-23": {
                                "cardNumber": "89B2-23",
                                "name": ["Prince Fielder"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89B2-24": {
                                "cardNumber": "89B2-24",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "89B2-25": {
                                "cardNumber": "89B2-25",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89B2-26": {
                                "cardNumber": "89B2-26",
                                "name": ["Kirby Puckett"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "89B2-27": {
                                "cardNumber": "89B2-27",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-28": {
                                "cardNumber": "89B2-28",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89B2-29": {
                                "cardNumber": "89B2-29",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-30": {
                                "cardNumber": "89B2-30",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-31": {
                                "cardNumber": "89B2-31",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89B2-32": {
                                "cardNumber": "89B2-32",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89B2-33": {
                                "cardNumber": "89B2-33",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-34": {
                                "cardNumber": "89B2-34",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-35": {
                                "cardNumber": "89B2-35",
                                "name": ["Randy Johnson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89B2-36": {
                                "cardNumber": "89B2-36",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-37": {
                                "cardNumber": "89B2-37",
                                "name": ["Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89B2-38": {
                                "cardNumber": "89B2-38",
                                "name": ["Matt McLain"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89B2-39": {
                                "cardNumber": "89B2-39",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-40": {
                                "cardNumber": "89B2-40",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-41": {
                                "cardNumber": "89B2-41",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-42": {
                                "cardNumber": "89B2-42",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89B2-43": {
                                "cardNumber": "89B2-43",
                                "name": ["Mike Piazza"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89B2-44": {
                                "cardNumber": "89B2-44",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "89B2-45": {
                                "cardNumber": "89B2-45",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-46": {
                                "cardNumber": "89B2-46",
                                "name": ["Triston McKenzie"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "89B2-47": {
                                "cardNumber": "89B2-47",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89B2-48": {
                                "cardNumber": "89B2-48",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-49": {
                                "cardNumber": "89B2-49",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89B2-50": {
                                "cardNumber": "89B2-50",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Chrome": {
                            "2T89C-1": {
                                "cardNumber": "2T89C-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "2T89C-2": {
                                "cardNumber": "2T89C-2",
                                "name": ["Ke'Bryan Hayes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "2T89C-3": {
                                "cardNumber": "2T89C-3",
                                "name": ["Dennis Eckersley"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "2T89C-4": {
                                "cardNumber": "2T89C-4",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "2T89C-5": {
                                "cardNumber": "2T89C-5",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "2T89C-6": {
                                "cardNumber": "2T89C-6",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "2T89C-7": {
                                "cardNumber": "2T89C-7",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-8": {
                                "cardNumber": "2T89C-8",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-9": {
                                "cardNumber": "2T89C-9",
                                "name": ["Emerson Hancock"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-10": {
                                "cardNumber": "2T89C-10",
                                "name": ["Greg Maddux"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2T89C-11": {
                                "cardNumber": "2T89C-11",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "2T89C-12": {
                                "cardNumber": "2T89C-12",
                                "name": ["Max Scherzer"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "2T89C-13": {
                                "cardNumber": "2T89C-13",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-14": {
                                "cardNumber": "2T89C-14",
                                "name": ["Johnny Bench"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "2T89C-15": {
                                "cardNumber": "2T89C-15",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-16": {
                                "cardNumber": "2T89C-16",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "2T89C-17": {
                                "cardNumber": "2T89C-17",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "2T89C-18": {
                                "cardNumber": "2T89C-18",
                                "name": ["Johan Rojas"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-19": {
                                "cardNumber": "2T89C-19",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2T89C-20": {
                                "cardNumber": "2T89C-20",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "2T89C-21": {
                                "cardNumber": "2T89C-21",
                                "name": ["Nathan Eovaldi"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "2T89C-22": {
                                "cardNumber": "2T89C-22",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "2T89C-23": {
                                "cardNumber": "2T89C-23",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "2T89C-24": {
                                "cardNumber": "2T89C-24",
                                "name": ["Andrés Giménez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "2T89C-25": {
                                "cardNumber": "2T89C-25",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-26": {
                                "cardNumber": "2T89C-26",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-27": {
                                "cardNumber": "2T89C-27",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-28": {
                                "cardNumber": "2T89C-28",
                                "name": ["Nick Gonzales"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-29": {
                                "cardNumber": "2T89C-29",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "2T89C-30": {
                                "cardNumber": "2T89C-30",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-31": {
                                "cardNumber": "2T89C-31",
                                "name": ["Fred McGriff"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "2T89C-32": {
                                "cardNumber": "2T89C-32",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "2T89C-33": {
                                "cardNumber": "2T89C-33",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-34": {
                                "cardNumber": "2T89C-34",
                                "name": ["Shane Bieber"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "2T89C-35": {
                                "cardNumber": "2T89C-35",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "2T89C-36": {
                                "cardNumber": "2T89C-36",
                                "name": ["Mike Mussina"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-37": {
                                "cardNumber": "2T89C-37",
                                "name": ["Parker Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-38": {
                                "cardNumber": "2T89C-38",
                                "name": ["Kyren Paris"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-39": {
                                "cardNumber": "2T89C-39",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-40": {
                                "cardNumber": "2T89C-40",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "2T89C-41": {
                                "cardNumber": "2T89C-41",
                                "name": ["Edgar Martinez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "2T89C-42": {
                                "cardNumber": "2T89C-42",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "2T89C-43": {
                                "cardNumber": "2T89C-43",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-44": {
                                "cardNumber": "2T89C-44",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-45": {
                                "cardNumber": "2T89C-45",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-46": {
                                "cardNumber": "2T89C-46",
                                "name": ["Jose Canseco"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "2T89C-47": {
                                "cardNumber": "2T89C-47",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-48": {
                                "cardNumber": "2T89C-48",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-49": {
                                "cardNumber": "2T89C-49",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-50": {
                                "cardNumber": "2T89C-50",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-51": {
                                "cardNumber": "2T89C-51",
                                "name": ["Dale Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2T89C-52": {
                                "cardNumber": "2T89C-52",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-53": {
                                "cardNumber": "2T89C-53",
                                "name": ["Wade Meckler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-54": {
                                "cardNumber": "2T89C-54",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "2T89C-55": {
                                "cardNumber": "2T89C-55",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "2T89C-56": {
                                "cardNumber": "2T89C-56",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2T89C-57": {
                                "cardNumber": "2T89C-57",
                                "name": ["Mike Piazza"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "2T89C-58": {
                                "cardNumber": "2T89C-58",
                                "name": ["Dominic Canzone"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-59": {
                                "cardNumber": "2T89C-59",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-60": {
                                "cardNumber": "2T89C-60",
                                "name": ["Paul Molitor"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "2T89C-61": {
                                "cardNumber": "2T89C-61",
                                "name": ["Matt McLain"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "2T89C-62": {
                                "cardNumber": "2T89C-62",
                                "name": ["Eddie Murray"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-63": {
                                "cardNumber": "2T89C-63",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-64": {
                                "cardNumber": "2T89C-64",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-65": {
                                "cardNumber": "2T89C-65",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "2T89C-66": {
                                "cardNumber": "2T89C-66",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-67": {
                                "cardNumber": "2T89C-67",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "2T89C-68": {
                                "cardNumber": "2T89C-68",
                                "name": ["Miguel Cabrera"],
                                "team": ["Florida Marlins™"],
                                "cardAttributes": []
                            },
                            "2T89C-69": {
                                "cardNumber": "2T89C-69",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-70": {
                                "cardNumber": "2T89C-70",
                                "name": ["Hanley Ramirez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "2T89C-71": {
                                "cardNumber": "2T89C-71",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "2T89C-72": {
                                "cardNumber": "2T89C-72",
                                "name": ["Prince Fielder"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "2T89C-73": {
                                "cardNumber": "2T89C-73",
                                "name": ["Reese Olson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-74": {
                                "cardNumber": "2T89C-74",
                                "name": ["Quinn Priester"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-75": {
                                "cardNumber": "2T89C-75",
                                "name": ["Triston McKenzie"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "2T89C-76": {
                                "cardNumber": "2T89C-76",
                                "name": ["Jonny Deluca"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-77": {
                                "cardNumber": "2T89C-77",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "2T89C-78": {
                                "cardNumber": "2T89C-78",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-79": {
                                "cardNumber": "2T89C-79",
                                "name": ["Lane Thomas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "2T89C-80": {
                                "cardNumber": "2T89C-80",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "2T89C-81": {
                                "cardNumber": "2T89C-81",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-82": {
                                "cardNumber": "2T89C-82",
                                "name": ["Eury Pérez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "2T89C-83": {
                                "cardNumber": "2T89C-83",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-84": {
                                "cardNumber": "2T89C-84",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "2T89C-85": {
                                "cardNumber": "2T89C-85",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-86": {
                                "cardNumber": "2T89C-86",
                                "name": ["José Rodríguez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-87": {
                                "cardNumber": "2T89C-87",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "2T89C-88": {
                                "cardNumber": "2T89C-88",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "2T89C-89": {
                                "cardNumber": "2T89C-89",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "2T89C-90": {
                                "cardNumber": "2T89C-90",
                                "name": ["Randy Johnson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "2T89C-91": {
                                "cardNumber": "2T89C-91",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "2T89C-92": {
                                "cardNumber": "2T89C-92",
                                "name": ["David Wright"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "2T89C-93": {
                                "cardNumber": "2T89C-93",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "2T89C-94": {
                                "cardNumber": "2T89C-94",
                                "name": ["Roger Clemens"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "2T89C-95": {
                                "cardNumber": "2T89C-95",
                                "name": ["Andre Dawson"],
                                "team": ["Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "2T89C-96": {
                                "cardNumber": "2T89C-96",
                                "name": ["Joe Mauer"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "2T89C-97": {
                                "cardNumber": "2T89C-97",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "2T89C-98": {
                                "cardNumber": "2T89C-98",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "2T89C-99": {
                                "cardNumber": "2T89C-99",
                                "name": ["Jacob Amaya"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-100": {
                                "cardNumber": "2T89C-100",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "Stars of MLB": {
                            "SMLB-31": {
                                "cardNumber": "SMLB-31",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SMLB-32": {
                                "cardNumber": "SMLB-32",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SMLB-33": {
                                "cardNumber": "SMLB-33",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SMLB-34": {
                                "cardNumber": "SMLB-34",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "SMLB-35": {
                                "cardNumber": "SMLB-35",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-36": {
                                "cardNumber": "SMLB-36",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SMLB-37": {
                                "cardNumber": "SMLB-37",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SMLB-38": {
                                "cardNumber": "SMLB-38",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-39": {
                                "cardNumber": "SMLB-39",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "SMLB-40": {
                                "cardNumber": "SMLB-40",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SMLB-41": {
                                "cardNumber": "SMLB-41",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "SMLB-42": {
                                "cardNumber": "SMLB-42",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "SMLB-43": {
                                "cardNumber": "SMLB-43",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SMLB-44": {
                                "cardNumber": "SMLB-44",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "SMLB-45": {
                                "cardNumber": "SMLB-45",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "SMLB-46": {
                                "cardNumber": "SMLB-46",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "SMLB-47": {
                                "cardNumber": "SMLB-47",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-48": {
                                "cardNumber": "SMLB-48",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-49": {
                                "cardNumber": "SMLB-49",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SMLB-50": {
                                "cardNumber": "SMLB-50",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-51": {
                                "cardNumber": "SMLB-51",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "SMLB-52": {
                                "cardNumber": "SMLB-52",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "SMLB-53": {
                                "cardNumber": "SMLB-53",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-54": {
                                "cardNumber": "SMLB-54",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-55": {
                                "cardNumber": "SMLB-55",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SMLB-56": {
                                "cardNumber": "SMLB-56",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-57": {
                                "cardNumber": "SMLB-57",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-58": {
                                "cardNumber": "SMLB-58",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "SMLB-59": {
                                "cardNumber": "SMLB-59",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SMLB-60": {
                                "cardNumber": "SMLB-60",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Chrome Stars of MLB": {
                            "CSMLB-31": {
                                "cardNumber": "CSMLB-31",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CSMLB-32": {
                                "cardNumber": "CSMLB-32",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CSMLB-33": {
                                "cardNumber": "CSMLB-33",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CSMLB-34": {
                                "cardNumber": "CSMLB-34",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "CSMLB-35": {
                                "cardNumber": "CSMLB-35",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-36": {
                                "cardNumber": "CSMLB-36",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CSMLB-37": {
                                "cardNumber": "CSMLB-37",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CSMLB-38": {
                                "cardNumber": "CSMLB-38",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-39": {
                                "cardNumber": "CSMLB-39",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "CSMLB-40": {
                                "cardNumber": "CSMLB-40",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CSMLB-41": {
                                "cardNumber": "CSMLB-41",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "CSMLB-42": {
                                "cardNumber": "CSMLB-42",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "CSMLB-43": {
                                "cardNumber": "CSMLB-43",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CSMLB-44": {
                                "cardNumber": "CSMLB-44",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CSMLB-45": {
                                "cardNumber": "CSMLB-45",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CSMLB-46": {
                                "cardNumber": "CSMLB-46",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "CSMLB-47": {
                                "cardNumber": "CSMLB-47",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-48": {
                                "cardNumber": "CSMLB-48",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-49": {
                                "cardNumber": "CSMLB-49",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "CSMLB-50": {
                                "cardNumber": "CSMLB-50",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-51": {
                                "cardNumber": "CSMLB-51",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "CSMLB-52": {
                                "cardNumber": "CSMLB-52",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "CSMLB-53": {
                                "cardNumber": "CSMLB-53",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-54": {
                                "cardNumber": "CSMLB-54",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-55": {
                                "cardNumber": "CSMLB-55",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CSMLB-56": {
                                "cardNumber": "CSMLB-56",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-57": {
                                "cardNumber": "CSMLB-57",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-58": {
                                "cardNumber": "CSMLB-58",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CSMLB-59": {
                                "cardNumber": "CSMLB-59",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CSMLB-60": {
                                "cardNumber": "CSMLB-60",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "40 70": {
                            "RAJ-1": {
                                "cardNumber": "RAJ-1",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-2": {
                                "cardNumber": "RAJ-2",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-3": {
                                "cardNumber": "RAJ-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-4": {
                                "cardNumber": "RAJ-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-5": {
                                "cardNumber": "RAJ-5",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-6": {
                                "cardNumber": "RAJ-6",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-7": {
                                "cardNumber": "RAJ-7",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-8": {
                                "cardNumber": "RAJ-8",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-9": {
                                "cardNumber": "RAJ-9",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-10": {
                                "cardNumber": "RAJ-10",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-11": {
                                "cardNumber": "RAJ-11",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-12": {
                                "cardNumber": "RAJ-12",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-13": {
                                "cardNumber": "RAJ-13",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-14": {
                                "cardNumber": "RAJ-14",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-15": {
                                "cardNumber": "RAJ-15",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-16": {
                                "cardNumber": "RAJ-16",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-17": {
                                "cardNumber": "RAJ-17",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-18": {
                                "cardNumber": "RAJ-18",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-19": {
                                "cardNumber": "RAJ-19",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJ-20": {
                                "cardNumber": "RAJ-20",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        "Social Media Follow Back Redemption": {
                            "SMFB-AH": {
                                "cardNumber": "SMFB-AH",
                                "name": ["Austin Hays"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SMFB-AR": {
                                "cardNumber": "SMFB-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SMFB-AS": {
                                "cardNumber": "SMFB-AS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-AW": {
                                "cardNumber": "SMFB-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-BS": {
                                "cardNumber": "SMFB-BS",
                                "name": ["Blake Snell"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "SMFB-BW": {
                                "cardNumber": "SMFB-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "SMFB-CC": {
                                "cardNumber": "SMFB-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-CR": {
                                "cardNumber": "SMFB-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-DS": {
                                "cardNumber": "SMFB-DS",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-DV": {
                                "cardNumber": "SMFB-DV",
                                "name": ["Daulton Varsho"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "SMFB-EC": {
                                "cardNumber": "SMFB-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-EP": {
                                "cardNumber": "SMFB-EP",
                                "name": ["Everson Pereira"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-GW": {
                                "cardNumber": "SMFB-GW",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-JC": {
                                "cardNumber": "SMFB-JC",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-KH": {
                                "cardNumber": "SMFB-KH",
                                "name": ["Ke'Bryan Hayes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "SMFB-KHA": {
                                "cardNumber": "SMFB-KHA",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-MW": {
                                "cardNumber": "SMFB-MW",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-PA": {
                                "cardNumber": "SMFB-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "SMFB-PC": {
                                "cardNumber": "SMFB-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-SF": {
                                "cardNumber": "SMFB-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-TS": {
                                "cardNumber": "SMFB-TS",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMFB-ZG": {
                                "cardNumber": "SMFB-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Homerun Challenge Code Cards": {
                            "HRC-1": {
                                "cardNumber": "HRC-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HRC-2": {
                                "cardNumber": "HRC-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "HRC-3": {
                                "cardNumber": "HRC-3",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "HRC-4": {
                                "cardNumber": "HRC-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HRC-5": {
                                "cardNumber": "HRC-5",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HRC-6": {
                                "cardNumber": "HRC-6",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HRC-7": {
                                "cardNumber": "HRC-7",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "HRC-8": {
                                "cardNumber": "HRC-8",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "HRC-9": {
                                "cardNumber": "HRC-9",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "HRC-10": {
                                "cardNumber": "HRC-10",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HRC-11": {
                                "cardNumber": "HRC-11",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HRC-12": {
                                "cardNumber": "HRC-12",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HRC-13": {
                                "cardNumber": "HRC-13",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HRC-14": {
                                "cardNumber": "HRC-14",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "HRC-15": {
                                "cardNumber": "HRC-15",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "HRC-16": {
                                "cardNumber": "HRC-16",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "HRC-17": {
                                "cardNumber": "HRC-17",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HRC-18": {
                                "cardNumber": "HRC-18",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "HRC-19": {
                                "cardNumber": "HRC-19",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HRC-20": {
                                "cardNumber": "HRC-20",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HRC-21": {
                                "cardNumber": "HRC-21",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "HRC-22": {
                                "cardNumber": "HRC-22",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "HRC-23": {
                                "cardNumber": "HRC-23",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HRC-24": {
                                "cardNumber": "HRC-24",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HRC-25": {
                                "cardNumber": "HRC-25",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "HRC-26": {
                                "cardNumber": "HRC-26",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "HRC-27": {
                                "cardNumber": "HRC-27",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "HRC-28": {
                                "cardNumber": "HRC-28",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "HRC-29": {
                                "cardNumber": "HRC-29",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "HRC-30": {
                                "cardNumber": "HRC-30",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Mega Stars": {
                            "TMS2-1": {
                                "cardNumber": "TMS2-1",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TMS2-2": {
                                "cardNumber": "TMS2-2",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TMS2-3": {
                                "cardNumber": "TMS2-3",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TMS2-4": {
                                "cardNumber": "TMS2-4",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "TMS2-5": {
                                "cardNumber": "TMS2-5",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "TMS2-6": {
                                "cardNumber": "TMS2-6",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TMS2-7": {
                                "cardNumber": "TMS2-7",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TMS2-8": {
                                "cardNumber": "TMS2-8",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "TMS2-9": {
                                "cardNumber": "TMS2-9",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "TMS2-10": {
                                "cardNumber": "TMS2-10",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "TMS2-11": {
                                "cardNumber": "TMS2-11",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "TMS2-12": {
                                "cardNumber": "TMS2-12",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TMS2-13": {
                                "cardNumber": "TMS2-13",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TMS2-14": {
                                "cardNumber": "TMS2-14",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TMS2-15": {
                                "cardNumber": "TMS2-15",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "TMS2-16": {
                                "cardNumber": "TMS2-16",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "TMS2-17": {
                                "cardNumber": "TMS2-17",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TMS2-18": {
                                "cardNumber": "TMS2-18",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "TMS2-19": {
                                "cardNumber": "TMS2-19",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TMS2-20": {
                                "cardNumber": "TMS2-20",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "Companion Cards": {
                            "COTB2-1": {
                                "cardNumber": "COTB2-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "COTB2-2": {
                                "cardNumber": "COTB2-2",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "COTB2-3": {
                                "cardNumber": "COTB2-3",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "COTB2-4": {
                                "cardNumber": "COTB2-4",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "COTB2-5": {
                                "cardNumber": "COTB2-5",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "COTB2-6": {
                                "cardNumber": "COTB2-6",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "COTB2-7": {
                                "cardNumber": "COTB2-7",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "COTB2-8": {
                                "cardNumber": "COTB2-8",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "COTB2-9": {
                                "cardNumber": "COTB2-9",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "COTB2-10": {
                                "cardNumber": "COTB2-10",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "COTB2-11": {
                                "cardNumber": "COTB2-11",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "COTB2-12": {
                                "cardNumber": "COTB2-12",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "COTB2-13": {
                                "cardNumber": "COTB2-13",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "COTB2-14": {
                                "cardNumber": "COTB2-14",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "COTB2-15": {
                                "cardNumber": "COTB2-15",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "COTB2-16": {
                                "cardNumber": "COTB2-16",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-17": {
                                "cardNumber": "COTB2-17",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-18": {
                                "cardNumber": "COTB2-18",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "COTB2-19": {
                                "cardNumber": "COTB2-19",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-20": {
                                "cardNumber": "COTB2-20",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-21": {
                                "cardNumber": "COTB2-21",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-22": {
                                "cardNumber": "COTB2-22",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-23": {
                                "cardNumber": "COTB2-23",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-24": {
                                "cardNumber": "COTB2-24",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "COTB2-25": {
                                "cardNumber": "COTB2-25",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Oversized 2024 Topps Baseball": {
                            "OTB2-1": {
                                "cardNumber": "OTB2-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "OTB2-2": {
                                "cardNumber": "OTB2-2",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "OTB2-3": {
                                "cardNumber": "OTB2-3",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "OTB2-4": {
                                "cardNumber": "OTB2-4",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "OTB2-5": {
                                "cardNumber": "OTB2-5",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OTB2-6": {
                                "cardNumber": "OTB2-6",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTB2-7": {
                                "cardNumber": "OTB2-7",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTB2-8": {
                                "cardNumber": "OTB2-8",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "OTB2-9": {
                                "cardNumber": "OTB2-9",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "OTB2-10": {
                                "cardNumber": "OTB2-10",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "OTB2-11": {
                                "cardNumber": "OTB2-11",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "OTB2-12": {
                                "cardNumber": "OTB2-12",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTB2-13": {
                                "cardNumber": "OTB2-13",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTB2-14": {
                                "cardNumber": "OTB2-14",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "OTB2-15": {
                                "cardNumber": "OTB2-15",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "OTB2-16": {
                                "cardNumber": "OTB2-16",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-17": {
                                "cardNumber": "OTB2-17",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-18": {
                                "cardNumber": "OTB2-18",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "OTB2-19": {
                                "cardNumber": "OTB2-19",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-20": {
                                "cardNumber": "OTB2-20",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-21": {
                                "cardNumber": "OTB2-21",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-22": {
                                "cardNumber": "OTB2-22",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-23": {
                                "cardNumber": "OTB2-23",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-24": {
                                "cardNumber": "OTB2-24",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "OTB2-25": {
                                "cardNumber": "OTB2-25",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "1989 Topps All Stars Baseball Foilboard Mega Box Cards": {
                            "89ASB-1": {
                                "cardNumber": "89ASB-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89ASB-2": {
                                "cardNumber": "89ASB-2",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-3": {
                                "cardNumber": "89ASB-3",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-4": {
                                "cardNumber": "89ASB-4",
                                "name": ["Willie Mays"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89ASB-5": {
                                "cardNumber": "89ASB-5",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASB-6": {
                                "cardNumber": "89ASB-6",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-7": {
                                "cardNumber": "89ASB-7",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-8": {
                                "cardNumber": "89ASB-8",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89ASB-9": {
                                "cardNumber": "89ASB-9",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "89ASB-10": {
                                "cardNumber": "89ASB-10",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASB-11": {
                                "cardNumber": "89ASB-11",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-12": {
                                "cardNumber": "89ASB-12",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-13": {
                                "cardNumber": "89ASB-13",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASB-14": {
                                "cardNumber": "89ASB-14",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-15": {
                                "cardNumber": "89ASB-15",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-16": {
                                "cardNumber": "89ASB-16",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASB-17": {
                                "cardNumber": "89ASB-17",
                                "name": ["Babe Ruth"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-18": {
                                "cardNumber": "89ASB-18",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89ASB-19": {
                                "cardNumber": "89ASB-19",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89ASB-20": {
                                "cardNumber": "89ASB-20",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89ASB-21": {
                                "cardNumber": "89ASB-21",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASB-22": {
                                "cardNumber": "89ASB-22",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASB-23": {
                                "cardNumber": "89ASB-23",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASB-24": {
                                "cardNumber": "89ASB-24",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASB-25": {
                                "cardNumber": "89ASB-25",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-26": {
                                "cardNumber": "89ASB-26",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89ASB-27": {
                                "cardNumber": "89ASB-27",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASB-28": {
                                "cardNumber": "89ASB-28",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-29": {
                                "cardNumber": "89ASB-29",
                                "name": ["Nolan Ryan"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASB-30": {
                                "cardNumber": "89ASB-30",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89ASB-31": {
                                "cardNumber": "89ASB-31",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASB-32": {
                                "cardNumber": "89ASB-32",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-33": {
                                "cardNumber": "89ASB-33",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89ASB-34": {
                                "cardNumber": "89ASB-34",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASB-35": {
                                "cardNumber": "89ASB-35",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89ASB-36": {
                                "cardNumber": "89ASB-36",
                                "name": ["Bo Jackson"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89ASB-37": {
                                "cardNumber": "89ASB-37",
                                "name": ["Mark McGwire"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89ASB-38": {
                                "cardNumber": "89ASB-38",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASB-39": {
                                "cardNumber": "89ASB-39",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89ASB-40": {
                                "cardNumber": "89ASB-40",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASB-41": {
                                "cardNumber": "89ASB-41",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASB-42": {
                                "cardNumber": "89ASB-42",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "89ASB-43": {
                                "cardNumber": "89ASB-43",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASB-44": {
                                "cardNumber": "89ASB-44",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "89ASB-45": {
                                "cardNumber": "89ASB-45",
                                "name": ["Satchel Paige"],
                                "team": ["St. Louis Browns™"],
                                "cardAttributes": []
                            },
                            "89ASB-46": {
                                "cardNumber": "89ASB-46",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASB-47": {
                                "cardNumber": "89ASB-47",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-48": {
                                "cardNumber": "89ASB-48",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASB-49": {
                                "cardNumber": "89ASB-49",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASB-50": {
                                "cardNumber": "89ASB-50",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Foilboard Mega Box Cards": {
                            "89B2-1": {
                                "cardNumber": "89B2-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89B2-2": {
                                "cardNumber": "89B2-2",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-3": {
                                "cardNumber": "89B2-3",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-4": {
                                "cardNumber": "89B2-4",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-5": {
                                "cardNumber": "89B2-5",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-6": {
                                "cardNumber": "89B2-6",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89B2-7": {
                                "cardNumber": "89B2-7",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-8": {
                                "cardNumber": "89B2-8",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89B2-9": {
                                "cardNumber": "89B2-9",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89B2-10": {
                                "cardNumber": "89B2-10",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89B2-11": {
                                "cardNumber": "89B2-11",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89B2-12": {
                                "cardNumber": "89B2-12",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89B2-13": {
                                "cardNumber": "89B2-13",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89B2-14": {
                                "cardNumber": "89B2-14",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-15": {
                                "cardNumber": "89B2-15",
                                "name": ["David Wright"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89B2-16": {
                                "cardNumber": "89B2-16",
                                "name": ["Ha-Seong Kim"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89B2-17": {
                                "cardNumber": "89B2-17",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89B2-18": {
                                "cardNumber": "89B2-18",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-19": {
                                "cardNumber": "89B2-19",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89B2-20": {
                                "cardNumber": "89B2-20",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89B2-21": {
                                "cardNumber": "89B2-21",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-22": {
                                "cardNumber": "89B2-22",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89B2-23": {
                                "cardNumber": "89B2-23",
                                "name": ["Prince Fielder"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89B2-24": {
                                "cardNumber": "89B2-24",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "89B2-25": {
                                "cardNumber": "89B2-25",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89B2-26": {
                                "cardNumber": "89B2-26",
                                "name": ["Kirby Puckett"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "89B2-27": {
                                "cardNumber": "89B2-27",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-28": {
                                "cardNumber": "89B2-28",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89B2-29": {
                                "cardNumber": "89B2-29",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-30": {
                                "cardNumber": "89B2-30",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89B2-31": {
                                "cardNumber": "89B2-31",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89B2-32": {
                                "cardNumber": "89B2-32",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89B2-33": {
                                "cardNumber": "89B2-33",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-34": {
                                "cardNumber": "89B2-34",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-35": {
                                "cardNumber": "89B2-35",
                                "name": ["Randy Johnson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89B2-36": {
                                "cardNumber": "89B2-36",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-37": {
                                "cardNumber": "89B2-37",
                                "name": ["Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89B2-38": {
                                "cardNumber": "89B2-38",
                                "name": ["Matt McLain"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89B2-39": {
                                "cardNumber": "89B2-39",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89B2-40": {
                                "cardNumber": "89B2-40",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-41": {
                                "cardNumber": "89B2-41",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-42": {
                                "cardNumber": "89B2-42",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89B2-43": {
                                "cardNumber": "89B2-43",
                                "name": ["Mike Piazza"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89B2-44": {
                                "cardNumber": "89B2-44",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "89B2-45": {
                                "cardNumber": "89B2-45",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-46": {
                                "cardNumber": "89B2-46",
                                "name": ["Triston McKenzie"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "89B2-47": {
                                "cardNumber": "89B2-47",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89B2-48": {
                                "cardNumber": "89B2-48",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B2-49": {
                                "cardNumber": "89B2-49",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89B2-50": {
                                "cardNumber": "89B2-50",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "2023 First Pitch": {
                            "FP-1": {
                                "cardNumber": "FP-1",
                                "name": ["Victor Wembanyama"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FP-2": {
                                "cardNumber": "FP-2",
                                "name": ["Myke Towers"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "FP-3": {
                                "cardNumber": "FP-3",
                                "name": ["Mallory Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "FP-4": {
                                "cardNumber": "FP-4",
                                "name": ["Daddy Yankee"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "FP-5": {
                                "cardNumber": "FP-5",
                                "name": ["George W. Bush"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            }
                        },
                        "2023 Play Ball": {
                            "PB-1": {
                                "cardNumber": "PB-1",
                                "name": ["CJ Stroud"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Relic": {
                        "Major League Material Cards": {
                            "MLM2-AB": {
                                "cardNumber": "MLM2-AB",
                                "name": ["Alec Bohm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLM2-ABE": {
                                "cardNumber": "MLM2-ABE",
                                "name": ["Andrew Benintendi"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLM2-AR": {
                                "cardNumber": "MLM2-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM2-BC": {
                                "cardNumber": "MLM2-BC",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLM2-CA": {
                                "cardNumber": "MLM2-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "MLM2-CB": {
                                "cardNumber": "MLM2-CB",
                                "name": ["Charlie Blackmon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "MLM2-CMO": {
                                "cardNumber": "MLM2-CMO",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLM2-CMU": {
                                "cardNumber": "MLM2-CMU",
                                "name": ["Cedric Mullins"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MLM2-CRA": {
                                "cardNumber": "MLM2-CRA",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MLM2-GH": {
                                "cardNumber": "MLM2-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MLM2-GM": {
                                "cardNumber": "MLM2-GM",
                                "name": ["Garrett Mitchell"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "MLM2-GMO": {
                                "cardNumber": "MLM2-GMO",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLM2-HB": {
                                "cardNumber": "MLM2-HB",
                                "name": ["Hunter Brown"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLM2-JAB": {
                                "cardNumber": "MLM2-JAB",
                                "name": ["José Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLM2-JCR": {
                                "cardNumber": "MLM2-JCR",
                                "name": ["Jake Cronenworth"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLM2-JDU": {
                                "cardNumber": "MLM2-JDU",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "MLM2-JJ": {
                                "cardNumber": "MLM2-JJ",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MLM2-JO": {
                                "cardNumber": "MLM2-JO",
                                "name": ["James Outman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLM2-JTR": {
                                "cardNumber": "MLM2-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLM2-JWA": {
                                "cardNumber": "MLM2-JWA",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MLM2-KG": {
                                "cardNumber": "MLM2-KG",
                                "name": ["Kevin Gausman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLM2-KM": {
                                "cardNumber": "MLM2-KM",
                                "name": ["Ketel Marte"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLM2-LR": {
                                "cardNumber": "MLM2-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLM2-MH": {
                                "cardNumber": "MLM2-MH",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLM2-MTR": {
                                "cardNumber": "MLM2-MTR",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLM2-MY": {
                                "cardNumber": "MLM2-MY",
                                "name": ["Mike Yastrzemski"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLM2-NC": {
                                "cardNumber": "MLM2-NC",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLM2-NG": {
                                "cardNumber": "MLM2-NG",
                                "name": ["Nolan Gorman"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MLM2-OC": {
                                "cardNumber": "MLM2-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLM2-RA": {
                                "cardNumber": "MLM2-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "MLM2-RAJ": {
                                "cardNumber": "MLM2-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLM2-RG": {
                                "cardNumber": "MLM2-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "MLM2-RL": {
                                "cardNumber": "MLM2-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLM2-SA": {
                                "cardNumber": "MLM2-SA",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "MLM2-SM": {
                                "cardNumber": "MLM2-SM",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MLM2-SS": {
                                "cardNumber": "MLM2-SS",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLM2-VG": {
                                "cardNumber": "MLM2-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLM2-WA": {
                                "cardNumber": "MLM2-WA",
                                "name": ["Willy Adames"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "MLM2-WC": {
                                "cardNumber": "MLM2-WC",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "MLM2-WS": {
                                "cardNumber": "MLM2-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLM2-XB": {
                                "cardNumber": "MLM2-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLM2-ZGA": {
                                "cardNumber": "MLM2-ZGA",
                                "name": ["Zac Gallen"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Relics": {
                            "89R2-AB": {
                                "cardNumber": "89R2-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89R2-AH": {
                                "cardNumber": "89R2-AH",
                                "name": ["Austin Hays"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89R2-AJ": {
                                "cardNumber": "89R2-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89R2-AM": {
                                "cardNumber": "89R2-AM",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "89R2-AV": {
                                "cardNumber": "89R2-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89R2-BS": {
                                "cardNumber": "89R2-BS",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89R2-BW": {
                                "cardNumber": "89R2-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89R2-CA": {
                                "cardNumber": "89R2-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "89R2-CB": {
                                "cardNumber": "89R2-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89R2-CBE": {
                                "cardNumber": "89R2-CBE",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89R2-CBL": {
                                "cardNumber": "89R2-CBL",
                                "name": ["Charlie Blackmon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "89R2-CC": {
                                "cardNumber": "89R2-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "89R2-CCA": {
                                "cardNumber": "89R2-CCA",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89R2-CY": {
                                "cardNumber": "89R2-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89R2-DJ": {
                                "cardNumber": "89R2-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89R2-EC": {
                                "cardNumber": "89R2-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89R2-EPE": {
                                "cardNumber": "89R2-EPE",
                                "name": ["Eury Pérez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "89R2-FF": {
                                "cardNumber": "89R2-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89R2-FL": {
                                "cardNumber": "89R2-FL",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89R2-GK": {
                                "cardNumber": "89R2-GK",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89R2-I": {
                                "cardNumber": "89R2-I",
                                "name": ["Ichiro"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "89R2-JA": {
                                "cardNumber": "89R2-JA",
                                "name": ["José Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89R2-JB": {
                                "cardNumber": "89R2-JB",
                                "name": ["Javier Báez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89R2-JD": {
                                "cardNumber": "89R2-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89R2-JM": {
                                "cardNumber": "89R2-JM",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89R2-JR": {
                                "cardNumber": "89R2-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89R2-JTR": {
                                "cardNumber": "89R2-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89R2-MC": {
                                "cardNumber": "89R2-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89R2-MM": {
                                "cardNumber": "89R2-MM",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89R2-MMA": {
                                "cardNumber": "89R2-MMA",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89R2-MMU": {
                                "cardNumber": "89R2-MMU",
                                "name": ["Max Muncy"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89R2-MT": {
                                "cardNumber": "89R2-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89R2-NC": {
                                "cardNumber": "89R2-NC",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89R2-NH": {
                                "cardNumber": "89R2-NH",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89R2-PA": {
                                "cardNumber": "89R2-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89R2-PG": {
                                "cardNumber": "89R2-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89R2-RA": {
                                "cardNumber": "89R2-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89R2-SP": {
                                "cardNumber": "89R2-SP",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89R2-ST": {
                                "cardNumber": "89R2-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89R2-TA": {
                                "cardNumber": "89R2-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89R2-TC": {
                                "cardNumber": "89R2-TC",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89R2-TM": {
                                "cardNumber": "89R2-TM",
                                "name": ["Triston McKenzie"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "89R2-VG": {
                                "cardNumber": "89R2-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89R2-YA": {
                                "cardNumber": "89R2-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89R2-YD": {
                                "cardNumber": "89R2-YD",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball All Stars Relics": {
                            "89ASR-AB": {
                                "cardNumber": "89ASR-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASR-AJ": {
                                "cardNumber": "89ASR-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-AM": {
                                "cardNumber": "89ASR-AM",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "89ASR-AP": {
                                "cardNumber": "89ASR-AP",
                                "name": ["Andy Pettitte"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-APU": {
                                "cardNumber": "89ASR-APU",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASR-AR": {
                                "cardNumber": "89ASR-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-AROD": {
                                "cardNumber": "89ASR-AROD",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-ARU": {
                                "cardNumber": "89ASR-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASR-BH": {
                                "cardNumber": "89ASR-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASR-BP": {
                                "cardNumber": "89ASR-BP",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89ASR-CC": {
                                "cardNumber": "89ASR-CC",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89ASR-CJ": {
                                "cardNumber": "89ASR-CJ",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASR-CR": {
                                "cardNumber": "89ASR-CR",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASR-CY": {
                                "cardNumber": "89ASR-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89ASR-DJ": {
                                "cardNumber": "89ASR-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-DO": {
                                "cardNumber": "89ASR-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASR-DS": {
                                "cardNumber": "89ASR-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89ASR-EM": {
                                "cardNumber": "89ASR-EM",
                                "name": ["Eddie Murray"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASR-FF": {
                                "cardNumber": "89ASR-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASR-FT": {
                                "cardNumber": "89ASR-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASR-FTH": {
                                "cardNumber": "89ASR-FTH",
                                "name": ["Frank Thomas"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89ASR-GC": {
                                "cardNumber": "89ASR-GC",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-I": {
                                "cardNumber": "89ASR-I",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASR-IR": {
                                "cardNumber": "89ASR-IR",
                                "name": ["Ivan Rodriguez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89ASR-JA": {
                                "cardNumber": "89ASR-JA",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASR-JR": {
                                "cardNumber": "89ASR-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASR-JRA": {
                                "cardNumber": "89ASR-JRA",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "89ASR-JS": {
                                "cardNumber": "89ASR-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-JV": {
                                "cardNumber": "89ASR-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89ASR-KG": {
                                "cardNumber": "89ASR-KG",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASR-KP": {
                                "cardNumber": "89ASR-KP",
                                "name": ["Kirby Puckett"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "89ASR-LR": {
                                "cardNumber": "89ASR-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89ASR-MB": {
                                "cardNumber": "89ASR-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASR-MC": {
                                "cardNumber": "89ASR-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89ASR-MM": {
                                "cardNumber": "89ASR-MM",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-MMA": {
                                "cardNumber": "89ASR-MMA",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASR-MO": {
                                "cardNumber": "89ASR-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASR-MP": {
                                "cardNumber": "89ASR-MP",
                                "name": ["Mike Piazza"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASR-MR": {
                                "cardNumber": "89ASR-MR",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASR-MS": {
                                "cardNumber": "89ASR-MS",
                                "name": ["Mike Schmidt"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASR-MT": {
                                "cardNumber": "89ASR-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89ASR-NA": {
                                "cardNumber": "89ASR-NA",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASR-PA": {
                                "cardNumber": "89ASR-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89ASR-PG": {
                                "cardNumber": "89ASR-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASR-RA": {
                                "cardNumber": "89ASR-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "89ASR-RAJ": {
                                "cardNumber": "89ASR-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASR-RD": {
                                "cardNumber": "89ASR-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASR-SO": {
                                "cardNumber": "89ASR-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASR-SS": {
                                "cardNumber": "89ASR-SS",
                                "name": ["Sammy Sosa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89ASR-TG": {
                                "cardNumber": "89ASR-TG",
                                "name": ["Tony Gwynn"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASR-TT": {
                                "cardNumber": "89ASR-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASR-VG": {
                                "cardNumber": "89ASR-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89ASR-YA": {
                                "cardNumber": "89ASR-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "Rounding the Bases Relics": {
                            "RTB-AJ": {
                                "cardNumber": "RTB-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "RTB-AR": {
                                "cardNumber": "RTB-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "RTB-ARI": {
                                "cardNumber": "RTB-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RTB-BH": {
                                "cardNumber": "RTB-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "RTB-BN": {
                                "cardNumber": "RTB-BN",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "RTB-COR": {
                                "cardNumber": "RTB-COR",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "RTB-CY": {
                                "cardNumber": "RTB-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "RTB-DS": {
                                "cardNumber": "RTB-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "RTB-FF": {
                                "cardNumber": "RTB-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "RTB-FL": {
                                "cardNumber": "RTB-FL",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "RTB-FT": {
                                "cardNumber": "RTB-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "RTB-JB": {
                                "cardNumber": "RTB-JB",
                                "name": ["Javier Báez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "RTB-JS": {
                                "cardNumber": "RTB-JS",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "RTB-KS": {
                                "cardNumber": "RTB-KS",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "RTB-MB": {
                                "cardNumber": "RTB-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "RTB-MC": {
                                "cardNumber": "RTB-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "RTB-MM": {
                                "cardNumber": "RTB-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "RTB-OA": {
                                "cardNumber": "RTB-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RTB-OCR": {
                                "cardNumber": "RTB-OCR",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "RTB-RA": {
                                "cardNumber": "RTB-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RTB-RAL": {
                                "cardNumber": "RTB-RAL",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "RTB-RD": {
                                "cardNumber": "RTB-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "RTB-SS": {
                                "cardNumber": "RTB-SS",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "RTB-VG": {
                                "cardNumber": "RTB-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "RTB-WS": {
                                "cardNumber": "RTB-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            }
                        },
                        "In the Name Relics": {
                            "ITN2-AB": {
                                "cardNumber": "ITN2-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-AR": {
                                "cardNumber": "ITN2-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-BB": {
                                "cardNumber": "ITN2-BB",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-BC": {
                                "cardNumber": "ITN2-BC",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-BH": {
                                "cardNumber": "ITN2-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-BN": {
                                "cardNumber": "ITN2-BN",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-BR": {
                                "cardNumber": "ITN2-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-BW": {
                                "cardNumber": "ITN2-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-CB": {
                                "cardNumber": "ITN2-CB",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-CBL": {
                                "cardNumber": "ITN2-CBL",
                                "name": ["Charlie Blackmon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-CBU": {
                                "cardNumber": "ITN2-CBU",
                                "name": ["Corbin Burnes"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-CC": {
                                "cardNumber": "ITN2-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-CCA": {
                                "cardNumber": "ITN2-CCA",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-CM": {
                                "cardNumber": "ITN2-CM",
                                "name": ["Cedric Mullins"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-CY": {
                                "cardNumber": "ITN2-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-DS": {
                                "cardNumber": "ITN2-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-EJ": {
                                "cardNumber": "ITN2-EJ",
                                "name": ["Eloy Jiménez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-FL": {
                                "cardNumber": "ITN2-FL",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-GS": {
                                "cardNumber": "ITN2-GS",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-HG": {
                                "cardNumber": "ITN2-HG",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JA": {
                                "cardNumber": "ITN2-JA",
                                "name": ["José Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JAL": {
                                "cardNumber": "ITN2-JAL",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JBA": {
                                "cardNumber": "ITN2-JBA",
                                "name": ["Javier Báez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JC": {
                                "cardNumber": "ITN2-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JI": {
                                "cardNumber": "ITN2-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JM": {
                                "cardNumber": "ITN2-JM",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JR": {
                                "cardNumber": "ITN2-JR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JS": {
                                "cardNumber": "ITN2-JS",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-JV": {
                                "cardNumber": "ITN2-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-KB": {
                                "cardNumber": "ITN2-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-KT": {
                                "cardNumber": "ITN2-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-LA": {
                                "cardNumber": "ITN2-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-MB": {
                                "cardNumber": "ITN2-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-MC": {
                                "cardNumber": "ITN2-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-MF": {
                                "cardNumber": "ITN2-MF",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-MM": {
                                "cardNumber": "ITN2-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-MT": {
                                "cardNumber": "ITN2-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-MY": {
                                "cardNumber": "ITN2-MY",
                                "name": ["Mike Yastrzemski"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-NH": {
                                "cardNumber": "ITN2-NH",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-OA": {
                                "cardNumber": "ITN2-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-PA": {
                                "cardNumber": "ITN2-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-RA": {
                                "cardNumber": "ITN2-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-RAJ": {
                                "cardNumber": "ITN2-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-RD": {
                                "cardNumber": "ITN2-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-RG": {
                                "cardNumber": "ITN2-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-RM": {
                                "cardNumber": "ITN2-RM",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-SA": {
                                "cardNumber": "ITN2-SA",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-SP": {
                                "cardNumber": "ITN2-SP",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-TA": {
                                "cardNumber": "ITN2-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-TS": {
                                "cardNumber": "ITN2-TS",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-VG": {
                                "cardNumber": "ITN2-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-XB": {
                                "cardNumber": "ITN2-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-YA": {
                                "cardNumber": "ITN2-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-YD": {
                                "cardNumber": "ITN2-YD",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["1/1"]
                            },
                            "ITN2-ZW": {
                                "cardNumber": "ITN2-ZW",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            }
                        },
                        "Cut Signature": {
                            "CS2-AK": {
                                "cardNumber": "CS2-AK",
                                "name": ["Al Kaline"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-BD": {
                                "cardNumber": "CS2-BD",
                                "name": ["Bill Dickey"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-BF": {
                                "cardNumber": "CS2-BF",
                                "name": ["Bob Feller"],
                                "team": ["Cleveland"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-BG": {
                                "cardNumber": "CS2-BG",
                                "name": ["Bob Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-BGR": {
                                "cardNumber": "CS2-BGR",
                                "name": ["Burleigh Grimes"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-BH": {
                                "cardNumber": "CS2-BH",
                                "name": ["Billy Herman"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-CG": {
                                "cardNumber": "CS2-CG",
                                "name": ["Charlie Gehringer"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-CY": {
                                "cardNumber": "CS2-CY",
                                "name": ["Cy Young"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-D": {
                                "cardNumber": "CS2-D",
                                "name": ["Joe DiMaggio"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-DD": {
                                "cardNumber": "CS2-DD",
                                "name": ["Don Drysdale"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-DDE": {
                                "cardNumber": "CS2-DDE",
                                "name": ["Dizzy Dean"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-DS": {
                                "cardNumber": "CS2-DS",
                                "name": ["Duke Snider"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-EM": {
                                "cardNumber": "CS2-EM",
                                "name": ["Eddie Mathews"],
                                "team": ["Milwaukee Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-GC": {
                                "cardNumber": "CS2-GC",
                                "name": ["Gary Carter"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-GHP": {
                                "cardNumber": "CS2-GHP",
                                "name": ["George \"High Pockets\" Kelly"],
                                "team": ["New York Giants™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-GK": {
                                "cardNumber": "CS2-GK",
                                "name": ["George Kell"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-HG": {
                                "cardNumber": "CS2-HG",
                                "name": ["Hank Greenberg"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-HK": {
                                "cardNumber": "CS2-HK",
                                "name": ["Harmon Killebrew"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-HW": {
                                "cardNumber": "CS2-HW",
                                "name": ["Hoyt Wilhelm"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-HWA": {
                                "cardNumber": "CS2-HWA",
                                "name": ["Honus Wagner"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-JMI": {
                                "cardNumber": "CS2-JMI",
                                "name": ["Johnny Mize"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-KP": {
                                "cardNumber": "CS2-KP",
                                "name": ["Kirby Puckett"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-LB": {
                                "cardNumber": "CS2-LB",
                                "name": ["Lou Brock"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-LBR": {
                                "cardNumber": "CS2-LBR",
                                "name": ["Lou Boudreau"],
                                "team": ["Cleveland"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-LG": {
                                "cardNumber": "CS2-LG",
                                "name": ["Lefty Grove"],
                                "team": ["Philadelphia Athletics™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-MI": {
                                "cardNumber": "CS2-MI",
                                "name": ["Monte Irvin"],
                                "team": ["New York Giants™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-MM": {
                                "cardNumber": "CS2-MM",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-MO": {
                                "cardNumber": "CS2-MO",
                                "name": ["Mel Ott"],
                                "team": ["New York Giants™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-PWR": {
                                "cardNumber": "CS2-PWR",
                                "name": ["Pee Wee Reese"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-RC": {
                                "cardNumber": "CS2-RC",
                                "name": ["Roy Campanella"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-RF": {
                                "cardNumber": "CS2-RF",
                                "name": ["Rick Ferrell"],
                                "team": ["St. Louis Browns™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-RK": {
                                "cardNumber": "CS2-RK",
                                "name": ["Ralph Kiner"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-RM": {
                                "cardNumber": "CS2-RM",
                                "name": ["Roger Maris"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-RS": {
                                "cardNumber": "CS2-RS",
                                "name": ["Ron Santo"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-SM": {
                                "cardNumber": "CS2-SM",
                                "name": ["Stan Musial"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-TG": {
                                "cardNumber": "CS2-TG",
                                "name": ["Tony Gwynn"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-TM": {
                                "cardNumber": "CS2-TM",
                                "name": ["Thurman Munson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-TW": {
                                "cardNumber": "CS2-TW",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-WF": {
                                "cardNumber": "CS2-WF",
                                "name": ["Whitey Ford"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-WH": {
                                "cardNumber": "CS2-WH",
                                "name": ["Waite Hoyt"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS2-WSP": {
                                "cardNumber": "CS2-WSP",
                                "name": ["Warren Spahn"],
                                "team": ["Milwaukee Braves™"],
                                "cardAttributes": ["1/1"]
                            }
                        },
                        "City Connect Swatch Collection": {
                            "CC2-ARE": {
                                "cardNumber": "CC2-ARE",
                                "name": ["Anthony Rendon"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CC2-BB": {
                                "cardNumber": "CC2-BB",
                                "name": ["Brayan Bello"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CC2-BD": {
                                "cardNumber": "CC2-BD",
                                "name": ["Bryan De La Cruz"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "CC2-BM": {
                                "cardNumber": "CC2-BM",
                                "name": ["Bryce Miller"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CC2-BW": {
                                "cardNumber": "CC2-BW",
                                "name": ["Brandon Woodruff"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "CC2-CB": {
                                "cardNumber": "CC2-CB",
                                "name": ["Charlie Blackmon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC2-CBE": {
                                "cardNumber": "CC2-CBE",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-CY": {
                                "cardNumber": "CC2-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "CC2-DS": {
                                "cardNumber": "CC2-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-ED": {
                                "cardNumber": "CC2-ED",
                                "name": ["Elias Díaz"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC2-ET": {
                                "cardNumber": "CC2-ET",
                                "name": ["Ezequiel Tovar"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC2-FT": {
                                "cardNumber": "CC2-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CC2-HG": {
                                "cardNumber": "CC2-HG",
                                "name": ["Hunter Goodman"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CC2-IH": {
                                "cardNumber": "CC2-IH",
                                "name": ["Ian Happ"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-JA": {
                                "cardNumber": "CC2-JA",
                                "name": ["José Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CC2-JC": {
                                "cardNumber": "CC2-JC",
                                "name": ["Jake Cronenworth"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CC2-JCJ": {
                                "cardNumber": "CC2-JCJ",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "CC2-JH": {
                                "cardNumber": "CC2-JH",
                                "name": ["Josh Hader"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CC2-JS": {
                                "cardNumber": "CC2-JS",
                                "name": ["Justin Steele"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-JSO": {
                                "cardNumber": "CC2-JSO",
                                "name": ["Jorge Soler"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "CC2-JT": {
                                "cardNumber": "CC2-JT",
                                "name": ["Jared Triolo"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CC2-JW": {
                                "cardNumber": "CC2-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CC2-KB": {
                                "cardNumber": "CC2-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC2-KP": {
                                "cardNumber": "CC2-KP",
                                "name": ["Kyren Paris"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CC2-LM": {
                                "cardNumber": "CC2-LM",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CC2-LO": {
                                "cardNumber": "CC2-LO",
                                "name": ["Logan O'Hoppe"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CC2-LU": {
                                "cardNumber": "CC2-LU",
                                "name": ["Luis Urías"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "CC2-MA": {
                                "cardNumber": "CC2-MA",
                                "name": ["Miguel Amaya"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-MB": {
                                "cardNumber": "CC2-MB",
                                "name": ["Michael Brantley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CC2-MC": {
                                "cardNumber": "CC2-MC",
                                "name": ["Michael Conforto"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "CC2-ML": {
                                "cardNumber": "CC2-ML",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CC2-MM": {
                                "cardNumber": "CC2-MM",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "CC2-MMA": {
                                "cardNumber": "CC2-MMA",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CC2-MY": {
                                "cardNumber": "CC2-MY",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CC2-NJ": {
                                "cardNumber": "CC2-NJ",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC2-NM": {
                                "cardNumber": "CC2-NM",
                                "name": ["Nick Madrigal"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-NV": {
                                "cardNumber": "CC2-NV",
                                "name": ["Nelson Velázquez"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-SS": {
                                "cardNumber": "CC2-SS",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CC2-YD": {
                                "cardNumber": "CC2-YD",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CC2-ZN": {
                                "cardNumber": "CC2-ZN",
                                "name": ["Zach Neto"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "2023 Silver Slugger Award Winners Cards": {
                            "SS-AR": {
                                "cardNumber": "SS-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-ARU": {
                                "cardNumber": "SS-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SS-BH": {
                                "cardNumber": "SS-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SS-CB": {
                                "cardNumber": "SS-CB",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "SS-CS": {
                                "cardNumber": "SS-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "SS-FL": {
                                "cardNumber": "SS-FL",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "SS-GH": {
                                "cardNumber": "SS-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SS-JR": {
                                "cardNumber": "SS-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "SS-JS": {
                                "cardNumber": "SS-JS",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "SS-KT": {
                                "cardNumber": "SS-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SS-LA": {
                                "cardNumber": "SS-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "SS-LR": {
                                "cardNumber": "SS-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "SS-MB": {
                                "cardNumber": "SS-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SS-MO": {
                                "cardNumber": "SS-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-MS": {
                                "cardNumber": "SS-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "SS-RA": {
                                "cardNumber": "SS-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-RD": {
                                "cardNumber": "SS-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "SS-SO": {
                                "cardNumber": "SS-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SS-WC": {
                                "cardNumber": "SS-WC",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "SS-YD": {
                                "cardNumber": "SS-YD",
                                "name": ["Yandy Díaz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Autograph Relic": {
                        "Topps Reverence Autograph Patch Cards": {
                            "TRAP2-AJ": {
                                "cardNumber": "TRAP2-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP2-AR": {
                                "cardNumber": "TRAP2-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TRAP2-BH": {
                                "cardNumber": "TRAP2-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TRAP2-BW": {
                                "cardNumber": "TRAP2-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TRAP2-CAR": {
                                "cardNumber": "TRAP2-CAR",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "TRAP2-CJ": {
                                "cardNumber": "TRAP2-CJ",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAP2-CK": {
                                "cardNumber": "TRAP2-CK",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TRAP2-COR": {
                                "cardNumber": "TRAP2-COR",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "TRAP2-CR": {
                                "cardNumber": "TRAP2-CR",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TRAP2-DJ": {
                                "cardNumber": "TRAP2-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP2-ED": {
                                "cardNumber": "TRAP2-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAP2-FT": {
                                "cardNumber": "TRAP2-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "TRAP2-HD": {
                                "cardNumber": "TRAP2-HD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAP2-HK": {
                                "cardNumber": "TRAP2-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAP2-JD": {
                                "cardNumber": "TRAP2-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAP2-JR": {
                                "cardNumber": "TRAP2-JR",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "TRAP2-JROD": {
                                "cardNumber": "TRAP2-JROD",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "TRAP2-JS": {
                                "cardNumber": "TRAP2-JS",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "TRAP2-MT": {
                                "cardNumber": "TRAP2-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "TRAP2-PG": {
                                "cardNumber": "TRAP2-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "TRAP2-RD": {
                                "cardNumber": "TRAP2-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "TRAP2-RIL": {
                                "cardNumber": "TRAP2-RIL",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAP2-VG": {
                                "cardNumber": "TRAP2-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "TRAP-ABR": {
                                "cardNumber": "TRAP-ABR",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "TRAP-AN": {
                                "cardNumber": "TRAP-AN",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TRAP-BWI": {
                                "cardNumber": "TRAP-BWI",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TRAP-CS": {
                                "cardNumber": "TRAP-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "TRAP-JI": {
                                "cardNumber": "TRAP-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "TRAP-JTR": {
                                "cardNumber": "TRAP-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TRAP-JW": {
                                "cardNumber": "TRAP-JW",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "TRAP-KT": {
                                "cardNumber": "TRAP-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "TRAP-LC": {
                                "cardNumber": "TRAP-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "TRAP-MM": {
                                "cardNumber": "TRAP-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "TRAP-MS": {
                                "cardNumber": "TRAP-MS",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TRAP-PA": {
                                "cardNumber": "TRAP-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TRAP-RA": {
                                "cardNumber": "TRAP-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "TRAP-SG": {
                                "cardNumber": "TRAP-SG",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "TRAP-XB": {
                                "cardNumber": "TRAP-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            }
                        },
                        "Major League Material Autograph Cards": {
                            "MLMA2-AJ": {
                                "cardNumber": "MLMA2-AJ",
                                "name": ["Andruw Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMA2-AP": {
                                "cardNumber": "MLMA2-AP",
                                "name": ["Andy Pettitte"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMA2-AR": {
                                "cardNumber": "MLMA2-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MLMA2-AW": {
                                "cardNumber": "MLMA2-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-BAG": {
                                "cardNumber": "MLMA2-BAG",
                                "name": ["Jeff Bagwell"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMA2-BC": {
                                "cardNumber": "MLMA2-BC",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLMA2-BR": {
                                "cardNumber": "MLMA2-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLMA2-BW": {
                                "cardNumber": "MLMA2-BW",
                                "name": ["Bernie Williams"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMA2-BWJ": {
                                "cardNumber": "MLMA2-BWJ",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MLMA2-CAR": {
                                "cardNumber": "MLMA2-CAR",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLMA2-CB": {
                                "cardNumber": "MLMA2-CB",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMA2-CF": {
                                "cardNumber": "MLMA2-CF",
                                "name": ["Cecil Fielder"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "MLMA2-CJ": {
                                "cardNumber": "MLMA2-CJ",
                                "name": ["Cristian Javier"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMA2-CM": {
                                "cardNumber": "MLMA2-CM",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-DL": {
                                "cardNumber": "MLMA2-DL",
                                "name": ["Derrek Lee"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLMA2-DM": {
                                "cardNumber": "MLMA2-DM",
                                "name": ["Dale Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMA2-DP": {
                                "cardNumber": "MLMA2-DP",
                                "name": ["Dustin Pedroia"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA2-DW": {
                                "cardNumber": "MLMA2-DW",
                                "name": ["Dontrelle Willis"],
                                "team": ["Florida Marlins™"],
                                "cardAttributes": []
                            },
                            "MLMA2-ED": {
                                "cardNumber": "MLMA2-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-EP": {
                                "cardNumber": "MLMA2-EP",
                                "name": ["Everson Pereira"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-FP": {
                                "cardNumber": "MLMA2-FP",
                                "name": ["Freddy Peralta"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "MLMA2-FT": {
                                "cardNumber": "MLMA2-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLMA2-GAL": {
                                "cardNumber": "MLMA2-GAL",
                                "name": ["Zac Gallen"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLMA2-GM": {
                                "cardNumber": "MLMA2-GM",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLMA2-GW": {
                                "cardNumber": "MLMA2-GW",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-HAY": {
                                "cardNumber": "MLMA2-HAY",
                                "name": ["Ke'Bryan Hayes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLMA2-HD": {
                                "cardNumber": "MLMA2-HD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-HP": {
                                "cardNumber": "MLMA2-HP",
                                "name": ["Hunter Pence"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLMA2-JB": {
                                "cardNumber": "MLMA2-JB",
                                "name": ["José Berríos"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLMA2-JC": {
                                "cardNumber": "MLMA2-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "MLMA2-JD": {
                                "cardNumber": "MLMA2-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-JG": {
                                "cardNumber": "MLMA2-JG",
                                "name": ["Juan Gonzalez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MLMA2-JM": {
                                "cardNumber": "MLMA2-JM",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLMA2-JMO": {
                                "cardNumber": "MLMA2-JMO",
                                "name": ["Justin Morneau"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLMA2-JR": {
                                "cardNumber": "MLMA2-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MLMA2-JS": {
                                "cardNumber": "MLMA2-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMA2-JV": {
                                "cardNumber": "MLMA2-JV",
                                "name": ["Jason Varitek"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA2-JW": {
                                "cardNumber": "MLMA2-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-KH": {
                                "cardNumber": "MLMA2-KH",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-KT": {
                                "cardNumber": "MLMA2-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMA2-LA": {
                                "cardNumber": "MLMA2-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "MLMA2-LC": {
                                "cardNumber": "MLMA2-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MLMA2-LM": {
                                "cardNumber": "MLMA2-LM",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-MC": {
                                "cardNumber": "MLMA2-MC",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLMA2-MG": {
                                "cardNumber": "MLMA2-MG",
                                "name": ["Mark Grace"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLMA2-MOR": {
                                "cardNumber": "MLMA2-MOR",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLMA2-MS": {
                                "cardNumber": "MLMA2-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MLMA2-MT": {
                                "cardNumber": "MLMA2-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLMA2-MW": {
                                "cardNumber": "MLMA2-MW",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-NC": {
                                "cardNumber": "MLMA2-NC",
                                "name": ["Nestor Cortes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMA2-NH": {
                                "cardNumber": "MLMA2-NH",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLMA2-NJ": {
                                "cardNumber": "MLMA2-NJ",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "MLMA2-NM": {
                                "cardNumber": "MLMA2-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-OS": {
                                "cardNumber": "MLMA2-OS",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MLMA2-PA": {
                                "cardNumber": "MLMA2-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MLMA2-PK": {
                                "cardNumber": "MLMA2-PK",
                                "name": ["Paul Konerko"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA2-PO": {
                                "cardNumber": "MLMA2-PO",
                                "name": ["Paul O'Neill"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMA2-RA": {
                                "cardNumber": "MLMA2-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "MLMA2-RAJ": {
                                "cardNumber": "MLMA2-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMA2-REY": {
                                "cardNumber": "MLMA2-REY",
                                "name": ["José Reyes"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MLMA2-RH": {
                                "cardNumber": "MLMA2-RH",
                                "name": ["Ryan Howard"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLMA2-RIC": {
                                "cardNumber": "MLMA2-RIC",
                                "name": ["Jim Rice"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA2-RM": {
                                "cardNumber": "MLMA2-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-SF": {
                                "cardNumber": "MLMA2-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-SG": {
                                "cardNumber": "MLMA2-SG",
                                "name": ["Steve Garvey"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLMA2-SM": {
                                "cardNumber": "MLMA2-SM",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MLMA2-SMO": {
                                "cardNumber": "MLMA2-SMO",
                                "name": ["John Smoltz"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMA2-ST": {
                                "cardNumber": "MLMA2-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "MLMA2-STE": {
                                "cardNumber": "MLMA2-STE",
                                "name": ["Tyler Stephenson"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "MLMA2-TG": {
                                "cardNumber": "MLMA2-TG",
                                "name": ["Tom Glavine"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMA2-TH": {
                                "cardNumber": "MLMA2-TH",
                                "name": ["Tim Hudson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMA2-TS": {
                                "cardNumber": "MLMA2-TS",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMA2-TT": {
                                "cardNumber": "MLMA2-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLMA2-VG": {
                                "cardNumber": "MLMA2-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLMA2-WS": {
                                "cardNumber": "MLMA2-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLMA2-YA": {
                                "cardNumber": "MLMA2-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMA2-ZG": {
                                "cardNumber": "MLMA2-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Heavy Lumber Autograph Relics": {
                            "HLA2-AR": {
                                "cardNumber": "HLA2-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HLA2-CJ": {
                                "cardNumber": "HLA2-CJ",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HLA2-CS": {
                                "cardNumber": "HLA2-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "HLA2-FT": {
                                "cardNumber": "HLA2-FT",
                                "name": ["Frank Thomas"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "HLA2-JB": {
                                "cardNumber": "HLA2-JB",
                                "name": ["Jeff Bagwell"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HLA2-MS": {
                                "cardNumber": "HLA2-MS",
                                "name": ["Mike Schmidt"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "HLA2-SS": {
                                "cardNumber": "HLA2-SS",
                                "name": ["Sammy Sosa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "HLA-FF": {
                                "cardNumber": "HLA-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HLA-PA": {
                                "cardNumber": "HLA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            }
                        },
                        "2023 Silver Slugger Award Winners Cards Autograph Variation": {
                            "SSA-AR": {
                                "cardNumber": "SSA-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-ARU": {
                                "cardNumber": "SSA-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SSA-CS": {
                                "cardNumber": "SSA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "SSA-GH": {
                                "cardNumber": "SSA-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SSA-JR": {
                                "cardNumber": "SSA-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "SSA-JS": {
                                "cardNumber": "SSA-JS",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "SSA-KT": {
                                "cardNumber": "SSA-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SSA-LA": {
                                "cardNumber": "SSA-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "SSA-LR": {
                                "cardNumber": "SSA-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "SSA-MO": {
                                "cardNumber": "SSA-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-MS": {
                                "cardNumber": "SSA-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "SSA-RA": {
                                "cardNumber": "SSA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-RD": {
                                "cardNumber": "SSA-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "SSA-YD": {
                                "cardNumber": "SSA-YD",
                                "name": ["Yandy Díaz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Autograph": {
                        "Significant Statistics Autograph": {
                            "SSA-AR": {
                                "cardNumber": "SSA-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-ARU": {
                                "cardNumber": "SSA-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SSA-CS": {
                                "cardNumber": "SSA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "SSA-GH": {
                                "cardNumber": "SSA-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SSA-JR": {
                                "cardNumber": "SSA-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "SSA-JS": {
                                "cardNumber": "SSA-JS",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "SSA-KT": {
                                "cardNumber": "SSA-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SSA-LA": {
                                "cardNumber": "SSA-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "SSA-LR": {
                                "cardNumber": "SSA-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "SSA-MO": {
                                "cardNumber": "SSA-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-MS": {
                                "cardNumber": "SSA-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "SSA-RA": {
                                "cardNumber": "SSA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-RD": {
                                "cardNumber": "SSA-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "SSA-YD": {
                                "cardNumber": "SSA-YD",
                                "name": ["Yandy Díaz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "SSA-BD": {
                                "cardNumber": "SSA-BD",
                                "name": ["Brenton Doyle"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "SSA-BDO": {
                                "cardNumber": "SSA-BDO",
                                "name": ["Brenton Doyle"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "SSA-BW": {
                                "cardNumber": "SSA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "SSA-DS": {
                                "cardNumber": "SSA-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "SSA-ED": {
                                "cardNumber": "SSA-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSA-EDLC": {
                                "cardNumber": "SSA-EDLC",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSA-GM": {
                                "cardNumber": "SSA-GM",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "SSA-KS": {
                                "cardNumber": "SSA-KS",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SSA-NJ": {
                                "cardNumber": "SSA-NJ",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "SSA-PB": {
                                "cardNumber": "SSA-PB",
                                "name": ["Patrick Bailey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "SSA-PL": {
                                "cardNumber": "SSA-PL",
                                "name": ["Pablo López"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SSA-RAC": {
                                "cardNumber": "SSA-RAC",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-RAJ": {
                                "cardNumber": "SSA-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SSA-RAR": {
                                "cardNumber": "SSA-RAR",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "SSA-SS": {
                                "cardNumber": "SSA-SS",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        "City to City Autograph": {
                            "C2C-AR": {
                                "cardNumber": "C2C-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "C2C-BB": {
                                "cardNumber": "C2C-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "C2C-BW": {
                                "cardNumber": "C2C-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "C2C-CC": {
                                "cardNumber": "C2C-CC",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "C2C-CY": {
                                "cardNumber": "C2C-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "C2C-EC": {
                                "cardNumber": "C2C-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "C2C-ED": {
                                "cardNumber": "C2C-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "C2C-FT": {
                                "cardNumber": "C2C-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "C2C-HD": {
                                "cardNumber": "C2C-HD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "C2C-JC": {
                                "cardNumber": "C2C-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "C2C-JD": {
                                "cardNumber": "C2C-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "C2C-JR": {
                                "cardNumber": "C2C-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "C2C-JS": {
                                "cardNumber": "C2C-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "C2C-LR": {
                                "cardNumber": "C2C-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "C2C-MO": {
                                "cardNumber": "C2C-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "C2C-MT": {
                                "cardNumber": "C2C-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "C2C-PA": {
                                "cardNumber": "C2C-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "C2C-PC": {
                                "cardNumber": "C2C-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "C2C-PG": {
                                "cardNumber": "C2C-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "C2C-RA": {
                                "cardNumber": "C2C-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "C2C-RAJ": {
                                "cardNumber": "C2C-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "C2C-RD": {
                                "cardNumber": "C2C-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "C2C-RM": {
                                "cardNumber": "C2C-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "C2C-SO": {
                                "cardNumber": "C2C-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "C2C-VG": {
                                "cardNumber": "C2C-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            }
                        },
                        "Signature Tunes Dual Autograph": {
                            "SDA-SY": {
                                "cardNumber": "SDA-SY",
                                "name": ["Daddy Yankee", "Juan Soto"],
                                "team": ["Musician", "New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Chrome Autograph": {
                            "2T89C-1": {
                                "cardNumber": "2T89C-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "2T89C-2": {
                                "cardNumber": "2T89C-2",
                                "name": ["Ke'Bryan Hayes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "2T89C-3": {
                                "cardNumber": "2T89C-3",
                                "name": ["Dennis Eckersley"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "2T89C-4": {
                                "cardNumber": "2T89C-4",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "2T89C-5": {
                                "cardNumber": "2T89C-5",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "2T89C-6": {
                                "cardNumber": "2T89C-6",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "2T89C-7": {
                                "cardNumber": "2T89C-7",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-8": {
                                "cardNumber": "2T89C-8",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-9": {
                                "cardNumber": "2T89C-9",
                                "name": ["Emerson Hancock"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-10": {
                                "cardNumber": "2T89C-10",
                                "name": ["Greg Maddux"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2T89C-11": {
                                "cardNumber": "2T89C-11",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "2T89C-12": {
                                "cardNumber": "2T89C-12",
                                "name": ["Max Scherzer"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "2T89C-13": {
                                "cardNumber": "2T89C-13",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-14": {
                                "cardNumber": "2T89C-14",
                                "name": ["Johnny Bench"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "2T89C-15": {
                                "cardNumber": "2T89C-15",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-16": {
                                "cardNumber": "2T89C-16",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "2T89C-17": {
                                "cardNumber": "2T89C-17",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "2T89C-18": {
                                "cardNumber": "2T89C-18",
                                "name": ["Johan Rojas"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-21": {
                                "cardNumber": "2T89C-21",
                                "name": ["Nathan Eovaldi"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "2T89C-23": {
                                "cardNumber": "2T89C-23",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "2T89C-24": {
                                "cardNumber": "2T89C-24",
                                "name": ["Andrés Giménez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "2T89C-25": {
                                "cardNumber": "2T89C-25",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-26": {
                                "cardNumber": "2T89C-26",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-28": {
                                "cardNumber": "2T89C-28",
                                "name": ["Nick Gonzales"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-29": {
                                "cardNumber": "2T89C-29",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "2T89C-30": {
                                "cardNumber": "2T89C-30",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-31": {
                                "cardNumber": "2T89C-31",
                                "name": ["Fred McGriff"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "2T89C-32": {
                                "cardNumber": "2T89C-32",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "2T89C-33": {
                                "cardNumber": "2T89C-33",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-34": {
                                "cardNumber": "2T89C-34",
                                "name": ["Shane Bieber"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "2T89C-35": {
                                "cardNumber": "2T89C-35",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "2T89C-36": {
                                "cardNumber": "2T89C-36",
                                "name": ["Mike Mussina"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-37": {
                                "cardNumber": "2T89C-37",
                                "name": ["Parker Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-38": {
                                "cardNumber": "2T89C-38",
                                "name": ["Kyren Paris"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-39": {
                                "cardNumber": "2T89C-39",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-40": {
                                "cardNumber": "2T89C-40",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "2T89C-41": {
                                "cardNumber": "2T89C-41",
                                "name": ["Edgar Martinez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "2T89C-44": {
                                "cardNumber": "2T89C-44",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-45": {
                                "cardNumber": "2T89C-45",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-46": {
                                "cardNumber": "2T89C-46",
                                "name": ["Jose Canseco"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "2T89C-47": {
                                "cardNumber": "2T89C-47",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-48": {
                                "cardNumber": "2T89C-48",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-49": {
                                "cardNumber": "2T89C-49",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-50": {
                                "cardNumber": "2T89C-50",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-51": {
                                "cardNumber": "2T89C-51",
                                "name": ["Dale Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2T89C-52": {
                                "cardNumber": "2T89C-52",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "2T89C-53": {
                                "cardNumber": "2T89C-53",
                                "name": ["Wade Meckler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-54": {
                                "cardNumber": "2T89C-54",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "2T89C-55": {
                                "cardNumber": "2T89C-55",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "2T89C-56": {
                                "cardNumber": "2T89C-56",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2T89C-57": {
                                "cardNumber": "2T89C-57",
                                "name": ["Mike Piazza"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "2T89C-58": {
                                "cardNumber": "2T89C-58",
                                "name": ["Dominic Canzone"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-59": {
                                "cardNumber": "2T89C-59",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-60": {
                                "cardNumber": "2T89C-60",
                                "name": ["Paul Molitor"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "2T89C-61": {
                                "cardNumber": "2T89C-61",
                                "name": ["Matt McLain"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "2T89C-62": {
                                "cardNumber": "2T89C-62",
                                "name": ["Eddie Murray"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "2T89C-63": {
                                "cardNumber": "2T89C-63",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-64": {
                                "cardNumber": "2T89C-64",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-66": {
                                "cardNumber": "2T89C-66",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-69": {
                                "cardNumber": "2T89C-69",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-70": {
                                "cardNumber": "2T89C-70",
                                "name": ["Hanley Ramirez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "2T89C-71": {
                                "cardNumber": "2T89C-71",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "2T89C-72": {
                                "cardNumber": "2T89C-72",
                                "name": ["Prince Fielder"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "2T89C-73": {
                                "cardNumber": "2T89C-73",
                                "name": ["Reese Olson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-74": {
                                "cardNumber": "2T89C-74",
                                "name": ["Quinn Priester"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-75": {
                                "cardNumber": "2T89C-75",
                                "name": ["Triston McKenzie"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "2T89C-76": {
                                "cardNumber": "2T89C-76",
                                "name": ["Jonny Deluca"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-77": {
                                "cardNumber": "2T89C-77",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "2T89C-78": {
                                "cardNumber": "2T89C-78",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-79": {
                                "cardNumber": "2T89C-79",
                                "name": ["Lane Thomas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "2T89C-80": {
                                "cardNumber": "2T89C-80",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "2T89C-82": {
                                "cardNumber": "2T89C-82",
                                "name": ["Eury Pérez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "2T89C-83": {
                                "cardNumber": "2T89C-83",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-84": {
                                "cardNumber": "2T89C-84",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "2T89C-85": {
                                "cardNumber": "2T89C-85",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-86": {
                                "cardNumber": "2T89C-86",
                                "name": ["José Rodríguez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-88": {
                                "cardNumber": "2T89C-88",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "2T89C-89": {
                                "cardNumber": "2T89C-89",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "2T89C-90": {
                                "cardNumber": "2T89C-90",
                                "name": ["Randy Johnson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "2T89C-91": {
                                "cardNumber": "2T89C-91",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "2T89C-92": {
                                "cardNumber": "2T89C-92",
                                "name": ["David Wright"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "2T89C-93": {
                                "cardNumber": "2T89C-93",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "2T89C-94": {
                                "cardNumber": "2T89C-94",
                                "name": ["Roger Clemens"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "2T89C-95": {
                                "cardNumber": "2T89C-95",
                                "name": ["Andre Dawson"],
                                "team": ["Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "2T89C-96": {
                                "cardNumber": "2T89C-96",
                                "name": ["Joe Mauer"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "2T89C-97": {
                                "cardNumber": "2T89C-97",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "2T89C-99": {
                                "cardNumber": "2T89C-99",
                                "name": ["Jacob Amaya"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2T89C-100": {
                                "cardNumber": "2T89C-100",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Autograph": {
                            "89BA2-AA": {
                                "cardNumber": "89BA2-AA",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-AH": {
                                "cardNumber": "89BA2-AH",
                                "name": ["Austin Hays"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89BA2-AW": {
                                "cardNumber": "89BA2-AW",
                                "name": ["Allan Winans"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-AWE": {
                                "cardNumber": "89BA2-AWE",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-BC": {
                                "cardNumber": "89BA2-BC",
                                "name": ["Bartolo Colon"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89BA2-BJ": {
                                "cardNumber": "89BA2-BJ",
                                "name": ["Bo Jackson"],
                                "team": ["California Angels™"],
                                "cardAttributes": []
                            },
                            "89BA2-BS": {
                                "cardNumber": "89BA2-BS",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89BA2-BW": {
                                "cardNumber": "89BA2-BW",
                                "name": ["Billy Williams"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA2-CC": {
                                "cardNumber": "89BA2-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-CES": {
                                "cardNumber": "89BA2-CES",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-CME": {
                                "cardNumber": "89BA2-CME",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-CMO": {
                                "cardNumber": "89BA2-CMO",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA2-CR": {
                                "cardNumber": "89BA2-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-DJ": {
                                "cardNumber": "89BA2-DJ",
                                "name": ["David Justice"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BA2-EC": {
                                "cardNumber": "89BA2-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-ED": {
                                "cardNumber": "89BA2-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-EL": {
                                "cardNumber": "89BA2-EL",
                                "name": ["Evan Longoria"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89BA2-FM": {
                                "cardNumber": "89BA2-FM",
                                "name": ["Fred McGriff"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BA2-GA": {
                                "cardNumber": "89BA2-GA",
                                "name": ["Garret Anderson"],
                                "team": ["California Angels™"],
                                "cardAttributes": []
                            },
                            "89BA2-GH": {
                                "cardNumber": "89BA2-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-HK": {
                                "cardNumber": "89BA2-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JBE": {
                                "cardNumber": "89BA2-JBE",
                                "name": ["José Berríos"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BA2-JBU": {
                                "cardNumber": "89BA2-JBU",
                                "name": ["Jay Buhner"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89BA2-JCO": {
                                "cardNumber": "89BA2-JCO",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JD": {
                                "cardNumber": "89BA2-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JHE": {
                                "cardNumber": "89BA2-JHE",
                                "name": ["Jonah Heim"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89BA2-JHL": {
                                "cardNumber": "89BA2-JHL",
                                "name": ["Jung-Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JJ": {
                                "cardNumber": "89BA2-JJ",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89BA2-JL": {
                                "cardNumber": "89BA2-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JO": {
                                "cardNumber": "89BA2-JO",
                                "name": ["James Outman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA2-JWG": {
                                "cardNumber": "89BA2-JWG",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-LN": {
                                "cardNumber": "89BA2-LN",
                                "name": ["Lars Nootbaar"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89BA2-NM": {
                                "cardNumber": "89BA2-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-NS": {
                                "cardNumber": "89BA2-NS",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-PC": {
                                "cardNumber": "89BA2-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-SF": {
                                "cardNumber": "89BA2-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-SG": {
                                "cardNumber": "89BA2-SG",
                                "name": ["Sawyer Gipson-Long"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-SM": {
                                "cardNumber": "89BA2-SM",
                                "name": ["Sean Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BA2-SST": {
                                "cardNumber": "89BA2-SST",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BA2-TC": {
                                "cardNumber": "89BA2-TC",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89BA2-TF": {
                                "cardNumber": "89BA2-TF",
                                "name": ["Tyler Fitzgerald"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-THU": {
                                "cardNumber": "89BA2-THU",
                                "name": ["Tim Hudson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BA2-TS": {
                                "cardNumber": "89BA2-TS",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-YY": {
                                "cardNumber": "89BA2-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-ZG": {
                                "cardNumber": "89BA2-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-AB": {
                                "cardNumber": "89BA-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89BA-ACAN": {
                                "cardNumber": "89BA-ACAN",
                                "name": ["Alexander Canario"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-AKI": {
                                "cardNumber": "89BA-AKI",
                                "name": ["Alika Williams"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-ARI": {
                                "cardNumber": "89BA-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BA-BBA": {
                                "cardNumber": "89BA-BBA",
                                "name": ["Brett Baty"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89BA-BM": {
                                "cardNumber": "89BA-BM",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA-BR": {
                                "cardNumber": "89BA-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "89BA-BS": {
                                "cardNumber": "89BA-BS",
                                "name": ["Blake Snell"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89BA-BWI": {
                                "cardNumber": "89BA-BWI",
                                "name": ["Brian Wilson"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89BA-BWJ": {
                                "cardNumber": "89BA-BWJ",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89BA-COP": {
                                "cardNumber": "89BA-COP",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-DPA": {
                                "cardNumber": "89BA-DPA",
                                "name": ["Daniel Palencia"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-DV": {
                                "cardNumber": "89BA-DV",
                                "name": ["Daulton Varsho"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BA-EDA": {
                                "cardNumber": "89BA-EDA",
                                "name": ["Eric Davis"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89BA-EPE": {
                                "cardNumber": "89BA-EPE",
                                "name": ["Everson Pereira"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-FF": {
                                "cardNumber": "89BA-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA-GS": {
                                "cardNumber": "89BA-GS",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BA-HUG": {
                                "cardNumber": "89BA-HUG",
                                "name": ["Hunter Goodman"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-HUR": {
                                "cardNumber": "89BA-HUR",
                                "name": ["Kyle Hurt"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-JC": {
                                "cardNumber": "89BA-JC",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-JDE": {
                                "cardNumber": "89BA-JDE",
                                "name": ["Jonny Deluca"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-JFE": {
                                "cardNumber": "89BA-JFE",
                                "name": ["Jose Ferrer"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-JI": {
                                "cardNumber": "89BA-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89BA-JP": {
                                "cardNumber": "89BA-JP",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89BA-JR": {
                                "cardNumber": "89BA-JR",
                                "name": ["Jimmy Rollins"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89BA-JWA": {
                                "cardNumber": "89BA-JWA",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89BA-JWI": {
                                "cardNumber": "89BA-JWI",
                                "name": ["Jordan Wicks"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-KL": {
                                "cardNumber": "89BA-KL",
                                "name": ["Kenny Lofton"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA-KSC": {
                                "cardNumber": "89BA-KSC",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89BA-LBU": {
                                "cardNumber": "89BA-LBU",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-LC": {
                                "cardNumber": "89BA-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89BA-MC": {
                                "cardNumber": "89BA-MC",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BA-MG": {
                                "cardNumber": "89BA-MG",
                                "name": ["Mark Grace"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA-MH": {
                                "cardNumber": "89BA-MH",
                                "name": ["Matt Holliday"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89BA-MMA": {
                                "cardNumber": "89BA-MMA",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89BA-NIL": {
                                "cardNumber": "89BA-NIL",
                                "name": ["Nick Loftin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-NSC": {
                                "cardNumber": "89BA-NSC",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-PBE": {
                                "cardNumber": "89BA-PBE",
                                "name": ["Prelander Berroa"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-PF": {
                                "cardNumber": "89BA-PF",
                                "name": ["Prince Fielder"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89BA-PME": {
                                "cardNumber": "89BA-PME",
                                "name": ["Parker Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-PO": {
                                "cardNumber": "89BA-PO",
                                "name": ["Paul O'Neill"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BA-RG": {
                                "cardNumber": "89BA-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89BA-RM": {
                                "cardNumber": "89BA-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-RUT": {
                                "cardNumber": "89BA-RUT",
                                "name": ["Blake Rutherford"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-SK": {
                                "cardNumber": "89BA-SK",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "89BA-SM": {
                                "cardNumber": "89BA-SM",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89BA-TG": {
                                "cardNumber": "89BA-TG",
                                "name": ["Tony Gonsolin"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA-TR": {
                                "cardNumber": "89BA-TR",
                                "name": ["Tim Raines"],
                                "team": ["Montreal Expos™"],
                                "cardAttributes": []
                            },
                            "89BA-WME": {
                                "cardNumber": "89BA-WME",
                                "name": ["Wade Meckler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-XB": {
                                "cardNumber": "89BA-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball All Stars Autograph": {
                            "89ASA-AD": {
                                "cardNumber": "89ASA-AD",
                                "name": ["Andre Dawson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89ASA-AJ": {
                                "cardNumber": "89ASA-AJ",
                                "name": ["Andruw Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-AJU": {
                                "cardNumber": "89ASA-AJU",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-AR": {
                                "cardNumber": "89ASA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASA-ARI": {
                                "cardNumber": "89ASA-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-BH": {
                                "cardNumber": "89ASA-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASA-BJ": {
                                "cardNumber": "89ASA-BJ",
                                "name": ["Bo Jackson"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89ASA-BW": {
                                "cardNumber": "89ASA-BW",
                                "name": ["Bernie Williams"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-CB": {
                                "cardNumber": "89ASA-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89ASA-CBI": {
                                "cardNumber": "89ASA-CBI",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASA-CC": {
                                "cardNumber": "89ASA-CC",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89ASA-CFI": {
                                "cardNumber": "89ASA-CFI",
                                "name": ["Carlton Fisk"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASA-CJ": {
                                "cardNumber": "89ASA-CJ",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-CK": {
                                "cardNumber": "89ASA-CK",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASA-CR": {
                                "cardNumber": "89ASA-CR",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASA-CSE": {
                                "cardNumber": "89ASA-CSE",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89ASA-DE": {
                                "cardNumber": "89ASA-DE",
                                "name": ["Dennis Eckersley"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89ASA-DG": {
                                "cardNumber": "89ASA-DG",
                                "name": ["Dwight Gooden"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89ASA-DJ": {
                                "cardNumber": "89ASA-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-DMA": {
                                "cardNumber": "89ASA-DMA",
                                "name": ["Don Mattingly"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-DS": {
                                "cardNumber": "89ASA-DS",
                                "name": ["Darryl Strawberry"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89ASA-DWI": {
                                "cardNumber": "89ASA-DWI",
                                "name": ["Dave Winfield"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-DWR": {
                                "cardNumber": "89ASA-DWR",
                                "name": ["David Wright"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89ASA-EMU": {
                                "cardNumber": "89ASA-EMU",
                                "name": ["Eddie Murray"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASA-FM": {
                                "cardNumber": "89ASA-FM",
                                "name": ["Fred McGriff"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-FT": {
                                "cardNumber": "89ASA-FT",
                                "name": ["Frank Thomas"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89ASA-FTJ": {
                                "cardNumber": "89ASA-FTJ",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89ASA-GB": {
                                "cardNumber": "89ASA-GB",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89ASA-GK": {
                                "cardNumber": "89ASA-GK",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASA-GM": {
                                "cardNumber": "89ASA-GM",
                                "name": ["Greg Maddux"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89ASA-HM": {
                                "cardNumber": "89ASA-HM",
                                "name": ["Hideki Matsui"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-I": {
                                "cardNumber": "89ASA-I",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASA-JB": {
                                "cardNumber": "89ASA-JB",
                                "name": ["Jeff Bagwell"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASA-JBE": {
                                "cardNumber": "89ASA-JBE",
                                "name": ["Johnny Bench"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89ASA-JC": {
                                "cardNumber": "89ASA-JC",
                                "name": ["Jose Canseco"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89ASA-JP": {
                                "cardNumber": "89ASA-JP",
                                "name": ["Jim Palmer"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89ASA-JRA": {
                                "cardNumber": "89ASA-JRA",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "89ASA-JRO": {
                                "cardNumber": "89ASA-JRO",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASA-JS": {
                                "cardNumber": "89ASA-JS",
                                "name": ["Justin Steele"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89ASA-JSM": {
                                "cardNumber": "89ASA-JSM",
                                "name": ["John Smoltz"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-JSO": {
                                "cardNumber": "89ASA-JSO",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-JV": {
                                "cardNumber": "89ASA-JV",
                                "name": ["Jason Varitek"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASA-KSC": {
                                "cardNumber": "89ASA-KSC",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASA-KT": {
                                "cardNumber": "89ASA-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASA-MM": {
                                "cardNumber": "89ASA-MM",
                                "name": ["Mark McGwire"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89ASA-MR": {
                                "cardNumber": "89ASA-MR",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89ASA-MS": {
                                "cardNumber": "89ASA-MS",
                                "name": ["Mike Schmidt"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASA-MT": {
                                "cardNumber": "89ASA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89ASA-NR": {
                                "cardNumber": "89ASA-NR",
                                "name": ["Nolan Ryan"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89ASA-OS": {
                                "cardNumber": "89ASA-OS",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASA-PG": {
                                "cardNumber": "89ASA-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASA-PM": {
                                "cardNumber": "89ASA-PM",
                                "name": ["Paul Molitor"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89ASA-RA": {
                                "cardNumber": "89ASA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-RD": {
                                "cardNumber": "89ASA-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASA-RH": {
                                "cardNumber": "89ASA-RH",
                                "name": ["Ryan Howard"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASA-RS": {
                                "cardNumber": "89ASA-RS",
                                "name": ["Ryne Sandberg"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89ASA-RY": {
                                "cardNumber": "89ASA-RY",
                                "name": ["Robin Yount"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89ASA-SC": {
                                "cardNumber": "89ASA-SC",
                                "name": ["Steve Carlton"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASA-SG": {
                                "cardNumber": "89ASA-SG",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASA-SGA": {
                                "cardNumber": "89ASA-SGA",
                                "name": ["Steve Garvey"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASA-SS": {
                                "cardNumber": "89ASA-SS",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-SSO": {
                                "cardNumber": "89ASA-SSO",
                                "name": ["Sammy Sosa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89ASA-TG": {
                                "cardNumber": "89ASA-TG",
                                "name": ["Tom Glavine"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-TT": {
                                "cardNumber": "89ASA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASA-VGU": {
                                "cardNumber": "89ASA-VGU",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89ASA-WB": {
                                "cardNumber": "89ASA-WB",
                                "name": ["Wade Boggs"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASA-WC": {
                                "cardNumber": "89ASA-WC",
                                "name": ["Will Clark"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            }
                        },
                        "Baseball Stars Dual Autograph": {
                            "BSDA2-CC": {
                                "cardNumber": "BSDA2-CC",
                                "name": ["Gerrit Cole", "Roger Clemens"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-CG": {
                                "cardNumber": "BSDA2-CG",
                                "name": ["Corbin Carroll", "Zac Gallen"],
                                "team": ["Arizona Diamondbacks®", "Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BSDA2-CJA": {
                                "cardNumber": "BSDA2-CJA",
                                "name": ["Luis Arraez", "Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®", "Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "BSDA2-CJR": {
                                "cardNumber": "BSDA2-CJR",
                                "name": ["Jazz Chisholm Jr.", "Hanley Ramirez"],
                                "team": ["Miami Marlins®", "Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "BSDA2-CLW": {
                                "cardNumber": "BSDA2-CLW",
                                "name": ["Will Clark", "Matt Williams"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BSDA2-FIS": {
                                "cardNumber": "BSDA2-FIS",
                                "name": ["CC Sabathia", "Prince Fielder"],
                                "team": ["Milwaukee Brewers™", "Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "BSDA2-FV": {
                                "cardNumber": "BSDA2-FV",
                                "name": ["Jason Varitek", "Carlton Fisk"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA2-GH": {
                                "cardNumber": "BSDA2-GH",
                                "name": ["Vladimir Guerrero", "Torii Hunter"],
                                "team": ["Angels®", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSDA2-GJG": {
                                "cardNumber": "BSDA2-GJG",
                                "name": ["Vladimir Guerrero Jr.", "Vladimir Guerrero"],
                                "team": ["Toronto Blue Jays®", "Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "BSDA2-GJS": {
                                "cardNumber": "BSDA2-GJS",
                                "name": ["Vladimir Guerrero Jr.", "Juan Soto"],
                                "team": ["Toronto Blue Jays®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-GJT": {
                                "cardNumber": "BSDA2-GJT",
                                "name": ["Ken Griffey Jr.", "Mike Trout"],
                                "team": ["Seattle Mariners™", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSDA2-GSP": {
                                "cardNumber": "BSDA2-GSP",
                                "name": ["Vladimir Guerrero Jr.", "George Springer"],
                                "team": ["Toronto Blue Jays®", "Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BSDA2-HAL": {
                                "cardNumber": "BSDA2-HAL",
                                "name": ["Keith Hernandez", "Pete Alonso"],
                                "team": ["New York Mets®", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSDA2-HEG": {
                                "cardNumber": "BSDA2-HEG",
                                "name": ["Paul Goldschmidt", "Keith Hernandez"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSDA2-HF": {
                                "cardNumber": "BSDA2-HF",
                                "name": ["Trevor Hoffman", "Steve Finley"],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BSDA2-HSW": {
                                "cardNumber": "BSDA2-HSW",
                                "name": ["Dansby Swanson", "Nico Hoerner"],
                                "team": ["Chicago Cubs®", "Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BSDA2-IOH": {
                                "cardNumber": "BSDA2-IOH",
                                "name": ["Shohei Ohtani", "Ichiro"],
                                "team": ["Los Angeles Dodgers®", "Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BSDA2-JC": {
                                "cardNumber": "BSDA2-JC",
                                "name": ["Corbin Carroll", "Randy Johnson"],
                                "team": ["Arizona Diamondbacks®", "Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BSDA2-JER": {
                                "cardNumber": "BSDA2-JER",
                                "name": ["Derek Jeter", "Mariano Rivera"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-JJ": {
                                "cardNumber": "BSDA2-JJ",
                                "name": ["Andruw Jones", "David Justice"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSDA2-JOL": {
                                "cardNumber": "BSDA2-JOL",
                                "name": ["Matt Olson", "Chipper Jones"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSDA2-JRJ": {
                                "cardNumber": "BSDA2-JRJ",
                                "name": ["Cal Ripken Jr.", "Derek Jeter"],
                                "team": ["Baltimore Orioles®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-JWJ": {
                                "cardNumber": "BSDA2-JWJ",
                                "name": ["Bobby Witt Jr.", "Bo Jackson"],
                                "team": ["Kansas City Royals®", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSDA2-KOB": {
                                "cardNumber": "BSDA2-KOB",
                                "name": ["Paul Konerko", "Mark Buehrle"],
                                "team": ["Chicago White Sox®", "Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA2-LAD": {
                                "cardNumber": "BSDA2-LAD",
                                "name": ["Barry Larkin", "Eric Davis"],
                                "team": ["Cincinnati Reds®", "Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BSDA2-MAB": {
                                "cardNumber": "BSDA2-MAB",
                                "name": ["Wade Boggs", "Don Mattingly"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-MAH": {
                                "cardNumber": "BSDA2-MAH",
                                "name": ["Torii Hunter", "Joe Mauer"],
                                "team": ["Minnesota Twins®", "Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSDA2-MAM": {
                                "cardNumber": "BSDA2-MAM",
                                "name": ["Joe Mauer", "Justin Morneau"],
                                "team": ["Minnesota Twins®", "Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSDA2-MB": {
                                "cardNumber": "BSDA2-MB",
                                "name": ["Jay Buhner", "Edgar Martinez"],
                                "team": ["Seattle Mariners™", "Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BSDA2-MCJ": {
                                "cardNumber": "BSDA2-MCJ",
                                "name": ["David Justice", "Fred McGriff"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSDA2-MGU": {
                                "cardNumber": "BSDA2-MGU",
                                "name": ["Vladimir Guerrero", "Pedro Martinez"],
                                "team": ["Montréal Expos™", "Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "BSDA2-OLR": {
                                "cardNumber": "BSDA2-OLR",
                                "name": ["Austin Riley", "Matt Olson"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSDA2-ONW": {
                                "cardNumber": "BSDA2-ONW",
                                "name": ["Bernie Williams", "Paul O'Neill"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-OP": {
                                "cardNumber": "BSDA2-OP",
                                "name": ["Dustin Pedroia", "David Ortiz"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA2-PEC": {
                                "cardNumber": "BSDA2-PEC",
                                "name": ["Hunter Pence", "Brandon Crawford"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BSDA2-PMO": {
                                "cardNumber": "BSDA2-PMO",
                                "name": ["Yadier Molina", "Albert Pujols"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSDA2-PP": {
                                "cardNumber": "BSDA2-PP",
                                "name": ["Jorge Posada", "Andy Pettitte"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-PV": {
                                "cardNumber": "BSDA2-PV",
                                "name": ["Dustin Pedroia", "Jason Varitek"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA2-PWR": {
                                "cardNumber": "BSDA2-PWR",
                                "name": ["Mike Piazza", "David Wright"],
                                "team": ["New York Mets®", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSDA2-RB": {
                                "cardNumber": "BSDA2-RB",
                                "name": ["Jeff Bagwell", "Nolan Ryan"],
                                "team": ["Houston Astros®", "Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSDA2-RHE": {
                                "cardNumber": "BSDA2-RHE",
                                "name": ["Gunnar Henderson", "Adley Rutschman"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BSDA2-RJ": {
                                "cardNumber": "BSDA2-RJ",
                                "name": ["Randy Johnson", "Nolan Ryan"],
                                "team": ["Seattle Mariners™", "Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BSDA2-RJH": {
                                "cardNumber": "BSDA2-RJH",
                                "name": ["Gunnar Henderson", "Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BSDA2-RMC": {
                                "cardNumber": "BSDA2-RMC",
                                "name": ["Triston McKenzie", "José Ramírez"],
                                "team": ["Cleveland Guardians™", "Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "BSDA2-ROE": {
                                "cardNumber": "BSDA2-ROE",
                                "name": ["Jim Edmonds", "Scott Rolen"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSDA2-RTR": {
                                "cardNumber": "BSDA2-RTR",
                                "name": ["Mike Trout", "Nolan Ryan"],
                                "team": ["Angels®", "California Angels™"],
                                "cardAttributes": []
                            },
                            "BSDA2-SCB": {
                                "cardNumber": "BSDA2-SCB",
                                "name": ["Mike Schmidt", "George Brett"],
                                "team": ["Philadelphia Phillies®", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSDA2-SCT": {
                                "cardNumber": "BSDA2-SCT",
                                "name": ["Trea Turner", "Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSDA2-SEG": {
                                "cardNumber": "BSDA2-SEG",
                                "name": ["Adolis García", "Corey Seager"],
                                "team": ["Texas Rangers®", "Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BSDA2-SMM": {
                                "cardNumber": "BSDA2-SMM",
                                "name": ["Ozzie Smith", "Yadier Molina"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSDA2-SS": {
                                "cardNumber": "BSDA2-SS",
                                "name": ["Spencer Strider", "John Smoltz"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSDA2-STE": {
                                "cardNumber": "BSDA2-STE",
                                "name": ["Dennis Eckersley", "Dave Stewart"],
                                "team": ["Oakland Athletics™", "Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "BSDA2-STF": {
                                "cardNumber": "BSDA2-STF",
                                "name": ["Spencer Strider", "Max Fried"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSDA2-STG": {
                                "cardNumber": "BSDA2-STG",
                                "name": ["Dwight Gooden", "Darryl Strawberry"],
                                "team": ["New York Mets®", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSDA2-TAJ": {
                                "cardNumber": "BSDA2-TAJ",
                                "name": ["Ronald Acuña Jr.", "Mike Trout"],
                                "team": ["Atlanta Braves™", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSDA2-TRJ": {
                                "cardNumber": "BSDA2-TRJ",
                                "name": ["Frank Thomas", "Luis Robert Jr."],
                                "team": ["Chicago White Sox®", "Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA2-TVO": {
                                "cardNumber": "BSDA2-TVO",
                                "name": ["Anthony Volpe", "Gleyber Torres"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA2-UTH": {
                                "cardNumber": "BSDA2-UTH",
                                "name": ["Ryan Howard", "Chase Utley"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSDA2-VG": {
                                "cardNumber": "BSDA2-VG",
                                "name": ["Mo Vaughn", "Nomar Garciaparra"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA2-YAF": {
                                "cardNumber": "BSDA2-YAF",
                                "name": ["Carlton Fisk", "Carl Yastrzemski"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "Baseball Stars Triple Autograph": {
                            "BSTA-ACP": {
                                "cardNumber": "BSTA-ACP",
                                "name": ["Jazz Chisholm Jr.", "Luis Arraez", "Eury Pérez"],
                                "team": ["Miami Marlins®", "Miami Marlins®", "Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "BSTA-AOR": {
                                "cardNumber": "BSTA-AOR",
                                "name": ["Ronald Acuña Jr.", "Matt Olson", "Austin Riley"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSTA-BBP": {
                                "cardNumber": "BSTA-BBP",
                                "name": ["Jeff Bagwell", "Andy Pettitte", "Craig Biggio"],
                                "team": ["Houston Astros®", "Houston Astros®", "Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSTA-BCF": {
                                "cardNumber": "BSTA-BCF",
                                "name": ["Wade Boggs", "Roger Clemens", "Carlton Fisk"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSTA-BJW": {
                                "cardNumber": "BSTA-BJW",
                                "name": ["George Brett", "Bobby Witt Jr.", "Bo Jackson"],
                                "team": ["Kansas City Royals®", "Kansas City Royals®", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSTA-BMR": {
                                "cardNumber": "BSTA-BMR",
                                "name": ["Anthony Rizzo", "Wade Boggs", "Don Mattingly"],
                                "team": ["New York Yankees®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSTA-CLM": {
                                "cardNumber": "BSTA-CLM",
                                "name": ["Gabriel Moreno", "Corbin Carroll", "Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®", "Arizona Diamondbacks®", "Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTA-DLV": {
                                "cardNumber": "BSTA-DLV",
                                "name": ["Eric Davis", "Barry Larkin", "Joey Votto"],
                                "team": ["Cincinnati Reds®", "Cincinnati Reds®", "Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BSTA-ESZ": {
                                "cardNumber": "BSTA-ESZ",
                                "name": ["Dennis Eckersley", "Dave Stewart", "Barry Zito"],
                                "team": ["Oakland Athletics™", "Oakland Athletics™", "Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "BSTA-GHA": {
                                "cardNumber": "BSTA-GHA",
                                "name": ["Garret Anderson", "Torii Hunter", "Vladimir Guerrero"],
                                "team": ["Angels®", "Angels®", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSTA-GIR": {
                                "cardNumber": "BSTA-GIR",
                                "name": ["Ken Griffey Jr.", "Ichiro", "Julio Rodríguez"],
                                "team": ["Seattle Mariners™", "Seattle Mariners™", "Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BSTA-GMD": {
                                "cardNumber": "BSTA-GMD",
                                "name": ["Vladimir Guerrero", "Pedro Martinez", "Andre Dawson"],
                                "team": ["Montréal Expos™", "Montréal Expos™", "Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "BSTA-GTR": {
                                "cardNumber": "BSTA-GTR",
                                "name": ["Mike Trout", "Vladimir Guerrero", "Nolan Ryan"],
                                "team": ["Angels®", "Angels®", "California Angels™"],
                                "cardAttributes": []
                            },
                            "BSTA-HRU": {
                                "cardNumber": "BSTA-HRU",
                                "name": ["Ryan Howard", "Jimmy Rollins", "Chase Utley"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSTA-MAV": {
                                "cardNumber": "BSTA-MAV",
                                "name": ["Jason Varitek", "Pedro Martinez", "David Ortiz"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSTA-MGS": {
                                "cardNumber": "BSTA-MGS",
                                "name": ["Tom Glavine", "John Smoltz", "Greg Maddux"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSTA-MJA": {
                                "cardNumber": "BSTA-MJA",
                                "name": ["Chipper Jones", "Ronald Acuña Jr.", "Dale Murphy"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSTA-MMH": {
                                "cardNumber": "BSTA-MMH",
                                "name": ["Torii Hunter", "Justin Morneau", "Joe Mauer"],
                                "team": ["Minnesota Twins®", "Minnesota Twins®", "Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSTA-PSC": {
                                "cardNumber": "BSTA-PSC",
                                "name": ["CC Sabathia", "Gerrit Cole", "Andy Pettitte"],
                                "team": ["New York Yankees®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSTA-RRP": {
                                "cardNumber": "BSTA-RRP",
                                "name": ["Cal Ripken Jr.", "Brooks Robinson", "Jim Palmer"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BSTA-SHC": {
                                "cardNumber": "BSTA-SHC",
                                "name": ["Pete Crow-Armstrong", "Nico Hoerner", "Dansby Swanson"],
                                "team": ["Chicago Cubs®", "Chicago Cubs®", "Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTA-SJC": {
                                "cardNumber": "BSTA-SJC",
                                "name": ["Corey Seager", "Evan Carter", "Josh Jung"],
                                "team": ["Texas Rangers®", "Texas Rangers®", "Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTA-SMD": {
                                "cardNumber": "BSTA-SMD",
                                "name": ["Ryne Sandberg", "Greg Maddux", "Andre Dawson"],
                                "team": ["Chicago Cubs®", "Chicago Cubs®", "Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BSTA-SRM": {
                                "cardNumber": "BSTA-SRM",
                                "name": ["Yadier Molina", "Scott Rolen", "Ozzie Smith"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSTA-STS": {
                                "cardNumber": "BSTA-STS",
                                "name": ["Kyle Schwarber", "Bryson Stott", "Trea Turner"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSTA-SWA": {
                                "cardNumber": "BSTA-SWA",
                                "name": ["Pete Alonso", "David Wright", "Darryl Strawberry"],
                                "team": ["New York Mets®", "New York Mets®", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSTA-TDV": {
                                "cardNumber": "BSTA-TDV",
                                "name": ["Gleyber Torres", "Jasson Domínguez", "Anthony Volpe"],
                                "team": ["New York Yankees®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTA-VCM": {
                                "cardNumber": "BSTA-VCM",
                                "name": ["Matt McLain", "Elly De La Cruz", "Joey Votto"],
                                "team": ["Cincinnati Reds®", "Cincinnati Reds®", "Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTA-WCC": {
                                "cardNumber": "BSTA-WCC",
                                "name": ["Brandon Crawford", "Matt Williams", "Will Clark"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BSTA-WOP": {
                                "cardNumber": "BSTA-WOP",
                                "name": ["Paul O'Neill", "Jorge Posada", "Bernie Williams"],
                                "team": ["New York Yankees®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "Baseball Stars Autograph": {
                            "BSA2-AB": {
                                "cardNumber": "BSA2-AB",
                                "name": ["Alec Burleson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSA2-AC": {
                                "cardNumber": "BSA2-AC",
                                "name": ["Alexander Canario"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-AWS": {
                                "cardNumber": "BSA2-AWS",
                                "name": ["Alika Williams"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-BJ": {
                                "cardNumber": "BSA2-BJ",
                                "name": ["Ben Joyce"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BSA2-BM": {
                                "cardNumber": "BSA2-BM",
                                "name": ["Bryce Miller"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BSA2-BT": {
                                "cardNumber": "BSA2-BT",
                                "name": ["Brice Turang"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "BSA2-BW": {
                                "cardNumber": "BSA2-BW",
                                "name": ["Bryan Woo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-CC": {
                                "cardNumber": "BSA2-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-CF": {
                                "cardNumber": "BSA2-CF",
                                "name": ["Cecil Fielder"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "BSA2-CMC": {
                                "cardNumber": "BSA2-CMC",
                                "name": ["Chas McCormick"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSA2-CME": {
                                "cardNumber": "BSA2-CME",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-CP": {
                                "cardNumber": "BSA2-CP",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-CR": {
                                "cardNumber": "BSA2-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-CRA": {
                                "cardNumber": "BSA2-CRA",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BSA2-DE": {
                                "cardNumber": "BSA2-DE",
                                "name": ["David Eckstein"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSA2-DG": {
                                "cardNumber": "BSA2-DG",
                                "name": ["Dwight Gooden"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSA2-DJ": {
                                "cardNumber": "BSA2-DJ",
                                "name": ["David Justice"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSA2-DO": {
                                "cardNumber": "BSA2-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSA2-DV": {
                                "cardNumber": "BSA2-DV",
                                "name": ["Darius Vines"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-DW": {
                                "cardNumber": "BSA2-DW",
                                "name": ["Devon White"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BSA2-EC": {
                                "cardNumber": "BSA2-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-EG": {
                                "cardNumber": "BSA2-EG",
                                "name": ["Eric Gagne"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSA2-EH": {
                                "cardNumber": "BSA2-EH",
                                "name": ["Emerson Hancock"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-ER": {
                                "cardNumber": "BSA2-ER",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-ERU": {
                                "cardNumber": "BSA2-ERU",
                                "name": ["Esteury Ruiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "BSA2-FP": {
                                "cardNumber": "BSA2-FP",
                                "name": ["Freddy Peralta"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "BSA2-GK": {
                                "cardNumber": "BSA2-GK",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BSA2-GM": {
                                "cardNumber": "BSA2-GM",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BSA2-GMI": {
                                "cardNumber": "BSA2-GMI",
                                "name": ["Garrett Mitchell"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "BSA2-GW": {
                                "cardNumber": "BSA2-GW",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-HD": {
                                "cardNumber": "BSA2-HD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-HK": {
                                "cardNumber": "BSA2-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-IMA": {
                                "cardNumber": "BSA2-IMA",
                                "name": ["Shōta Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-JB": {
                                "cardNumber": "BSA2-JB",
                                "name": ["Ji Hwan Bae"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BSA2-JH": {
                                "cardNumber": "BSA2-JH",
                                "name": ["Jonah Heim"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BSA2-JHL": {
                                "cardNumber": "BSA2-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-JHO": {
                                "cardNumber": "BSA2-JHO",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-JL": {
                                "cardNumber": "BSA2-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-JME": {
                                "cardNumber": "BSA2-JME",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-JP": {
                                "cardNumber": "BSA2-JP",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSA2-JPM": {
                                "cardNumber": "BSA2-JPM",
                                "name": ["J.P. Martinez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-KB": {
                                "cardNumber": "BSA2-KB",
                                "name": ["Khalil Lee"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSA2-KM": {
                                "cardNumber": "BSA2-KM",
                                "name": ["Keston Hiura"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "BSA2-KV": {
                                "cardNumber": "BSA2-KV",
                                "name": ["Khalil Watson"],
                                "team": ["Miami Marlins™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-LC": {
                                "cardNumber": "BSA2-LC",
                                "name": ["Luis Campusano"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BSA2-LR": {
                                "cardNumber": "BSA2-LR",
                                "name": ["Luis Robert"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "BSA2-MA": {
                                "cardNumber": "BSA2-MA",
                                "name": ["Mickey Moniak"],
                                "team": ["Los Angeles Angels®"],
                                "cardAttributes": []
                            },
                            "BSA2-MB": {
                                "cardNumber": "BSA2-MB",
                                "name": ["Mason Barnett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSA2-MC": {
                                "cardNumber": "BSA2-MC",
                                "name": ["Matt Canterino"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-MK": {
                                "cardNumber": "BSA2-MK",
                                "name": ["Matt Kroon"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BSA2-MV": {
                                "cardNumber": "BSA2-MV",
                                "name": ["Matthew Vargas"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSA2-MW": {
                                "cardNumber": "BSA2-MW",
                                "name": ["Mason Wainwright"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BSA2-NM": {
                                "cardNumber": "BSA2-NM",
                                "name": ["Nick Madigan"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BSA2-NO": {
                                "cardNumber": "BSA2-NO",
                                "name": ["Nate Orf"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "BSA2-NR": {
                                "cardNumber": "BSA2-NR",
                                "name": ["Nolan Ryan"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BSA2-OB": {
                                "cardNumber": "BSA2-OB",
                                "name": ["Oscar Binoa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BSA2-OL": {
                                "cardNumber": "BSA2-OL",
                                "name": ["Orel Hershiser"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSA2-PA": {
                                "cardNumber": "BSA2-PA",
                                "name": ["Peyton Burdick"],
                                "team": ["Miami Marlins™"],
                                "cardAttributes": []
                            },
                            "BSA2-PJ": {
                                "cardNumber": "BSA2-PJ",
                                "name": ["Peyton Pallette"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "BSA2-PL": {
                                "cardNumber": "BSA2-PL",
                                "name": ["Paul Lo Duca"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSA2-RB": {
                                "cardNumber": "BSA2-RB",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-RD": {
                                "cardNumber": "BSA2-RD",
                                "name": ["Ryan Doumit"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BSA2-RP": {
                                "cardNumber": "BSA2-RP",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-RW": {
                                "cardNumber": "BSA2-RW",
                                "name": ["Ryan Weathers"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BSA2-SC": {
                                "cardNumber": "BSA2-SC",
                                "name": ["Sandy Alcántara"],
                                "team": ["Miami Marlins™"],
                                "cardAttributes": []
                            },
                            "BSA2-SM": {
                                "cardNumber": "BSA2-SM",
                                "name": ["Salvador Pérez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSA2-SP": {
                                "cardNumber": "BSA2-SP",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-SS": {
                                "cardNumber": "BSA2-SS",
                                "name": ["Shane Shifflett"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-TD": {
                                "cardNumber": "BSA2-TD",
                                "name": ["Trey Mancini"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BSA2-TG": {
                                "cardNumber": "BSA2-TG",
                                "name": ["Travis Swaggerty"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BSA2-TW": {
                                "cardNumber": "BSA2-TW",
                                "name": ["Tyler Wells"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BSA2-VA": {
                                "cardNumber": "BSA2-VA",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BSA2-VG": {
                                "cardNumber": "BSA2-VG",
                                "name": ["Vince Velasquez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BSA2-VR": {
                                "cardNumber": "BSA2-VR",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "BSA2-WM": {
                                "cardNumber": "BSA2-WM",
                                "name": ["Willy Adames"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "BSA2-WP": {
                                "cardNumber": "BSA2-WP",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "BSA2-WR": {
                                "cardNumber": "BSA2-WR",
                                "name": ["Walker Buehler"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSA2-WS": {
                                "cardNumber": "BSA2-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            }
                        },
                        "40 70 Autograph": {
                            "RAJA-1": {
                                "cardNumber": "RAJA-1",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-2": {
                                "cardNumber": "RAJA-2",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-3": {
                                "cardNumber": "RAJA-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-4": {
                                "cardNumber": "RAJA-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-5": {
                                "cardNumber": "RAJA-5",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-11": {
                                "cardNumber": "RAJA-11",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-16": {
                                "cardNumber": "RAJA-16",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-17": {
                                "cardNumber": "RAJA-17",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-18": {
                                "cardNumber": "RAJA-18",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-19": {
                                "cardNumber": "RAJA-19",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "RAJA-20": {
                                "cardNumber": "RAJA-20",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        "2023 First Pitch Autograph": {
                            "FPA-MSW": {
                                "cardNumber": "FPA-MSW",
                                "name": ["Mallory Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "FPA-MTO": {
                                "cardNumber": "FPA-MTO",
                                "name": ["Myke Towers"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            }
                        },
                        "2023 Play Ball Autograph": {
                            "PBA-CJS": {
                                "cardNumber": "PBA-CJS",
                                "name": ["CJ Stroud"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "2023 Dynamic Dual Autograph": {
                            "FPBDA-WS": {
                                "cardNumber": "FPBDA-WS",
                                "name": ["CJ Stroud", "Victor Wembanyama"],
                                "team": ["Houston Astros®", "New York Yankees®"],
                                "cardAttributes": []
                            }
                        }
                    }
                }
            }
        }
    }
}

export const MLB_2024_TOPPS_SERIES2_PARALLELS = {
    "MLB": {
        "2024": {
            "Topps": {
                "Series 2": {
                    "Base Card Set": {
                        "Base": [
                            { "name": "AQUA", "color": "#00FFDF", "limitedTo": null },
                            { "name": "ROYAL BLUE", "color": "#4169E1", "limitedTo": null },
                            { "name": "YELLOW", "color": "#EBD834", "limitedTo": null },
                            { "name": "RAINBOW FOIL", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "GOLD FOIL", "color": "#FFD700", "limitedTo": null },
                            { "name": "YELLOW FOIL", "color": "#EBD834", "limitedTo": null },
                            { "name": "GOLDEN MIRROR IMAGE VARIATION", "color": "#FFD700", "limitedTo": null },
                            { "name": "HOT DOGS", "color": "#FF5733", "limitedTo": null },
                            { "name": "STATUE OF LIBERTY", "color": "#A1C7B6", "limitedTo": null },
                            { "name": "TEAM COLOR BORDER VARIATION", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "TRUE PHOTO", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 2024 },
                            { "name": "BLUE HOLOFOIL BOARD", "color": "#1E90FF", "limitedTo": 999 },
                            { "name": "PURPLE HOLOFOIL BOARD", "color": "#800080", "limitedTo": 799 },
                            { "name": "GREEN CRACKLE FOILBOARD", "color": "#008000", "limitedTo": 499 },
                            { "name": "AQUA CRACKLE FOILBOARD", "color": "#00FFDF", "limitedTo": 399 },
                            { "name": "ORANGE CRACKLE FOILBOARD", "color": "#FFA500", "limitedTo": 299 },
                            { "name": "RED CRACKLE FOILBOARD", "color": "#FF0000", "limitedTo": 199 },
                            { "name": "VINTAGE STOCK", "color": "#DEB887", "limitedTo": 99 },
                            { "name": "INDEPENDENCE DAY", "color": "#B22222", "limitedTo": 76 },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 73 },
                            { "name": "BEACH UMBRELLA", "color": "#F7C6C7", "limitedTo": 50 },
                            { "name": "YELLOW CRACKLE FOILBOARD", "color": "#EBD834", "limitedTo": 50 },
                            { "name": "MOTHER'S DAY HOT PINK", "color": "#FF69B4", "limitedTo": 50 },
                            { "name": "FATHER'S DAY POWDER BLUE", "color": "#B0E0E6", "limitedTo": 50 },
                            { "name": "MEMORIAL DAY CAMO", "color": "#4B5320", "limitedTo": 25 },
                            { "name": "CLEAR", "color": "transparent", "limitedTo": 10 },
                            { "name": "SUN", "color": "#FFD700", "limitedTo": 10 },
                            { "name": "FIREWORKS", "color": "#FF4500", "limitedTo": 5 },
                            { "name": "WAVE", "color": "#1E90FF", "limitedTo": 1 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (BLACK)", "color": "#000000", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (CYAN)", "color": "#00FFFF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (MAGENTA)", "color": "#FF00FF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (YELLOW)", "color": "#EBD834", "limitedTo": 1 }
                        ]
                    },
                    "Insert": {
                        "40 70": [
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "1989 Topps All Star Baseball": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball Foilboard Mega Box Cards": [
                            { "name": "PURPLE FOIL", "color": "#800080", "limitedTo": 75 },
                            { "name": "GOLD FOIL", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE FOIL", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "RED FOIL", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "BLACK FOIL", "color": "#000000", "limitedTo": 1 }
                        ],
                        "1989 Topps All Stars Baseball Foilboard Mega Box Cards": [
                            { "name": "PURPLE FOIL", "color": "#800080", "limitedTo": 75 },
                            { "name": "GOLD FOIL", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE FOIL", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "RED FOIL", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "BLACK FOIL", "color": "#000000", "limitedTo": 1 }
                        ],
                        "2023 First Pitch": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "2023 Play Ball": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Around the Horn": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "City to City": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Covering the Field": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Fantasy Favorites": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Significant Statistics": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Stars of MLB": [
                            { "name": "RED", "color": "#FF0000", "limitedTo": 75 },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Chrome Stars of MLB": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 70 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 25 },
                            { "name": "SUPERFRACTOR", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Topps Mega Stars": [
                            { "name": "PURPLE FOIL", "color": "#800080", "limitedTo": 75 },
                            { "name": "GOLD FOIL", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE FOIL", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "RED FOIL", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "BLACK FOIL", "color": "#000000", "limitedTo": 1 }
                        ],
                        "Women in Baseball": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                    },
                    "Relic": {
                        "2023 Silver Slugger Award Winners Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball Relics": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball All Stars Relics": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "City Connect Swatch Collection": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Major League Material Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Rounding the Bases Relics": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 99 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Autograph Relic": {
                        "2023 Silver Slugger Award Winners Cards Autograph Variation": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 }
                        ],
                        "Heavy Lumber Autograph Relics": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                        "Major League Material Autograph Cards": [
                            { "name": "# to 50", "color": "#FFFFFF", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Autograph": {
                        "40 70 Autograph": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball Autograph": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball All Stars Autograph": [
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "2023 Dynamic Dual Autograph": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "2023 First Pitch Autograph": [
                            { "name": "# to 99", "color": "#FFFFFF", "limitedTo": 99 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "2023 Play Ball Autograph": [
                            { "name": "# to 99", "color": "#FFFFFF", "limitedTo": 99 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Baseball Stars Autograph": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 99 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Baseball Stars Dual Autograph": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Baseball Stars Triple Autograph": [
                            { "name": "# to 5", "color": "#FFFFFF", "limitedTo": 5 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "City to City Autograph": [
                            { "name": "# to 99", "color": "#FFFFFF", "limitedTo": 99 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Signature Tunes Dual Autograph": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 }
                        ]
                    }
                }
            }
        }
    }
}