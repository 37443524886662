import { createSelector } from "reselect";

const selectUserReducer = state => state.user;

export const selectUser = createSelector(
    [selectUserReducer],
    (user) => user.user
);
export const selectAuthIsReady = createSelector(
    [selectUserReducer],
    (user) => user.authIsReady
);
export const selectTeamIndex = createSelector(
    [selectUserReducer],
    (user) => user.teamIndex
);
export const selectActivityLog = createSelector(
    [selectUserReducer],
    (user) => user.activityLog
);
export const selectRecentlyViewed = createSelector(
    [selectUserReducer],
    (user) => user.recentlyViewed
);
export const selectUserDataIsLoading = createSelector(
    [selectUserReducer],
    (user) => user.userDataIsLoading
);
export const selectCardCount = createSelector(
    [selectUserReducer],
    (user) => user.cardCount
);