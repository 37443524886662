import styled from "styled-components";

export const CardAttributeContainer = styled.h3`
background-color: ${(props) => {
        if (props.color) {
            const hex = props.color.replace('#', '');
            const bigint = parseInt(hex, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            return `rgba(${r}, ${g}, ${b}, 0.2)`;
        }
        return 'rgba(255, 255, 255, 0.1)';
    }};
    border: ${(props) => props.color ?
        `solid ${props.size === "S" ? "1px" : "2px"} ${props.color}`
        : `solid ${props.size === "S" ? "1px" : "2px"} #FFFFFF`};
    border-radius: 100px;
    font-size: ${(props) => props.size === "S" ? "10px" : "12px"};
    font-weight: 500;
    padding: 0px 8px;
    width: fit-content;

    -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
`