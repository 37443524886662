import { SYSTEM_ACTION_TYPES } from './system.types';
import { GRADIENT_DATA } from '../../data/theme-data/gradient-data';

export const setBgIsChanging = (status) => {
    return ({ type: SYSTEM_ACTION_TYPES.SET_BG_IS_CHANGING, payload: !status });
}
export const setDisplayedGradient = (gradientType) => {
    return { type: SYSTEM_ACTION_TYPES.SET_DISPLAYED_GRADIENT, payload: gradientType}
};
export const setDefaultGradient = (gradient) => {
    return ({type: SYSTEM_ACTION_TYPES.SET_DEFAULT_GRADIENT, payload: gradient }); 
}
export const setGradient = (gradientType, presetIndex=null) => {
    const newTeamIndex = Math.floor(Math.random() * Object.keys(GRADIENT_DATA).length);

    if (gradientType === "A") {
        return ({ type: SYSTEM_ACTION_TYPES.SET_GRADIENT_A, payload: presetIndex ? presetIndex : newTeamIndex });
    } else if (gradientType === "B") {
        return ({ type: SYSTEM_ACTION_TYPES.SET_GRADIENT_B, payload: presetIndex ? presetIndex : newTeamIndex });
    } else {
        alert("Error: Error changing background :(")
    }
}
export const setSystemNotification = (text) => {
    return (dispatch) => {
      dispatch({ type: SYSTEM_ACTION_TYPES.SET_SYSTEM_NOTIFICATION, payload: text });
  
      setTimeout(() => {
        dispatch({ type: SYSTEM_ACTION_TYPES.SET_SYSTEM_NOTIFICATION, payload: null });
      }, 3000);

      return
    };
  };