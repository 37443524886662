export const GRADIENT_DATA = {
    0: {
        0: '125,46,53',
        1: '171,34,41',
        team: 'Los Angeles Angels of Anaheim',
        teamID: 'LAA'
    },
    1: {
        0: '220,117,55',
        1: '12,50,130',
        team: 'Houston Astros',
        teamID: 'HOU'
    },
    2: {
        0: '245,186,70',
        1: '40,96,68',
        team: 'Oakland Athletics',
        teamID: 'OAK'
    },
    3: {
        0: '32,46,89',
        1: '34,75,138',
        team: 'Toronto Blue Jays',
        teamID: 'TOR'
    },
    4: {
        0: '172,39,52',
        1: '17,36,62',
        team: 'Atlanta Braves',
        teamID: 'ATL'
    },
    5: {
        0: '208,170,75',
        1: '23,41,72',
        team: 'Milwaukee Brewers',
        teamID: 'MIL'
    },
    6: {
        0: '3,21,62',
        1: '176,39,34',
        team: 'St. Louis Cardinals',
        teamID: 'STL'
    },
    7: {
        0: '189,65,58',
        1: '23,53,129',
        team: 'Chicago Cubs',
        teamID: 'CHC'
    },
    8: {
        0: '183,171,131',
        1: '154,41,52',
        team: 'Arizona Diamondbacks',
        teamID: 'ARI'
    },
    9: {
        0: '122,148,178',
        1: '25,70,124',
        team: 'Los Angeles Dodgers',
        teamID: 'LAD'
    },
    10: {
        0: '235,101,53',
        1: '51,51,51',
        team: 'San Francisco Giants',
        teamID: 'SFG'
    },
    11: {
        0: '184,40,32',
        1: '16,37,59',
        team: 'Cleveland Guardians',
        teamID: 'CLE'
    },
    12: {
        0: '38,91,91',
        1: '20,44,83',
        team: 'Seattle Mariners',
        teamID: 'SEA'
    },
    13: {
        0: '70,161,218',
        1: '2,16,72',
        team: 'Miami Marlins',
        teamID: 'MIA'
    },
    14: {
        0: '180,86,51',
        1: '13,46,110',
        team: 'New York Mets',
        teamID: 'NYM'
    },
    15: {
        0: '20,36,88',
        1: '171,38,52',
        team: 'Washington Nationals',
        teamID: 'WSN'
    },
    16: {
        0: '207,82,36',
        1: '0,0,0',
        team: 'Baltimore Orioles',
        teamID: 'BAL'
    },
    17: {
        0: '188,150,55',
        1: '45,36,30',
        team: 'San Diego Padres',
        teamID: 'SDP'
    },
    18: {
        0: '2,8,47',
        1: '179,41,55',
        team: 'Philadelphia Phillies',
        teamID: 'PHI'
    },
    19: {
        0: '206,167,51',
        1: '0,0,0',
        team: 'Pittsburgh Pirates',
        teamID: 'PIT'
    },
    20: {
        0: '177,42,41',
        1: '38,54,107',
        team: 'Texas Rangers',
        teamID: 'TEX'
    },
    21: {
        0: '22,61,88',
        1: '19,44,89',
        team: 'Tampa Bay Rays',
        teamID: 'TBR'
    },
    22: {
        0: '175,60,62',
        1: '20,43,83',
        team: 'Boston Red Sox',
        teamID: 'BOS'
    },
    23: {
        0: '0,0,0',
        1: '182,37,41',
        team: 'Cincinnati Reds',
        teamID: 'CIN'
    },
    24: {
        0: '0,0,0',
        1: '46,11,107',
        team: 'Colorado Rockies',
        teamID: 'COL'
    },
    25: {
        0: '134,178,217',
        1: '25,70,137',
        team: 'Kansas City Royals',
        teamID: 'KCR'
    },
    26: {
        0: '232,84,46',
        1: '6,29,64',
        team: 'Detroit Tigers',
        teamID: 'DET'
    },
    27: {
        0: '5,59,228',
        1: '235,51,35',
        team: 'Minnesota Twins',
        teamID: 'MIN'
    },
    28: {
        0: '0,0,0',
        1: '90,90,90',
        team: 'Chicago White Sox',
        teamID: 'CHC'
    },
    29: {
        0: '22,36,69',
        1: '11,41,85',
        team: 'New York Yankees',
        teamID: 'NYY'
    }
}
