import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTeamIndex } from '../../../../store/user/user.action.js';
import { selectTeamIndex } from '../../../../store/user/user.selector.js';
import { setSystemNotification } from '../../../../store/system/system.action.js';

// styles
import { FavoriteTeam, SignUpContainer } from './CreateAccount.styles.js';

// components
import InputBar from '../../../../components/input-bar/InputBar';
import InputsContainer from '../../../../components/inputs-container/InputsContainer';
import PrimaryBtn from '../../../../components/btns/primary-btn/PrimaryBtn';

// hooks
import { useCreateAccount } from '../../../../hooks/useCreateAccount';

export default function CreateAccount() {
  const dispatch = useDispatch();
  const teamIndex = useSelector(selectTeamIndex);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { error, isPending, createAccount } = useCreateAccount();

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value !== -1) {
      dispatch(setTeamIndex(value));
    }
  };

  const handleClick = () => {
    const passwordRequirements = {
      minLength: 8,
      upperCase: /[A-Z]/,
      lowerCase: /[a-z]/,
      numbers: /[0-9]/,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/
    };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!teamIndex) {
      dispatch(setSystemNotification('Please select a favorite team.'));
    } else if (!username) {
      dispatch(setSystemNotification('Please enter a username.'));
    } else if (!email) {
      dispatch(setSystemNotification('Please enter an email.'));
    } else if (!emailRegex.test(email)) {
      dispatch(setSystemNotification("Please enter a valid email."));
    } else if (!password) {
      dispatch(setSystemNotification('Please enter a password.'));
    } else if (password && !confirmPassword) {
      dispatch(setSystemNotification('Please confirm your password.'));
    } else if (password !== confirmPassword) {
      dispatch(setSystemNotification('Passwords do not match.'));
    } 
    else if (
      password.length < passwordRequirements.minLength ||
      !passwordRequirements.upperCase.test(password) ||
      !passwordRequirements.lowerCase.test(password) ||
      !passwordRequirements.numbers.test(password) ||
      !passwordRequirements.specialChar.test(password)
    ) {
      dispatch(setSystemNotification('Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters.'));
    } 
    else {
      createAccount(email, password, username, teamIndex)
        .then(() => {
          if (!error) {
            setUsername('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
          } else {
            setPassword('');
            setConfirmPassword('');
          }
        })
    }
  }

  useEffect(() => {
    setPassword('');
    setConfirmPassword('');
  }, [error]);

  return (
    <SignUpContainer>
      <InputsContainer>
        <FavoriteTeam
          className='panel-bg'
          name='teams'
          id='teams'
          onChange={handleChange}
        >
          <option value='-1' defaultValue>SELECT YOUR FAVORITE TEAM</option>
          <option value='' disabled>---AL WEST---</option>
          <option value='0'>Angels®</option>
          <option value='1'>Houston Astros®</option>
          <option value='2'>Oakland Athletics™</option>
          <option value='12'>Seattle Mariners™</option>
          <option value='20'>Texas Rangers®</option>
          <option value='' disabled>---AL CENTRAL---</option>
          <option value='11'>Cleveland Guardians™</option>
          <option value='25'>Kansas City Royals®</option>
          <option value='26'>Detroit Tigers®</option>
          <option value='27'>Minnesota Twins®</option>
          <option value='28'>Chicago White Sox®</option>
          <option value='' disabled>---AL EAST---</option>
          <option value='3'>Toronto Blue Jays®</option>
          <option value='16'>Baltimore Orioles®</option>
          <option value='21'>Tampa Bay Rays™</option>
          <option value='22'>Boston Red Sox®</option>
          <option value='29'>New York Yankees®</option>
          <option value='' disabled>---NL WEST---</option>
          <option value='8'>Arizona Diamondbacks®</option>
          <option value='9'>Los Angeles Dodgers®</option>
          <option value='10'>San Francisco Giants®</option>
          <option value='17'>San Diego Padres™</option>
          <option value='24'>Colorado Rockies™</option>
          <option value='' disabled>---NL CENTRAL---</option>
          <option value='5'>Milwaukee Brewers™</option>
          <option value='6'>St. Louis Cardinals®</option>
          <option value='7'>Chicago Cubs®</option>
          <option value='19'>Pittsburgh Pirates®</option>
          <option value='23'>Cincinnati Reds®</option>
          <option value='' disabled>---NL EAST---</option>
          <option value='4'>Atlanta Braves™</option>
          <option value='13'>Miami Marlins®</option>
          <option value='14'>New York Mets®</option>
          <option value='15'>Washington Nationals®</option>
          <option value='18'>Philadelphia Phillies®</option>
        </FavoriteTeam>
        <InputBar type='text' pH='USERNAME' value={username} updateValue={setUsername} />
        <InputBar type='email' pH='EMAIL' value={email} updateValue={setEmail} />
        <InputBar type='password' pH='PASSWORD' value={password} updateValue={setPassword} />
        <InputBar type='password' pH='CONFIRM PASSWORD' value={confirmPassword} updateValue={setConfirmPassword} />
        <div onClick={handleClick}>
          <PrimaryBtn title={!isPending ? 'SIGN UP' : 'LOADING...'} />
        </div>
      </InputsContainer>
      <h6 className='agreement'><i>By continuing you agree to the</i> <b>CardVault Terms of Service</b><i>, and acknowledge you’ve read our </i><b>Privacy Policy</b>.</h6>
    </SignUpContainer>
  )
}