import { combineReducers } from "redux"

// sub-reducers
import { cardReducer } from "./card/card.reducer";
import { systemReducer } from "./system/system.reducer";
import { userReducer } from "./user/user.reducer";

export const rootReducer = combineReducers({
    user: userReducer,
    card: cardReducer,
    system: systemReducer
})