import styled from "styled-components";
import { motion } from 'framer-motion';

export const PanelMediumContainer = styled(motion.div)`
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: fit-content;
    min-height: 160px;
    position: relative;
    width: 294px;

    .cover-image-container {
        width: 80px;
    }
`