// styles
import { InputBarContainer } from './InputBar.styles.js';

export default function InputBar({ type, pH="", value, updateValue }) {

  return (
      <InputBarContainer
          className='panel-bg'
          type={type}
          onChange={(e) => updateValue(e.target.value)}
          placeholder={pH}
          value={value}
      >
      </InputBarContainer>
  )
}