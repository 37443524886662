import styled from "styled-components";

export const TeamSelectionContainer = styled.div`
`
export const TeamBar = styled.div`
    border-radius: 4px;
    color: #FFFFFF;
    transition: 0.3s;
    transition-timing-function: ease-out;
    width: 420px;

    h3 {
        font-size: 12px;
        font-weight: 600;
    }
`
export const ExpandedContent = styled.div`
    align-items: start;
    display: flex;
    gap: 21px;
    flex-wrap: wrap;
    justify-content: start;
    overflow: hidden;
    padding: 16px;
`
export const TeamDisplay = styled.div`
    height: 36px;
    width: 36px;

    .active {
        border: 3px solid rgba(255,255,255,0.67);
        box-sizing: border-box;
    }

    img {
        border-radius: 50%;
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
`
export const MinimizedContent = styled.div`
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;

    img {
        border: 2px solid rgba(255,255,255,0.15);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translate(0%, -50%);
    }
`
export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`