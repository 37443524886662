import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ProfileWrapper = styled.div`
  position: relative;
`
export const HeaderProfilePillContainer = styled.div`
  align-items: center;
  backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.25);
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  height: 44px;
  position: relative;
  transition: height 0.4s ease-in-out, transform 0.4s, border-radius 0.4s ease-in-out;

  img {
    border-radius: 100px;
    height: 40px;
    padding: 2px 0px 2px 2px;
    user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
  }

  h3 {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding: 0px 16px;
    user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
  }
`;
export const DropdownMenu = styled(motion.div)`
  background: transparent;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  width: 196px;
  z-index: 100;

  ul {
    background-color: rgba(255,255,255,0.25);
    border-radius: 12px;
    list-style-type: none;
    margin: 0;
    padding: 4px;
    text-align: right;
    
    li {
      border-radius: 8px;
      color: #FFFFFF;
      cursor: pointer;
      padding: 12px 16px;
      transition: background-color 0.2s ease-in-out;
      user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;
export const MenuContent = styled.div`
  background-color: transparent;

  div {
    height: 8px;
  }
`