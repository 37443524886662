// components
import AccountDetails from './components/account-details/AccountDetails';
import ChangePassword from './components/change-password/ChangePassword';

// styles
import {
  AccountPageContainer,
  AccountContent
} from './AccountPage.styles.js';

export default function AccountPage() {
  return (
    <AccountPageContainer>
      <h1>ACCOUNT</h1>
      <AccountContent>
        <AccountDetails />
        <ChangePassword />
      </AccountContent>
    </AccountPageContainer>
  )
}