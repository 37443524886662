import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/user/user.selector';
import { getAvailableSports } from '../../../utils/firebase/firebase.utils';

// components
import SelectionPanel from '../../selection-panel/SelectionPanel';

export default function SportSelection({ type }) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const params = useParams();
  const [availableSports, setAvailableSports] = useState([]);

  useEffect(() => {
    const fetchSports = async () => {
      const sports = type === "add" ? await getAvailableSports() : await getAvailableSports(user.uid);
      setAvailableSports(sports);
    };
  
    fetchSports();
  }, [type, user.uid]);

  const addBasePath = `/add${Object.values(params).map(param => `/${param}`).join('')}`;
  const viewBasePath = `/collection${Object.values(params).map(param => `/${param}`).join('')}`;

  const displayData = availableSports.map(sport => ({
    name: sport.sport,
    count: sport.totalCardCount
  }));

  const handleItemClick = (sport) => {
    navigate(`${type === "add" ? addBasePath : viewBasePath}/${sport}`);
  };

  return <SelectionPanel data={displayData} onItemClick={handleItemClick} basePath={type === "add" ? addBasePath : viewBasePath} />;
}