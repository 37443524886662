// styles
import { ShowcaseContainer } from "./Showcase.styles"

export default function Showcase() {
  return (
    <ShowcaseContainer>
      <h1>SHOWCASE</h1>
    </ShowcaseContainer>
  )
}
