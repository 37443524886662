import React from 'react';
import PropTypes from 'prop-types';
import { fadeInUp } from '../../animations/Animations.js';
import { motion } from 'framer-motion';

// styles
import {
  SelectionPanelContainer
} from './SelectionPanel.styles.js';

// components
import CardPanel from '../panel-medium/components/CardPanel.js';
import PanelMedium from '../panel-medium/PanelMedium.js';

function SelectionPanel({ data, basePath }) {
  const dataArray = Array.isArray(data) ? data : Object.keys(data);

  return (
    <SelectionPanelContainer>
      {dataArray.map((item, index) => (
        <motion.div
          key={index}
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
          custom={index * 0.025}
        >
          <PanelMedium path={`${basePath}/${item.name}`}>
            <CardPanel title={item.name} cardCount={item.count} />
          </PanelMedium>
        </motion.div>
      ))}
    </SelectionPanelContainer>
  );
}

SelectionPanel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number
  })).isRequired,
  basePath: PropTypes.string.isRequired,
};

export default SelectionPanel;