import { useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../utils/firebase/firebase.utils';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/user/user.action';

export const useLogOut = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const logOutUser = async () => {
        setError(null);
        setIsPending(true);

        try {
            await signOut(auth);
            dispatch(setUser()); // Dispatch the logOut action to Redux
            setError(null);
            setIsPending(false);
        } catch (err) {
            setError(err.message);
            setIsPending(false);
        }
    };

    return { logOut: logOutUser, error, isPending };
};