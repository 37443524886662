import styled from "styled-components";

export const MainContainer = styled.div`
    width: 100vw;
`
export const MainGradientBGContainer = styled.div`
    height: 100vh;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: -500;
`