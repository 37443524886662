// CHECKLIST: COMPLETE* (* Oversized All Star Game Red Carpet Card Redemptions, First Pitch Autograph not added)
// PARALLELS: INCOMPLETE

export const MLB_2024_TOPPS_US = {
    "MLB": {
        "2024": {
            "Topps": {
                "Update Series": {
                    "Base Card Set": {
                        "Base": {
                            "US1": {
                                "cardNumber": "US1",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US2": {
                                "cardNumber": "US2",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US3": {
                                "cardNumber": "US3",
                                "name": ["Tyler Nevin"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US4": {
                                "cardNumber": "US4",
                                "name": ["Jorge Alcala"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US5": {
                                "cardNumber": "US5",
                                "name": ["Mitchell Parker"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US6": {
                                "cardNumber": "US6",
                                "name": ["Randal Grichuk"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US7": {
                                "cardNumber": "US7",
                                "name": ["Alan Trejo"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US8": {
                                "cardNumber": "US8",
                                "name": ["Yuki Matsui"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US9": {
                                "cardNumber": "US9",
                                "name": ["Isiah Kiner-Falefa"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US10": {
                                "cardNumber": "US10",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US11": {
                                "cardNumber": "US11",
                                "name": ["Robbie Grossman"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US12": {
                                "cardNumber": "US12",
                                "name": ["Ha-Seong Kim", "Jung Hoo Lee"],
                                "team": ["San Diego Padres™", "San Francisco Giants®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US13": {
                                "cardNumber": "US13",
                                "name": ["Shohei Ohtani", "Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers™", "Los Angeles Dodgers™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US14": {
                                "cardNumber": "US14",
                                "name": ["Chris Paddack"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US15": {
                                "cardNumber": "US15",
                                "name": ["Reynaldo López"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US16": {
                                "cardNumber": "US16",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US17": {
                                "cardNumber": "US17",
                                "name": ["Elvis Peguero"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US18": {
                                "cardNumber": "US18",
                                "name": ["Héctor Neris"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US19": {
                                "cardNumber": "US19",
                                "name": ["Kevin Kiermaier"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US20": {
                                "cardNumber": "US20",
                                "name": ["Mitch Garver"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US21": {
                                "cardNumber": "US21",
                                "name": ["Romy Gonzalez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US22": {
                                "cardNumber": "US22",
                                "name": ["Jorge Barrosa"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US23": {
                                "cardNumber": "US23",
                                "name": ["Victor Caratini"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US24": {
                                "cardNumber": "US24",
                                "name": ["Daniel Hudson"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "US25": {
                                "cardNumber": "US25",
                                "name": ["Matt Sauer"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US26": {
                                "cardNumber": "US26",
                                "name": ["J.P. France"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US27": {
                                "cardNumber": "US27",
                                "name": ["James Paxton"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "US28": {
                                "cardNumber": "US28",
                                "name": ["Mitch Spence"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US29": {
                                "cardNumber": "US29",
                                "name": ["Blake Walston"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US30": {
                                "cardNumber": "US30",
                                "name": ["Samad Taylor"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US31": {
                                "cardNumber": "US31",
                                "name": ["Brandon Crawford"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US32": {
                                "cardNumber": "US32",
                                "name": ["Cristopher Sánchez"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US33": {
                                "cardNumber": "US33",
                                "name": ["Tyler Holton"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US34": {
                                "cardNumber": "US34",
                                "name": ["Brett Sullivan"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US35": {
                                "cardNumber": "US35",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US36": {
                                "cardNumber": "US36",
                                "name": ["Freddy Fermin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US37": {
                                "cardNumber": "US37",
                                "name": ["Kyle McCann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US38": {
                                "cardNumber": "US38",
                                "name": ["Ricky Vanasco"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US39": {
                                "cardNumber": "US39",
                                "name": ["Gary Sánchez"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US40": {
                                "cardNumber": "US40",
                                "name": ["Nate Pearson"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US41": {
                                "cardNumber": "US41",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US42": {
                                "cardNumber": "US42",
                                "name": ["Scott Barlow"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US43": {
                                "cardNumber": "US43",
                                "name": ["Griffin Jax"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US44": {
                                "cardNumber": "US44",
                                "name": ["Adley Rutschman", "Craig Kimbrel"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US45": {
                                "cardNumber": "US45",
                                "name": ["Bryse Wilson"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US46": {
                                "cardNumber": "US46",
                                "name": ["Trent Grisham"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US47": {
                                "cardNumber": "US47",
                                "name": ["Justin Topa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US48": {
                                "cardNumber": "US48",
                                "name": ["Jackson Merrill", "Fernando Tatis Jr."],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US49": {
                                "cardNumber": "US49",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US50": {
                                "cardNumber": "US50",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US51": {
                                "cardNumber": "US51",
                                "name": ["Bryan Ramos"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US52": {
                                "cardNumber": "US52",
                                "name": ["Spencer Arrighetti"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US53": {
                                "cardNumber": "US53",
                                "name": ["Randy Rodríguez"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US54": {
                                "cardNumber": "US54",
                                "name": ["Trevor Rogers"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US55": {
                                "cardNumber": "US55",
                                "name": ["Patrick Bailey", "Blake Snell"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US56": {
                                "cardNumber": "US56",
                                "name": ["José Buttó"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US57": {
                                "cardNumber": "US57",
                                "name": ["Blake Snell"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US58": {
                                "cardNumber": "US58",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US59": {
                                "cardNumber": "US59",
                                "name": ["Clayton Beeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US60": {
                                "cardNumber": "US60",
                                "name": ["John Schreiber"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US61": {
                                "cardNumber": "US61",
                                "name": ["Forrest Whitley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US62": {
                                "cardNumber": "US62",
                                "name": ["Amed Rosario"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US63": {
                                "cardNumber": "US63",
                                "name": ["Vladimir Guerrero Jr.", "Justin Turner"],
                                "team": ["Toronto Blue Jays®", "Toronto Blue Jays®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US64": {
                                "cardNumber": "US64",
                                "name": ["Jorge Soler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US65": {
                                "cardNumber": "US65",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US66": {
                                "cardNumber": "US66",
                                "name": ["Alek Jacob"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US67": {
                                "cardNumber": "US67",
                                "name": ["Nelson Velázquez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US68": {
                                "cardNumber": "US68",
                                "name": ["Ryan Yarbrough"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "US69": {
                                "cardNumber": "US69",
                                "name": ["Osvaldo Bido", "Tyler Ferguson"],
                                "team": ["Oakland Athletics™", "Oakland Athletics™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US70": {
                                "cardNumber": "US70",
                                "name": ["Emmanuel Rivera"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US71": {
                                "cardNumber": "US71",
                                "name": ["Nick Gordon"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US72": {
                                "cardNumber": "US72",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US73": {
                                "cardNumber": "US73",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US74": {
                                "cardNumber": "US74",
                                "name": ["Jeffrey Springs"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US75": {
                                "cardNumber": "US75",
                                "name": ["Martín Perez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US76": {
                                "cardNumber": "US76",
                                "name": ["Shohei Ohtani", "Mike Trout"],
                                "team": ["Los Angeles Dodgers™", "Angels®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US77": {
                                "cardNumber": "US77",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US78": {
                                "cardNumber": "US78",
                                "name": ["Brent Suter"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US79": {
                                "cardNumber": "US79",
                                "name": ["Daniel Vogelbach"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US80": {
                                "cardNumber": "US80",
                                "name": ["Kyle Muller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US81": {
                                "cardNumber": "US81",
                                "name": ["Manuel Margot"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US82": {
                                "cardNumber": "US82",
                                "name": ["Dedniel Núñez", "Tyler Jay"],
                                "team": ["New York Mets®", "New York Mets®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US83": {
                                "cardNumber": "US83",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US84": {
                                "cardNumber": "US84",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US85": {
                                "cardNumber": "US85",
                                "name": ["Abraham Toro"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US86": {
                                "cardNumber": "US86",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US87": {
                                "cardNumber": "US87",
                                "name": ["Hoby Milner"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US88": {
                                "cardNumber": "US88",
                                "name": ["Fernando Tatis Jr.", "Luis Arraez"],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US89": {
                                "cardNumber": "US89",
                                "name": ["Andrew Knizner"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US90": {
                                "cardNumber": "US90",
                                "name": ["Adam Duvall"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US91": {
                                "cardNumber": "US91",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US92": {
                                "cardNumber": "US92",
                                "name": ["Tyler Rogers"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US93": {
                                "cardNumber": "US93",
                                "name": ["Lucas Erceg"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US94": {
                                "cardNumber": "US94",
                                "name": ["Jordan Leasure"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US95": {
                                "cardNumber": "US95",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US96": {
                                "cardNumber": "US96",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US97": {
                                "cardNumber": "US97",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US98": {
                                "cardNumber": "US98",
                                "name": ["Nick Ahmed"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US99": {
                                "cardNumber": "US99",
                                "name": ["Ryan Feltner"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US101": {
                                "cardNumber": "US101",
                                "name": ["Hunter Harvey"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US102": {
                                "cardNumber": "US102",
                                "name": ["Jason Adam"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US103": {
                                "cardNumber": "US103",
                                "name": ["Ryan Weathers"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US104": {
                                "cardNumber": "US104",
                                "name": ["David Peterson"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US105": {
                                "cardNumber": "US105",
                                "name": ["Bryan Hudson"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US106": {
                                "cardNumber": "US106",
                                "name": ["Porter Hodge", "Jake Wong"],
                                "team": ["Chicago Cubs®", "Chicago Cubs®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US107": {
                                "cardNumber": "US107",
                                "name": ["René Pinto"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US108": {
                                "cardNumber": "US108",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US109": {
                                "cardNumber": "US109",
                                "name": ["Cole Winn"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US110": {
                                "cardNumber": "US110",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US111": {
                                "cardNumber": "US111",
                                "name": ["Ryan Fernandez"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US112": {
                                "cardNumber": "US112",
                                "name": ["DL Hall"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US113": {
                                "cardNumber": "US113",
                                "name": ["Michael Siani"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US114": {
                                "cardNumber": "US114",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US115": {
                                "cardNumber": "US115",
                                "name": ["Dylan Cease"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US116": {
                                "cardNumber": "US116",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US117": {
                                "cardNumber": "US117",
                                "name": ["Eddie Rosario"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US118": {
                                "cardNumber": "US118",
                                "name": ["Carlos Carrasco"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US119": {
                                "cardNumber": "US119",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US120": {
                                "cardNumber": "US120",
                                "name": ["Dylan Lee"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US121": {
                                "cardNumber": "US121",
                                "name": ["Ryne Stanek"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US122": {
                                "cardNumber": "US122",
                                "name": ["Joey Bart"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US123": {
                                "cardNumber": "US123",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US124": {
                                "cardNumber": "US124",
                                "name": ["Jon Berti"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US125": {
                                "cardNumber": "US125",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US126": {
                                "cardNumber": "US126",
                                "name": ["Cade Cavalli"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US127": {
                                "cardNumber": "US127",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US128": {
                                "cardNumber": "US128",
                                "name": ["Lane Ramsey", "Edgar Navarro"],
                                "team": ["Chicago White Sox®", "Chicago White Sox®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US129": {
                                "cardNumber": "US129",
                                "name": ["Tanner Banks"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US130": {
                                "cardNumber": "US130",
                                "name": ["Joey Estes"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US131": {
                                "cardNumber": "US131",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US132": {
                                "cardNumber": "US132",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US133": {
                                "cardNumber": "US133",
                                "name": ["Matt Carpenter"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US134": {
                                "cardNumber": "US134",
                                "name": ["Cade Smith"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US135": {
                                "cardNumber": "US135",
                                "name": ["Joe Ross"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US136": {
                                "cardNumber": "US136",
                                "name": ["Joey Gallo"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US137": {
                                "cardNumber": "US137",
                                "name": ["Robert Garcia"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US138": {
                                "cardNumber": "US138",
                                "name": ["Cole Irvin"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US139": {
                                "cardNumber": "US139",
                                "name": ["Zack Littell"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US140": {
                                "cardNumber": "US140",
                                "name": ["Michael Rucker"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US141": {
                                "cardNumber": "US141",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US142": {
                                "cardNumber": "US142",
                                "name": ["Jeimer Candelario"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US143": {
                                "cardNumber": "US143",
                                "name": ["Trey Lipscomb"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US144": {
                                "cardNumber": "US144",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US145": {
                                "cardNumber": "US145",
                                "name": ["Adrian Houser"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US146": {
                                "cardNumber": "US146",
                                "name": ["Frankie Montas"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US147": {
                                "cardNumber": "US147",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US148": {
                                "cardNumber": "US148",
                                "name": ["Kris Bubic"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US149": {
                                "cardNumber": "US149",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US150": {
                                "cardNumber": "US150",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US151": {
                                "cardNumber": "US151",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US152": {
                                "cardNumber": "US152",
                                "name": ["Alex Verdugo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US153": {
                                "cardNumber": "US153",
                                "name": ["Joel Payamps"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US154": {
                                "cardNumber": "US154",
                                "name": ["Orlando Arcia", "Francisco Lindor"],
                                "team": ["Atlanta Braves™", "New York Mets®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US155": {
                                "cardNumber": "US155",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US156": {
                                "cardNumber": "US156",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US157": {
                                "cardNumber": "US157",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US158": {
                                "cardNumber": "US158",
                                "name": ["Danny Coulombe"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US159": {
                                "cardNumber": "US159",
                                "name": ["Chris Martin"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US160": {
                                "cardNumber": "US160",
                                "name": ["Yasmani Grandal", "Paul Skenes"],
                                "team": ["Pittsburgh Pirates®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US161": {
                                "cardNumber": "US161",
                                "name": ["José Caballero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US162": {
                                "cardNumber": "US162",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US163": {
                                "cardNumber": "US163",
                                "name": ["Clayton Andrews", "Josh Maciejewski"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US164": {
                                "cardNumber": "US164",
                                "name": ["Jesse Winker"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US165": {
                                "cardNumber": "US165",
                                "name": ["Jacob Hurtubise"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US166": {
                                "cardNumber": "US166",
                                "name": ["Michael Wacha"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US167": {
                                "cardNumber": "US167",
                                "name": ["Luke Maile"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US168": {
                                "cardNumber": "US168",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US169": {
                                "cardNumber": "US169",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US170": {
                                "cardNumber": "US170",
                                "name": ["Tom Murphy"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US171": {
                                "cardNumber": "US171",
                                "name": ["Colin Rea"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US172": {
                                "cardNumber": "US172",
                                "name": ["Colin Holderman"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US173": {
                                "cardNumber": "US173",
                                "name": ["Omar Narváez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US174": {
                                "cardNumber": "US174",
                                "name": ["Austin Hedges"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US176": {
                                "cardNumber": "US176",
                                "name": ["Lucas Giolito"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US177": {
                                "cardNumber": "US177",
                                "name": ["Trevor Williams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US178": {
                                "cardNumber": "US178",
                                "name": ["Justin Slaten"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US179": {
                                "cardNumber": "US179",
                                "name": ["Mason Black"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US180": {
                                "cardNumber": "US180",
                                "name": ["Seby Zavala"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US181": {
                                "cardNumber": "US181",
                                "name": ["Aaron Judge", "Juan Soto"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US182": {
                                "cardNumber": "US182",
                                "name": ["Matt Moore"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US183": {
                                "cardNumber": "US183",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US184": {
                                "cardNumber": "US184",
                                "name": ["Ronel Blanco"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US185": {
                                "cardNumber": "US185",
                                "name": ["Jhonny Pereda", "Emmanuel Ramirez"],
                                "team": ["Miami Marlins®", "Miami Marlins®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US186": {
                                "cardNumber": "US186",
                                "name": ["Landen Roupp"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US187": {
                                "cardNumber": "US187",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US188": {
                                "cardNumber": "US188",
                                "name": ["Eduardo Rodriguez"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US189": {
                                "cardNumber": "US189",
                                "name": ["Kyle Leahy", "Chris Roycroft"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US190": {
                                "cardNumber": "US190",
                                "name": ["Travis Jankowski"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US191": {
                                "cardNumber": "US191",
                                "name": ["Josh Gibson"],
                                "team": ["Homestead"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US192": {
                                "cardNumber": "US192",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US193": {
                                "cardNumber": "US193",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US194": {
                                "cardNumber": "US194",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US195": {
                                "cardNumber": "US195",
                                "name": ["Carson Kelly"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US196": {
                                "cardNumber": "US196",
                                "name": ["Blake Treinen"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US197": {
                                "cardNumber": "US197",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US198": {
                                "cardNumber": "US198",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US199": {
                                "cardNumber": "US199",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US200": {
                                "cardNumber": "US200",
                                "name": ["Luis Arraez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US201": {
                                "cardNumber": "US201",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US202": {
                                "cardNumber": "US202",
                                "name": ["Miguel Andujar"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US203": {
                                "cardNumber": "US203",
                                "name": ["Tim Mayza"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US204": {
                                "cardNumber": "US204",
                                "name": ["Kevin Newman"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US205": {
                                "cardNumber": "US205",
                                "name": ["Seiya Suzuki", "Shota Imanaga"],
                                "team": ["Chicago Cubs®", "Chicago Cubs®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US206": {
                                "cardNumber": "US206",
                                "name": ["Jake Bauers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US207": {
                                "cardNumber": "US207",
                                "name": ["Josh Smith"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US208": {
                                "cardNumber": "US208",
                                "name": ["Rowdy Tellez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US209": {
                                "cardNumber": "US209",
                                "name": ["Bailey Falter"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US210": {
                                "cardNumber": "US210",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US211": {
                                "cardNumber": "US211",
                                "name": ["Hunter Gaddis"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US212": {
                                "cardNumber": "US212",
                                "name": ["Kai-Wei Teng"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US213": {
                                "cardNumber": "US213",
                                "name": ["Richie Palacios"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US214": {
                                "cardNumber": "US214",
                                "name": ["Kevin Pillar"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US215": {
                                "cardNumber": "US215",
                                "name": ["Adam Frazier"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US216": {
                                "cardNumber": "US216",
                                "name": ["Vidal Bruján"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US217": {
                                "cardNumber": "US217",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US218": {
                                "cardNumber": "US218",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US219": {
                                "cardNumber": "US219",
                                "name": ["Michael Stefanic"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US220": {
                                "cardNumber": "US220",
                                "name": ["Max Schuemann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US221": {
                                "cardNumber": "US221",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US222": {
                                "cardNumber": "US222",
                                "name": ["Alex Speas"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US223": {
                                "cardNumber": "US223",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US224": {
                                "cardNumber": "US224",
                                "name": ["Carlos Santana"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US225": {
                                "cardNumber": "US225",
                                "name": ["Nick Nastrini"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US226": {
                                "cardNumber": "US226",
                                "name": ["Michael Lorenzen"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US227": {
                                "cardNumber": "US227",
                                "name": ["Chandler Seagle", "Mason McCoy"],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US228": {
                                "cardNumber": "US228",
                                "name": ["Jose Trevino"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US229": {
                                "cardNumber": "US229",
                                "name": ["Michael Taylor"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US230": {
                                "cardNumber": "US230",
                                "name": ["Jorge Polanco"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US231": {
                                "cardNumber": "US231",
                                "name": ["J.D. Davis"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US232": {
                                "cardNumber": "US232",
                                "name": ["Jeff Hoffman"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US233": {
                                "cardNumber": "US233",
                                "name": ["Wenceel Pérez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US234": {
                                "cardNumber": "US234",
                                "name": ["Ben Rortvedt"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US235": {
                                "cardNumber": "US235",
                                "name": ["Tyler Alexander"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US236": {
                                "cardNumber": "US236",
                                "name": ["Max Meyer"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US237": {
                                "cardNumber": "US237",
                                "name": ["Tanner Scott"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US238": {
                                "cardNumber": "US238",
                                "name": ["Elly De La Cruz", "Julio Rodríguez"],
                                "team": ["Cincinnati Reds®", "Seattle Mariners™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US239": {
                                "cardNumber": "US239",
                                "name": ["Spencer Turnbull"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US240": {
                                "cardNumber": "US240",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US241": {
                                "cardNumber": "US241",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US242": {
                                "cardNumber": "US242",
                                "name": ["Chris Roller"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US243": {
                                "cardNumber": "US243",
                                "name": ["J.D. Martinez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US244": {
                                "cardNumber": "US244",
                                "name": ["Korey Lee"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US245": {
                                "cardNumber": "US245",
                                "name": ["Luis Vázquez"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US246": {
                                "cardNumber": "US246",
                                "name": ["Jacob Stallings"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US247": {
                                "cardNumber": "US247",
                                "name": ["Eugenio Suárez"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US248": {
                                "cardNumber": "US248",
                                "name": ["Davis Wendzel"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US249": {
                                "cardNumber": "US249",
                                "name": ["Tucker Barnhart"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["LaMonte Wade Jr."],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US251": {
                                "cardNumber": "US251",
                                "name": ["Tony Kemp"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US252": {
                                "cardNumber": "US252",
                                "name": ["Blair Henley", "Shawn Dubin"],
                                "team": ["Houston Astros®", "Houston Astros®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US253": {
                                "cardNumber": "US253",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US254": {
                                "cardNumber": "US254",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US255": {
                                "cardNumber": "US255",
                                "name": ["Nick Martinez"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US256": {
                                "cardNumber": "US256",
                                "name": ["Alec Burleson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US257": {
                                "cardNumber": "US257",
                                "name": ["Joey Ortiz"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US258": {
                                "cardNumber": "US258",
                                "name": ["Alex Vesia"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US259": {
                                "cardNumber": "US259",
                                "name": ["Stephen Kolek"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US260": {
                                "cardNumber": "US260",
                                "name": ["Luke Raley"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US261": {
                                "cardNumber": "US261",
                                "name": ["Austin Slater"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US262": {
                                "cardNumber": "US262",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US263": {
                                "cardNumber": "US263",
                                "name": ["Ryan Helsley"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US264": {
                                "cardNumber": "US264",
                                "name": ["Nick Senzel"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US265": {
                                "cardNumber": "US265",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US266": {
                                "cardNumber": "US266",
                                "name": ["Tyler Wade"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US267": {
                                "cardNumber": "US267",
                                "name": ["Nasim Nuñez"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US268": {
                                "cardNumber": "US268",
                                "name": ["Naoyuki Uwasawa"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US269": {
                                "cardNumber": "US269",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US270": {
                                "cardNumber": "US270",
                                "name": ["Evan Justice", "Anthony Molina"],
                                "team": ["Colorado Rockies™", "Colorado Rockies™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US271": {
                                "cardNumber": "US271",
                                "name": ["Erik Miller", "Nick Avila"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US272": {
                                "cardNumber": "US272",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US273": {
                                "cardNumber": "US273",
                                "name": ["Tyler O'Neill"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US274": {
                                "cardNumber": "US274",
                                "name": ["Matt Thaiss"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US275": {
                                "cardNumber": "US275",
                                "name": ["Austin Shenton"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US276": {
                                "cardNumber": "US276",
                                "name": ["Alex Wood"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US277": {
                                "cardNumber": "US277",
                                "name": ["Robbie Ray"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US278": {
                                "cardNumber": "US278",
                                "name": ["Mark Leiter"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US279": {
                                "cardNumber": "US279",
                                "name": ["Leo Rivas"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US280": {
                                "cardNumber": "US280",
                                "name": ["Keegan Akin"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US281": {
                                "cardNumber": "US281",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US282": {
                                "cardNumber": "US282",
                                "name": ["Adam Cimber"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US283": {
                                "cardNumber": "US283",
                                "name": ["Johnathan Rodríguez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US284": {
                                "cardNumber": "US284",
                                "name": ["David Robertson"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US285": {
                                "cardNumber": "US285",
                                "name": ["Jackson Holliday", "Gunnar Henderson"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US286": {
                                "cardNumber": "US286",
                                "name": ["J.D. Martinez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US287": {
                                "cardNumber": "US287",
                                "name": ["Jordan Hicks"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US288": {
                                "cardNumber": "US288",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US289": {
                                "cardNumber": "US289",
                                "name": ["Byron Buxton", "Carlos Correa"],
                                "team": ["Minnesota Twins®", "Minnesota Twins®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US290": {
                                "cardNumber": "US290",
                                "name": ["Jason Foley"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US291": {
                                "cardNumber": "US291",
                                "name": ["Lenyn Sosa"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US292": {
                                "cardNumber": "US292",
                                "name": ["Miguel Sano"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US293": {
                                "cardNumber": "US293",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US294": {
                                "cardNumber": "US294",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US295": {
                                "cardNumber": "US295",
                                "name": ["Simeon Woods Richardson"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US296": {
                                "cardNumber": "US296",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US297": {
                                "cardNumber": "US297",
                                "name": ["Jordan Montgomery"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US298": {
                                "cardNumber": "US298",
                                "name": ["Robert Suarez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US299": {
                                "cardNumber": "US299",
                                "name": ["Tobias Myers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US301": {
                                "cardNumber": "US301",
                                "name": ["Jesse Chavez"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US302": {
                                "cardNumber": "US302",
                                "name": ["Kirby Yates"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US303": {
                                "cardNumber": "US303",
                                "name": ["Ildemaro Vargas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US304": {
                                "cardNumber": "US304",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US305": {
                                "cardNumber": "US305",
                                "name": ["Eli Villalobos", "Anthony Maldonado"],
                                "team": ["Miami Marlins®", "Miami Marlins®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US306": {
                                "cardNumber": "US306",
                                "name": ["Easton Lucas", "Angel Felipe"],
                                "team": ["Oakland Athletics™", "Oakland Athletics™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US307": {
                                "cardNumber": "US307",
                                "name": ["Santiago Espinal"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US308": {
                                "cardNumber": "US308",
                                "name": ["Fernando Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US309": {
                                "cardNumber": "US309",
                                "name": ["Adam Ottavino"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US310": {
                                "cardNumber": "US310",
                                "name": ["Oliver Dunn"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US311": {
                                "cardNumber": "US311",
                                "name": ["TJ Hopkins"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US312": {
                                "cardNumber": "US312",
                                "name": ["Erik Swanson"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US313": {
                                "cardNumber": "US313",
                                "name": ["Luis Gil"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US314": {
                                "cardNumber": "US314",
                                "name": ["Jair Camargo"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US315": {
                                "cardNumber": "US315",
                                "name": ["David Bañuelos", "Matt Krook"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US316": {
                                "cardNumber": "US316",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US317": {
                                "cardNumber": "US317",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US318": {
                                "cardNumber": "US318",
                                "name": ["Nick Martini"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US319": {
                                "cardNumber": "US319",
                                "name": ["Ronel Blanco"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US320": {
                                "cardNumber": "US320",
                                "name": ["Zach DeLoach"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US321": {
                                "cardNumber": "US321",
                                "name": ["Peter Fairbanks"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US322": {
                                "cardNumber": "US322",
                                "name": ["Roddery Muñoz"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US323": {
                                "cardNumber": "US323",
                                "name": ["Jon Singleton"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US324": {
                                "cardNumber": "US324",
                                "name": ["Dairon Blanco"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US325": {
                                "cardNumber": "US325",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US326": {
                                "cardNumber": "US326",
                                "name": ["Giovanny Gallegos"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US327": {
                                "cardNumber": "US327",
                                "name": ["Dinelson Lamet"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US328": {
                                "cardNumber": "US328",
                                "name": ["Ramón Urías"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US329": {
                                "cardNumber": "US329",
                                "name": ["Steven Matz"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US330": {
                                "cardNumber": "US330",
                                "name": ["A.J. Puk"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US331": {
                                "cardNumber": "US331",
                                "name": ["Cody Bradford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US332": {
                                "cardNumber": "US332",
                                "name": ["Andrew Chafin"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US333": {
                                "cardNumber": "US333",
                                "name": ["Yasmani Grandal"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US334": {
                                "cardNumber": "US334",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US335": {
                                "cardNumber": "US335",
                                "name": ["Pierce Johnson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US336": {
                                "cardNumber": "US336",
                                "name": ["Addison Barger"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US337": {
                                "cardNumber": "US337",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US338": {
                                "cardNumber": "US338",
                                "name": ["Pedro Pagés"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US339": {
                                "cardNumber": "US339",
                                "name": ["Aroldis Chapman"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US340": {
                                "cardNumber": "US340",
                                "name": ["Gavin Stone"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US341": {
                                "cardNumber": "US341",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US342": {
                                "cardNumber": "US342",
                                "name": ["Dakota Hudson"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US343": {
                                "cardNumber": "US343",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US344": {
                                "cardNumber": "US344",
                                "name": ["Ernie Clement"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US345": {
                                "cardNumber": "US345",
                                "name": ["Louie Varland"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US346": {
                                "cardNumber": "US346",
                                "name": ["Gio Urshela"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US347": {
                                "cardNumber": "US347",
                                "name": ["Jonathan Cannon"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US348": {
                                "cardNumber": "US348",
                                "name": ["Hunter Stratton"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US349": {
                                "cardNumber": "US349",
                                "name": ["Cam Eden"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US350": {
                                "cardNumber": "US350",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            }
                        },
                        "Golden Mirror Image Variation": {
                            "US1": {
                                "cardNumber": "US1",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US2": {
                                "cardNumber": "US2",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US3": {
                                "cardNumber": "US3",
                                "name": ["Tyler Nevin"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US4": {
                                "cardNumber": "US4",
                                "name": ["Jorge Alcala"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US5": {
                                "cardNumber": "US5",
                                "name": ["Mitchell Parker"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US6": {
                                "cardNumber": "US6",
                                "name": ["Randal Grichuk"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US7": {
                                "cardNumber": "US7",
                                "name": ["Alan Trejo"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US8": {
                                "cardNumber": "US8",
                                "name": ["Yuki Matsui"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US9": {
                                "cardNumber": "US9",
                                "name": ["Isiah Kiner-Falefa"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US10": {
                                "cardNumber": "US10",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US11": {
                                "cardNumber": "US11",
                                "name": ["Robbie Grossman"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US12": {
                                "cardNumber": "US12",
                                "name": ["Ha-Seong Kim", "Jung Hoo Lee"],
                                "team": ["San Diego Padres™", "San Francisco Giants®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US13": {
                                "cardNumber": "US13",
                                "name": ["Shohei Ohtani", "Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers™", "Los Angeles Dodgers™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US14": {
                                "cardNumber": "US14",
                                "name": ["Chris Paddack"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US15": {
                                "cardNumber": "US15",
                                "name": ["Reynaldo López"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US16": {
                                "cardNumber": "US16",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US17": {
                                "cardNumber": "US17",
                                "name": ["Elvis Peguero"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US18": {
                                "cardNumber": "US18",
                                "name": ["Héctor Neris"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US19": {
                                "cardNumber": "US19",
                                "name": ["Kevin Kiermaier"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US20": {
                                "cardNumber": "US20",
                                "name": ["Mitch Garver"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US21": {
                                "cardNumber": "US21",
                                "name": ["Romy Gonzalez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US22": {
                                "cardNumber": "US22",
                                "name": ["Jorge Barrosa"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US23": {
                                "cardNumber": "US23",
                                "name": ["Victor Caratini"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US24": {
                                "cardNumber": "US24",
                                "name": ["Daniel Hudson"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "US25": {
                                "cardNumber": "US25",
                                "name": ["Matt Sauer"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US26": {
                                "cardNumber": "US26",
                                "name": ["J.P. France"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US27": {
                                "cardNumber": "US27",
                                "name": ["James Paxton"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "US28": {
                                "cardNumber": "US28",
                                "name": ["Mitch Spence"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US29": {
                                "cardNumber": "US29",
                                "name": ["Blake Walston"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US30": {
                                "cardNumber": "US30",
                                "name": ["Samad Taylor"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US31": {
                                "cardNumber": "US31",
                                "name": ["Brandon Crawford"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US32": {
                                "cardNumber": "US32",
                                "name": ["Cristopher Sánchez"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US33": {
                                "cardNumber": "US33",
                                "name": ["Tyler Holton"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US34": {
                                "cardNumber": "US34",
                                "name": ["Brett Sullivan"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US35": {
                                "cardNumber": "US35",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US36": {
                                "cardNumber": "US36",
                                "name": ["Freddy Fermin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US37": {
                                "cardNumber": "US37",
                                "name": ["Kyle McCann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US38": {
                                "cardNumber": "US38",
                                "name": ["Ricky Vanasco"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US39": {
                                "cardNumber": "US39",
                                "name": ["Gary Sánchez"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US40": {
                                "cardNumber": "US40",
                                "name": ["Nate Pearson"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US41": {
                                "cardNumber": "US41",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US42": {
                                "cardNumber": "US42",
                                "name": ["Scott Barlow"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US43": {
                                "cardNumber": "US43",
                                "name": ["Griffin Jax"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US44": {
                                "cardNumber": "US44",
                                "name": ["Adley Rutschman", "Craig Kimbrel"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US45": {
                                "cardNumber": "US45",
                                "name": ["Bryse Wilson"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US46": {
                                "cardNumber": "US46",
                                "name": ["Trent Grisham"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US47": {
                                "cardNumber": "US47",
                                "name": ["Justin Topa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US48": {
                                "cardNumber": "US48",
                                "name": ["Jackson Merrill", "Fernando Tatis Jr."],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US49": {
                                "cardNumber": "US49",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US50": {
                                "cardNumber": "US50",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US51": {
                                "cardNumber": "US51",
                                "name": ["Bryan Ramos"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US52": {
                                "cardNumber": "US52",
                                "name": ["Spencer Arrighetti"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US53": {
                                "cardNumber": "US53",
                                "name": ["Randy Rodríguez"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US54": {
                                "cardNumber": "US54",
                                "name": ["Trevor Rogers"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US55": {
                                "cardNumber": "US55",
                                "name": ["Patrick Bailey", "Blake Snell"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US56": {
                                "cardNumber": "US56",
                                "name": ["José Buttó"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US57": {
                                "cardNumber": "US57",
                                "name": ["Blake Snell"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US58": {
                                "cardNumber": "US58",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US59": {
                                "cardNumber": "US59",
                                "name": ["Clayton Beeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US60": {
                                "cardNumber": "US60",
                                "name": ["John Schreiber"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US61": {
                                "cardNumber": "US61",
                                "name": ["Forrest Whitley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US62": {
                                "cardNumber": "US62",
                                "name": ["Amed Rosario"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US63": {
                                "cardNumber": "US63",
                                "name": ["Vladimir Guerrero Jr.", "Justin Turner"],
                                "team": ["Toronto Blue Jays®", "Toronto Blue Jays®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US64": {
                                "cardNumber": "US64",
                                "name": ["Jorge Soler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US65": {
                                "cardNumber": "US65",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US66": {
                                "cardNumber": "US66",
                                "name": ["Alek Jacob"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US67": {
                                "cardNumber": "US67",
                                "name": ["Nelson Velázquez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US68": {
                                "cardNumber": "US68",
                                "name": ["Ryan Yarbrough"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "US69": {
                                "cardNumber": "US69",
                                "name": ["Osvaldo Bido", "Tyler Ferguson"],
                                "team": ["Oakland Athletics™", "Oakland Athletics™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US70": {
                                "cardNumber": "US70",
                                "name": ["Emmanuel Rivera"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US71": {
                                "cardNumber": "US71",
                                "name": ["Nick Gordon"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US72": {
                                "cardNumber": "US72",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US73": {
                                "cardNumber": "US73",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US74": {
                                "cardNumber": "US74",
                                "name": ["Jeffrey Springs"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US75": {
                                "cardNumber": "US75",
                                "name": ["Martín Perez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US76": {
                                "cardNumber": "US76",
                                "name": ["Shohei Ohtani", "Mike Trout"],
                                "team": ["Los Angeles Dodgers™", "Angels®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US77": {
                                "cardNumber": "US77",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US78": {
                                "cardNumber": "US78",
                                "name": ["Brent Suter"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US79": {
                                "cardNumber": "US79",
                                "name": ["Daniel Vogelbach"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US80": {
                                "cardNumber": "US80",
                                "name": ["Kyle Muller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US81": {
                                "cardNumber": "US81",
                                "name": ["Manuel Margot"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US82": {
                                "cardNumber": "US82",
                                "name": ["Dedniel Núñez", "Tyler Jay"],
                                "team": ["New York Mets®", "New York Mets®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US83": {
                                "cardNumber": "US83",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US84": {
                                "cardNumber": "US84",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US85": {
                                "cardNumber": "US85",
                                "name": ["Abraham Toro"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US86": {
                                "cardNumber": "US86",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US87": {
                                "cardNumber": "US87",
                                "name": ["Hoby Milner"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US88": {
                                "cardNumber": "US88",
                                "name": ["Fernando Tatis Jr.", "Luis Arraez"],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US89": {
                                "cardNumber": "US89",
                                "name": ["Andrew Knizner"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US90": {
                                "cardNumber": "US90",
                                "name": ["Adam Duvall"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US91": {
                                "cardNumber": "US91",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US92": {
                                "cardNumber": "US92",
                                "name": ["Tyler Rogers"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US93": {
                                "cardNumber": "US93",
                                "name": ["Lucas Erceg"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US94": {
                                "cardNumber": "US94",
                                "name": ["Jordan Leasure"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US95": {
                                "cardNumber": "US95",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US96": {
                                "cardNumber": "US96",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US97": {
                                "cardNumber": "US97",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US98": {
                                "cardNumber": "US98",
                                "name": ["Nick Ahmed"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US99": {
                                "cardNumber": "US99",
                                "name": ["Ryan Feltner"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US101": {
                                "cardNumber": "US101",
                                "name": ["Hunter Harvey"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US102": {
                                "cardNumber": "US102",
                                "name": ["Jason Adam"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US103": {
                                "cardNumber": "US103",
                                "name": ["Ryan Weathers"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US104": {
                                "cardNumber": "US104",
                                "name": ["David Peterson"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US105": {
                                "cardNumber": "US105",
                                "name": ["Bryan Hudson"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US106": {
                                "cardNumber": "US106",
                                "name": ["Porter Hodge", "Jake Wong"],
                                "team": ["Chicago Cubs®", "Chicago Cubs®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US107": {
                                "cardNumber": "US107",
                                "name": ["René Pinto"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US108": {
                                "cardNumber": "US108",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US109": {
                                "cardNumber": "US109",
                                "name": ["Cole Winn"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US110": {
                                "cardNumber": "US110",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US111": {
                                "cardNumber": "US111",
                                "name": ["Ryan Fernandez"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US112": {
                                "cardNumber": "US112",
                                "name": ["DL Hall"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US113": {
                                "cardNumber": "US113",
                                "name": ["Michael Siani"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US114": {
                                "cardNumber": "US114",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US115": {
                                "cardNumber": "US115",
                                "name": ["Dylan Cease"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US116": {
                                "cardNumber": "US116",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US117": {
                                "cardNumber": "US117",
                                "name": ["Eddie Rosario"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US118": {
                                "cardNumber": "US118",
                                "name": ["Carlos Carrasco"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US119": {
                                "cardNumber": "US119",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US120": {
                                "cardNumber": "US120",
                                "name": ["Dylan Lee"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US121": {
                                "cardNumber": "US121",
                                "name": ["Ryne Stanek"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US122": {
                                "cardNumber": "US122",
                                "name": ["Joey Bart"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US123": {
                                "cardNumber": "US123",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US124": {
                                "cardNumber": "US124",
                                "name": ["Jon Berti"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US125": {
                                "cardNumber": "US125",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US126": {
                                "cardNumber": "US126",
                                "name": ["Cade Cavalli"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US127": {
                                "cardNumber": "US127",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US128": {
                                "cardNumber": "US128",
                                "name": ["Lane Ramsey", "Edgar Navarro"],
                                "team": ["Chicago White Sox®", "Chicago White Sox®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US129": {
                                "cardNumber": "US129",
                                "name": ["Tanner Banks"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US130": {
                                "cardNumber": "US130",
                                "name": ["Joey Estes"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US131": {
                                "cardNumber": "US131",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US132": {
                                "cardNumber": "US132",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US133": {
                                "cardNumber": "US133",
                                "name": ["Matt Carpenter"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US134": {
                                "cardNumber": "US134",
                                "name": ["Cade Smith"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US135": {
                                "cardNumber": "US135",
                                "name": ["Joe Ross"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US136": {
                                "cardNumber": "US136",
                                "name": ["Joey Gallo"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US137": {
                                "cardNumber": "US137",
                                "name": ["Robert Garcia"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US138": {
                                "cardNumber": "US138",
                                "name": ["Cole Irvin"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US139": {
                                "cardNumber": "US139",
                                "name": ["Zack Littell"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US140": {
                                "cardNumber": "US140",
                                "name": ["Michael Rucker"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US141": {
                                "cardNumber": "US141",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US142": {
                                "cardNumber": "US142",
                                "name": ["Jeimer Candelario"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US143": {
                                "cardNumber": "US143",
                                "name": ["Trey Lipscomb"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US144": {
                                "cardNumber": "US144",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US145": {
                                "cardNumber": "US145",
                                "name": ["Adrian Houser"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US146": {
                                "cardNumber": "US146",
                                "name": ["Frankie Montas"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US147": {
                                "cardNumber": "US147",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US148": {
                                "cardNumber": "US148",
                                "name": ["Kris Bubic"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US149": {
                                "cardNumber": "US149",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US150": {
                                "cardNumber": "US150",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US151": {
                                "cardNumber": "US151",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US152": {
                                "cardNumber": "US152",
                                "name": ["Alex Verdugo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US153": {
                                "cardNumber": "US153",
                                "name": ["Joel Payamps"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US154": {
                                "cardNumber": "US154",
                                "name": ["Orlando Arcia", "Francisco Lindor"],
                                "team": ["Atlanta Braves™", "New York Mets®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US155": {
                                "cardNumber": "US155",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US156": {
                                "cardNumber": "US156",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US157": {
                                "cardNumber": "US157",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US158": {
                                "cardNumber": "US158",
                                "name": ["Danny Coulombe"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US159": {
                                "cardNumber": "US159",
                                "name": ["Chris Martin"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US160": {
                                "cardNumber": "US160",
                                "name": ["Yasmani Grandal", "Paul Skenes"],
                                "team": ["Pittsburgh Pirates®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US161": {
                                "cardNumber": "US161",
                                "name": ["José Caballero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US162": {
                                "cardNumber": "US162",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US163": {
                                "cardNumber": "US163",
                                "name": ["Clayton Andrews", "Josh Maciejewski"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US164": {
                                "cardNumber": "US164",
                                "name": ["Jesse Winker"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US165": {
                                "cardNumber": "US165",
                                "name": ["Jacob Hurtubise"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US166": {
                                "cardNumber": "US166",
                                "name": ["Michael Wacha"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US167": {
                                "cardNumber": "US167",
                                "name": ["Luke Maile"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US168": {
                                "cardNumber": "US168",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US169": {
                                "cardNumber": "US169",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US170": {
                                "cardNumber": "US170",
                                "name": ["Tom Murphy"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US171": {
                                "cardNumber": "US171",
                                "name": ["Colin Rea"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US172": {
                                "cardNumber": "US172",
                                "name": ["Colin Holderman"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US173": {
                                "cardNumber": "US173",
                                "name": ["Omar Narváez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US174": {
                                "cardNumber": "US174",
                                "name": ["Austin Hedges"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US176": {
                                "cardNumber": "US176",
                                "name": ["Lucas Giolito"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US177": {
                                "cardNumber": "US177",
                                "name": ["Trevor Williams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US178": {
                                "cardNumber": "US178",
                                "name": ["Justin Slaten"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US179": {
                                "cardNumber": "US179",
                                "name": ["Mason Black"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US180": {
                                "cardNumber": "US180",
                                "name": ["Seby Zavala"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US181": {
                                "cardNumber": "US181",
                                "name": ["Aaron Judge", "Juan Soto"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US182": {
                                "cardNumber": "US182",
                                "name": ["Matt Moore"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US183": {
                                "cardNumber": "US183",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US184": {
                                "cardNumber": "US184",
                                "name": ["Ronel Blanco"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US185": {
                                "cardNumber": "US185",
                                "name": ["Jhonny Pereda", "Emmanuel Ramirez"],
                                "team": ["Miami Marlins®", "Miami Marlins®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US186": {
                                "cardNumber": "US186",
                                "name": ["Landen Roupp"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US187": {
                                "cardNumber": "US187",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US188": {
                                "cardNumber": "US188",
                                "name": ["Eduardo Rodriguez"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US189": {
                                "cardNumber": "US189",
                                "name": ["Kyle Leahy", "Chris Roycroft"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US190": {
                                "cardNumber": "US190",
                                "name": ["Travis Jankowski"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US191": {
                                "cardNumber": "US191",
                                "name": ["Josh Gibson"],
                                "team": ["Homestead"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US192": {
                                "cardNumber": "US192",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US193": {
                                "cardNumber": "US193",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US194": {
                                "cardNumber": "US194",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US195": {
                                "cardNumber": "US195",
                                "name": ["Carson Kelly"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US196": {
                                "cardNumber": "US196",
                                "name": ["Blake Treinen"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US197": {
                                "cardNumber": "US197",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US198": {
                                "cardNumber": "US198",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US199": {
                                "cardNumber": "US199",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US200": {
                                "cardNumber": "US200",
                                "name": ["Luis Arraez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US201": {
                                "cardNumber": "US201",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US202": {
                                "cardNumber": "US202",
                                "name": ["Miguel Andujar"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US203": {
                                "cardNumber": "US203",
                                "name": ["Tim Mayza"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US204": {
                                "cardNumber": "US204",
                                "name": ["Kevin Newman"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US205": {
                                "cardNumber": "US205",
                                "name": ["Seiya Suzuki", "Shota Imanaga"],
                                "team": ["Chicago Cubs®", "Chicago Cubs®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US206": {
                                "cardNumber": "US206",
                                "name": ["Jake Bauers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US207": {
                                "cardNumber": "US207",
                                "name": ["Josh Smith"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US208": {
                                "cardNumber": "US208",
                                "name": ["Rowdy Tellez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US209": {
                                "cardNumber": "US209",
                                "name": ["Bailey Falter"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US210": {
                                "cardNumber": "US210",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US211": {
                                "cardNumber": "US211",
                                "name": ["Hunter Gaddis"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US212": {
                                "cardNumber": "US212",
                                "name": ["Kai-Wei Teng"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US213": {
                                "cardNumber": "US213",
                                "name": ["Richie Palacios"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US214": {
                                "cardNumber": "US214",
                                "name": ["Kevin Pillar"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US215": {
                                "cardNumber": "US215",
                                "name": ["Adam Frazier"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US216": {
                                "cardNumber": "US216",
                                "name": ["Vidal Bruján"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US217": {
                                "cardNumber": "US217",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US218": {
                                "cardNumber": "US218",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US219": {
                                "cardNumber": "US219",
                                "name": ["Michael Stefanic"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US220": {
                                "cardNumber": "US220",
                                "name": ["Max Schuemann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US221": {
                                "cardNumber": "US221",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US222": {
                                "cardNumber": "US222",
                                "name": ["Alex Speas"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US223": {
                                "cardNumber": "US223",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US224": {
                                "cardNumber": "US224",
                                "name": ["Carlos Santana"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US225": {
                                "cardNumber": "US225",
                                "name": ["Nick Nastrini"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US226": {
                                "cardNumber": "US226",
                                "name": ["Michael Lorenzen"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US227": {
                                "cardNumber": "US227",
                                "name": ["Chandler Seagle", "Mason McCoy"],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US228": {
                                "cardNumber": "US228",
                                "name": ["Jose Trevino"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US229": {
                                "cardNumber": "US229",
                                "name": ["Michael Taylor"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US230": {
                                "cardNumber": "US230",
                                "name": ["Jorge Polanco"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US231": {
                                "cardNumber": "US231",
                                "name": ["J.D. Davis"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US232": {
                                "cardNumber": "US232",
                                "name": ["Jeff Hoffman"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US233": {
                                "cardNumber": "US233",
                                "name": ["Wenceel Pérez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US234": {
                                "cardNumber": "US234",
                                "name": ["Ben Rortvedt"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US235": {
                                "cardNumber": "US235",
                                "name": ["Tyler Alexander"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US236": {
                                "cardNumber": "US236",
                                "name": ["Max Meyer"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US237": {
                                "cardNumber": "US237",
                                "name": ["Tanner Scott"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US238": {
                                "cardNumber": "US238",
                                "name": ["Elly De La Cruz", "Julio Rodríguez"],
                                "team": ["Cincinnati Reds®", "Seattle Mariners™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US239": {
                                "cardNumber": "US239",
                                "name": ["Spencer Turnbull"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US240": {
                                "cardNumber": "US240",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US241": {
                                "cardNumber": "US241",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US242": {
                                "cardNumber": "US242",
                                "name": ["Chris Roller"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US243": {
                                "cardNumber": "US243",
                                "name": ["J.D. Martinez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US244": {
                                "cardNumber": "US244",
                                "name": ["Korey Lee"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US245": {
                                "cardNumber": "US245",
                                "name": ["Luis Vázquez"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US246": {
                                "cardNumber": "US246",
                                "name": ["Jacob Stallings"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US247": {
                                "cardNumber": "US247",
                                "name": ["Eugenio Suárez"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US248": {
                                "cardNumber": "US248",
                                "name": ["Davis Wendzel"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US249": {
                                "cardNumber": "US249",
                                "name": ["Tucker Barnhart"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["LaMonte Wade Jr."],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US251": {
                                "cardNumber": "US251",
                                "name": ["Tony Kemp"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US252": {
                                "cardNumber": "US252",
                                "name": ["Blair Henley", "Shawn Dubin"],
                                "team": ["Houston Astros®", "Houston Astros®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US253": {
                                "cardNumber": "US253",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US254": {
                                "cardNumber": "US254",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US255": {
                                "cardNumber": "US255",
                                "name": ["Nick Martinez"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US256": {
                                "cardNumber": "US256",
                                "name": ["Alec Burleson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US257": {
                                "cardNumber": "US257",
                                "name": ["Joey Ortiz"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US258": {
                                "cardNumber": "US258",
                                "name": ["Alex Vesia"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US259": {
                                "cardNumber": "US259",
                                "name": ["Stephen Kolek"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US260": {
                                "cardNumber": "US260",
                                "name": ["Luke Raley"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US261": {
                                "cardNumber": "US261",
                                "name": ["Austin Slater"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US262": {
                                "cardNumber": "US262",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US263": {
                                "cardNumber": "US263",
                                "name": ["Ryan Helsley"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US264": {
                                "cardNumber": "US264",
                                "name": ["Nick Senzel"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US265": {
                                "cardNumber": "US265",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US266": {
                                "cardNumber": "US266",
                                "name": ["Tyler Wade"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US267": {
                                "cardNumber": "US267",
                                "name": ["Nasim Nuñez"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US268": {
                                "cardNumber": "US268",
                                "name": ["Naoyuki Uwasawa"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US269": {
                                "cardNumber": "US269",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US270": {
                                "cardNumber": "US270",
                                "name": ["Evan Justice", "Anthony Molina"],
                                "team": ["Colorado Rockies™", "Colorado Rockies™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US271": {
                                "cardNumber": "US271",
                                "name": ["Erik Miller", "Nick Avila"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US272": {
                                "cardNumber": "US272",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US273": {
                                "cardNumber": "US273",
                                "name": ["Tyler O'Neill"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US274": {
                                "cardNumber": "US274",
                                "name": ["Matt Thaiss"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US275": {
                                "cardNumber": "US275",
                                "name": ["Austin Shenton"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US276": {
                                "cardNumber": "US276",
                                "name": ["Alex Wood"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US277": {
                                "cardNumber": "US277",
                                "name": ["Robbie Ray"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US278": {
                                "cardNumber": "US278",
                                "name": ["Mark Leiter"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US279": {
                                "cardNumber": "US279",
                                "name": ["Leo Rivas"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US280": {
                                "cardNumber": "US280",
                                "name": ["Keegan Akin"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US281": {
                                "cardNumber": "US281",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US282": {
                                "cardNumber": "US282",
                                "name": ["Adam Cimber"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US283": {
                                "cardNumber": "US283",
                                "name": ["Johnathan Rodríguez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US284": {
                                "cardNumber": "US284",
                                "name": ["David Robertson"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US285": {
                                "cardNumber": "US285",
                                "name": ["Jackson Holliday", "Gunnar Henderson"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US286": {
                                "cardNumber": "US286",
                                "name": ["J.D. Martinez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US287": {
                                "cardNumber": "US287",
                                "name": ["Jordan Hicks"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US288": {
                                "cardNumber": "US288",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US289": {
                                "cardNumber": "US289",
                                "name": ["Byron Buxton", "Carlos Correa"],
                                "team": ["Minnesota Twins®", "Minnesota Twins®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US290": {
                                "cardNumber": "US290",
                                "name": ["Jason Foley"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US291": {
                                "cardNumber": "US291",
                                "name": ["Lenyn Sosa"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US292": {
                                "cardNumber": "US292",
                                "name": ["Miguel Sano"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US293": {
                                "cardNumber": "US293",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US294": {
                                "cardNumber": "US294",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US295": {
                                "cardNumber": "US295",
                                "name": ["Simeon Woods Richardson"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US296": {
                                "cardNumber": "US296",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US297": {
                                "cardNumber": "US297",
                                "name": ["Jordan Montgomery"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US298": {
                                "cardNumber": "US298",
                                "name": ["Robert Suarez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US299": {
                                "cardNumber": "US299",
                                "name": ["Tobias Myers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US301": {
                                "cardNumber": "US301",
                                "name": ["Jesse Chavez"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US302": {
                                "cardNumber": "US302",
                                "name": ["Kirby Yates"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US303": {
                                "cardNumber": "US303",
                                "name": ["Ildemaro Vargas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US304": {
                                "cardNumber": "US304",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US305": {
                                "cardNumber": "US305",
                                "name": ["Eli Villalobos", "Anthony Maldonado"],
                                "team": ["Miami Marlins®", "Miami Marlins®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US306": {
                                "cardNumber": "US306",
                                "name": ["Easton Lucas", "Angel Felipe"],
                                "team": ["Oakland Athletics™", "Oakland Athletics™"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US307": {
                                "cardNumber": "US307",
                                "name": ["Santiago Espinal"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US308": {
                                "cardNumber": "US308",
                                "name": ["Fernando Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US309": {
                                "cardNumber": "US309",
                                "name": ["Adam Ottavino"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US310": {
                                "cardNumber": "US310",
                                "name": ["Oliver Dunn"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US311": {
                                "cardNumber": "US311",
                                "name": ["TJ Hopkins"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US312": {
                                "cardNumber": "US312",
                                "name": ["Erik Swanson"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US313": {
                                "cardNumber": "US313",
                                "name": ["Luis Gil"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US314": {
                                "cardNumber": "US314",
                                "name": ["Jair Camargo"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US315": {
                                "cardNumber": "US315",
                                "name": ["David Bañuelos", "Matt Krook"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Combo"]
                            },
                            "US316": {
                                "cardNumber": "US316",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US317": {
                                "cardNumber": "US317",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US318": {
                                "cardNumber": "US318",
                                "name": ["Nick Martini"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US319": {
                                "cardNumber": "US319",
                                "name": ["Ronel Blanco"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US320": {
                                "cardNumber": "US320",
                                "name": ["Zach DeLoach"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US321": {
                                "cardNumber": "US321",
                                "name": ["Peter Fairbanks"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US322": {
                                "cardNumber": "US322",
                                "name": ["Roddery Muñoz"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US323": {
                                "cardNumber": "US323",
                                "name": ["Jon Singleton"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US324": {
                                "cardNumber": "US324",
                                "name": ["Dairon Blanco"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US325": {
                                "cardNumber": "US325",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US326": {
                                "cardNumber": "US326",
                                "name": ["Giovanny Gallegos"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US327": {
                                "cardNumber": "US327",
                                "name": ["Dinelson Lamet"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US328": {
                                "cardNumber": "US328",
                                "name": ["Ramón Urías"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US329": {
                                "cardNumber": "US329",
                                "name": ["Steven Matz"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US330": {
                                "cardNumber": "US330",
                                "name": ["A.J. Puk"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US331": {
                                "cardNumber": "US331",
                                "name": ["Cody Bradford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US332": {
                                "cardNumber": "US332",
                                "name": ["Andrew Chafin"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US333": {
                                "cardNumber": "US333",
                                "name": ["Yasmani Grandal"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US334": {
                                "cardNumber": "US334",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US335": {
                                "cardNumber": "US335",
                                "name": ["Pierce Johnson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US336": {
                                "cardNumber": "US336",
                                "name": ["Addison Barger"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US337": {
                                "cardNumber": "US337",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US338": {
                                "cardNumber": "US338",
                                "name": ["Pedro Pagés"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US339": {
                                "cardNumber": "US339",
                                "name": ["Aroldis Chapman"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US340": {
                                "cardNumber": "US340",
                                "name": ["Gavin Stone"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US341": {
                                "cardNumber": "US341",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US342": {
                                "cardNumber": "US342",
                                "name": ["Dakota Hudson"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US343": {
                                "cardNumber": "US343",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US344": {
                                "cardNumber": "US344",
                                "name": ["Ernie Clement"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US345": {
                                "cardNumber": "US345",
                                "name": ["Louie Varland"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US346": {
                                "cardNumber": "US346",
                                "name": ["Gio Urshela"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US347": {
                                "cardNumber": "US347",
                                "name": ["Jonathan Cannon"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US348": {
                                "cardNumber": "US348",
                                "name": ["Hunter Stratton"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US349": {
                                "cardNumber": "US349",
                                "name": ["Cam Eden"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US350": {
                                "cardNumber": "US350",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            }
                        },
                        "Oversized 2024 Base Card": {
                            "US1": {
                                "cardNumber": "US1",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US16": {
                                "cardNumber": "US16",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US49": {
                                "cardNumber": "US49",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US50": {
                                "cardNumber": "US50",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US65": {
                                "cardNumber": "US65",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US73": {
                                "cardNumber": "US73",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US151": {
                                "cardNumber": "US151",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US183": {
                                "cardNumber": "US183",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US210": {
                                "cardNumber": "US210",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US218": {
                                "cardNumber": "US218",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US221": {
                                "cardNumber": "US221",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US240": {
                                "cardNumber": "US240",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US262": {
                                "cardNumber": "US262",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US41": {
                                "cardNumber": "US41",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US58": {
                                "cardNumber": "US58",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US86": {
                                "cardNumber": "US86",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US91": {
                                "cardNumber": "US91",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US97": {
                                "cardNumber": "US97",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US110": {
                                "cardNumber": "US110",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US131": {
                                "cardNumber": "US131",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US149": {
                                "cardNumber": "US149",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US155": {
                                "cardNumber": "US155",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US169": {
                                "cardNumber": "US169",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US223": {
                                "cardNumber": "US223",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US281": {
                                "cardNumber": "US281",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US288": {
                                "cardNumber": "US288",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US341": {
                                "cardNumber": "US341",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US343": {
                                "cardNumber": "US343",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US350": {
                                "cardNumber": "US350",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            }
                        },
                        "Companion Card": {
                            "US1": {
                                "cardNumber": "US1",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US16": {
                                "cardNumber": "US16",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US49": {
                                "cardNumber": "US49",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US50": {
                                "cardNumber": "US50",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US65": {
                                "cardNumber": "US65",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US73": {
                                "cardNumber": "US73",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US151": {
                                "cardNumber": "US151",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US183": {
                                "cardNumber": "US183",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US210": {
                                "cardNumber": "US210",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US218": {
                                "cardNumber": "US218",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US221": {
                                "cardNumber": "US221",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US240": {
                                "cardNumber": "US240",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US262": {
                                "cardNumber": "US262",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US41": {
                                "cardNumber": "US41",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US58": {
                                "cardNumber": "US58",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US86": {
                                "cardNumber": "US86",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US91": {
                                "cardNumber": "US91",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US97": {
                                "cardNumber": "US97",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US110": {
                                "cardNumber": "US110",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US131": {
                                "cardNumber": "US131",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US149": {
                                "cardNumber": "US149",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US155": {
                                "cardNumber": "US155",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US169": {
                                "cardNumber": "US169",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US223": {
                                "cardNumber": "US223",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US281": {
                                "cardNumber": "US281",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US288": {
                                "cardNumber": "US288",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US341": {
                                "cardNumber": "US341",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US343": {
                                "cardNumber": "US343",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US350": {
                                "cardNumber": "US350",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            }
                        }
                    },
                    "Insert": {
                        "Let Him Cook": {
                            "LHC-1": {
                                "cardNumber": "LHC-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "LHC-2": {
                                "cardNumber": "LHC-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "LHC-3": {
                                "cardNumber": "LHC-3",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "LHC-4": {
                                "cardNumber": "LHC-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "LHC-5": {
                                "cardNumber": "LHC-5",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "LHC-6": {
                                "cardNumber": "LHC-6",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-7": {
                                "cardNumber": "LHC-7",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-8": {
                                "cardNumber": "LHC-8",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "LHC-9": {
                                "cardNumber": "LHC-9",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "LHC-10": {
                                "cardNumber": "LHC-10",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "LHC-11": {
                                "cardNumber": "LHC-11",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "LHC-12": {
                                "cardNumber": "LHC-12",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-13": {
                                "cardNumber": "LHC-13",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-14": {
                                "cardNumber": "LHC-14",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "LHC-15": {
                                "cardNumber": "LHC-15",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-16": {
                                "cardNumber": "LHC-16",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-17": {
                                "cardNumber": "LHC-17",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "LHC-18": {
                                "cardNumber": "LHC-18",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-19": {
                                "cardNumber": "LHC-19",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-20": {
                                "cardNumber": "LHC-20",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "LHC-21": {
                                "cardNumber": "LHC-21",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-22": {
                                "cardNumber": "LHC-22",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "LHC-23": {
                                "cardNumber": "LHC-23",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHC-24": {
                                "cardNumber": "LHC-24",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "LHC-25": {
                                "cardNumber": "LHC-25",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Autumn Tales": {
                            "AT-1": {
                                "cardNumber": "AT-1",
                                "name": ["Joe Carter"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "AT-2": {
                                "cardNumber": "AT-2",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "AT-3": {
                                "cardNumber": "AT-3",
                                "name": ["Yogi Berra"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-4": {
                                "cardNumber": "AT-4",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "AT-5": {
                                "cardNumber": "AT-5",
                                "name": ["Cody Bellinger"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AT-6": {
                                "cardNumber": "AT-6",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "AT-7": {
                                "cardNumber": "AT-7",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "AT-8": {
                                "cardNumber": "AT-8",
                                "name": ["Edgar Martinez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "AT-9": {
                                "cardNumber": "AT-9",
                                "name": ["Keith Hernandez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "AT-10": {
                                "cardNumber": "AT-10",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "AT-11": {
                                "cardNumber": "AT-11",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "AT-12": {
                                "cardNumber": "AT-12",
                                "name": ["Tony Gwynn"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "AT-13": {
                                "cardNumber": "AT-13",
                                "name": ["Brooks Robinson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "AT-14": {
                                "cardNumber": "AT-14",
                                "name": ["Carlton Fisk"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "AT-15": {
                                "cardNumber": "AT-15",
                                "name": ["Kirby Puckett"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "AT-16": {
                                "cardNumber": "AT-16",
                                "name": ["Don Larsen"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-17": {
                                "cardNumber": "AT-17",
                                "name": ["Bill Mazeroski"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "AT-18": {
                                "cardNumber": "AT-18",
                                "name": ["Willie Mays"],
                                "team": ["New York Giants™"],
                                "cardAttributes": []
                            },
                            "AT-19": {
                                "cardNumber": "AT-19",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "AT-20": {
                                "cardNumber": "AT-20",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "AT-21": {
                                "cardNumber": "AT-21",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "AT-22": {
                                "cardNumber": "AT-22",
                                "name": ["Babe Ruth"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "AT-23": {
                                "cardNumber": "AT-23",
                                "name": ["Bob Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "AT-24": {
                                "cardNumber": "AT-24",
                                "name": ["Reggie Jackson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-25": {
                                "cardNumber": "AT-25",
                                "name": ["Babe Ruth"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-26": {
                                "cardNumber": "AT-26",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "AT-27": {
                                "cardNumber": "AT-27",
                                "name": ["Christy Mathewson"],
                                "team": ["New York Giants™"],
                                "cardAttributes": []
                            },
                            "AT-28": {
                                "cardNumber": "AT-28",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-29": {
                                "cardNumber": "AT-29",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AT-30": {
                                "cardNumber": "AT-30",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "AT-31": {
                                "cardNumber": "AT-31",
                                "name": ["Jack Morris"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "AT-32": {
                                "cardNumber": "AT-32",
                                "name": ["Babe Ruth"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-33": {
                                "cardNumber": "AT-33",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "AT-34": {
                                "cardNumber": "AT-34",
                                "name": ["Corey Seager"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AT-35": {
                                "cardNumber": "AT-35",
                                "name": ["Stephen Strasburg"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "AT-36": {
                                "cardNumber": "AT-36",
                                "name": ["Kris Bryant"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "AT-37": {
                                "cardNumber": "AT-37",
                                "name": ["Randy Johnson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "AT-38": {
                                "cardNumber": "AT-38",
                                "name": ["Willie Stargell"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "AT-39": {
                                "cardNumber": "AT-39",
                                "name": ["Hank Aaron"],
                                "team": ["Milwaukee Braves™"],
                                "cardAttributes": []
                            },
                            "AT-40": {
                                "cardNumber": "AT-40",
                                "name": ["Yogi Berra"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-41": {
                                "cardNumber": "AT-41",
                                "name": ["Lou Gehrig"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AT-42": {
                                "cardNumber": "AT-42",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "AT-43": {
                                "cardNumber": "AT-43",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "AT-44": {
                                "cardNumber": "AT-44",
                                "name": ["Adolis García"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "AT-45": {
                                "cardNumber": "AT-45",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "AT-46": {
                                "cardNumber": "AT-46",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "AT-47": {
                                "cardNumber": "AT-47",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "AT-48": {
                                "cardNumber": "AT-48",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "AT-49": {
                                "cardNumber": "AT-49",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "AT-50": {
                                "cardNumber": "AT-50",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "All Class": {
                            "AC-1": {
                                "cardNumber": "AC-1",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "AC-2": {
                                "cardNumber": "AC-2",
                                "name": ["Nolan Ryan"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "AC-3": {
                                "cardNumber": "AC-3",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AC-4": {
                                "cardNumber": "AC-4",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "AC-5": {
                                "cardNumber": "AC-5",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "AC-6": {
                                "cardNumber": "AC-6",
                                "name": ["Willie Mays"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "AC-7": {
                                "cardNumber": "AC-7",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "AC-8": {
                                "cardNumber": "AC-8",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AC-9": {
                                "cardNumber": "AC-9",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AC-10": {
                                "cardNumber": "AC-10",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "AC-11": {
                                "cardNumber": "AC-11",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "AC-12": {
                                "cardNumber": "AC-12",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "AC-13": {
                                "cardNumber": "AC-13",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "AC-14": {
                                "cardNumber": "AC-14",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AC-15": {
                                "cardNumber": "AC-15",
                                "name": ["Greg Maddux"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "AC-16": {
                                "cardNumber": "AC-16",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AC-17": {
                                "cardNumber": "AC-17",
                                "name": ["Tony Gwynn"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "AC-18": {
                                "cardNumber": "AC-18",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AC-19": {
                                "cardNumber": "AC-19",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "AC-20": {
                                "cardNumber": "AC-20",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "AC-21": {
                                "cardNumber": "AC-21",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-22": {
                                "cardNumber": "AC-22",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-23": {
                                "cardNumber": "AC-23",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-24": {
                                "cardNumber": "AC-24",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-25": {
                                "cardNumber": "AC-25",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "2024 All Star Game": {
                            "ASG-1": {
                                "cardNumber": "ASG-1",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASG-2": {
                                "cardNumber": "ASG-2",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASG-3": {
                                "cardNumber": "ASG-3",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASG-4": {
                                "cardNumber": "ASG-4",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASG-5": {
                                "cardNumber": "ASG-5",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASG-6": {
                                "cardNumber": "ASG-6",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASG-7": {
                                "cardNumber": "ASG-7",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASG-8": {
                                "cardNumber": "ASG-8",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASG-9": {
                                "cardNumber": "ASG-9",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASG-10": {
                                "cardNumber": "ASG-10",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASG-11": {
                                "cardNumber": "ASG-11",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASG-12": {
                                "cardNumber": "ASG-12",
                                "name": ["Ketel Marte"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "ASG-13": {
                                "cardNumber": "ASG-13",
                                "name": ["Alec Bohm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASG-14": {
                                "cardNumber": "ASG-14",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASG-15": {
                                "cardNumber": "ASG-15",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASG-16": {
                                "cardNumber": "ASG-16",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASG-17": {
                                "cardNumber": "ASG-17",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASG-18": {
                                "cardNumber": "ASG-18",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-19": {
                                "cardNumber": "ASG-19",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASG-20": {
                                "cardNumber": "ASG-20",
                                "name": ["Emmanuel Clase"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASG-21": {
                                "cardNumber": "ASG-21",
                                "name": ["Garrett Crochet"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASG-22": {
                                "cardNumber": "ASG-22",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASG-23": {
                                "cardNumber": "ASG-23",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "ASG-24": {
                                "cardNumber": "ASG-24",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASG-25": {
                                "cardNumber": "ASG-25",
                                "name": ["Tarik Skubal"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASG-26": {
                                "cardNumber": "ASG-26",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-27": {
                                "cardNumber": "ASG-27",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASG-28": {
                                "cardNumber": "ASG-28",
                                "name": ["Chris Sale"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASG-29": {
                                "cardNumber": "ASG-29",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASG-30": {
                                "cardNumber": "ASG-30",
                                "name": ["Ranger Suárez"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASG-31": {
                                "cardNumber": "ASG-31",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASG-32": {
                                "cardNumber": "ASG-32",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASG-33": {
                                "cardNumber": "ASG-33",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASG-34": {
                                "cardNumber": "ASG-34",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-35": {
                                "cardNumber": "ASG-35",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASG-36": {
                                "cardNumber": "ASG-36",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASG-37": {
                                "cardNumber": "ASG-37",
                                "name": ["Josh Naylor"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASG-38": {
                                "cardNumber": "ASG-38",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASG-39": {
                                "cardNumber": "ASG-39",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASG-40": {
                                "cardNumber": "ASG-40",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASG-41": {
                                "cardNumber": "ASG-41",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASG-42": {
                                "cardNumber": "ASG-42",
                                "name": ["Luis Arraez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASG-43": {
                                "cardNumber": "ASG-43",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-44": {
                                "cardNumber": "ASG-44",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASG-45": {
                                "cardNumber": "ASG-45",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-46": {
                                "cardNumber": "ASG-46",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASG-47": {
                                "cardNumber": "ASG-47",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASG-48": {
                                "cardNumber": "ASG-48",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASG-49": {
                                "cardNumber": "ASG-49",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASG-50": {
                                "cardNumber": "ASG-50",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            }
                        },
                        "Trading Cards Enamel Pins": {
                            "1 of 10": {
                                "cardNumber": "1 of 10",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "2 of 10": {
                                "cardNumber": "2 of 10",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "3 of 10": {
                                "cardNumber": "3 of 10",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "4 of 10": {
                                "cardNumber": "4 of 10",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "5 of 10": {
                                "cardNumber": "5 of 10",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "6 of 10": {
                                "cardNumber": "6 of 10",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "7 of 10": {
                                "cardNumber": "7 of 10",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "8 of 10": {
                                "cardNumber": "8 of 10",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "9 of 10": {
                                "cardNumber": "9 of 10",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "10 of 10": {
                                "cardNumber": "10 of 10",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Topps Black Gold": {
                            "BG-1": {
                                "cardNumber": "BG-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BG-2": {
                                "cardNumber": "BG-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BG-3": {
                                "cardNumber": "BG-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BG-4": {
                                "cardNumber": "BG-4",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BG-5": {
                                "cardNumber": "BG-5",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BG-6": {
                                "cardNumber": "BG-6",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-7": {
                                "cardNumber": "BG-7",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-8": {
                                "cardNumber": "BG-8",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BG-9": {
                                "cardNumber": "BG-9",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BG-10": {
                                "cardNumber": "BG-10",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-11": {
                                "cardNumber": "BG-11",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BG-12": {
                                "cardNumber": "BG-12",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-13": {
                                "cardNumber": "BG-13",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-14": {
                                "cardNumber": "BG-14",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-15": {
                                "cardNumber": "BG-15",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-16": {
                                "cardNumber": "BG-16",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-17": {
                                "cardNumber": "BG-17",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-18": {
                                "cardNumber": "BG-18",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-19": {
                                "cardNumber": "BG-19",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-20": {
                                "cardNumber": "BG-20",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "BG-21": {
                                "cardNumber": "BG-21",
                                "name": ["Willie Mays"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BG-22": {
                                "cardNumber": "BG-22",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BG-23": {
                                "cardNumber": "BG-23",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BG-24": {
                                "cardNumber": "BG-24",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BG-25": {
                                "cardNumber": "BG-25",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Mystical": {
                            "MYS-1": {
                                "cardNumber": "MYS-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MYS-2": {
                                "cardNumber": "MYS-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MYS-3": {
                                "cardNumber": "MYS-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYS-4": {
                                "cardNumber": "MYS-4",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MYS-5": {
                                "cardNumber": "MYS-5",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MYS-6": {
                                "cardNumber": "MYS-6",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-7": {
                                "cardNumber": "MYS-7",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-8": {
                                "cardNumber": "MYS-8",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MYS-9": {
                                "cardNumber": "MYS-9",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MYS-10": {
                                "cardNumber": "MYS-10",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-11": {
                                "cardNumber": "MYS-11",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MYS-12": {
                                "cardNumber": "MYS-12",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-13": {
                                "cardNumber": "MYS-13",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-14": {
                                "cardNumber": "MYS-14",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-15": {
                                "cardNumber": "MYS-15",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-16": {
                                "cardNumber": "MYS-16",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-17": {
                                "cardNumber": "MYS-17",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MYS-18": {
                                "cardNumber": "MYS-18",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MYS-19": {
                                "cardNumber": "MYS-19",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-20": {
                                "cardNumber": "MYS-20",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-21": {
                                "cardNumber": "MYS-21",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-22": {
                                "cardNumber": "MYS-22",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MYS-23": {
                                "cardNumber": "MYS-23",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MYS-24": {
                                "cardNumber": "MYS-24",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYS-25": {
                                "cardNumber": "MYS-25",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MYS-26": {
                                "cardNumber": "MYS-26",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MYS-27": {
                                "cardNumber": "MYS-27",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-28": {
                                "cardNumber": "MYS-28",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYS-29": {
                                "cardNumber": "MYS-29",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MYS-30": {
                                "cardNumber": "MYS-30",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MYS-31": {
                                "cardNumber": "MYS-31",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MYS-32": {
                                "cardNumber": "MYS-32",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MYS-33": {
                                "cardNumber": "MYS-33",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MYS-34": {
                                "cardNumber": "MYS-34",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MYS-35": {
                                "cardNumber": "MYS-35",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MYS-36": {
                                "cardNumber": "MYS-36",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MYS-37": {
                                "cardNumber": "MYS-37",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYS-38": {
                                "cardNumber": "MYS-38",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MYS-39": {
                                "cardNumber": "MYS-39",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYS-40": {
                                "cardNumber": "MYS-40",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "MYS-41": {
                                "cardNumber": "MYS-41",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MYS-42": {
                                "cardNumber": "MYS-42",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYS-43": {
                                "cardNumber": "MYS-43",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MYS-44": {
                                "cardNumber": "MYS-44",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MYS-45": {
                                "cardNumber": "MYS-45",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MYS-46": {
                                "cardNumber": "MYS-46",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MYS-47": {
                                "cardNumber": "MYS-47",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MYS-48": {
                                "cardNumber": "MYS-48",
                                "name": ["Randy Arozarena"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MYS-49": {
                                "cardNumber": "MYS-49",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MYS-50": {
                                "cardNumber": "MYS-50",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            }
                        },
                        "Heavy Lumber": {
                            "HL-41": {
                                "cardNumber": "HL-41",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HL-42": {
                                "cardNumber": "HL-42",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "HL-43": {
                                "cardNumber": "HL-43",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HL-44": {
                                "cardNumber": "HL-44",
                                "name": ["Reggie Jackson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HL-45": {
                                "cardNumber": "HL-45",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HL-46": {
                                "cardNumber": "HL-46",
                                "name": ["Lou Gehrig"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HL-47": {
                                "cardNumber": "HL-47",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HL-48": {
                                "cardNumber": "HL-48",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "HL-49": {
                                "cardNumber": "HL-49",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "HL-50": {
                                "cardNumber": "HL-50",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "HL-51": {
                                "cardNumber": "HL-51",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HL-52": {
                                "cardNumber": "HL-52",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "HL-53": {
                                "cardNumber": "HL-53",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HL-54": {
                                "cardNumber": "HL-54",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "HL-55": {
                                "cardNumber": "HL-55",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "HL-56": {
                                "cardNumber": "HL-56",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HL-57": {
                                "cardNumber": "HL-57",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HL-58": {
                                "cardNumber": "HL-58",
                                "name": ["Mark McGwire"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "HL-59": {
                                "cardNumber": "HL-59",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "HL-60": {
                                "cardNumber": "HL-60",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "HL-61": {
                                "cardNumber": "HL-61",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HL-62": {
                                "cardNumber": "HL-62",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HL-63": {
                                "cardNumber": "HL-63",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HL-64": {
                                "cardNumber": "HL-64",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "HL-65": {
                                "cardNumber": "HL-65",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Homefield Advantage": {
                            "HFA-21": {
                                "cardNumber": "HFA-21",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-22": {
                                "cardNumber": "HFA-22",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-23": {
                                "cardNumber": "HFA-23",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-24": {
                                "cardNumber": "HFA-24",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-25": {
                                "cardNumber": "HFA-25",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-26": {
                                "cardNumber": "HFA-26",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "HFA-27": {
                                "cardNumber": "HFA-27",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-28": {
                                "cardNumber": "HFA-28",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-29": {
                                "cardNumber": "HFA-29",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HFA-30": {
                                "cardNumber": "HFA-30",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-31": {
                                "cardNumber": "HFA-31",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-32": {
                                "cardNumber": "HFA-32",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-33": {
                                "cardNumber": "HFA-33",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "HFA-34": {
                                "cardNumber": "HFA-34",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HFA-35": {
                                "cardNumber": "HFA-35",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HFA-36": {
                                "cardNumber": "HFA-36",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-37": {
                                "cardNumber": "HFA-37",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFA-38": {
                                "cardNumber": "HFA-38",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "HFA-39": {
                                "cardNumber": "HFA-39",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "HFA-40": {
                                "cardNumber": "HFA-40",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "Legendary Homefield Advantage": {
                            "LHA-11": {
                                "cardNumber": "LHA-11",
                                "name": ["Babe Ruth"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "LHA-12": {
                                "cardNumber": "LHA-12",
                                "name": ["Robin Yount"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "LHA-13": {
                                "cardNumber": "LHA-13",
                                "name": ["Don Mattingly"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "LHA-14": {
                                "cardNumber": "LHA-14",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "LHA-15": {
                                "cardNumber": "LHA-15",
                                "name": ["Kirby Puckett"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball": {
                            "89US-1": {
                                "cardNumber": "89US-1",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89US-2": {
                                "cardNumber": "89US-2",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89US-3": {
                                "cardNumber": "89US-3",
                                "name": ["Willie Mays"],
                                "team": ["New York Giants™"],
                                "cardAttributes": []
                            },
                            "89US-4": {
                                "cardNumber": "89US-4",
                                "name": ["Parker Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-5": {
                                "cardNumber": "89US-5",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89US-6": {
                                "cardNumber": "89US-6",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89US-7": {
                                "cardNumber": "89US-7",
                                "name": ["Darryl Strawberry"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89US-8": {
                                "cardNumber": "89US-8",
                                "name": ["Willson Contreras"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89US-9": {
                                "cardNumber": "89US-9",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89US-10": {
                                "cardNumber": "89US-10",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-11": {
                                "cardNumber": "89US-11",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-12": {
                                "cardNumber": "89US-12",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89US-13": {
                                "cardNumber": "89US-13",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-14": {
                                "cardNumber": "89US-14",
                                "name": ["Matt Chapman"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89US-15": {
                                "cardNumber": "89US-15",
                                "name": ["Rhys Hoskins"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89US-16": {
                                "cardNumber": "89US-16",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-17": {
                                "cardNumber": "89US-17",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89US-18": {
                                "cardNumber": "89US-18",
                                "name": ["Michael Harris"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89US-19": {
                                "cardNumber": "89US-19",
                                "name": ["Roger Clemens"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89US-20": {
                                "cardNumber": "89US-20",
                                "name": ["Eloy Jiménez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89US-21": {
                                "cardNumber": "89US-21",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-22": {
                                "cardNumber": "89US-22",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89US-23": {
                                "cardNumber": "89US-23",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89US-24": {
                                "cardNumber": "89US-24",
                                "name": ["Teoscar Hernández"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89US-25": {
                                "cardNumber": "89US-25",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89US-26": {
                                "cardNumber": "89US-26",
                                "name": ["Francisco Alvarez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89US-27": {
                                "cardNumber": "89US-27",
                                "name": ["Eddie Murray"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89US-28": {
                                "cardNumber": "89US-28",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-29": {
                                "cardNumber": "89US-29",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-30": {
                                "cardNumber": "89US-30",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89US-31": {
                                "cardNumber": "89US-31",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89US-32": {
                                "cardNumber": "89US-32",
                                "name": ["Blake Snell"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89US-33": {
                                "cardNumber": "89US-33",
                                "name": ["Sandy Koufax"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "89US-34": {
                                "cardNumber": "89US-34",
                                "name": ["Greg Maddux"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89US-35": {
                                "cardNumber": "89US-35",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-36": {
                                "cardNumber": "89US-36",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-37": {
                                "cardNumber": "89US-37",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89US-38": {
                                "cardNumber": "89US-38",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-39": {
                                "cardNumber": "89US-39",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-40": {
                                "cardNumber": "89US-40",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-41": {
                                "cardNumber": "89US-41",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89US-42": {
                                "cardNumber": "89US-42",
                                "name": ["Nolan Ryan"],
                                "team": ["California Angels™"],
                                "cardAttributes": []
                            },
                            "89US-43": {
                                "cardNumber": "89US-43",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89US-44": {
                                "cardNumber": "89US-44",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-45": {
                                "cardNumber": "89US-45",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89US-46": {
                                "cardNumber": "89US-46",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-47": {
                                "cardNumber": "89US-47",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89US-48": {
                                "cardNumber": "89US-48",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-49": {
                                "cardNumber": "89US-49",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89US-50": {
                                "cardNumber": "89US-50",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Chrome": {
                            "T89CU-1": {
                                "cardNumber": "T89CU-1",
                                "name": ["Chase Utley"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-2": {
                                "cardNumber": "T89CU-2",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-3": {
                                "cardNumber": "T89CU-3",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-4": {
                                "cardNumber": "T89CU-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-5": {
                                "cardNumber": "T89CU-5",
                                "name": ["Eloy Jiménez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "T89CU-6": {
                                "cardNumber": "T89CU-6",
                                "name": ["Steve Carlton"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-7": {
                                "cardNumber": "T89CU-7",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-8": {
                                "cardNumber": "T89CU-8",
                                "name": ["Greg Maddux"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T89CU-9": {
                                "cardNumber": "T89CU-9",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-10": {
                                "cardNumber": "T89CU-10",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-11": {
                                "cardNumber": "T89CU-11",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-12": {
                                "cardNumber": "T89CU-12",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "T89CU-13": {
                                "cardNumber": "T89CU-13",
                                "name": ["Stan Musial"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "T89CU-14": {
                                "cardNumber": "T89CU-14",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "T89CU-15": {
                                "cardNumber": "T89CU-15",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-16": {
                                "cardNumber": "T89CU-16",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-17": {
                                "cardNumber": "T89CU-17",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-18": {
                                "cardNumber": "T89CU-18",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-19": {
                                "cardNumber": "T89CU-19",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-20": {
                                "cardNumber": "T89CU-20",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "T89CU-21": {
                                "cardNumber": "T89CU-21",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-22": {
                                "cardNumber": "T89CU-22",
                                "name": ["Darryl Strawberry"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T89CU-23": {
                                "cardNumber": "T89CU-23",
                                "name": ["Willie Mays"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "T89CU-24": {
                                "cardNumber": "T89CU-24",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T89CU-25": {
                                "cardNumber": "T89CU-25",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T89CU-26": {
                                "cardNumber": "T89CU-26",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-27": {
                                "cardNumber": "T89CU-27",
                                "name": ["Rhys Hoskins"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "T89CU-28": {
                                "cardNumber": "T89CU-28",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-29": {
                                "cardNumber": "T89CU-29",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-30": {
                                "cardNumber": "T89CU-30",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-31": {
                                "cardNumber": "T89CU-31",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-32": {
                                "cardNumber": "T89CU-32",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "T89CU-33": {
                                "cardNumber": "T89CU-33",
                                "name": ["Reggie Jackson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "T89CU-34": {
                                "cardNumber": "T89CU-34",
                                "name": ["Blake Snell"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "T89CU-35": {
                                "cardNumber": "T89CU-35",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "T89CU-36": {
                                "cardNumber": "T89CU-36",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-37": {
                                "cardNumber": "T89CU-37",
                                "name": ["John Smoltz"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-38": {
                                "cardNumber": "T89CU-38",
                                "name": ["J.P. Crawford"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-39": {
                                "cardNumber": "T89CU-39",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-40": {
                                "cardNumber": "T89CU-40",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-41": {
                                "cardNumber": "T89CU-41",
                                "name": ["Hunter Goodman"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-42": {
                                "cardNumber": "T89CU-42",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-43": {
                                "cardNumber": "T89CU-43",
                                "name": ["Fred McGriff"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-44": {
                                "cardNumber": "T89CU-44",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "T89CU-45": {
                                "cardNumber": "T89CU-45",
                                "name": ["Max Kepler"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "T89CU-46": {
                                "cardNumber": "T89CU-46",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-47": {
                                "cardNumber": "T89CU-47",
                                "name": ["Dave Winfield"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-48": {
                                "cardNumber": "T89CU-48",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "T89CU-49": {
                                "cardNumber": "T89CU-49",
                                "name": ["Mike Yastrzemski"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "T89CU-50": {
                                "cardNumber": "T89CU-50",
                                "name": ["Ketel Marte"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "T89CU-51": {
                                "cardNumber": "T89CU-51",
                                "name": ["Matt Chapman"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "T89CU-52": {
                                "cardNumber": "T89CU-52",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-53": {
                                "cardNumber": "T89CU-53",
                                "name": ["Tom Seaver"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T89CU-54": {
                                "cardNumber": "T89CU-54",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "T89CU-55": {
                                "cardNumber": "T89CU-55",
                                "name": ["Addison Barger"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-56": {
                                "cardNumber": "T89CU-56",
                                "name": ["Nick Loftin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-57": {
                                "cardNumber": "T89CU-57",
                                "name": ["Roger Clemens"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-58": {
                                "cardNumber": "T89CU-58",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-59": {
                                "cardNumber": "T89CU-59",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-60": {
                                "cardNumber": "T89CU-60",
                                "name": ["Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "T89CU-61": {
                                "cardNumber": "T89CU-61",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "T89CU-62": {
                                "cardNumber": "T89CU-62",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-63": {
                                "cardNumber": "T89CU-63",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-64": {
                                "cardNumber": "T89CU-64",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-65": {
                                "cardNumber": "T89CU-65",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-66": {
                                "cardNumber": "T89CU-66",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "T89CU-67": {
                                "cardNumber": "T89CU-67",
                                "name": ["Ryan Howard"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-68": {
                                "cardNumber": "T89CU-68",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-69": {
                                "cardNumber": "T89CU-69",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "T89CU-70": {
                                "cardNumber": "T89CU-70",
                                "name": ["Babe Ruth"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-71": {
                                "cardNumber": "T89CU-71",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "T89CU-72": {
                                "cardNumber": "T89CU-72",
                                "name": ["Kodai Senga"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T89CU-73": {
                                "cardNumber": "T89CU-73",
                                "name": ["Teoscar Hernández"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "T89CU-74": {
                                "cardNumber": "T89CU-74",
                                "name": ["Ha-Seong Kim"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "T89CU-75": {
                                "cardNumber": "T89CU-75",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-76": {
                                "cardNumber": "T89CU-76",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-77": {
                                "cardNumber": "T89CU-77",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "T89CU-78": {
                                "cardNumber": "T89CU-78",
                                "name": ["Sammy Sosa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T89CU-79": {
                                "cardNumber": "T89CU-79",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-80": {
                                "cardNumber": "T89CU-80",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-81": {
                                "cardNumber": "T89CU-81",
                                "name": ["Freddy Peralta"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "T89CU-82": {
                                "cardNumber": "T89CU-82",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-83": {
                                "cardNumber": "T89CU-83",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-84": {
                                "cardNumber": "T89CU-84",
                                "name": ["Yuki Matsui"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-85": {
                                "cardNumber": "T89CU-85",
                                "name": ["Jacob deGrom"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "T89CU-86": {
                                "cardNumber": "T89CU-86",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-87": {
                                "cardNumber": "T89CU-87",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-88": {
                                "cardNumber": "T89CU-88",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-89": {
                                "cardNumber": "T89CU-89",
                                "name": ["Rod Carew"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "T89CU-90": {
                                "cardNumber": "T89CU-90",
                                "name": ["Randy Johnson"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-91": {
                                "cardNumber": "T89CU-91",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-92": {
                                "cardNumber": "T89CU-92",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "T89CU-93": {
                                "cardNumber": "T89CU-93",
                                "name": ["Lars Nootbaar"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "T89CU-94": {
                                "cardNumber": "T89CU-94",
                                "name": ["Roberto Clemente"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "T89CU-95": {
                                "cardNumber": "T89CU-95",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-96": {
                                "cardNumber": "T89CU-96",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-97": {
                                "cardNumber": "T89CU-97",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-98": {
                                "cardNumber": "T89CU-98",
                                "name": ["Nolan Ryan"],
                                "team": ["California Angels™"],
                                "cardAttributes": []
                            },
                            "T89CU-99": {
                                "cardNumber": "T89CU-99",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "T89CU-100": {
                                "cardNumber": "T89CU-100",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "Stars of MLB": {
                            "SMLB-61": {
                                "cardNumber": "SMLB-61",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SMLB-62": {
                                "cardNumber": "SMLB-62",
                                "name": ["Matt Chapman"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "SMLB-63": {
                                "cardNumber": "SMLB-63",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "SMLB-64": {
                                "cardNumber": "SMLB-64",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-65": {
                                "cardNumber": "SMLB-65",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SMLB-66": {
                                "cardNumber": "SMLB-66",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SMLB-67": {
                                "cardNumber": "SMLB-67",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-68": {
                                "cardNumber": "SMLB-68",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SMLB-69": {
                                "cardNumber": "SMLB-69",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SMLB-70": {
                                "cardNumber": "SMLB-70",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "SMLB-71": {
                                "cardNumber": "SMLB-71",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-72": {
                                "cardNumber": "SMLB-72",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-73": {
                                "cardNumber": "SMLB-73",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-74": {
                                "cardNumber": "SMLB-74",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-75": {
                                "cardNumber": "SMLB-75",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-76": {
                                "cardNumber": "SMLB-76",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SMLB-77": {
                                "cardNumber": "SMLB-77",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-78": {
                                "cardNumber": "SMLB-78",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SMLB-79": {
                                "cardNumber": "SMLB-79",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "SMLB-80": {
                                "cardNumber": "SMLB-80",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "SMLB-81": {
                                "cardNumber": "SMLB-81",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-82": {
                                "cardNumber": "SMLB-82",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-83": {
                                "cardNumber": "SMLB-83",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-84": {
                                "cardNumber": "SMLB-84",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SMLB-85": {
                                "cardNumber": "SMLB-85",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Chrome Stars of MLB": {
                            "CSMLB-61": {
                                "cardNumber": "CSMLB-61",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CSMLB-62": {
                                "cardNumber": "CSMLB-62",
                                "name": ["Matt Chapman"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "CSMLB-63": {
                                "cardNumber": "CSMLB-63",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CSMLB-64": {
                                "cardNumber": "CSMLB-64",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-65": {
                                "cardNumber": "CSMLB-65",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CSMLB-66": {
                                "cardNumber": "CSMLB-66",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CSMLB-67": {
                                "cardNumber": "CSMLB-67",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-68": {
                                "cardNumber": "CSMLB-68",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CSMLB-69": {
                                "cardNumber": "CSMLB-69",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "CSMLB-70": {
                                "cardNumber": "CSMLB-70",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CSMLB-71": {
                                "cardNumber": "CSMLB-71",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-72": {
                                "cardNumber": "CSMLB-72",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-73": {
                                "cardNumber": "CSMLB-73",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-74": {
                                "cardNumber": "CSMLB-74",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-75": {
                                "cardNumber": "CSMLB-75",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-76": {
                                "cardNumber": "CSMLB-76",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CSMLB-77": {
                                "cardNumber": "CSMLB-77",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-78": {
                                "cardNumber": "CSMLB-78",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CSMLB-79": {
                                "cardNumber": "CSMLB-79",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "CSMLB-80": {
                                "cardNumber": "CSMLB-80",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "CSMLB-81": {
                                "cardNumber": "CSMLB-81",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-82": {
                                "cardNumber": "CSMLB-82",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-83": {
                                "cardNumber": "CSMLB-83",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-84": {
                                "cardNumber": "CSMLB-84",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CSMLB-85": {
                                "cardNumber": "CSMLB-85",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Stratospheric Stars": {
                            "SS-1": {
                                "cardNumber": "SS-1",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SS-2": {
                                "cardNumber": "SS-2",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SS-3": {
                                "cardNumber": "SS-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SS-4": {
                                "cardNumber": "SS-4",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SS-5": {
                                "cardNumber": "SS-5",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SS-6": {
                                "cardNumber": "SS-6",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SS-7": {
                                "cardNumber": "SS-7",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SS-8": {
                                "cardNumber": "SS-8",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SS-9": {
                                "cardNumber": "SS-9",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SS-10": {
                                "cardNumber": "SS-10",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        // "Oversized All Star Game Red Carpet Card Redemptions": {

                        // }
                    },
                    "Relic": {
                        "Major League Material Cards": {
                            "MLMU-AJ": {
                                "cardNumber": "MLMU-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMU-AR": {
                                "cardNumber": "MLMU-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMU-BW": {
                                "cardNumber": "MLMU-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MLMU-CC": {
                                "cardNumber": "MLMU-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-CM": {
                                "cardNumber": "MLMU-CM",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLMU-CR": {
                                "cardNumber": "MLMU-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-CY": {
                                "cardNumber": "MLMU-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "MLMU-DJ": {
                                "cardNumber": "MLMU-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMU-EC": {
                                "cardNumber": "MLMU-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-ED": {
                                "cardNumber": "MLMU-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-FF": {
                                "cardNumber": "MLMU-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLMU-FT": {
                                "cardNumber": "MLMU-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLMU-GH": {
                                "cardNumber": "MLMU-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MLMU-HK": {
                                "cardNumber": "MLMU-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JC": {
                                "cardNumber": "MLMU-JC",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JCA": {
                                "cardNumber": "MLMU-JCA",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JD": {
                                "cardNumber": "MLMU-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JH": {
                                "cardNumber": "MLMU-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JHL": {
                                "cardNumber": "MLMU-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JL": {
                                "cardNumber": "MLMU-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JME": {
                                "cardNumber": "MLMU-JME",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-JR": {
                                "cardNumber": "MLMU-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MLMU-JS": {
                                "cardNumber": "MLMU-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMU-JTR": {
                                "cardNumber": "MLMU-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLMU-JW": {
                                "cardNumber": "MLMU-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-MM": {
                                "cardNumber": "MLMU-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLMU-MT": {
                                "cardNumber": "MLMU-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLMU-NM": {
                                "cardNumber": "MLMU-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-OC": {
                                "cardNumber": "MLMU-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLMU-PA": {
                                "cardNumber": "MLMU-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MLMU-PC": {
                                "cardNumber": "MLMU-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-PG": {
                                "cardNumber": "MLMU-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MLMU-PS": {
                                "cardNumber": "MLMU-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-RA": {
                                "cardNumber": "MLMU-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMU-SI": {
                                "cardNumber": "MLMU-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-SP": {
                                "cardNumber": "MLMU-SP",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MLMU-VG": {
                                "cardNumber": "MLMU-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLMU-WL": {
                                "cardNumber": "MLMU-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMU-YA": {
                                "cardNumber": "MLMU-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMU-YY": {
                                "cardNumber": "MLMU-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "1989 Topps Baseball Relic Cards": {
                            "89RU-AJ": {
                                "cardNumber": "89RU-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89RU-BB": {
                                "cardNumber": "89RU-BB",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-BW": {
                                "cardNumber": "89RU-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89RU-CC": {
                                "cardNumber": "89RU-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-CR": {
                                "cardNumber": "89RU-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-CRJ": {
                                "cardNumber": "89RU-CRJ",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89RU-CY": {
                                "cardNumber": "89RU-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89RU-DJ": {
                                "cardNumber": "89RU-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89RU-DO": {
                                "cardNumber": "89RU-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89RU-DS": {
                                "cardNumber": "89RU-DS",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-EC": {
                                "cardNumber": "89RU-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-ED": {
                                "cardNumber": "89RU-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-FF": {
                                "cardNumber": "89RU-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89RU-FT": {
                                "cardNumber": "89RU-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89RU-GH": {
                                "cardNumber": "89RU-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89RU-HK": {
                                "cardNumber": "89RU-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JCA": {
                                "cardNumber": "89RU-JCA",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JCH": {
                                "cardNumber": "89RU-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JD": {
                                "cardNumber": "89RU-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JH": {
                                "cardNumber": "89RU-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JHL": {
                                "cardNumber": "89RU-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JJ": {
                                "cardNumber": "89RU-JJ",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JLA": {
                                "cardNumber": "89RU-JLA",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-JM": {
                                "cardNumber": "89RU-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89RU-JR": {
                                "cardNumber": "89RU-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89RU-JS": {
                                "cardNumber": "89RU-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89RU-JV": {
                                "cardNumber": "89RU-JV",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89RU-JWE": {
                                "cardNumber": "89RU-JWE",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-LB": {
                                "cardNumber": "89RU-LB",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-LM": {
                                "cardNumber": "89RU-LM",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-MB": {
                                "cardNumber": "89RU-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89RU-MM": {
                                "cardNumber": "89RU-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89RU-MT": {
                                "cardNumber": "89RU-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89RU-OC": {
                                "cardNumber": "89RU-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "89RU-PA": {
                                "cardNumber": "89RU-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89RU-PC": {
                                "cardNumber": "89RU-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-PG": {
                                "cardNumber": "89RU-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89RU-PS": {
                                "cardNumber": "89RU-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-RA": {
                                "cardNumber": "89RU-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89RU-SI": {
                                "cardNumber": "89RU-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-VG": {
                                "cardNumber": "89RU-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89RU-WL": {
                                "cardNumber": "89RU-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-YA": {
                                "cardNumber": "89RU-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89RU-YY": {
                                "cardNumber": "89RU-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89RU-ZG": {
                                "cardNumber": "89RU-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Rising Rookie Relic Cards": {
                            "RRR-AC": {
                                "cardNumber": "RRR-AC",
                                "name": ["Alexander Canario"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-AS": {
                                "cardNumber": "RRR-AS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-AW": {
                                "cardNumber": "RRR-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-BB": {
                                "cardNumber": "RRR-BB",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-CC": {
                                "cardNumber": "RRR-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-CE": {
                                "cardNumber": "RRR-CE",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-CHO": {
                                "cardNumber": "RRR-CHO",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-CR": {
                                "cardNumber": "RRR-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-DS": {
                                "cardNumber": "RRR-DS",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-EC": {
                                "cardNumber": "RRR-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-ED": {
                                "cardNumber": "RRR-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-ES": {
                                "cardNumber": "RRR-ES",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-GW": {
                                "cardNumber": "RRR-GW",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-HD": {
                                "cardNumber": "RRR-HD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-HK": {
                                "cardNumber": "RRR-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-JC": {
                                "cardNumber": "RRR-JC",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-JD": {
                                "cardNumber": "RRR-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-JH": {
                                "cardNumber": "RRR-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-JHL": {
                                "cardNumber": "RRR-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-JJ": {
                                "cardNumber": "RRR-JJ",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-JL": {
                                "cardNumber": "RRR-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-JM": {
                                "cardNumber": "RRR-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-KH": {
                                "cardNumber": "RRR-KH",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-LB": {
                                "cardNumber": "RRR-LB",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-LM": {
                                "cardNumber": "RRR-LM",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-MW": {
                                "cardNumber": "RRR-MW",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-NM": {
                                "cardNumber": "RRR-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-NS": {
                                "cardNumber": "RRR-NS",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-PC": {
                                "cardNumber": "RRR-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-PS": {
                                "cardNumber": "RRR-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-RM": {
                                "cardNumber": "RRR-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-SF": {
                                "cardNumber": "RRR-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-SI": {
                                "cardNumber": "RRR-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-TS": {
                                "cardNumber": "RRR-TS",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-WEST": {
                                "cardNumber": "RRR-WEST",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-WIC": {
                                "cardNumber": "RRR-WIC",
                                "name": ["Jordan Wicks"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-WL": {
                                "cardNumber": "RRR-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-WM": {
                                "cardNumber": "RRR-WM",
                                "name": ["Wade Meckler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-YY": {
                                "cardNumber": "RRR-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRR-ZG": {
                                "cardNumber": "RRR-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Own the Name Relics": {
                            "OTN-AB": {
                                "cardNumber": "OTN-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTN-ABE": {
                                "cardNumber": "OTN-ABE",
                                "name": ["Andrew Benintendi"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-AN": {
                                "cardNumber": "OTN-AN",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTN-AR": {
                                "cardNumber": "OTN-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "OTN-ARI": {
                                "cardNumber": "OTN-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "OTN-BB": {
                                "cardNumber": "OTN-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "OTN-BH": {
                                "cardNumber": "OTN-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTN-BN": {
                                "cardNumber": "OTN-BN",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "OTN-BR": {
                                "cardNumber": "OTN-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "OTN-BW": {
                                "cardNumber": "OTN-BW",
                                "name": ["Brandon Woodruff"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "OTN-BWJ": {
                                "cardNumber": "OTN-BWJ",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "OTN-CB": {
                                "cardNumber": "OTN-CB",
                                "name": ["Charlie Blackmon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "OTN-CBE": {
                                "cardNumber": "OTN-CBE",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OTN-CC": {
                                "cardNumber": "OTN-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "OTN-CCA": {
                                "cardNumber": "OTN-CCA",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "OTN-CM": {
                                "cardNumber": "OTN-CM",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OTN-CMU": {
                                "cardNumber": "OTN-CMU",
                                "name": ["Cedric Mullins"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "OTN-CR": {
                                "cardNumber": "OTN-CR",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "OTN-CY": {
                                "cardNumber": "OTN-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "OTN-DS": {
                                "cardNumber": "OTN-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OTN-EJ": {
                                "cardNumber": "OTN-EJ",
                                "name": ["Eloy Jiménez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-ET": {
                                "cardNumber": "OTN-ET",
                                "name": ["Ezequiel Tovar"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "OTN-FL": {
                                "cardNumber": "OTN-FL",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "OTN-FP": {
                                "cardNumber": "OTN-FP",
                                "name": ["Freddy Peralta"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "OTN-GM": {
                                "cardNumber": "OTN-GM",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "OTN-GS": {
                                "cardNumber": "OTN-GS",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "OTN-HG": {
                                "cardNumber": "OTN-HG",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "OTN-IH": {
                                "cardNumber": "OTN-IH",
                                "name": ["Ian Happ"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OTN-JA": {
                                "cardNumber": "OTN-JA",
                                "name": ["José Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTN-JAL": {
                                "cardNumber": "OTN-JAL",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTN-JB": {
                                "cardNumber": "OTN-JB",
                                "name": ["José Berríos"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "OTN-JC": {
                                "cardNumber": "OTN-JC",
                                "name": ["J.P. Crawford"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "OTN-JCJ": {
                                "cardNumber": "OTN-JCJ",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "OTN-JD": {
                                "cardNumber": "OTN-JD",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-JI": {
                                "cardNumber": "OTN-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "OTN-JM": {
                                "cardNumber": "OTN-JM",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "OTN-JMC": {
                                "cardNumber": "OTN-JMC",
                                "name": ["Jeff McNeil"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "OTN-JP": {
                                "cardNumber": "OTN-JP",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTN-JR": {
                                "cardNumber": "OTN-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "OTN-JRA": {
                                "cardNumber": "OTN-JRA",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "OTN-JTR": {
                                "cardNumber": "OTN-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTN-JV": {
                                "cardNumber": "OTN-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "OTN-KB": {
                                "cardNumber": "OTN-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "OTN-KH": {
                                "cardNumber": "OTN-KH",
                                "name": ["Ke'Bryan Hayes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "OTN-KS": {
                                "cardNumber": "OTN-KS",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTN-KT": {
                                "cardNumber": "OTN-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTN-LA": {
                                "cardNumber": "OTN-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "OTN-LC": {
                                "cardNumber": "OTN-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "OTN-LR": {
                                "cardNumber": "OTN-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-MB": {
                                "cardNumber": "OTN-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "OTN-MC": {
                                "cardNumber": "OTN-MC",
                                "name": ["Michael Conforto"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "OTN-MF": {
                                "cardNumber": "OTN-MF",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "OTN-MK": {
                                "cardNumber": "OTN-MK",
                                "name": ["Max Kepler"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "OTN-MM": {
                                "cardNumber": "OTN-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "OTN-MME": {
                                "cardNumber": "OTN-MME",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "OTN-MMU": {
                                "cardNumber": "OTN-MMU",
                                "name": ["Max Muncy"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "OTN-MS": {
                                "cardNumber": "OTN-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "OTN-MT": {
                                "cardNumber": "OTN-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "OTN-MY": {
                                "cardNumber": "OTN-MY",
                                "name": ["Mike Yastrzemski"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "OTN-MYO": {
                                "cardNumber": "OTN-MYO",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-NC": {
                                "cardNumber": "OTN-NC",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTN-NH": {
                                "cardNumber": "OTN-NH",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OTN-OA": {
                                "cardNumber": "OTN-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "OTN-PA": {
                                "cardNumber": "OTN-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "OTN-PB": {
                                "cardNumber": "OTN-PB",
                                "name": ["Patrick Bailey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "OTN-RA": {
                                "cardNumber": "OTN-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "OTN-RAJ": {
                                "cardNumber": "OTN-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "OTN-RD": {
                                "cardNumber": "OTN-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-RG": {
                                "cardNumber": "OTN-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "OTN-RM": {
                                "cardNumber": "OTN-RM",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "OTN-SM": {
                                "cardNumber": "OTN-SM",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "OTN-SP": {
                                "cardNumber": "OTN-SP",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "OTN-SS": {
                                "cardNumber": "OTN-SS",
                                "name": ["Spencer Steer"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "OTN-SST": {
                                "cardNumber": "OTN-SST",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "OTN-SSU": {
                                "cardNumber": "OTN-SSU",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OTN-ST": {
                                "cardNumber": "OTN-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "OTN-TC": {
                                "cardNumber": "OTN-TC",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-TM": {
                                "cardNumber": "OTN-TM",
                                "name": ["Triston McKenzie"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "OTN-TS": {
                                "cardNumber": "OTN-TS",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "OTN-TT": {
                                "cardNumber": "OTN-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "OTN-VG": {
                                "cardNumber": "OTN-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "OTN-WA": {
                                "cardNumber": "OTN-WA",
                                "name": ["Willy Adames"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "OTN-WB": {
                                "cardNumber": "OTN-WB",
                                "name": ["Walker Buehler"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "OTN-WC": {
                                "cardNumber": "OTN-WC",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "OTN-WS": {
                                "cardNumber": "OTN-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "OTN-XB": {
                                "cardNumber": "OTN-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "OTN-YA": {
                                "cardNumber": "OTN-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "OTN-YD": {
                                "cardNumber": "OTN-YD",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "OTN-ZG": {
                                "cardNumber": "OTN-ZG",
                                "name": ["Zac Gallen"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "OTN-ZW": {
                                "cardNumber": "OTN-ZW",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            }
                        },
                        "Cut Signatures": {
                            "CSU-AK": {
                                "cardNumber": "CSU-AK",
                                "name": ["Al Kaline"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "CSU-JM": {
                                "cardNumber": "CSU-JM",
                                "name": ["Johnny Mize"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CSU-MI": {
                                "cardNumber": "CSU-MI",
                                "name": ["Monte Irvin"],
                                "team": ["New Giants™"],
                                "cardAttributes": []
                            },
                            "CSU-MM": {
                                "cardNumber": "CSU-MM",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CSU-RC": {
                                "cardNumber": "CSU-RC",
                                "name": ["Roy Campanella"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "CSU-RM": {
                                "cardNumber": "CSU-RM",
                                "name": ["Roger Maris"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CSU-SM": {
                                "cardNumber": "CSU-SM",
                                "name": ["Stan Musial"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitches Cards": {
                            "ASR-AB": {
                                "cardNumber": "ASR-AB",
                                "name": ["Alec Bohm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASR-AJ": {
                                "cardNumber": "ASR-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASR-AR": {
                                "cardNumber": "ASR-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASR-BH": {
                                "cardNumber": "ASR-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASR-BR": {
                                "cardNumber": "ASR-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "ASR-BW": {
                                "cardNumber": "ASR-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASR-CA": {
                                "cardNumber": "ASR-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASR-CB": {
                                "cardNumber": "ASR-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASR-CC": {
                                "cardNumber": "ASR-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASR-CH": {
                                "cardNumber": "ASR-CH",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASR-CR": {
                                "cardNumber": "ASR-CR",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASR-CRI": {
                                "cardNumber": "ASR-CRI",
                                "name": ["Cristopher Sánchez"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASR-CS": {
                                "cardNumber": "ASR-CS",
                                "name": ["Chris Sale"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASR-CY": {
                                "cardNumber": "ASR-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASR-DF": {
                                "cardNumber": "ASR-DF",
                                "name": ["David Fry"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASR-EC": {
                                "cardNumber": "ASR-EC",
                                "name": ["Emmanuel Clase"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASR-ED": {
                                "cardNumber": "ASR-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASR-FF": {
                                "cardNumber": "ASR-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASR-FRI": {
                                "cardNumber": "ASR-FRI",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASR-GC": {
                                "cardNumber": "ASR-GC",
                                "name": ["Garrett Crochet"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASR-GH": {
                                "cardNumber": "ASR-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASR-HG": {
                                "cardNumber": "ASR-HG",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASR-HR": {
                                "cardNumber": "ASR-HR",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASR-IP": {
                                "cardNumber": "ASR-IP",
                                "name": ["Isaac Paredes"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "ASR-JD": {
                                "cardNumber": "ASR-JD",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASR-JH": {
                                "cardNumber": "ASR-JH",
                                "name": ["Jeff Hoffman"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASR-JM": {
                                "cardNumber": "ASR-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASR-JN": {
                                "cardNumber": "ASR-JN",
                                "name": ["Josh Naylor"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASR-JP": {
                                "cardNumber": "ASR-JP",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASR-JR": {
                                "cardNumber": "ASR-JR",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASR-JS": {
                                "cardNumber": "ASR-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASR-JW": {
                                "cardNumber": "ASR-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASR-KM": {
                                "cardNumber": "ASR-KM",
                                "name": ["Ketel Marte"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "ASR-KY": {
                                "cardNumber": "ASR-KY",
                                "name": ["Kirby Yates"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASR-LA": {
                                "cardNumber": "ASR-LA",
                                "name": ["Luis Arraez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASR-LG": {
                                "cardNumber": "ASR-LG",
                                "name": ["Logan Gilbert"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ASR-LW": {
                                "cardNumber": "ASR-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASR-MM": {
                                "cardNumber": "ASR-MM",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "ASR-MO": {
                                "cardNumber": "ASR-MO",
                                "name": ["Marcell Ozuna"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASR-MS": {
                                "cardNumber": "ASR-MS",
                                "name": ["Matt Strahm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASR-MSE": {
                                "cardNumber": "ASR-MSE",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASR-MUN": {
                                "cardNumber": "ASR-MUN",
                                "name": ["Andrés Muñoz"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ASR-PA": {
                                "cardNumber": "ASR-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASR-PS": {
                                "cardNumber": "ASR-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASR-RG": {
                                "cardNumber": "ASR-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASR-RH": {
                                "cardNumber": "ASR-RH",
                                "name": ["Ryan Helsley"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASR-RL": {
                                "cardNumber": "ASR-RL",
                                "name": ["Reynaldo López"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASR-RM": {
                                "cardNumber": "ASR-RM",
                                "name": ["Ryan McMahon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "ASR-RS": {
                                "cardNumber": "ASR-RS",
                                "name": ["Robert Suarez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASR-SAN": {
                                "cardNumber": "ASR-SAN",
                                "name": ["Anthony Santander"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASR-SI": {
                                "cardNumber": "ASR-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASR-SK": {
                                "cardNumber": "ASR-SK",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASR-SL": {
                                "cardNumber": "ASR-SL",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASR-SO": {
                                "cardNumber": "ASR-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASR-SP": {
                                "cardNumber": "ASR-SP",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASR-TA": {
                                "cardNumber": "ASR-TA",
                                "name": ["Tyler Anderson"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASR-TG": {
                                "cardNumber": "ASR-TG",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASR-TH": {
                                "cardNumber": "ASR-TH",
                                "name": ["Teoscar Hernández"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASR-THO": {
                                "cardNumber": "ASR-THO",
                                "name": ["Tanner Houck"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASR-TS": {
                                "cardNumber": "ASR-TS",
                                "name": ["Tanner Scott"],
                                "team": ["Miami Marlins™"],
                                "cardAttributes": []
                            },
                            "ASR-TSK": {
                                "cardNumber": "ASR-TSK",
                                "name": ["Tarik Skubal"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASR-TT": {
                                "cardNumber": "ASR-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASR-VG": {
                                "cardNumber": "ASR-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASR-WC": {
                                "cardNumber": "ASR-WC",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASR-WIL": {
                                "cardNumber": "ASR-WIL",
                                "name": ["Willi Castro"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASR-WS": {
                                "cardNumber": "ASR-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASR-YA": {
                                "cardNumber": "ASR-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Jumbo Patch Cards": {
                            "ASJP-AB": {
                                "cardNumber": "ASJP-AB",
                                "name": ["Alec Bohm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASJP-AJ": {
                                "cardNumber": "ASJP-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJP-AR": {
                                "cardNumber": "ASJP-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASJP-BH": {
                                "cardNumber": "ASJP-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASJP-BR": {
                                "cardNumber": "ASJP-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "ASJP-BW": {
                                "cardNumber": "ASJP-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASJP-CA": {
                                "cardNumber": "ASJP-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASJP-CB": {
                                "cardNumber": "ASJP-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASJP-CC": {
                                "cardNumber": "ASJP-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASJP-CH": {
                                "cardNumber": "ASJP-CH",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJP-CR": {
                                "cardNumber": "ASJP-CR",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASJP-CS": {
                                "cardNumber": "ASJP-CS",
                                "name": ["Chris Sale"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJP-CY": {
                                "cardNumber": "ASJP-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASJP-EC": {
                                "cardNumber": "ASJP-EC",
                                "name": ["Emmanuel Clase"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASJP-ED": {
                                "cardNumber": "ASJP-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASJP-FF": {
                                "cardNumber": "ASJP-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASJP-FRI": {
                                "cardNumber": "ASJP-FRI",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJP-GC": {
                                "cardNumber": "ASJP-GC",
                                "name": ["Garrett Crochet"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASJP-GH": {
                                "cardNumber": "ASJP-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASJP-HG": {
                                "cardNumber": "ASJP-HG",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASJP-HR": {
                                "cardNumber": "ASJP-HR",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASJP-JD": {
                                "cardNumber": "ASJP-JD",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASJP-JM": {
                                "cardNumber": "ASJP-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASJP-JN": {
                                "cardNumber": "ASJP-JN",
                                "name": ["Josh Naylor"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASJP-JP": {
                                "cardNumber": "ASJP-JP",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASJP-JR": {
                                "cardNumber": "ASJP-JR",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASJP-JS": {
                                "cardNumber": "ASJP-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJP-JW": {
                                "cardNumber": "ASJP-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASJP-KM": {
                                "cardNumber": "ASJP-KM",
                                "name": ["Ketel Marte"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "ASJP-KY": {
                                "cardNumber": "ASJP-KY",
                                "name": ["Kirby Yates"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASJP-LA": {
                                "cardNumber": "ASJP-LA",
                                "name": ["Luis Arraez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASJP-LG": {
                                "cardNumber": "ASJP-LG",
                                "name": ["Logan Gilbert"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ASJP-LW": {
                                "cardNumber": "ASJP-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASJP-MM": {
                                "cardNumber": "ASJP-MM",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "ASJP-MO": {
                                "cardNumber": "ASJP-MO",
                                "name": ["Marcell Ozuna"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJP-MS": {
                                "cardNumber": "ASJP-MS",
                                "name": ["Matt Strahm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASJP-MSE": {
                                "cardNumber": "ASJP-MSE",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASJP-PA": {
                                "cardNumber": "ASJP-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASJP-PS": {
                                "cardNumber": "ASJP-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASJP-RG": {
                                "cardNumber": "ASJP-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASJP-RH": {
                                "cardNumber": "ASJP-RH",
                                "name": ["Ryan Helsley"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASJP-SI": {
                                "cardNumber": "ASJP-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASJP-SK": {
                                "cardNumber": "ASJP-SK",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASJP-SL": {
                                "cardNumber": "ASJP-SL",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASJP-SO": {
                                "cardNumber": "ASJP-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASJP-SP": {
                                "cardNumber": "ASJP-SP",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASJP-TG": {
                                "cardNumber": "ASJP-TG",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASJP-TH": {
                                "cardNumber": "ASJP-TH",
                                "name": ["Teoscar Hernández"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASJP-THO": {
                                "cardNumber": "ASJP-THO",
                                "name": ["Tanner Houck"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASJP-TS": {
                                "cardNumber": "ASJP-TS",
                                "name": ["Tarik Skubal"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASJP-TT": {
                                "cardNumber": "ASJP-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASJP-VG": {
                                "cardNumber": "ASJP-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASJP-WC": {
                                "cardNumber": "ASJP-WC",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASJP-WS": {
                                "cardNumber": "ASJP-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASJP-YA": {
                                "cardNumber": "ASJP-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitches Dual Cards": {
                            "ASSD-AM": {
                                "cardNumber": "ASSD-AM",
                                "name": ["Jackson Merrill", "Luis Arraez"],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSD-DLG": {
                                "cardNumber": "ASSD-DLG",
                                "name": ["Elly De La Cruz", "Hunter Greene"],
                                "team": ["Cincinnati Reds®", "Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSD-FO": {
                                "cardNumber": "ASSD-FO",
                                "name": ["Marcell Ozuna", "Max Fried"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSD-GLS": {
                                "cardNumber": "ASSD-GLS",
                                "name": ["Tyler Glasnow", "Will Smith"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSD-GS": {
                                "cardNumber": "ASSD-GS",
                                "name": ["Tarik Skubal", "Riley Greene"],
                                "team": ["Detroit Tigers®", "Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASSD-HAT": {
                                "cardNumber": "ASSD-HAT",
                                "name": ["Trea Turner", "Bryce Harper"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASSD-HD": {
                                "cardNumber": "ASSD-HD",
                                "name": ["Tanner Houck", "Jarren Duran"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASSD-HGJ": {
                                "cardNumber": "ASSD-HGJ",
                                "name": ["Bryce Harper", "Vladimir Guerrero Jr."],
                                "team": ["Philadelphia Phillies®", "Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSD-HWE": {
                                "cardNumber": "ASSD-HWE",
                                "name": ["Jordan Westburg", "Gunnar Henderson"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSD-HWJ": {
                                "cardNumber": "ASSD-HWJ",
                                "name": ["Bobby Witt Jr.", "Gunnar Henderson"],
                                "team": ["Kansas City Royals®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASSD-JSO": {
                                "cardNumber": "ASSD-JSO",
                                "name": ["Juan Soto", "Aaron Judge"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSD-LR": {
                                "cardNumber": "ASSD-LR",
                                "name": ["Seth Lugo", "Cole Ragans"],
                                "team": ["Kansas City Royals®", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASSD-OF": {
                                "cardNumber": "ASSD-OF",
                                "name": ["Freddie Freeman", "Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSD-OHJ": {
                                "cardNumber": "ASSD-OHJ",
                                "name": ["Aaron Judge", "Shohei Ohtani"],
                                "team": ["New York Yankees®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSD-RAW": {
                                "cardNumber": "ASSD-RAW",
                                "name": ["Logan Webb", "Heliot Ramos"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASSD-RH": {
                                "cardNumber": "ASSD-RH",
                                "name": ["Adley Rutschman", "Gunnar Henderson"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASSD-RK": {
                                "cardNumber": "ASSD-RK",
                                "name": ["Steven Kwan", "José Ramírez"],
                                "team": ["Cleveland Guardians™", "Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASSD-SGJ": {
                                "cardNumber": "ASSD-SGJ",
                                "name": ["Vladimir Guerrero Jr.", "Juan Soto"],
                                "team": ["Toronto Blue Jays®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSD-SKI": {
                                "cardNumber": "ASSD-SKI",
                                "name": ["Shota Imanaga", "Paul Skenes"],
                                "team": ["Chicago Cubs®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSD-SKR": {
                                "cardNumber": "ASSD-SKR",
                                "name": ["Bryan Reynolds", "Paul Skenes"],
                                "team": ["Pittsburgh Pirates®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSD-WJP": {
                                "cardNumber": "ASSD-WJP",
                                "name": ["Bobby Witt Jr.", "Salvador Perez"],
                                "team": ["Kansas City Royals®", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASSD-YEC": {
                                "cardNumber": "ASSD-YEC",
                                "name": ["Christian Yelich", "William Contreras"],
                                "team": ["Milwaukee Brewers™", "Milwaukee Brewers™"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitches Triple Cards": {
                            "ASST-EHW": {
                                "cardNumber": "ASST-EHW",
                                "name": ["Bobby Witt Jr.", "Elly De La Cruz", "Gunnar Henderson"],
                                "team": ["Kansas City Royals®", "Cincinnati Reds®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASST-FHA": {
                                "cardNumber": "ASST-FHA",
                                "name": ["Bryce Harper", "Pete Alonso", "Freddie Freeman"],
                                "team": ["Philadelphia Phillies®", "New York Mets®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASST-GFS": {
                                "cardNumber": "ASST-GFS",
                                "name": ["Freddie Freeman", "Will Smith", "Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASST-GSG": {
                                "cardNumber": "ASST-GSG",
                                "name": ["Paul Skenes", "Hunter Greene", "Tyler Glasnow"],
                                "team": ["Pittsburgh Pirates®", "Cincinnati Reds®", "Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASST-HTB": {
                                "cardNumber": "ASST-HTB",
                                "name": ["Bryce Harper", "Alec Bohm", "Trea Turner"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASST-JEV": {
                                "cardNumber": "ASST-JEV",
                                "name": ["Elly De La Cruz", "Juan Soto", "Vladimir Guerrero Jr."],
                                "team": ["Cincinnati Reds®", "New York Yankees®", "Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASST-JSH-1": {
                                "cardNumber": "ASST-JSH",
                                "name": ["Aaron Judge", "Clay Holmes", "Juan Soto"],
                                "team": ["New York Yankees®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASST-MDS": {
                                "cardNumber": "ASST-MDS",
                                "name": ["Jackson Merrill", "Elly De La Cruz", "Paul Skenes"],
                                "team": ["San Diego Padres™", "Cincinnati Reds®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASST-OHF": {
                                "cardNumber": "ASST-OHF",
                                "name": ["Teoscar Hernández", "Shohei Ohtani", "Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASST-OJH": {
                                "cardNumber": "ASST-OJH",
                                "name": ["Bryce Harper", "Aaron Judge", "Shohei Ohtani"],
                                "team": ["Philadelphia Phillies®", "New York Yankees®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASST-PAM": {
                                "cardNumber": "ASST-PAM",
                                "name": ["Jackson Merrill", "Jurickson Profar", "Luis Arraez"],
                                "team": ["San Diego Padres™", "San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASST-RAK": {
                                "cardNumber": "ASST-RAK",
                                "name": ["José Ramírez", "Josh Naylor", "Steven Kwan"],
                                "team": ["Cleveland Guardians™", "Cleveland Guardians™", "Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASST-RHB": {
                                "cardNumber": "ASST-RHB",
                                "name": ["Gunnar Henderson", "Adley Rutschman", "Corbin Burnes"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASST-RHH": {
                                "cardNumber": "ASST-RHH",
                                "name": ["Adley Rutschman", "Jordan Westburg", "Gunnar Henderson"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASST-WPR": {
                                "cardNumber": "ASST-WPR",
                                "name": ["Salvador Perez", "Bobby Witt Jr.", "Cole Ragans"],
                                "team": ["Kansas City Royals®", "Kansas City Royals®", "Kansas City Royals®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Autograph Relic": {
                        "Topps Reverence Autograph Patch Cards": {
                            "TRAPU-ARU": {
                                "cardNumber": "TRAPU-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TRAPU-AW": {
                                "cardNumber": "TRAPU-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-BW": {
                                "cardNumber": "TRAPU-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TRAPU-CC": {
                                "cardNumber": "TRAPU-CC",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "TRAPU-CCO": {
                                "cardNumber": "TRAPU-CCO",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-CE": {
                                "cardNumber": "TRAPU-CE",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-CM": {
                                "cardNumber": "TRAPU-CM",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-HK": {
                                "cardNumber": "TRAPU-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-JCH": {
                                "cardNumber": "TRAPU-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-JD": {
                                "cardNumber": "TRAPU-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-JL": {
                                "cardNumber": "TRAPU-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-JR": {
                                "cardNumber": "TRAPU-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "TRAPU-ML": {
                                "cardNumber": "TRAPU-ML",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-MT": {
                                "cardNumber": "TRAPU-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "TRAPU-PA": {
                                "cardNumber": "TRAPU-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TRAPU-PG": {
                                "cardNumber": "TRAPU-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "TRAPU-RA": {
                                "cardNumber": "TRAPU-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAPU-RM": {
                                "cardNumber": "TRAPU-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-SF": {
                                "cardNumber": "TRAPU-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAPU-SS": {
                                "cardNumber": "TRAPU-SS",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAPU-VG": {
                                "cardNumber": "TRAPU-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "TRAP-BP": {
                                "cardNumber": "TRAP-BP",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "TRAP-DS": {
                                "cardNumber": "TRAP-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "TRAP-KB": {
                                "cardNumber": "TRAP-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "TRAP-MY": {
                                "cardNumber": "TRAP-MY",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "TRAP2-ABE": {
                                "cardNumber": "TRAP2-ABE",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "TRAP2-BP": {
                                "cardNumber": "TRAP2-BP",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "TRAP2-DO": {
                                "cardNumber": "TRAP2-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "TRAP2-JAZ": {
                                "cardNumber": "TRAP2-JAZ",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "TRAP2-JV": {
                                "cardNumber": "TRAP2-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "TRAP2-ML": {
                                "cardNumber": "TRAP2-ML",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAP2-NM": {
                                "cardNumber": "TRAP2-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TRAP2-PA": {
                                "cardNumber": "TRAP2-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TRAP2-RAJ": {
                                "cardNumber": "TRAP2-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAP2-SO": {
                                "cardNumber": "TRAP2-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "Heavy Lumber Autograph Cards": {
                            "HLAU-AR": {
                                "cardNumber": "HLAU-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "HLAU-BH": {
                                "cardNumber": "HLAU-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "HLAU-CC": {
                                "cardNumber": "HLAU-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HLAU-DJ": {
                                "cardNumber": "HLAU-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HLAU-DM": {
                                "cardNumber": "HLAU-DM",
                                "name": ["Don Mattingly"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HLAU-DMU": {
                                "cardNumber": "HLAU-DMU",
                                "name": ["Dale Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HLAU-ED": {
                                "cardNumber": "HLAU-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HLAU-JCH": {
                                "cardNumber": "HLAU-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HLAU-JH": {
                                "cardNumber": "HLAU-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HLAU-JM": {
                                "cardNumber": "HLAU-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HLAU-JS": {
                                "cardNumber": "HLAU-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HLAU-MM": {
                                "cardNumber": "HLAU-MM",
                                "name": ["Mark McGwire"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "HLAU-MT": {
                                "cardNumber": "HLAU-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "HLAU-NA": {
                                "cardNumber": "HLAU-NA",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HLAU-RA": {
                                "cardNumber": "HLAU-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HLAU-RH": {
                                "cardNumber": "HLAU-RH",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "HLAU-SO": {
                                "cardNumber": "HLAU-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HLAU-WC": {
                                "cardNumber": "HLAU-WC",
                                "name": ["Will Clark"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "HLA2-AB": {
                                "cardNumber": "HLA2-AB",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "HLA2-AP": {
                                "cardNumber": "HLA2-AP",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HLA2-AROD": {
                                "cardNumber": "HLA2-AROD",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HLA2-DO": {
                                "cardNumber": "HLA2-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "HLA2-JA": {
                                "cardNumber": "HLA2-JA",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HLA2-JV": {
                                "cardNumber": "HLA2-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "HLA2-KGJ": {
                                "cardNumber": "HLA2-KGJ",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "HLA2-MO": {
                                "cardNumber": "HLA2-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HLA2-MP": {
                                "cardNumber": "HLA2-MP",
                                "name": ["Mike Piazza"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "HLA2-RS": {
                                "cardNumber": "HLA2-RS",
                                "name": ["Ryne Sandberg"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            }
                        },
                        "Major League Material Autograph Cards": {
                            "MLMAU-AS": {
                                "cardNumber": "MLMAU-AS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-AW": {
                                "cardNumber": "MLMAU-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-BB": {
                                "cardNumber": "MLMAU-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLMAU-BM": {
                                "cardNumber": "MLMAU-BM",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLMAU-CC": {
                                "cardNumber": "MLMAU-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-CCA": {
                                "cardNumber": "MLMAU-CCA",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLMAU-CMO": {
                                "cardNumber": "MLMAU-CMO",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLMAU-CP": {
                                "cardNumber": "MLMAU-CP",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-CR": {
                                "cardNumber": "MLMAU-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-DS": {
                                "cardNumber": "MLMAU-DS",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-EC": {
                                "cardNumber": "MLMAU-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-ED": {
                                "cardNumber": "MLMAU-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-EL": {
                                "cardNumber": "MLMAU-EL",
                                "name": ["Evan Longoria"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLMAU-ES": {
                                "cardNumber": "MLMAU-ES",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-FT": {
                                "cardNumber": "MLMAU-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLMAU-GW": {
                                "cardNumber": "MLMAU-GW",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-HK": {
                                "cardNumber": "MLMAU-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JC": {
                                "cardNumber": "MLMAU-JC",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JCH": {
                                "cardNumber": "MLMAU-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JD": {
                                "cardNumber": "MLMAU-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JH": {
                                "cardNumber": "MLMAU-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JHL": {
                                "cardNumber": "MLMAU-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JLA": {
                                "cardNumber": "MLMAU-JLA",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JP": {
                                "cardNumber": "MLMAU-JP",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMAU-JS": {
                                "cardNumber": "MLMAU-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMAU-JW": {
                                "cardNumber": "MLMAU-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-JWA": {
                                "cardNumber": "MLMAU-JWA",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MLMAU-KH": {
                                "cardNumber": "MLMAU-KH",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-KT": {
                                "cardNumber": "MLMAU-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMAU-LC": {
                                "cardNumber": "MLMAU-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MLMAU-MC": {
                                "cardNumber": "MLMAU-MC",
                                "name": ["Matt Chapman"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLMAU-MF": {
                                "cardNumber": "MLMAU-MF",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMAU-MH": {
                                "cardNumber": "MLMAU-MH",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMAU-MS": {
                                "cardNumber": "MLMAU-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MLMAU-MW": {
                                "cardNumber": "MLMAU-MW",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-NM": {
                                "cardNumber": "MLMAU-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-NS": {
                                "cardNumber": "MLMAU-NS",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-PS": {
                                "cardNumber": "MLMAU-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-RA": {
                                "cardNumber": "MLMAU-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLMAU-RG": {
                                "cardNumber": "MLMAU-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "MLMAU-RH": {
                                "cardNumber": "MLMAU-RH",
                                "name": ["Rhys Hoskins"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "MLMAU-SF": {
                                "cardNumber": "MLMAU-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-SI": {
                                "cardNumber": "MLMAU-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-TS": {
                                "cardNumber": "MLMAU-TS",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-VG": {
                                "cardNumber": "MLMAU-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLMAU-WL": {
                                "cardNumber": "MLMAU-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-YY": {
                                "cardNumber": "MLMAU-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLMAU-ZG": {
                                "cardNumber": "MLMAU-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "All Star Stitch Autograph Cards": {
                            "ASSA-AJ": {
                                "cardNumber": "ASSA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSA-AR": {
                                "cardNumber": "ASSA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASSA-BR": {
                                "cardNumber": "ASSA-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "ASSA-BW": {
                                "cardNumber": "ASSA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASSA-CA": {
                                "cardNumber": "ASSA-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASSA-CB": {
                                "cardNumber": "ASSA-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASSA-CR": {
                                "cardNumber": "ASSA-CR",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASSA-CY": {
                                "cardNumber": "ASSA-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASSA-ED": {
                                "cardNumber": "ASSA-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSA-GH": {
                                "cardNumber": "ASSA-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASSA-HR": {
                                "cardNumber": "ASSA-HR",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASSA-JM": {
                                "cardNumber": "ASSA-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSA-JR": {
                                "cardNumber": "ASSA-JR",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASSA-JS": {
                                "cardNumber": "ASSA-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSA-JW": {
                                "cardNumber": "ASSA-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSA-LW": {
                                "cardNumber": "ASSA-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASSA-MAX": {
                                "cardNumber": "ASSA-MAX",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSA-MM": {
                                "cardNumber": "ASSA-MM",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "ASSA-MS": {
                                "cardNumber": "ASSA-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASSA-PA": {
                                "cardNumber": "ASSA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASSA-PS": {
                                "cardNumber": "ASSA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSA-SI": {
                                "cardNumber": "ASSA-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASSA-SO": {
                                "cardNumber": "ASSA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSA-TT": {
                                "cardNumber": "ASSA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASSA-VG": {
                                "cardNumber": "ASSA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSA-WS": {
                                "cardNumber": "ASSA-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSA-YA": {
                                "cardNumber": "ASSA-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitch Dual Autograph Cards": {
                            "ASSDA-HWJ": {
                                "cardNumber": "ASSDA-HWJ",
                                "name": ["Gunnar Henderson", "Bobby Witt Jr."],
                                "team": ["Baltimore Orioles®", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASSDA-JSO": {
                                "cardNumber": "ASSDA-JSO",
                                "name": ["Aaron Judge", "Juan Soto"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSDA-OJ": {
                                "cardNumber": "ASSDA-OJ",
                                "name": ["Aaron Judge", "Shohei Ohtani"],
                                "team": ["New York Yankees®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSDA-RH": {
                                "cardNumber": "ASSDA-RH",
                                "name": ["Gunnar Henderson", "Adley Rutschman"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASSDA-SGJ": {
                                "cardNumber": "ASSDA-SGJ",
                                "name": ["Vladimir Guerrero Jr.", "Juan Soto"],
                                "team": ["Toronto Blue Jays®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSDA-SKR": {
                                "cardNumber": "ASSDA-SKR",
                                "name": ["Bryan Reynolds", "Paul Skenes"],
                                "team": ["Pittsburgh Pirates®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "All Star Autographed Jumbo Patch": {
                            "ASAJP-AJ": {
                                "cardNumber": "ASAJP-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASAJP-AR": {
                                "cardNumber": "ASAJP-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASAJP-BR": {
                                "cardNumber": "ASAJP-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "ASAJP-BW": {
                                "cardNumber": "ASAJP-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASAJP-CA": {
                                "cardNumber": "ASAJP-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASAJP-CB": {
                                "cardNumber": "ASAJP-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASAJP-CR": {
                                "cardNumber": "ASAJP-CR",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASAJP-CY": {
                                "cardNumber": "ASAJP-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASAJP-ED": {
                                "cardNumber": "ASAJP-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASAJP-GH": {
                                "cardNumber": "ASAJP-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASAJP-HG": {
                                "cardNumber": "ASAJP-HG",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASAJP-HR": {
                                "cardNumber": "ASAJP-HR",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASAJP-JM": {
                                "cardNumber": "ASAJP-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASAJP-JR": {
                                "cardNumber": "ASAJP-JR",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASAJP-JS": {
                                "cardNumber": "ASAJP-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASAJP-JW": {
                                "cardNumber": "ASAJP-JW",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASAJP-LW": {
                                "cardNumber": "ASAJP-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASAJP-MAX": {
                                "cardNumber": "ASAJP-MAX",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASAJP-MM": {
                                "cardNumber": "ASAJP-MM",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "ASAJP-MS": {
                                "cardNumber": "ASAJP-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASAJP-PA": {
                                "cardNumber": "ASAJP-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASAJP-PS": {
                                "cardNumber": "ASAJP-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASAJP-SI": {
                                "cardNumber": "ASAJP-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASAJP-SO": {
                                "cardNumber": "ASAJP-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASAJP-TT": {
                                "cardNumber": "ASAJP-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASAJP-VG": {
                                "cardNumber": "ASAJP-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASAJP-WS": {
                                "cardNumber": "ASAJP-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASAJP-YA": {
                                "cardNumber": "ASAJP-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Autograph": {
                        "Let Him Cook Autograph Variation": {
                            "LHCA-BW": {
                                "cardNumber": "LHCA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "LHCA-CC": {
                                "cardNumber": "LHCA-CC",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "LHCA-CS": {
                                "cardNumber": "LHCA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "LHCA-EC": {
                                "cardNumber": "LHCA-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-ED": {
                                "cardNumber": "LHCA-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-FT": {
                                "cardNumber": "LHCA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "LHCA-GH": {
                                "cardNumber": "LHCA-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "LHCA-JC": {
                                "cardNumber": "LHCA-JC",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-JCH": {
                                "cardNumber": "LHCA-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-JD": {
                                "cardNumber": "LHCA-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-JH": {
                                "cardNumber": "LHCA-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-JL": {
                                "cardNumber": "LHCA-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-JR": {
                                "cardNumber": "LHCA-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "LHCA-JS": {
                                "cardNumber": "LHCA-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "LHCA-ML": {
                                "cardNumber": "LHCA-ML",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-MO": {
                                "cardNumber": "LHCA-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "LHCA-MT": {
                                "cardNumber": "LHCA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "LHCA-NA": {
                                "cardNumber": "LHCA-NA",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "LHCA-PA": {
                                "cardNumber": "LHCA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "LHCA-PC": {
                                "cardNumber": "LHCA-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-PS": {
                                "cardNumber": "LHCA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-RA": {
                                "cardNumber": "LHCA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "LHCA-SO": {
                                "cardNumber": "LHCA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "LHCA-VG": {
                                "cardNumber": "LHCA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "LHCA-WL": {
                                "cardNumber": "LHCA-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LHCA-YY": {
                                "cardNumber": "LHCA-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Autumn Tales Autograph Variation": {
                            "ATA-AP": {
                                "cardNumber": "ATA-AP",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ATA-BG": {
                                "cardNumber": "ATA-BG",
                                "name": ["Bob Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ATA-BM": {
                                "cardNumber": "ATA-BM",
                                "name": ["Bill Mazeroski"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "ATA-BS": {
                                "cardNumber": "ATA-BS",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ATA-CAL": {
                                "cardNumber": "ATA-CAL",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ATA-CB": {
                                "cardNumber": "ATA-CB",
                                "name": ["Cody Bellinger"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ATA-CBE": {
                                "cardNumber": "ATA-CBE",
                                "name": ["Carlos Beltrán"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ATA-CF": {
                                "cardNumber": "ATA-CF",
                                "name": ["Carlton Fisk"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ATA-CR": {
                                "cardNumber": "ATA-CR",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ATA-CS": {
                                "cardNumber": "ATA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ATA-CSE": {
                                "cardNumber": "ATA-CSE",
                                "name": ["Corey Seager"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ATA-DJ": {
                                "cardNumber": "ATA-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ATA-DO": {
                                "cardNumber": "ATA-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ATA-DOR": {
                                "cardNumber": "ATA-DOR",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ATA-EM": {
                                "cardNumber": "ATA-EM",
                                "name": ["Edgar Martinez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ATA-JA": {
                                "cardNumber": "ATA-JA",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ATA-JB": {
                                "cardNumber": "ATA-JB",
                                "name": ["Jay Buhner"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ATA-JC": {
                                "cardNumber": "ATA-JC",
                                "name": ["Joe Carter"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ATA-JS": {
                                "cardNumber": "ATA-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ATA-KB": {
                                "cardNumber": "ATA-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ATA-KG": {
                                "cardNumber": "ATA-KG",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "ATA-KH": {
                                "cardNumber": "ATA-KH",
                                "name": ["Keith Hernandez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ATA-MH": {
                                "cardNumber": "ATA-MH",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ATA-OS": {
                                "cardNumber": "ATA-OS",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ATA-RA": {
                                "cardNumber": "ATA-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "ATA-RJ": {
                                "cardNumber": "ATA-RJ",
                                "name": ["Randy Johnson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "ATA-RL": {
                                "cardNumber": "ATA-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ATA-SK": {
                                "cardNumber": "ATA-SK",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ATA-SS": {
                                "cardNumber": "ATA-SS",
                                "name": ["Stephen Strasburg"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ATA-TT": {
                                "cardNumber": "ATA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ATA-YA": {
                                "cardNumber": "ATA-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Black Gold Autograph Variation": {
                            "BGA-BW": {
                                "cardNumber": "BGA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BGA-CC": {
                                "cardNumber": "BGA-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-EC": {
                                "cardNumber": "BGA-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-ED": {
                                "cardNumber": "BGA-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-FT": {
                                "cardNumber": "BGA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BGA-JC": {
                                "cardNumber": "BGA-JC",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-JCA": {
                                "cardNumber": "BGA-JCA",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-JD": {
                                "cardNumber": "BGA-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-JH": {
                                "cardNumber": "BGA-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-JL": {
                                "cardNumber": "BGA-JL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-JLA": {
                                "cardNumber": "BGA-JLA",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-JROD": {
                                "cardNumber": "BGA-JROD",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BGA-JS": {
                                "cardNumber": "BGA-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BGA-KG": {
                                "cardNumber": "BGA-KG",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BGA-MT": {
                                "cardNumber": "BGA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BGA-NA": {
                                "cardNumber": "BGA-NA",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BGA-PS": {
                                "cardNumber": "BGA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-RA": {
                                "cardNumber": "BGA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BGA-SI": {
                                "cardNumber": "BGA-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-SO": {
                                "cardNumber": "BGA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BGA-WL": {
                                "cardNumber": "BGA-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BGA-YY": {
                                "cardNumber": "BGA-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Mystical Autograph Variation": {
                            "MYSA-AR": {
                                "cardNumber": "MYSA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MYSA-ARI": {
                                "cardNumber": "MYSA-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYSA-AV": {
                                "cardNumber": "MYSA-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MYSA-BW": {
                                "cardNumber": "MYSA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MYSA-CB": {
                                "cardNumber": "MYSA-CB",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MYSA-CC": {
                                "cardNumber": "MYSA-CC",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MYSA-CK": {
                                "cardNumber": "MYSA-CK",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MYSA-CS": {
                                "cardNumber": "MYSA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MYSA-EC": {
                                "cardNumber": "MYSA-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-ED": {
                                "cardNumber": "MYSA-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-FT": {
                                "cardNumber": "MYSA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MYSA-GH": {
                                "cardNumber": "MYSA-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MYSA-JC": {
                                "cardNumber": "MYSA-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "MYSA-JCA": {
                                "cardNumber": "MYSA-JCA",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-JCH": {
                                "cardNumber": "MYSA-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-JD": {
                                "cardNumber": "MYSA-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-JH": {
                                "cardNumber": "MYSA-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-JJ": {
                                "cardNumber": "MYSA-JJ",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MYSA-JL": {
                                "cardNumber": "MYSA-JL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-JLA": {
                                "cardNumber": "MYSA-JLA",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-JROD": {
                                "cardNumber": "MYSA-JROD",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MYSA-JS": {
                                "cardNumber": "MYSA-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MYSA-KG": {
                                "cardNumber": "MYSA-KG",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MYSA-LR": {
                                "cardNumber": "MYSA-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MYSA-MH": {
                                "cardNumber": "MYSA-MH",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYSA-MM": {
                                "cardNumber": "MYSA-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MYSA-MO": {
                                "cardNumber": "MYSA-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYSA-MT": {
                                "cardNumber": "MYSA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MYSA-NA": {
                                "cardNumber": "MYSA-NA",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MYSA-OA": {
                                "cardNumber": "MYSA-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYSA-OC": {
                                "cardNumber": "MYSA-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MYSA-PA": {
                                "cardNumber": "MYSA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MYSA-PS": {
                                "cardNumber": "MYSA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-RA": {
                                "cardNumber": "MYSA-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "MYSA-RAJ": {
                                "cardNumber": "MYSA-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYSA-RL": {
                                "cardNumber": "MYSA-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MYSA-SG": {
                                "cardNumber": "MYSA-SG",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MYSA-SI": {
                                "cardNumber": "MYSA-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-SO": {
                                "cardNumber": "MYSA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MYSA-SS": {
                                "cardNumber": "MYSA-SS",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MYSA-TT": {
                                "cardNumber": "MYSA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MYSA-VG": {
                                "cardNumber": "MYSA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MYSA-WL": {
                                "cardNumber": "MYSA-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MYSA-YY": {
                                "cardNumber": "MYSA-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Baseball Stars Autographs": {
                            "BSAU-AJ": {
                                "cardNumber": "BSAU-AJ",
                                "name": ["Alek Jacob"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-AM": {
                                "cardNumber": "BSAU-AM",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-AP": {
                                "cardNumber": "BSAU-AP",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-AS": {
                                "cardNumber": "BSAU-AS",
                                "name": ["Austin Shenton"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-BEC": {
                                "cardNumber": "BSAU-BEC",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-BL": {
                                "cardNumber": "BSAU-BL",
                                "name": ["Mason Black"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-CK": {
                                "cardNumber": "BSAU-CK",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-CS": {
                                "cardNumber": "BSAU-CS",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-DH": {
                                "cardNumber": "BSAU-DH",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-DW": {
                                "cardNumber": "BSAU-DW",
                                "name": ["Davis Wendzel"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-FW": {
                                "cardNumber": "BSAU-FW",
                                "name": ["Forrest Whitley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-GP": {
                                "cardNumber": "BSAU-GP",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-HAR": {
                                "cardNumber": "BSAU-HAR",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JC": {
                                "cardNumber": "BSAU-JC",
                                "name": ["Jonathan Cannon"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JCL": {
                                "cardNumber": "BSAU-JCL",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JF": {
                                "cardNumber": "BSAU-JF",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JH": {
                                "cardNumber": "BSAU-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JHL": {
                                "cardNumber": "BSAU-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JJO": {
                                "cardNumber": "BSAU-JJO",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JL": {
                                "cardNumber": "BSAU-JL",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JLE": {
                                "cardNumber": "BSAU-JLE",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-JM": {
                                "cardNumber": "BSAU-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-LK": {
                                "cardNumber": "BSAU-LK",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-MS": {
                                "cardNumber": "BSAU-MS",
                                "name": ["Max Schuemann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-NN": {
                                "cardNumber": "BSAU-NN",
                                "name": ["Nick Nastrini"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-PP": {
                                "cardNumber": "BSAU-PP",
                                "name": ["Pedro Pages"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-SA": {
                                "cardNumber": "BSAU-SA",
                                "name": ["Spencer Arrighetti"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-SI": {
                                "cardNumber": "BSAU-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-TL": {
                                "cardNumber": "BSAU-TL",
                                "name": ["Trey Lipscomb"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-TOB": {
                                "cardNumber": "BSAU-TOB",
                                "name": ["Tobias Myers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-VS": {
                                "cardNumber": "BSAU-VS",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-WL": {
                                "cardNumber": "BSAU-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-WP": {
                                "cardNumber": "BSAU-WP",
                                "name": ["Wenceel Pérez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-YR": {
                                "cardNumber": "BSAU-YR",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSAU-YY": {
                                "cardNumber": "BSAU-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-AG": {
                                "cardNumber": "BSA-AG",
                                "name": ["Andrés Giménez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "BSA-BBR": {
                                "cardNumber": "BSA-BBR",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-BUT": {
                                "cardNumber": "BSA-BUT",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-BW": {
                                "cardNumber": "BSA-BW",
                                "name": ["Brian Wilson"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BSA-ESH": {
                                "cardNumber": "BSA-ESH",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-JC": {
                                "cardNumber": "BSA-JC",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-KM": {
                                "cardNumber": "BSA-KM",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-LG": {
                                "cardNumber": "BSA-LG",
                                "name": ["Lourdes Gurriel Jr."],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BSA-NSC": {
                                "cardNumber": "BSA-NSC",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-PAR": {
                                "cardNumber": "BSA-PAR",
                                "name": ["Kyren Paris"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-RG": {
                                "cardNumber": "BSA-RG",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-AA": {
                                "cardNumber": "BSA2-AA",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-AL": {
                                "cardNumber": "BSA2-AL",
                                "name": ["Andre Lipcius"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-BMI": {
                                "cardNumber": "BSA2-BMI",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSA2-DS": {
                                "cardNumber": "BSA2-DS",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-JCA": {
                                "cardNumber": "BSA2-JCA",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-JE": {
                                "cardNumber": "BSA2-JE",
                                "name": ["Joey Estes"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-LT": {
                                "cardNumber": "BSA2-LT",
                                "name": ["Lane Thomas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "BSA2-NM": {
                                "cardNumber": "BSA2-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-PS": {
                                "cardNumber": "BSA2-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA2-YD": {
                                "cardNumber": "BSA2-YD",
                                "name": ["Yandy Díaz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            }
                        },
                        "2024 MLB All Star Game Autographs": {
                            "ASGA-AR": {
                                "cardNumber": "ASGA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASGA-BW": {
                                "cardNumber": "ASGA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASGA-CA": {
                                "cardNumber": "ASGA-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASGA-CH": {
                                "cardNumber": "ASGA-CH",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASGA-CR": {
                                "cardNumber": "ASGA-CR",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASGA-DF": {
                                "cardNumber": "ASGA-DF",
                                "name": ["David Fry"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASGA-ED": {
                                "cardNumber": "ASGA-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGA-FT": {
                                "cardNumber": "ASGA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASGA-GH": {
                                "cardNumber": "ASGA-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASGA-HR": {
                                "cardNumber": "ASGA-HR",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASGA-JA": {
                                "cardNumber": "ASGA-JA",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASGA-JD": {
                                "cardNumber": "ASGA-JD",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASGA-JM": {
                                "cardNumber": "ASGA-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGA-JR": {
                                "cardNumber": "ASGA-JR",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASGA-JS": {
                                "cardNumber": "ASGA-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASGA-KT": {
                                "cardNumber": "ASGA-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASGA-LW": {
                                "cardNumber": "ASGA-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASGA-MM": {
                                "cardNumber": "ASGA-MM",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "ASGA-PA": {
                                "cardNumber": "ASGA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASGA-PS": {
                                "cardNumber": "ASGA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGA-RG": {
                                "cardNumber": "ASGA-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASGA-SI": {
                                "cardNumber": "ASGA-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGA-TT": {
                                "cardNumber": "ASGA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASGA-VG": {
                                "cardNumber": "ASGA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASGA-WS": {
                                "cardNumber": "ASGA-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            }
                        },
                        "Baseball Stars Dual Autographs": {
                            "BSDAU-AJO": {
                                "cardNumber": "BSDAU-AJO",
                                "name": ["Matt Olson", "Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSDAU-CC": {
                                "cardNumber": "BSDAU-CC",
                                "name": ["Will Clark", "Matt Chapman"],
                                "team": ["San Francisco Giants®", "San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BSDAU-CL": {
                                "cardNumber": "BSDAU-CL",
                                "name": ["Evan Carter", "Wyatt Langford"],
                                "team": ["Texas Rangers®", "Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSDAU-CLA": {
                                "cardNumber": "BSDAU-CLA",
                                "name": ["Jordan Lawlar", "Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®", "Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BSDAU-GGJ": {
                                "cardNumber": "BSDAU-GGJ",
                                "name": ["Vladimir Guerrero Jr.", "Vladimir Guerrero"],
                                "team": ["Toronto Blue Jays®", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSDAU-GIM": {
                                "cardNumber": "BSDAU-GIM",
                                "name": ["Bob Gibson", "Greg Maddux"],
                                "team": ["St. Louis Cardinals®", "Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BSDAU-HH": {
                                "cardNumber": "BSDAU-HH",
                                "name": ["Jackson Holliday", "Matt Holliday"],
                                "team": ["Baltimore Orioles®", "St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSDAU-JOH": {
                                "cardNumber": "BSDAU-JOH",
                                "name": ["Randy Johnson", "Félix Hernández"],
                                "team": ["Seattle Mariners™", "Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BSDAU-KO": {
                                "cardNumber": "BSDAU-KO",
                                "name": ["Shohei Ohtani", "Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSDAU-MCO": {
                                "cardNumber": "BSDAU-MCO",
                                "name": ["Carlos Correa", "Joe Mauer"],
                                "team": ["Minnesota Twins®", "Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSDAU-OY": {
                                "cardNumber": "BSDAU-OY",
                                "name": ["Yoshinobu Yamamoto", "Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSDAU-RH": {
                                "cardNumber": "BSDAU-RH",
                                "name": ["Adley Rutschman", "Jackson Holliday"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BSDAU-RSC": {
                                "cardNumber": "BSDAU-RSC",
                                "name": ["Max Scherzer", "Nolan Ryan"],
                                "team": ["Texas Rangers®", "Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BSDAU-SAR": {
                                "cardNumber": "BSDAU-SAR",
                                "name": ["Nolan Arenado", "Ozzie Smith"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSDAU-SD": {
                                "cardNumber": "BSDAU-SD",
                                "name": ["Juan Soto", "Jasson Domínguez"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDAU-TRO": {
                                "cardNumber": "BSDAU-TRO",
                                "name": ["Julio Rodríguez", "Mike Trout"],
                                "team": ["Seattle Mariners™", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSDAU-YC": {
                                "cardNumber": "BSDAU-YC",
                                "name": ["Jackson Chourio", "Christian Yelich"],
                                "team": ["Milwaukee Brewers™", "Milwaukee Brewers™"],
                                "cardAttributes": []
                            }
                        },
                        "Baseball Stars Triple Autographs": {
                            "BSTAU-AOG": {
                                "cardNumber": "BSTAU-AOG",
                                "name": ["Paul Goldschmidt", "Matt Olson", "Pete Alonso"],
                                "team": ["St. Louis Cardinals®", "Atlanta Braves™", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "BSTAU-ART": {
                                "cardNumber": "BSTAU-ART",
                                "name": ["Fernando Tatis Jr.", "Julio Rodríguez", "Ronald Acuña Jr."],
                                "team": ["San Diego Padres™", "Seattle Mariners™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSTAU-IMY": {
                                "cardNumber": "BSTAU-IMY",
                                "name": ["Hideki Matsui", "Ichiro", "Yoshinobu Yamamoto"],
                                "team": ["New York Yankees®", "Seattle Mariners™", "Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTAU-KOY": {
                                "cardNumber": "BSTAU-KOY",
                                "name": ["Yoshinobu Yamamoto", "Clayton Kershaw", "Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTAU-MJM": {
                                "cardNumber": "BSTAU-MJM",
                                "name": ["Randy Johnson", "Greg Maddux", "Pedro Martinez"],
                                "team": ["Seattle Mariners™", "Atlanta Braves™", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSTAU-PMA": {
                                "cardNumber": "BSTAU-PMA",
                                "name": ["Albert Pujols", "Yadier Molina", "Nolan Arenado"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSTAU-RHH": {
                                "cardNumber": "BSTAU-RHH",
                                "name": ["Jackson Holliday", "Gunnar Henderson", "Adley Rutschman"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTAU-RSM": {
                                "cardNumber": "BSTAU-RSM",
                                "name": ["Don Mattingly", "Ryne Sandberg", "Cal Ripken Jr."],
                                "team": ["New York Yankees®", "Chicago Cubs®", "Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BSTAU-SBJ": {
                                "cardNumber": "BSTAU-SBJ",
                                "name": ["Chipper Jones", "George Brett", "Mike Schmidt"],
                                "team": ["Atlanta Braves™", "Kansas City Royals®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSTAU-SCL": {
                                "cardNumber": "BSTAU-SCL",
                                "name": ["Evan Carter", "Wyatt Langford", "Corey Seager"],
                                "team": ["Texas Rangers®", "Texas Rangers®", "Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTAU-SCS": {
                                "cardNumber": "BSTAU-SCS",
                                "name": ["Paul Skenes", "Gerrit Cole", "Stephen Strasburg"],
                                "team": ["Pittsburgh Pirates®", "New York Yankees®", "Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSTAU-SRL": {
                                "cardNumber": "BSTAU-SRL",
                                "name": ["Cal Ripken Jr.", "Ozzie Smith", "Barry Larkin"],
                                "team": ["Baltimore Orioles®", "St. Louis Cardinals®", "Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BSTAU-TAR": {
                                "cardNumber": "BSTAU-TAR",
                                "name": ["Ronald Acuña Jr.", "Mike Trout", "Julio Rodríguez"],
                                "team": ["Atlanta Braves™", "Angels®", "Seattle Mariners™"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Chrome Cards Autograph Variation": {
                            "T89CU-AA": {
                                "cardNumber": "T89CU-AA",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-AJ": {
                                "cardNumber": "T89CU-AJ",
                                "name": ["Andruw Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-AM": {
                                "cardNumber": "T89CU-AM",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-AP": {
                                "cardNumber": "T89CU-AP",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-AR": {
                                "cardNumber": "T89CU-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "T89CU-AROD": {
                                "cardNumber": "T89CU-AROD",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-AS": {
                                "cardNumber": "T89CU-AS",
                                "name": ["Austin Shenton"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-BB": {
                                "cardNumber": "T89CU-BB",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-BD": {
                                "cardNumber": "T89CU-BD",
                                "name": ["Brenton Doyle"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "T89CU-BEC": {
                                "cardNumber": "T89CU-BEC",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-BM": {
                                "cardNumber": "T89CU-BM",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "T89CU-BN": {
                                "cardNumber": "T89CU-BN",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T89CU-CHO": {
                                "cardNumber": "T89CU-CHO",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-CK": {
                                "cardNumber": "T89CU-CK",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-CLA": {
                                "cardNumber": "T89CU-CLA",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-CODY": {
                                "cardNumber": "T89CU-CODY",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T89CU-CRJ": {
                                "cardNumber": "T89CU-CRJ",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "T89CU-CS": {
                                "cardNumber": "T89CU-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "T89CU-CU": {
                                "cardNumber": "T89CU-CU",
                                "name": ["Chase Utley"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-DH": {
                                "cardNumber": "T89CU-DH",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-DS": {
                                "cardNumber": "T89CU-DS",
                                "name": ["Darryl Strawberry"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T89CU-DW": {
                                "cardNumber": "T89CU-DW",
                                "name": ["Dave Winfield"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-EC": {
                                "cardNumber": "T89CU-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-ED": {
                                "cardNumber": "T89CU-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-FLA": {
                                "cardNumber": "T89CU-FLA",
                                "name": ["Jack Flaherty"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "T89CU-FM": {
                                "cardNumber": "T89CU-FM",
                                "name": ["Fred McGriff"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-FP": {
                                "cardNumber": "T89CU-FP",
                                "name": ["Freddy Peralta"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "T89CU-GAS": {
                                "cardNumber": "T89CU-GAS",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-GK": {
                                "cardNumber": "T89CU-GK",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-GM": {
                                "cardNumber": "T89CU-GM",
                                "name": ["Greg Maddux"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T89CU-GP": {
                                "cardNumber": "T89CU-GP",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-GRE": {
                                "cardNumber": "T89CU-GRE",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "T89CU-GS": {
                                "cardNumber": "T89CU-GS",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "T89CU-HAR": {
                                "cardNumber": "T89CU-HAR",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-HG": {
                                "cardNumber": "T89CU-HG",
                                "name": ["Hunter Goodman"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-HOW": {
                                "cardNumber": "T89CU-HOW",
                                "name": ["Ryan Howard"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-I": {
                                "cardNumber": "T89CU-I",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-JF": {
                                "cardNumber": "T89CU-JF",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-JH": {
                                "cardNumber": "T89CU-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-JHL": {
                                "cardNumber": "T89CU-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-JJ": {
                                "cardNumber": "T89CU-JJ",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-JL": {
                                "cardNumber": "T89CU-JL",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-JM": {
                                "cardNumber": "T89CU-JM",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "T89CU-JP": {
                                "cardNumber": "T89CU-JP",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "T89CU-JS": {
                                "cardNumber": "T89CU-JS",
                                "name": ["John Smoltz"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-JV": {
                                "cardNumber": "T89CU-JV",
                                "name": ["Jason Varitek"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "T89CU-KG": {
                                "cardNumber": "T89CU-KG",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-KH": {
                                "cardNumber": "T89CU-KH",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-KNA": {
                                "cardNumber": "T89CU-KNA",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-LC": {
                                "cardNumber": "T89CU-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-LEI": {
                                "cardNumber": "T89CU-LEI",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-LUZ": {
                                "cardNumber": "T89CU-LUZ",
                                "name": ["Jesús Luzardo"],
                                "team": ["Miami Marlins™"],
                                "cardAttributes": []
                            },
                            "T89CU-MAN": {
                                "cardNumber": "T89CU-MAN",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-MC": {
                                "cardNumber": "T89CU-MC",
                                "name": ["Matt Chapman"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "T89CU-MER": {
                                "cardNumber": "T89CU-MER",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-MF": {
                                "cardNumber": "T89CU-MF",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-MH": {
                                "cardNumber": "T89CU-MH",
                                "name": ["Matt Holliday"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "T89CU-MT": {
                                "cardNumber": "T89CU-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "T89CU-NG": {
                                "cardNumber": "T89CU-NG",
                                "name": ["Nomar Garciaparra"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "T89CU-NL": {
                                "cardNumber": "T89CU-NL",
                                "name": ["Nick Loftin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-NR": {
                                "cardNumber": "T89CU-NR",
                                "name": ["Nolan Ryan"],
                                "team": ["California Angels™"],
                                "cardAttributes": []
                            },
                            "T89CU-OA": {
                                "cardNumber": "T89CU-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-OC": {
                                "cardNumber": "T89CU-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "T89CU-PM": {
                                "cardNumber": "T89CU-PM",
                                "name": ["Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "T89CU-PS": {
                                "cardNumber": "T89CU-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-RA": {
                                "cardNumber": "T89CU-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-RAN": {
                                "cardNumber": "T89CU-RAN",
                                "name": ["Randy Johnson"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T89CU-RC": {
                                "cardNumber": "T89CU-RC",
                                "name": ["Roger Clemens"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-RG": {
                                "cardNumber": "T89CU-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "T89CU-RICK": {
                                "cardNumber": "T89CU-RICK",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "T89CU-RIL": {
                                "cardNumber": "T89CU-RIL",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-SC": {
                                "cardNumber": "T89CU-SC",
                                "name": ["Steve Carlton"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T89CU-SCO": {
                                "cardNumber": "T89CU-SCO",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-SF": {
                                "cardNumber": "T89CU-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-SG": {
                                "cardNumber": "T89CU-SG",
                                "name": ["Sawyer Gipson-Long"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-SI": {
                                "cardNumber": "T89CU-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-SO": {
                                "cardNumber": "T89CU-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "T89CU-SOSA": {
                                "cardNumber": "T89CU-SOSA",
                                "name": ["Sammy Sosa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T89CU-SOTO": {
                                "cardNumber": "T89CU-SOTO",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T89CU-SS": {
                                "cardNumber": "T89CU-SS",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T89CU-TH": {
                                "cardNumber": "T89CU-TH",
                                "name": ["Teoscar Hernández"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "T89CU-VOT": {
                                "cardNumber": "T89CU-VOT",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "T89CU-VS": {
                                "cardNumber": "T89CU-VS",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-WAL": {
                                "cardNumber": "T89CU-WAL",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "T89CU-WL": {
                                "cardNumber": "T89CU-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-YR": {
                                "cardNumber": "T89CU-YR",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T89CU-YY": {
                                "cardNumber": "T89CU-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "1989 Topps Baseball Autograph Cards": {
                            "89AU-AUM": {
                                "cardNumber": "89AU-AUM",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-AW": {
                                "cardNumber": "89AU-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-BW": {
                                "cardNumber": "89AU-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89AU-CBU": {
                                "cardNumber": "89AU-CBU",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89AU-CC": {
                                "cardNumber": "89AU-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-CCA": {
                                "cardNumber": "89AU-CCA",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89AU-CHS": {
                                "cardNumber": "89AU-CHS",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-DAH": {
                                "cardNumber": "89AU-DAH",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-EC": {
                                "cardNumber": "89AU-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-GRP": {
                                "cardNumber": "89AU-GRP",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-HB": {
                                "cardNumber": "89AU-HB",
                                "name": ["Harrison Bader"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89AU-HK": {
                                "cardNumber": "89AU-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JAJ": {
                                "cardNumber": "89AU-JAJ",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JC": {
                                "cardNumber": "89AU-JC",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JCH": {
                                "cardNumber": "89AU-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JCL": {
                                "cardNumber": "89AU-JCL",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JDO": {
                                "cardNumber": "89AU-JDO",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JG": {
                                "cardNumber": "89AU-JG",
                                "name": ["Juan Gonzalez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89AU-JM": {
                                "cardNumber": "89AU-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JUF": {
                                "cardNumber": "89AU-JUF",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-JV": {
                                "cardNumber": "89AU-JV",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89AU-KNA": {
                                "cardNumber": "89AU-KNA",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-LOP": {
                                "cardNumber": "89AU-LOP",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-MT": {
                                "cardNumber": "89AU-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89AU-PS": {
                                "cardNumber": "89AU-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-RA": {
                                "cardNumber": "89AU-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89AU-SF": {
                                "cardNumber": "89AU-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-SG": {
                                "cardNumber": "89AU-SG",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89AU-SI": {
                                "cardNumber": "89AU-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-VSC": {
                                "cardNumber": "89AU-VSC",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-WL": {
                                "cardNumber": "89AU-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-YR": {
                                "cardNumber": "89AU-YR",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89AU-YY": {
                                "cardNumber": "89AU-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89B-WWI": {
                                "cardNumber": "89B-WWI",
                                "name": ["Weston Wilson"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-AM": {
                                "cardNumber": "89BA-AM",
                                "name": ["Alek Manoah"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BA-AP": {
                                "cardNumber": "89BA-AP",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-BAS": {
                                "cardNumber": "89BA-BAS",
                                "name": ["Osleivis Basabe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-BB": {
                                "cardNumber": "89BA-BB",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-BID": {
                                "cardNumber": "89BA-BID",
                                "name": ["Osvaldo Bido"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-BP": {
                                "cardNumber": "89BA-BP",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89BA-CK": {
                                "cardNumber": "89BA-CK",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA-CKE": {
                                "cardNumber": "89BA-CKE",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-CRA": {
                                "cardNumber": "89BA-CRA",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-DAV": {
                                "cardNumber": "89BA-DAV",
                                "name": ["Darius Vines"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-FWL": {
                                "cardNumber": "89BA-FWL",
                                "name": ["Forrest Wall"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-GB": {
                                "cardNumber": "89BA-GB",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89BA-GHA": {
                                "cardNumber": "89BA-GHA",
                                "name": ["Grant Hartwig"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-GM": {
                                "cardNumber": "89BA-GM",
                                "name": ["Greg Maddux"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BA-JFN": {
                                "cardNumber": "89BA-JFN",
                                "name": ["José Fermín"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-JL": {
                                "cardNumber": "89BA-JL",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-KT": {
                                "cardNumber": "89BA-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89BA-KW": {
                                "cardNumber": "89BA-KW",
                                "name": ["Keaton Winn"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-LYO": {
                                "cardNumber": "89BA-LYO",
                                "name": ["Lyon Richardson"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-ML": {
                                "cardNumber": "89BA-ML",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-NMA": {
                                "cardNumber": "89BA-NMA",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-RJ": {
                                "cardNumber": "89BA-RJ",
                                "name": ["Reggie Jackson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89BA-ROM": {
                                "cardNumber": "89BA-ROM",
                                "name": ["Drew Rom"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-RUTL": {
                                "cardNumber": "89BA-RUTL",
                                "name": ["Jackson Rutledge"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-SA": {
                                "cardNumber": "89BA-SA",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins™"],
                                "cardAttributes": []
                            },
                            "89BA-WC": {
                                "cardNumber": "89BA-WC",
                                "name": ["Wilyer Abreu"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA-YD": {
                                "cardNumber": "89BA-YD",
                                "name": ["Yandy Díaz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "89BA2-AL": {
                                "cardNumber": "89BA2-AL",
                                "name": ["Andre Lipcius"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-AM": {
                                "cardNumber": "89BA2-AM",
                                "name": ["Drew Millas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-AR": {
                                "cardNumber": "89BA2-AR",
                                "name": ["Aramis Ramirez"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA2-AS": {
                                "cardNumber": "89BA2-AS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-AV": {
                                "cardNumber": "89BA2-AV",
                                "name": ["Anthony Veneziano"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-BM": {
                                "cardNumber": "89BA2-BM",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA2-CE": {
                                "cardNumber": "89BA2-CE",
                                "name": ["Cam Eden"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-CMC": {
                                "cardNumber": "89BA2-CMC",
                                "name": ["Chas McCormick"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89BA2-DL": {
                                "cardNumber": "89BA2-DL",
                                "name": ["Derrek Lee"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA2-EGA": {
                                "cardNumber": "89BA2-EGA",
                                "name": ["Eric Gagne"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA2-EPE": {
                                "cardNumber": "89BA2-EPE",
                                "name": ["Eury Pérez"],
                                "team": ["Miami Marlins™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-FP": {
                                "cardNumber": "89BA2-FP",
                                "name": ["Freddy Peralta"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89BA2-HD": {
                                "cardNumber": "89BA2-HD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-HP": {
                                "cardNumber": "89BA2-HP",
                                "name": ["Hunter Pence"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89BA2-IR": {
                                "cardNumber": "89BA2-IR",
                                "name": ["Ivan Rodriguez"],
                                "team": ["Florida Marlins™"],
                                "cardAttributes": []
                            },
                            "89BA2-JA": {
                                "cardNumber": "89BA2-JA",
                                "name": ["Jim Abbott"],
                                "team": ["California Angels™"],
                                "cardAttributes": []
                            },
                            "89BA2-JC": {
                                "cardNumber": "89BA2-JC",
                                "name": ["Joe Carter"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BA2-JE": {
                                "cardNumber": "89BA2-JE",
                                "name": ["Joey Estes"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JH": {
                                "cardNumber": "89BA2-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JMA": {
                                "cardNumber": "89BA2-JMA",
                                "name": ["J.P. Martinez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JP": {
                                "cardNumber": "89BA2-JP",
                                "name": ["Jorge Posada"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BA2-JS": {
                                "cardNumber": "89BA2-JS",
                                "name": ["José Soriano"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-JSO": {
                                "cardNumber": "89BA2-JSO",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BA2-JWE": {
                                "cardNumber": "89BA2-JWE",
                                "name": ["Jayson Werth"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89BA2-JY": {
                                "cardNumber": "89BA2-JY",
                                "name": ["Jacob Young"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-KF": {
                                "cardNumber": "89BA2-KF",
                                "name": ["Kody Funderburk"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-KW": {
                                "cardNumber": "89BA2-KW",
                                "name": ["Kerry Wood"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA2-LB": {
                                "cardNumber": "89BA2-LB",
                                "name": ["Luken Baker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-LM": {
                                "cardNumber": "89BA2-LM",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-NH": {
                                "cardNumber": "89BA2-NH",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA2-OK": {
                                "cardNumber": "89BA2-OK",
                                "name": ["Orion Kerkering"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-RF": {
                                "cardNumber": "89BA2-RF",
                                "name": ["Rollie Fingers"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "89BA2-RM": {
                                "cardNumber": "89BA2-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-SK": {
                                "cardNumber": "89BA2-SK",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BA2-SS": {
                                "cardNumber": "89BA2-SS",
                                "name": ["Spencer Steer"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-TG": {
                                "cardNumber": "89BA2-TG",
                                "name": ["Tristan Gray"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-TH": {
                                "cardNumber": "89BA2-TH",
                                "name": ["TJ Hopkins"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89BA2-YG": {
                                "cardNumber": "89BA2-YG",
                                "name": ["Yoendrys Gómez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89ASA-ABE": {
                                "cardNumber": "89ASA-ABE",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89ASA-AP": {
                                "cardNumber": "89ASA-AP",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89ASA-BL": {
                                "cardNumber": "89ASA-BL",
                                "name": ["Barry Larkin"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "89ASA-BP": {
                                "cardNumber": "89ASA-BP",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "89ASA-DM": {
                                "cardNumber": "89ASA-DM",
                                "name": ["Dale Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89ASA-DO": {
                                "cardNumber": "89ASA-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASA-DP": {
                                "cardNumber": "89ASA-DP",
                                "name": ["Dustin Pedroia"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89ASA-EM": {
                                "cardNumber": "89ASA-EM",
                                "name": ["Edgar Martinez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASA-FH": {
                                "cardNumber": "89ASA-FH",
                                "name": ["Félix Hernández"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASA-JJ": {
                                "cardNumber": "89ASA-JJ",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89ASA-JROL": {
                                "cardNumber": "89ASA-JROL",
                                "name": ["Jimmy Rollins"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89ASA-PA": {
                                "cardNumber": "89ASA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89ASA-PF": {
                                "cardNumber": "89ASA-PF",
                                "name": ["Prince Fielder"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89ASA-RJ": {
                                "cardNumber": "89ASA-RJ",
                                "name": ["Randy Johnson"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89ASA-SK": {
                                "cardNumber": "89ASA-SK",
                                "name": ["Sandy Koufax"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASA-SO": {
                                "cardNumber": "89ASA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89ASA-TH": {
                                "cardNumber": "89ASA-TH",
                                "name": ["Torii Hunter"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "89ASA-VG": {
                                "cardNumber": "89ASA-VG",
                                "name": ["Vladimir Guerrero"],
                                "team": ["Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "89ASA-YA": {
                                "cardNumber": "89ASA-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        // "First Pitch Autograph" : {

                        // }
                    }
                }
            }
        }
    }
}

export const MLB_2024_TOPPS_US_PARALLELS = {
    "MLB": {
        "2024": {
            "Topps": {
                "Update Series": {
                    "Base Card Set": {
                        "Base": [
                            { "name": "AQUA", "color": "#00FFDF", "limitedTo": null },
                            { "name": "ROYAL BLUE", "color": "#4169E1", "limitedTo": null },
                            { "name": "YELLOW", "color": "#EBD834", "limitedTo": null },
                            { "name": "RAINBOW FOIL", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "GOLD RAINBOW FOIL", "color": "#FFD700", "limitedTo": null },
                            { "name": "YELLOW RAINBOW FOIL", "color": "#EBD834", "limitedTo": null },
                            { "name": "GHOST", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "HOLIDAY", "color": "#2A8344", "limitedTo": null },
                            { "name": "JACK O' LANTERN", "color": "#FFA500", "limitedTo": null },
                            { "name": "MUMMY", "color": "#D3CCC5", "limitedTo": null },
                            { "name": "SILVER CRACKLE FOILBOARD", "color": "#C0C0C0", "limitedTo": null },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 2024 },
                            { "name": "BLUE HOLOFOIL BOARD", "color": "#1E90FF", "limitedTo": 999 },
                            { "name": "PURPLE HOLOFOIL BOARD", "color": "#800080", "limitedTo": 799 },
                            { "name": "GREEN CRACKLE FOILBOARD", "color": "#008000", "limitedTo": 499 },
                            { "name": "ORANGE CRACKLE FOILBOARD", "color": "#FFA500", "limitedTo": 299 },
                            { "name": "RED CRACKLE FOILBOARD", "color": "#FF0000", "limitedTo": 199 },
                            { "name": "VINTAGE STOCK", "color": "#DEB887", "limitedTo": 99 },
                            { "name": "INDEPENDENCE DAY", "color": "#B22222", "limitedTo": 76 },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 74 },
                            { "name": "MOTHER'S DAY HOT PINK", "color": "#FF69B4", "limitedTo": 50 },
                            { "name": "FATHER'S DAY POWDER BLUE", "color": "#B0E0E6", "limitedTo": 50 },
                            { "name": "YELLOW CRACKLE FOILBOARD", "color": "#EBD834", "limitedTo": 50 },
                            { "name": "MEMORIAL DAY CAMO", "color": "#4B5320", "limitedTo": 25 },
                            { "name": "BLACK CAT", "color": "#000000", "limitedTo": 10 },
                            { "name": "CLEAR VARIATION", "color": "transparent", "limitedTo": 10 },
                            { "name": "WITCHES HAT", "color": "#000000", "limitedTo": 5 },
                            { "name": "BATS", "color": "#000000", "limitedTo": 1 },
                            { "name": "FIRST CARD", "color": "#FFFFFF", "limitedTo": 1 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (BLACK)", "color": "#000000", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (CYAN)", "color": "#00FFFF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (MAGENTA)", "color": "#FF00FF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (YELLOW)", "color": "#EBD834", "limitedTo": 1 }
                        ]
                    },
                    "Insert": {
                        "1989 Topps Baseball": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "2024 All Star Game": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "All Class": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Autumn Tales": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "First Pitch": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Let Him Cook": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Mystical": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Stars of MLB": [
                            { "name": "RED", "color": "#FF0000", "limitedTo": 75 },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Chrome Stars of MLB": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 75 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 25 },
                            { "name": "SUPERFRACTOR", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Topps Black Gold": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Relic": {
                        "1989 Topps Baseball Relic Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "All Star Jumbo Patch Cards": [
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "All Star Stitches Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "All Star Stitches Dual Cards": [
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "All Star Stitches Triple Cards": [
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Major League Material Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Rising Rookie Relic Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Autograph Relic": {
                        "All Star Autographed Jumbo Patch": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "All Star Stitch Autograph Cards": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "All Star Stitch Dual Autograph Cards": [
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Topps Reverence Autograph Patch Cards": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Major League Material Autograph Cards": [
                            { "name": "# to 50", "color": "#FFFFFF", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Autograph": {
                        "1989 Topps Baseball Autograph Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "2024 MLB All Star Game Autographs": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Baseball Stars Autographs": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        // "First Pitch Autograph": [
                        //     { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                        //     { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                        //     { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                        //     { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        // ]
                    }
                }
            }
        }
    }
}