import styled from "styled-components";

export const InputBarContainer = styled.input`
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    opacity: 0.67;
    outline: none;
    padding-left: 16px;
    width: 100%;

    &::placeholder {
        color: #FFFFFF;
        opacity: 0.67;
    }

    &:focus {
        filter: drop-shadow(2px 2px rgba(0,0,0,0.15));
        opacity: 1.0;
    }
`