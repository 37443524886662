import { useNavigate } from 'react-router-dom'

// styles
import { SecondaryBtnContainer } from './SecondaryBtn.styles.js'

export default function SecondaryBtn({isDisabled=false, title, route=null}) {
  const navigate = useNavigate()

  const handleAction = () => {
    if (route) {
      navigate(route)
    }
  }

  return (
    <SecondaryBtnContainer className={"panel-bg"} onClick={handleAction} disabled={isDisabled}>{title}</SecondaryBtnContainer>
  )
}