import styled from "styled-components";
import { motion } from "framer-motion";

export const StatBoxContainer = styled(motion.div)`
    background-color: ${props => (props.$isActive ? "rgba(255,255,255,0.4)" : "rgba(255,255,255,0.1)")};
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 80px;
    position: relative;
    width: 100%;

    .label, .value {
        font-size: 16px;
        position: absolute;
    }

    .label {
        font-size: 14px;
        font-weight: 500;
        left: 8px;
        top: 8px;
    }

    .value {
        bottom: 8px;
        font-weight: 600;
        right: 8px;
    }
`