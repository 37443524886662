import { useEffect, useState } from 'react';

// styles
import {
    CollectionUpdatedEntryContainer,
    TopContent,
    LeftContent,
    DateDisplay,
    ActionDisplayContainer,
    ActionDisplay,
    CardNumberDisplay,
    CardCount,
    CardsDisplay
} from './CollectionUpdatedEntry.styles';

// assets
import cardIcon from '../../../../assets/system/icons/card-icon.png';

// components
import CardDisplay from './components/card-display/CardDisplay';

export default function CollectionUpdatedEntry({ content }) {
    const newCount = content.details?.prevCount + content.details?.increment;
    const prevCount = content.details.prevCount;
    const actionType = content.actionType;
    const addedCards = content.details?.cards;
    const addedCardCount = content.details?.cards?.totalCardCount;
    const formattedTimestamp = content.timestamp?.toDate().toLocaleString();
    const [isExpanded, setIsExpanded] = useState(false);
    const [removedCards, setRemovedCards] = useState(false);

    const handleExpand = () => {
        if (isExpanded) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
        }
    }

    useEffect(() => {
        if (actionType === "removedFromCollection") {
            setRemovedCards(true);
        } else {
            setRemovedCards(false);
        }
    }, [actionType])

    return (
        <CollectionUpdatedEntryContainer $isExpanded={isExpanded} className='panel-bg'>
            <TopContent>
                <LeftContent>
                    <DateDisplay>{formattedTimestamp}</DateDisplay>
                    <ActionDisplayContainer>
                        <ActionDisplay>
                            {actionType === "addedToCollection" ? "Added" : "Removed"} {addedCardCount} {addedCardCount === 1 ? 'card' : 'cards'} to collection
                        </ActionDisplay>
                        <h3 onClick={handleExpand}>VIEW {isExpanded ? '↑' : '↓'}</h3>
                    </ActionDisplayContainer>

                </LeftContent>
                <CardNumberDisplay>
                    <CardCount>
                        <h3>{prevCount}</h3>
                    </CardCount>
                    <span>&#x279F;</span>
                    <CardCount>
                        <h3>{newCount}</h3>
                        <img src={cardIcon} alt="card icon" />
                    </CardCount>
                </CardNumberDisplay>
            </TopContent>
            {isExpanded &&
                <CardsDisplay>
                    {Object.keys(addedCards).map((sportKey) => {
                        if (sportKey === "totalCardCount") return null;
                        const sportData = addedCards[sportKey];
                        return (
                            <div key={sportKey} className="sport-section">
                                <h2>{sportKey}</h2>
                                {Object.keys(sportData).map((yearKey) => {
                                    if (yearKey === "totalCardCount") return null;
                                    const yearData = sportData[yearKey];
                                    return (
                                        <div key={yearKey} className="year-section">
                                            <h2>{yearKey}</h2>
                                            {Object.keys(yearData).map((manufacturerKey) => {
                                                if (manufacturerKey === "totalCardCount") return null;
                                                const manufacturerData = yearData[manufacturerKey];
                                                return (
                                                    <div key={manufacturerKey} className="manufacturer-section">
                                                        <h2>{manufacturerKey}</h2>
                                                        {Object.keys(manufacturerData).map((seriesKey) => {
                                                            if (seriesKey === "totalCardCount") return null;
                                                            const seriesData = manufacturerData[seriesKey];
                                                            return (
                                                                <div key={seriesKey} className="series-section">
                                                                    <h2>{seriesKey}</h2>
                                                                    {Object.keys(seriesData).map((cardTypeKey) => {
                                                                        if (cardTypeKey === "totalCardCount") return null;
                                                                        const cardTypeData = seriesData[cardTypeKey];
                                                                        return (
                                                                            <div key={cardTypeKey} className="cardtype-section">
                                                                                <h2>{cardTypeKey}</h2>
                                                                                {Object.keys(cardTypeData).map((categoryKey) => {
                                                                                    if (categoryKey === "totalCardCount") return null;
                                                                                    const categoryData = cardTypeData[categoryKey];
                                                                                    return (
                                                                                        <div key={categoryKey} className="cardtype-category-section">
                                                                                            <h2>{categoryKey}</h2>
                                                                                            {Object.keys(categoryData)
                                                                                                .filter(cardNumberKey => cardNumberKey !== "totalCardCount") // Exclude the "totalCardCount" key
                                                                                                .sort((a, b) => {
                                                                                                    const getNumericPart = (cardNumber) => {
                                                                                                        const parts = cardNumber.split('-'); // Split on '-'
                                                                                                        const lastPart = parts[parts.length - 1]; // Get the last part
                                                                                                        return parseInt(lastPart.replace(/^\D+/g, ''), 10) || 0; // Remove non-digits and parse to integer
                                                                                                    };
                                                                                                    return getNumericPart(a) - getNumericPart(b); // Sort based on numeric value
                                                                                                })
                                                                                                .map((cardNumberKey) => {
                                                                                                    const card = categoryData[cardNumberKey];
                                                                                                    return (
                                                                                                        <div key={card.cardNumber} style={{ opacity: removedCards ? "0.67" : "1.0" }}>
                                                                                                            <CardDisplay
                                                                                                                key={`${card.cardNumber}${categoryKey}`}
                                                                                                                wasRemoved={removedCards}
                                                                                                                card={card}
                                                                                                                path={removedCards
                                                                                                                    ? `/add/${card.checklistPath.split('/').slice(1, -1).join('/')}`
                                                                                                                    : `/collection/${sportKey}/${yearKey}/${manufacturerKey}/${seriesKey}/${cardTypeKey}/${categoryKey}`
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        );

                    })}
                </CardsDisplay>
            }
        </CollectionUpdatedEntryContainer>
    )
}
