import styled from "styled-components";

export const QueueCardContainer = styled.div`
    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 8px;
`;
export const CardDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const CardHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 4px;

    span {
        color: rgba(255,255,255,0.4 );
    }

    h3 {
        color: #FFFFFF;
        display: inline;
        font-size: 12px;
        font-weight: 600;
    }
`;
export const CardNumber = styled.div`
    box-sizing: border-box;
    align-items: center;
    display: flex;
    height: 40px;
    width: 80px;

    h4 {
        font-size: 12px;
        padding: 4px 12px;
    };
`;
export const AttributesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 0; /* 8px vertical gap, 0px horizontal gap */

  h4 {
    font-size: 10px;
    font-weight: 500;
    padding: 0 8px; /* 0px vertical padding, 8px horizontal padding */
    width: fit-content;
  }
`;
export const AddBtn = styled.div`
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    padding: 4px 12px;
    width: fit-content;
    white-space: nowrap;

    h4 {
        width: 100%;
        margin: 0;
        white-space: nowrap;
    };

    &:hover {
        background: rgba(255,255,255,0.2);
    };
`;
export const CardContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
export const LeftCardContent = styled.div`
    align-items: center;
    display: flex;
`;
export const QuantityControlsContainer = styled.div`
    justify-content: center;
    display: flex;
    height: 100%;
`;
export const QtyContent = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`;
export const QtyBtns = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;

    h4 {
        font-weight: 600;
        min-width: 16px;
        text-align: center;
    }
`;
export const QtyButton = styled.div`
    cursor: ${(props) => props.$isActive ? 'pointer' : 'default'};
    height: 16px;
    opacity: ${(props) => props.$isActive ? '1.0' : '0.25'};
    width: 16px;

    img {
        height: 100%;
        width: 100%;
    };
`;