import styled from "styled-components";

export const SecondaryBtnContainer = styled.button`
    border: 2px solid rgba(255,255,255,1.0);
    border-radius: 100px;
    color: #FFFFFF;
    height: 40px;
    width: 246px;

    font-size: 12px;
    font-weight: 700;
    margin: 0;
    opacity: ${({ disabled }) => (disabled ? "0.25" : "1.0")};
    transition: 0.3s ease-in-out;

    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

    &:hover {
        filter: ${({ disabled }) => (disabled ? "none" : "drop-shadow(2px 2px rgba(0,0,0,0.15))")};
        transform: ${({ disabled }) => (disabled ? "none" : "scale(1.015)")};
    }
`;