import styled from "styled-components";

export const ChangePasswordContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 690px;
    width: 100%;
`
export const InputContainer = styled.div`
    width: 420px;
`