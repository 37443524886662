import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils.js";
import { selectUser } from "../../store/user/user.selector.js";

// styles
import {
  ActivityLogContainer,
  ActivityLogDisplay
} from "./ActivityLog.styles.js";

// components
import AccountUpdatedEntry from "./components/account-updated-entry/AccountUpdatedEntry.js";
import CollectionUpdatedEntry from "./components/collection-updated-entry/CollectionUpdatedEntry.js";
import TeamUpdatedEntry from "./components/team-updated-entry/TeamUpdatedEntry.js";

export default function ActivityLog() {
  const user = useSelector(selectUser);
  const [activityLog, setActivityLog] = useState([]);

  useEffect(() => {
    const fetchActivityLog = async () => {
      if (!user) return;

      try {
        const activityLogRef = collection(db, `users/${user.uid}/activityLog`);
        const q = query(activityLogRef, orderBy("timestamp", "desc"));
        const querySnapshot = await getDocs(q);

        const logEntries = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setActivityLog(logEntries);
      } catch (error) {
        console.error("Error fetching activity log:", error);
      }
    };

    fetchActivityLog();
  }, [user]);

  const renderedActivityLog = useMemo(() => {
    return activityLog.map((action) => {
      switch (action.actionType) {
        case "updatedTeam":
          return <TeamUpdatedEntry key={action.timestamp} content={action} />;
        case "updatedAccount":
          return <AccountUpdatedEntry key={action.timestamp} content={action} />;
        case "addedToCollection":
        case "removedFromCollection":
          return <CollectionUpdatedEntry key={action.timestamp} content={action} />;
        default:
          return <h4 key={action.id} style={{ color: "#FFFFFF" }}>"Unaccounted for actionType"</h4>;
      }
    });
  }, [activityLog]);

  return (
    <ActivityLogContainer>
      <h1>ACTIVITY LOG</h1>
      <ActivityLogDisplay>
        {renderedActivityLog}
      </ActivityLogDisplay>
    </ActivityLogContainer>
  );
}