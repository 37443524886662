import { Outlet, useNavigate } from 'react-router-dom';

// styles
import {
  HomePageContainer,
  HomeNavContainer,
  NavItem
} from './HomePage.styles.js';

export default function HomePage() {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  }

  return (
    <HomePageContainer>
      <HomeNavContainer>
        <NavItem onClick={() => handleNavigate("/add")}>+ ADD CARD</NavItem>
        <NavItem onClick={() => handleNavigate("/")}>HOME</NavItem>
        <NavItem onClick={() => handleNavigate("/collection")}>COLLECTION</NavItem>
        <NavItem onClick={() => handleNavigate("/card-shop")}>CARD SHOP</NavItem>
        <NavItem onClick={() => handleNavigate("/checklist-manager")}>CHECKLIST MANAGER</NavItem>
        <NavItem onClick={() => handleNavigate("/activity-log")}>ACTIVITY LOG</NavItem>
      </HomeNavContainer>
      <Outlet />
    </HomePageContainer>
  );
}