import styled from "styled-components";

export const CollectionUpdatedEntryContainer = styled.div`
    align-items: center;
    border-radius: 32px;
    border-radius: ${(props) => props.$isExpanded ? '24px 24px 8px 8px;' : '32px'};
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    padding: 8px 8px;

    h3 {
        color: #FFFFFF;
    }
`
export const TopContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`
export const LeftContent = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
`
export const DateDisplay = styled.h4`
    color: #FFFFFF;
    background: rgba(255,255,255,0.25);
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    font-size: 10px;
    padding: 8px 16px;
    padding: 8px 16px;
    text-align: center;
    width: 128px;
`
export const ActionDisplayContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;

    h3 {
        border: 1px solid #FFFFFF;
        border-radius: 100px;
        cursor: pointer;
        font-size: 10px;
        font-weight: 500;
        padding: 0px 8px;
        width: fit-content;

        &:hover {
            background: rgba(255,255,255,0.4);
        }
    };
`
export const ActionDisplay = styled.h4`
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
`
export const CardNumberDisplay = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    margin-right: 8px;

    img {
        height: 16px;
    }

    span {
        color: #FFFFFF;
    }
`
export const CardCount = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`
export const CardsDisplay = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: ${(props) => props.$wasRemoved ? "0.25" : "1.0"};
    width: 100%;

    .year-section, .manufacturer-section, .series-section, .cardtype-section, .cardtype-category-section {
        padding-left: 16px;
    }

    h2 {
        border: 1px solid #FFFFFF;
        border-radius: 100px;
        cursor: pointer;
        font-size: 10px;
        font-weight: 600;
        margin: 8px 0px;
        padding: 0px 8px;
        width: fit-content;
    }
`