import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSystemNotification } from '../../../../store/system/system.action.js';

// components
import InputBar from '../../../../components/input-bar/InputBar';
import InputsContainer from '../../../../components/inputs-container/InputsContainer';
import PrimaryBtn from '../../../../components/btns/primary-btn/PrimaryBtn';

// hooks
import { useSignIn } from '../../../../hooks/useSignIn';

// styles
import { SignInContainer } from './SignIn.styles.js';

export default function SignIn() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { error, isPending, signIn } = useSignIn();

  const handleLogin = () => {
    if (!email) {
      dispatch(setSystemNotification("Please enter a valid email."));
      return;
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        dispatch(setSystemNotification("Please enter a valid email."));
      return;
      }
    }
    if (!password) {
      dispatch(setSystemNotification("Please enter a password."));
      return;
    }
    signIn(email, password);
  }

  useEffect(() => {
    if (error) {
      if (error === "Firebase: Error (auth/user-not-found).") {
        dispatch(setSystemNotification("Account with that email was not found."));
      }
    }

  }, [dispatch, error])

  return (
    <SignInContainer>
      <InputsContainer>
        <InputBar type='email' pH='EMAIL' value={email} updateValue={setEmail} />
        <InputBar type='password' pH='PASSWORD' value={password} updateValue={setPassword} />
        <div onClick={handleLogin}>
          <PrimaryBtn title={isPending ? 'SIGNING IN...' : 'SIGN IN'} disabled={isPending && true} />
        </div>
      </InputsContainer>
    </SignInContainer>
  )
}