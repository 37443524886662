import { USER_ACTION_TYPES } from '../user/user.types.js';
import { GRADIENT_DATA } from '../../data/theme-data/gradient-data.js'

export const setUser = (user) => {
    if (user) {
        return { type: USER_ACTION_TYPES.LOG_IN, payload: user }
    } else {
        return { type: USER_ACTION_TYPES.LOG_OUT }
    }
}

export const setCardCount = (count) => {
    return { type: USER_ACTION_TYPES.SET_CARD_COUNT, payload: count }
}

export const authIsReady = (user = null) => {
    return { type: USER_ACTION_TYPES.AUTH_IS_READY, payload: user }
}

const setTeamIndexHelper = (index) => {
    if (index === null) {
        const newIndex = Math.floor(Math.random() * GRADIENT_DATA.length);
        return newIndex;
    } else {
        return index;
    }
};

export const setTeamIndex = (index = null) => {
    const newIndex = setTeamIndexHelper(index)
    return { type: USER_ACTION_TYPES.SET_TEAM_INDEX, payload: newIndex }
};

export const updateActivityLog = (activityLog, activity) => {
    const newLog = [...activityLog, activity]
    return { type: USER_ACTION_TYPES.UPDATE_ACTIVITY_LOG, payload: newLog }
}

/// FETCHING USER DATA ON SIGN-IN
export const fetchProductsStart = () => (
    { type: USER_ACTION_TYPES.FETCH_DATA_START }
);

export const fetchProductsSuccess = (productsArray) => (
    { type: USER_ACTION_TYPES.FETCH_DATA_SUCCESS, payload: productsArray }
);

export const fetchProductsFailed = (error) => (
    { type: USER_ACTION_TYPES.FETCH_DATA_FAILED, payload: error }
);