import ANGELS from '../../assets/team-logos/AL_W_ANGELS.png'
import ASTROS from '../../assets/team-logos/AL_W_ASTROS.png'
import ATHLETICS from '../../assets/team-logos/AL_W_ATHLETICS.png'
import BLUE_JAYS from '../../assets/team-logos/AL_E_BLUE_JAYS.png'
import BRAVES from '../../assets/team-logos/NL_E_BRAVES.png'

import BREWERS from '../../assets/team-logos/NL_C_BREWERS.png'
import CARDINALS from '../../assets/team-logos/NL_C_CARDINALS.png'
import CUBS from '../../assets/team-logos/NL_C_CUBS.png'
import DIAMONDBACKS from '../../assets/team-logos/NL_W_DIAMONDBACKS.png'
import DODGERS from '../../assets/team-logos/NL_W_DODGERS.png'

import GIANTS from '../../assets/team-logos/NL_W_GIANTS.png'
import GUARDIANS from '../../assets/team-logos/AL_C_GUARDIANS.png'
import MARINERS from '../../assets/team-logos/AL_W_MARINERS.png'
import MARLINS from '../../assets/team-logos/NL_E_MARLINS.png'
import METS from '../../assets/team-logos/NL_E_METS.png'

import NATIONALS from '../../assets/team-logos/NL_E_NATIONALS.png'
import ORIOLES from '../../assets/team-logos/AL_E_ORIOLES.png'
import PADRES from '../../assets/team-logos/NL_W_PADRES.png'
import PHILLIES from '../../assets/team-logos/NL_E_PHILLIES.png'
import PIRATES from '../../assets/team-logos/NL_C_PIRATES.png'

import RANGERS from '../../assets/team-logos/AL_W_RANGERS.png'
import RAYS from '../../assets/team-logos/AL_E_RAYS.png'
import RED_SOX from '../../assets/team-logos/AL_E_RED_SOX.png'
import REDS from '../../assets/team-logos/NL_C_REDS.png'
import ROCKIES from '../../assets/team-logos/NL_W_ROCKIES.png'

import ROYALS from '../../assets/team-logos/AL_C_ROYALS.png'
import TIGERS from '../../assets/team-logos/AL_C_TIGERS.png'
import TWINS from '../../assets/team-logos/AL_C_TWINS.png'
import WHITE_SOX from '../../assets/team-logos/AL_C_WHITE_SOX.png'
import YANKEES from '../../assets/team-logos/AL_E_YANKEES.png'

export const TEAM_LOGOS = {
    0: {
        teamID: 'ANA',
        logo: ANGELS,
        name: "Angels®"
    },
    1: {
        teamID: 'HOU',
        logo: ASTROS,
        name: "Houston Astros®"
    },
    2: {
        teamID: 'OAK',
        logo: ATHLETICS,
        name: "Oakland Athletics™"
    },
    3: {
        teamID: 'TOR',
        logo: BLUE_JAYS,
        name: "Toronto Blue Jays®"
    },
    4: {
        teamID: 'ATL',
        logo: BRAVES,
        name: "Atlanta Braves™"
    },
    5: {
        teamID: 'MIL',
        logo: BREWERS,
        name: "Milwaukee Brewers™"
    },
    6: {
        teamID: 'STL',
        logo: CARDINALS,
        name: "St. Louis Cardinals®"
    },
    7: {
        teamID: 'CHC',
        logo: CUBS,
        name: "Chicago Cubs®"
    },
    8: {
        teamID: 'ARI',
        logo: DIAMONDBACKS,
        name: "Arizona Diamondbacks®"
    },
    9: {
        teamID: 'LAD',
        logo: DODGERS,
        name: "Los Angeles Dodgers®"
    },
    10: {
        teamID: 'SF',
        logo: GIANTS,
        name: "San Francisco Giants®"
    },
    11: {
        teamID: 'CLE',
        logo: GUARDIANS,
        name: "Cleveland Guardians™"
    },
    12: {
        teamID: 'SEA',
        logo: MARINERS,
        name: "Seattle Mariners™"
    },
    13: {
        teamID: 'FLA',
        logo: MARLINS,
        name: "Miami Marlins®"
    },
    14: {
        teamID: 'NYM',
        logo: METS,
        name: "New York Mets®"
    },
    15: {
        teamID: 'WSH',
        logo: NATIONALS,
        name: "Washington Nationals®"
    },
    16: {
        teamID: 'BAL',
        logo: ORIOLES,
        name: "Baltimore Orioles®"
    },
    17: {
        teamID: 'SD',
        logo: PADRES,
        name: "San Diego Padres™"
    },
    18: {
        teamID: 'PHI',
        logo: PHILLIES,
        name: "Philadelphia Phillies®"
    },
    19: {
        teamID: 'PIT',
        logo: PIRATES,
        name: "Pittsburgh Pirates®"
    },
    20: {
        teamID: 'TEX',
        logo: RANGERS,
        name: "Texas Rangers®"
    },
    21: {
        teamID: 'TB',
        logo: RAYS,
        name: "Tampa Bay Rays™"
    },
    22: {
        teamID: 'BOS',
        logo: RED_SOX,
        name: "Boston Red Sox®"
    },
    23: {
        teamID: 'CIN',
        logo: REDS,
        name: "Cincinnati Reds®"
    },
    24: {
        teamID: 'COL',
        logo: ROCKIES,
        name: "Colorado Rockies™"
    },
    25: {
        teamID: 'KC',
        logo: ROYALS,
        name: "Kansas City Royals®"
    },
    26: {
        teamID: 'DET',
        logo: TIGERS,
        name: "Detroit Tigers®"
    },
    27: {
        teamID: 'MIN',
        logo: TWINS,
        name: "Minnesota Twins®"
    },
    28: {
        teamID: 'CWS',
        logo: WHITE_SOX,
        name: "Chicago White Sox®"
    },
    29: {
        teamID: 'NYY',
        logo: YANKEES,
        name: "New York Yankees®"
    }

}