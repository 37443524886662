// assets
import cardIcon from '../../../assets/system/icons/card-icon.png';

// styles
import {
  CardPanelContainer,
  CardCountContainer
} from './CardPanel.styles.js'

export default function CardPanel({title, cardCount = 0}) {
  return (
    <CardPanelContainer>
      <h2>{title}</h2>
      <CardCountContainer>
          <img src={cardIcon} alt='card icon' />
          <h5>{cardCount.toLocaleString()}</h5>
      </CardCountContainer>
    </CardPanelContainer>  
  )
}
