import styled from "styled-components";

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 48px 48px;
`
export const LeftContent = styled.div`
    align-items: center;
    display: flex;

    img {
        cursor: pointer;
        height: 44px;
        transition: 0.3s ease-in-out;

        &:hover {
            transform: scale(1.01);
            transition: 0.3s ease-in-out;
        };
    };
`
export const RightContent = styled.div`
    align-items: center;
    display: flex;
    gap: 12px;
`