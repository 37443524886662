import styled from "styled-components";

export const HeaderDataPillContainer = styled.div`
    align-items: center;
    border: 2px solid rgb(255, 255, 255, 0.33);
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 40px;
    padding: 0px 20px;
    transition: 0.4s;

    &:hover {
        transform: scale(1.05);
        transition: 0.4s;
    }

    h5 {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    img {
        height: 16px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
`