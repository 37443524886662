// styles
import {
    CardShopContainer
 } from "./CardShop.styles";

export default function CardShop() {
    return (
        <CardShopContainer>
            <h1>CARD SHOP</h1>
        </CardShopContainer>
    )
}
