import styled from "styled-components";

export const QueuedCardsContainer = styled.div`
    border: 1px solid rgba(255,255,255,0.25);
    border-radius: 24px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: fit-content;
    max-height: 640px;
    padding: 8px;
    width: 560px;
`
export const QueueHeader = styled.div`
    align-items: center;
    display: flex;
    gap: 12px;

    -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;

    h3 {
        font-size: 24px;
        line-height: 24px;
        margin: 8px 0px;
        margin-left: 4px;
        opacity: 0.24;
    }

    h4 {
        background-color: rgba(255,255,255,0.4);
        border: 1px solid rgba(255,255,255,0.67);
        border-radius: 100px;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 26px;
        min-width: 48px;
        opacity: 0.5;
        text-align: center;
    }
`
export const QueueBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
    max-height: 560px;
`
export const QueueFooter = styled.div`
    display: flex;
    gap: 8px;
    margin: 12px 0px;

    button {
        background: none;
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: 600;
        height: 44px;
        width: 100%;

        &:hover {
            background-color: rgba(255,255,255,0.25);
        }
    }

`