import { useState } from 'react'

// components
import ActionField from '../../../../components/action-field/ActionField.js';
import InputBar from '../../../../components/input-bar/InputBar';
import SecondaryBtn from '../../../../components/btns/secondary-btn/SecondaryBtn';

// styles
import { ChangePasswordContainer } from './ChangePassword.styles.js';
import { InputContainer } from '../account-details/AccountDetails.styles.js';

export default function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handlePasswordChange = () => {
    alert("Password was updated.");
    setNewPassword("");
    setConfirmNewPassword("");
  }

  return (
    <ChangePasswordContainer>
      <ActionField>
        <InputContainer>
          <InputBar type={'password'} pH={'NEW PASSWORD'} value={newPassword} updateValue={setNewPassword} />
        </InputContainer>
        <div><h5>(PW STRENGTH INDICATOR)</h5></div>
      </ActionField>
      <ActionField>
        <InputContainer>
          <InputBar type={'password'} pH={'CONFIRM NEW PASSWORD'} value={confirmNewPassword} updateValue={setConfirmNewPassword} />
        </InputContainer>
        <div onClick={handlePasswordChange}>
          <SecondaryBtn title='UPDATE PASSWORD' />
        </div>
      </ActionField>
    </ChangePasswordContainer>
  )
}