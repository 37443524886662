import { CARD_ACTION_TYPES } from "./card.types.js";

const CARD_INITIAL_STATE = {
    cardData: {},
    checklistData: {},
    cardParallels: [],
    queuedCards: {},
    queuedCardsDisplay: [],
    cardCollection: {},
    isPending: false
}

export const cardReducer = (state = CARD_INITIAL_STATE, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case CARD_ACTION_TYPES.SET_CARD_DATA:
            return { ...state, cardData: payload };
        case CARD_ACTION_TYPES.SET_CHECKLIST_DATA:
            return { ...state, checklistData: payload };
        case CARD_ACTION_TYPES.SET_PARALLELS_ROUTES:
            return { ...state, cardParallels: payload };
        case CARD_ACTION_TYPES.SET_QUEUED_CARDS:
            return { ...state, queuedCards: payload };
        case CARD_ACTION_TYPES.SET_QUEUED_CARDS_DISPLAY:
            return { ...state, queuedCardsDisplay: payload };
        case CARD_ACTION_TYPES.SET_CARD_COLLECTION:
            return { ...state, cardCollection: payload, queuedCards: {}, queuedCardsDisplay: [] };
        case CARD_ACTION_TYPES.UPDATE_CARD_QUANTITY:
            return { ...state, cardCollection: payload };
        case CARD_ACTION_TYPES.CLEAR_QUEUE:
            return { ...state, queuedCards: {}, queuedCardsDisplay: [] };
        default:
            return state;
    }
};