import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTeamIndex } from '../../store/user/user.selector.js';
import { selectSystemNotification } from '../../store/system/system.selector.js';
import { AnimatePresence } from 'framer-motion';

// data
import { GRADIENT_DATA } from '../../data/theme-data/gradient-data.js';

// styles
import { MainContainer, MainGradientBGContainer } from './SiteContainer.styles.js';

// components
import GradientBG from '../../components/gradient-bg/GradientBG.js';
import Header from '../../components/header/Header.js';
import Notification from '../../components/notification/Notification.js';

export default function ParentPage() {
  const teamIndex = useSelector(selectTeamIndex);
  const systemNotification = useSelector(selectSystemNotification);

  return (
    <MainContainer>
      <Header acctTab={true} logOutBtn={true} acctStats={true} />
      <AnimatePresence>
        {systemNotification && <Notification text={systemNotification} key="notification" />}
      </AnimatePresence>
      <Outlet />
      <MainGradientBGContainer>
        <GradientBG setGradient={GRADIENT_DATA[teamIndex]} />
      </MainGradientBGContainer>
    </MainContainer>
  )
}
