import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

// assets
import logo from '../../assets/system/pull-vault-logo-light.png';

// components
import HeaderDataPill from './components/header-data-pill/HeaderDataPill';
import HeaderProfilePill from './components/header-profile-pill/HeaderProfilePill';

// styles
import {
  HeaderContainer,
  LeftContent,
  RightContent
} from './Header.styles.js';

export default function Header({ rightContent = true }) {
  const navigate = useNavigate();
  const logoMemo = useMemo(() => logo, []);

  const handleClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <HeaderContainer>
      <LeftContent>
        <img src={logoMemo} alt='Pull Vault logo' onClick={handleClick} />
      </LeftContent>
      {rightContent &&
        <RightContent>
          <div onClick={handleClick}>
            <HeaderDataPill />
          </div>
          <HeaderProfilePill />
        </RightContent>
      }
    </HeaderContainer>
  );
}