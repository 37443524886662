import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeamIndex } from '../../store/user/user.selector.js';
import { selectBgIsChanging, selectDisplayedGradient, selectGradientData, selectSystemNotification } from '../../store/system/system.selector.js';
import { setGradient, setDisplayedGradient, setBgIsChanging } from '../../store/system/system.action.js';
import { setTeamIndex } from '../../store/user/user.action.js';
import { AnimatePresence } from 'framer-motion';

// data
import { GRADIENT_DATA } from '../../data/theme-data/gradient-data.js';

// components
import CreateAccount from './components/create-account/CreateAccount';
import GradientBG from '../../components/gradient-bg/GradientBG.js';
import Header from '../../components/header/Header.js';
import Notification from '../../components/notification/Notification.js';
import SignIn from './components/sign-in/SignIn';

// styles
import {
    AuthPageContainer,
    Content,
    LeftCol,
    RightCol,
    MainGradientBGContainer
} from './AuthPage.styles.js';


export default function AuthPage() {
    const dispatch = useDispatch();
    const displayedGradient = useSelector(selectDisplayedGradient);
    const teamIndex = useSelector(selectTeamIndex);
    const bgIsChanging = useSelector(selectBgIsChanging);
    const gradientData = useSelector(selectGradientData);
    const systemNotification = useSelector(selectSystemNotification);
    const [authToggle, setAuthToggle] = useState('signIn');

    useEffect(() => {
        dispatch(setBgIsChanging(true));
        const randomGradientIndex = Math.floor(Math.random() * Object.keys(GRADIENT_DATA).length);
        dispatch(setTeamIndex(randomGradientIndex));

        if (!displayedGradient) {
            dispatch(setDisplayedGradient('A'));
        }
    }, [dispatch, displayedGradient]);

    useEffect(() => {
        if (bgIsChanging) {
            const interval = setInterval(() => {
                if (displayedGradient === 'A') {
                    dispatch(setGradient('B'));
                    dispatch(setDisplayedGradient('B'));
                } else {
                    dispatch(setGradient('A'));
                    dispatch(setDisplayedGradient('A'));
                }
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [dispatch, bgIsChanging, displayedGradient]);

    return (
        <AuthPageContainer>
            <Header rightContent={false} />
            <AnimatePresence>
                {systemNotification && <Notification text={systemNotification} key="notification" />}
            </AnimatePresence>

            <Content>
                <LeftCol $toggle={authToggle}>
                    <div className='left-col-content'>
                        <div className='toggle-container'>
                            <div className='toggle-option'>
                                <h3 className={authToggle === 'signIn' ? 'underline' : ''} onClick={() => setAuthToggle('signIn')}>Sign In</h3>
                            </div>
                            <div className='toggle-option'>
                                <h3 className={authToggle === 'createAccount' ? 'underline' : ''} onClick={() => setAuthToggle('createAccount')}>Create Account</h3>
                            </div>
                        </div>
                        {authToggle === 'signIn' ? <SignIn /> : <CreateAccount />}
                    </div>
                </LeftCol>
                {authToggle === 'signIn' &&
                    <RightCol />
                }
            </Content>
            <MainGradientBGContainer>
                <GradientBG setGradient={gradientData[teamIndex]} />
            </MainGradientBGContainer>
        </AuthPageContainer>
    )
}
