import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectChecklistData } from '../../../../store/card/card.selector';
import { fetchChecklistData } from '../../../../utils/firebase/firebase.utils';

// styles
import {
  ChecklistViewContainer,
  InputField,
  ChecklistDisplay
} from './ChecklistView.styles';

// components
import ChecklistItemDisplay from '../../../../components/checklist-item-display/ChecklistItemDisplay';

export default function ChecklistView() {
  const [enteredSearchTerm, setEnteredSearchTerm] = useState('');
  const [activeCardNumber, setActiveCardNumber] = useState(null);
  const checklistData = useSelector(selectChecklistData);
  const dispatch = useDispatch();
  const { sport, year, manufacturer, series, cardType, cardTypeCategory } = useParams();

  useEffect(() => {
    const checklistPath = `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}`;
    dispatch(fetchChecklistData(checklistPath));
  }, [dispatch, sport, year, manufacturer, series, cardType, cardTypeCategory]);

  const filteredCards = Object.keys(checklistData)
    .filter(key => {
      const card = checklistData[key];
      return (
        card.cardNumber && card.cardNumber.toLowerCase().includes(enteredSearchTerm.toLowerCase())
      ) || (
          card.name && card.name.some(name => name.toLowerCase().includes(enteredSearchTerm.toLowerCase()))
        );
    })
    .map(key => checklistData[key]);

  const handleToggleAddParallel = (cardNumber) => {
    setActiveCardNumber((prev) => (prev === cardNumber ? null : cardNumber));
  };

  return (
    <>
      {checklistData &&
        <ChecklistViewContainer>
          <InputField
            type='text'
            placeholder='SEARCH...'
            value={enteredSearchTerm}
            onChange={e => setEnteredSearchTerm(e.target.value)}
          />
          <ChecklistDisplay>
            {filteredCards.length > 0 && filteredCards
              .sort((a, b) => {
                const getNumericPart = (cardNumber) => {
                  const parts = cardNumber.split('-'); // Split on '-'
                  const lastPart = parts[parts.length - 1]; // Get the last part
                  return parseInt(lastPart.replace(/^\D+/g, ''), 10) || 0; // Remove leading non-digits and parse to int
                };
                const aNum = getNumericPart(a.cardNumber);
                const bNum = getNumericPart(b.cardNumber);
                return aNum - bNum; // Sort by numeric value
              })
              .map((card, index) => (
                <ChecklistItemDisplay
                  key={`${index}`}
                  card={card}
                  isAddingParallel={activeCardNumber === card.cardNumber}
                  parallels={card.availableParallels}
                  onToggleAddParallel={() => handleToggleAddParallel(card.cardNumber)}
                />
              ))}
          </ChecklistDisplay>

          {filteredCards.length <= 0 &&
            <h3>No matches found.</h3>
          }
        </ChecklistViewContainer>
      }
    </>
  );
}