import React from 'react';

// styles
import { PrimaryBtnContainer } from './PrimaryBtn.styles.js';

const PrimaryBtn = React.memo(function PrimaryBtn({ disabled = false, title }) {
  return (
    <PrimaryBtnContainer disabled={disabled}>
      {title}
    </PrimaryBtnContainer>
  );
});

export default PrimaryBtn;