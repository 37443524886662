// styles
import { StatBoxContainer } from "./StatBox.styles"

export default function StatBox({ isActive, label, value }) {
  return (
    <StatBoxContainer $isActive={isActive} whileHover={{scale: 1.025}}>
        <h4 className='label'>{label}</h4>
        <h4 className='value'>{value}</h4>
    </StatBoxContainer>
  )
}
