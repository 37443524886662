import styled from "styled-components";

export const SignInContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    
    .error {
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
    }
`
