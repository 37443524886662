import styled from "styled-components";

export const ChecklistViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`
export const InputField = styled.input`
    background: none;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
    color: #FFFFFF;
    font-weight: 600;
    height: 44px;
    padding: 8px;
    width: 240px;

    &::placeholder {
        color: rgba(255,255,255,0.4);
    }
`
export const ChecklistDisplay = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 48px;
`