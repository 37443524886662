// CHECKLIST: INCOMPLETE
// PARALLELS: INCOMPLETE

export const MLB_2024_TOPPS_SERIES1 = {
    "MLB": {
        "2024": {
            "Topps": {
                "Series 1": {
                    "Base Card Set": {
                        "Base": {
                            "1": {
                                "cardNumber": "1",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2": {
                                "cardNumber": "2",
                                "name": ["San Diego Padres™"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "3": {
                                "cardNumber": "3",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "4": {
                                "cardNumber": "4",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "5": {
                                "cardNumber": "5",
                                "name": ["Owen White"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "6": {
                                "cardNumber": "6",
                                "name": ["Detroit Tigers®"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "7": {
                                "cardNumber": "7",
                                "name": ["Brendan Donovan"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "8": {
                                "cardNumber": "8",
                                "name": ["Brendan White"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "9": {
                                "cardNumber": "9",
                                "name": ["Joey Wiemer"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "10": {
                                "cardNumber": "10",
                                "name": ["Josh Naylor"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "11": {
                                "cardNumber": "11",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "12": {
                                "cardNumber": "12",
                                "name": ["Bryan Woo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "13": {
                                "cardNumber": "13",
                                "name": ["Nick Robertson"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "14": {
                                "cardNumber": "14",
                                "name": ["Michael Massey"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "15": {
                                "cardNumber": "15",
                                "name": ["José Soriano"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "16": {
                                "cardNumber": "16",
                                "name": ["Jon Gray"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "17": {
                                "cardNumber": "17",
                                "name": ["Nick Pivetta"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "18": {
                                "cardNumber": "18",
                                "name": ["Lars Nootbaar"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "19": {
                                "cardNumber": "19",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "20": {
                                "cardNumber": "20",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "21": {
                                "cardNumber": "21",
                                "name": ["Esteury Ruiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "22": {
                                "cardNumber": "22",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "23": {
                                "cardNumber": "23",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "24": {
                                "cardNumber": "24",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "25": {
                                "cardNumber": "25",
                                "name": ["Andy Ibáñez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "26": {
                                "cardNumber": "26",
                                "name": ["Freddy Peralta"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "27": {
                                "cardNumber": "27",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "28": {
                                "cardNumber": "28",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "29": {
                                "cardNumber": "29",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "30": {
                                "cardNumber": "30",
                                "name": ["Hunter Brown"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "31": {
                                "cardNumber": "31",
                                "name": ["Carmen Mlodzinski"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "32": {
                                "cardNumber": "32",
                                "name": ["Samad Taylor"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "33": {
                                "cardNumber": "33",
                                "name": ["Willy Adames"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "34": {
                                "cardNumber": "34",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "35": {
                                "cardNumber": "35",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "36": {
                                "cardNumber": "36",
                                "name": ["Prelander Berroa"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "37": {
                                "cardNumber": "37",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "38": {
                                "cardNumber": "38",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "39": {
                                "cardNumber": "39",
                                "name": ["Emerson Hancock"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "40": {
                                "cardNumber": "40",
                                "name": ["Dylan Cease"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "41": {
                                "cardNumber": "41",
                                "name": ["Geraldo Perdomo"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "42": {
                                "cardNumber": "42",
                                "name": ["Jorge Mateo"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "43": {
                                "cardNumber": "43",
                                "name": ["Daulton Varsho"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "44": {
                                "cardNumber": "44",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "45": {
                                "cardNumber": "45",
                                "name": ["Félix Bautista"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "46": {
                                "cardNumber": "46",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "47": {
                                "cardNumber": "47",
                                "name": ["Cavan Biggio"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "48": {
                                "cardNumber": "48",
                                "name": ["Bryce Elder"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "49": {
                                "cardNumber": "49",
                                "name": ["J.P. Crawford"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "50": {
                                "cardNumber": "50",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "51": {
                                "cardNumber": "51",
                                "name": ["Nestor Cortes Jr."],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "52": {
                                "cardNumber": "52",
                                "name": ["Yandy Díaz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "53": {
                                "cardNumber": "53",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "54": {
                                "cardNumber": "54",
                                "name": ["Luis Castillo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "55": {
                                "cardNumber": "55",
                                "name": ["Sean Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "56": {
                                "cardNumber": "56",
                                "name": ["Kyle Bradish"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "57": {
                                "cardNumber": "57",
                                "name": ["Chris Bassitt", "Zach Eflin", "Gerrit Cole"],
                                "team": ["Toronto Blue Jays®", "Tampa Bay Rays™", "New York Yankees®"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "58": {
                                "cardNumber": "58",
                                "name": ["Isaiah Campbell"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "59": {
                                "cardNumber": "59",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "60": {
                                "cardNumber": "60",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "61": {
                                "cardNumber": "61",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "62": {
                                "cardNumber": "62",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "63": {
                                "cardNumber": "63",
                                "name": ["Christian Walker"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "64": {
                                "cardNumber": "64",
                                "name": ["J.D. Davis"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "65": {
                                "cardNumber": "65",
                                "name": ["Johan Oviedo"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "66": {
                                "cardNumber": "66",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "67": {
                                "cardNumber": "67",
                                "name": ["Jorge Polanco"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "68": {
                                "cardNumber": "68",
                                "name": ["Stone Garrett"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "69": {
                                "cardNumber": "69",
                                "name": ["Griffin Canning"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "70": {
                                "cardNumber": "70",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "71": {
                                "cardNumber": "71",
                                "name": ["Brandon Marsh"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "72": {
                                "cardNumber": "72",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "73": {
                                "cardNumber": "73",
                                "name": ["Chris Murphy"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "74": {
                                "cardNumber": "74",
                                "name": ["Mickey Moniak"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "75": {
                                "cardNumber": "75",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "76": {
                                "cardNumber": "76",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "77": {
                                "cardNumber": "77",
                                "name": ["Yainer Díaz"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "78": {
                                "cardNumber": "78",
                                "name": ["Mark Vientos"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "79": {
                                "cardNumber": "79",
                                "name": ["Seth Brown"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "80": {
                                "cardNumber": "80",
                                "name": ["JP Sears"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "81": {
                                "cardNumber": "81",
                                "name": ["Nick Fortes"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "82": {
                                "cardNumber": "82",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "83": {
                                "cardNumber": "83",
                                "name": ["Keaton Winn"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "84": {
                                "cardNumber": "84",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "85": {
                                "cardNumber": "85",
                                "name": ["Jose Ferrer"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "86": {
                                "cardNumber": "86",
                                "name": ["José Rodríguez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "87": {
                                "cardNumber": "87",
                                "name": ["Tony Gonsolin"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "88": {
                                "cardNumber": "88",
                                "name": ["Orlando Arcia"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89": {
                                "cardNumber": "89",
                                "name": ["Tyler Glasnow"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "90": {
                                "cardNumber": "90",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "91": {
                                "cardNumber": "91",
                                "name": ["Matt Wallner"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "92": {
                                "cardNumber": "92",
                                "name": ["Coco Montes"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "93": {
                                "cardNumber": "93",
                                "name": ["Quinn Priester"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "94": {
                                "cardNumber": "94",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "95": {
                                "cardNumber": "95",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "96": {
                                "cardNumber": "96",
                                "name": ["Christian Bethancourt"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "97": {
                                "cardNumber": "97",
                                "name": ["Alex Verdugo"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "98": {
                                "cardNumber": "98",
                                "name": ["José Quintana"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "99": {
                                "cardNumber": "99",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "100": {
                                "cardNumber": "100",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "101": {
                                "cardNumber": "101",
                                "name": ["Jordan Romano"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "102": {
                                "cardNumber": "102",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "103": {
                                "cardNumber": "103",
                                "name": ["Brandon Woodruff"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "104": {
                                "cardNumber": "104",
                                "name": ["Connor Wong"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "105": {
                                "cardNumber": "105",
                                "name": ["Oscar Colás"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "106": {
                                "cardNumber": "106",
                                "name": ["Adolis García", "Kyle Tucker", "Julio Rodríguez"],
                                "team": ["Texas Rangers®", "Houston Astros®", "Seattle Mariners™"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "107": {
                                "cardNumber": "107",
                                "name": ["Shane Bieber"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "108": {
                                "cardNumber": "108",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "109": {
                                "cardNumber": "109",
                                "name": ["Michael Kopech"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "110": {
                                "cardNumber": "110",
                                "name": ["Zac Gallen"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "111": {
                                "cardNumber": "111",
                                "name": ["Grayson Rodriguez"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "112": {
                                "cardNumber": "112",
                                "name": ["Merrill Kelly"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "113": {
                                "cardNumber": "113",
                                "name": ["MacKenzie Gore"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "114": {
                                "cardNumber": "114",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "115": {
                                "cardNumber": "115",
                                "name": ["Bobby Miller"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "116": {
                                "cardNumber": "116",
                                "name": ["Emmanuel Clase"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "117": {
                                "cardNumber": "117",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "118": {
                                "cardNumber": "118",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "119": {
                                "cardNumber": "119",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "120": {
                                "cardNumber": "120",
                                "name": ["Edwin Díaz"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "121": {
                                "cardNumber": "121",
                                "name": ["Bailey Ober"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "122": {
                                "cardNumber": "122",
                                "name": ["Cal Raleigh"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "123": {
                                "cardNumber": "123",
                                "name": ["Jesús Luzardo"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "124": {
                                "cardNumber": "124",
                                "name": ["Mike Yastrzemski"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "125": {
                                "cardNumber": "125",
                                "name": ["Jake Burger"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "126": {
                                "cardNumber": "126",
                                "name": ["Philadelphia Phillies®"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "127": {
                                "cardNumber": "127",
                                "name": ["Ha-Seong Kim"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "128": {
                                "cardNumber": "128",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "129": {
                                "cardNumber": "129",
                                "name": ["Adbert Alzolay"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "130": {
                                "cardNumber": "130",
                                "name": ["Grant Hartwig"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "131": {
                                "cardNumber": "131",
                                "name": ["Eduardo Rodriguez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "132": {
                                "cardNumber": "132",
                                "name": ["Houston Astros®"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "133": {
                                "cardNumber": "133",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "134": {
                                "cardNumber": "134",
                                "name": ["Adolis García"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "135": {
                                "cardNumber": "135",
                                "name": ["Devin Williams"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "136": {
                                "cardNumber": "136",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "137": {
                                "cardNumber": "137",
                                "name": ["Reid Detmers"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "138": {
                                "cardNumber": "138",
                                "name": ["Mookie Betts"],
                                "team": ["Major League Baseball™"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "139": {
                                "cardNumber": "139",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "140": {
                                "cardNumber": "140",
                                "name": ["Brad Keller"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "141": {
                                "cardNumber": "141",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "142": {
                                "cardNumber": "142",
                                "name": ["Joey Meneses"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "143": {
                                "cardNumber": "143",
                                "name": ["David Bednar"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "144": {
                                "cardNumber": "144",
                                "name": ["Jacob Amaya"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "145": {
                                "cardNumber": "145",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "146": {
                                "cardNumber": "146",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "147": {
                                "cardNumber": "147",
                                "name": ["JJ Bleday"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "148": {
                                "cardNumber": "148",
                                "name": ["Alex Kirilloff"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "149": {
                                "cardNumber": "149",
                                "name": ["Bo Bichette", "Yandy Díaz", "Corey Seager"],
                                "team": ["Toronto Blue Jays®", "Tampa Bay Rays™", "Texas Rangers®"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "150": {
                                "cardNumber": "150",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "151": {
                                "cardNumber": "151",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "152": {
                                "cardNumber": "152",
                                "name": ["Lane Thomas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "153": {
                                "cardNumber": "153",
                                "name": ["Kyle Finnegan"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "154": {
                                "cardNumber": "154",
                                "name": ["Atlanta Braves™"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "155": {
                                "cardNumber": "155",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "156": {
                                "cardNumber": "156",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "157": {
                                "cardNumber": "157",
                                "name": ["Trent Grisham"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "158": {
                                "cardNumber": "158",
                                "name": ["Yennier Cano"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "159": {
                                "cardNumber": "159",
                                "name": ["Luis Rengifo"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "160": {
                                "cardNumber": "160",
                                "name": ["Ji Hwan Bae"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "161": {
                                "cardNumber": "161",
                                "name": ["Elias Díaz"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "162": {
                                "cardNumber": "162",
                                "name": ["Matt Waldron"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "163": {
                                "cardNumber": "163",
                                "name": ["Alec Marsh"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "164": {
                                "cardNumber": "164",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "165": {
                                "cardNumber": "165",
                                "name": ["Chicago Cubs®"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "166": {
                                "cardNumber": "166",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "167": {
                                "cardNumber": "167",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "168": {
                                "cardNumber": "168",
                                "name": ["Nick Gonzales"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "169": {
                                "cardNumber": "169",
                                "name": ["Tyler O'Neill"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "170": {
                                "cardNumber": "170",
                                "name": ["Aaron Civale"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "171": {
                                "cardNumber": "171",
                                "name": ["Jacob deGrom"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "172": {
                                "cardNumber": "172",
                                "name": ["Spencer Horwitz"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "173": {
                                "cardNumber": "173",
                                "name": ["Brady Singer"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "174": {
                                "cardNumber": "174",
                                "name": ["Nathaniel Lowe"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "175": {
                                "cardNumber": "175",
                                "name": ["Joe Ryan"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "176": {
                                "cardNumber": "176",
                                "name": ["Taylor Rogers"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "177": {
                                "cardNumber": "177",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "178": {
                                "cardNumber": "178",
                                "name": ["Daniel Palencia"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "179": {
                                "cardNumber": "179",
                                "name": ["Shohei Ohtani", "Adolis García", "Luis Robert Jr."],
                                "team": ["Angels®", "Texas Rangers®", "Chicago White Sox®"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "180": {
                                "cardNumber": "180",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "181": {
                                "cardNumber": "181",
                                "name": ["Luke Raley"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "182": {
                                "cardNumber": "182",
                                "name": ["Jameson Taillon"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "183": {
                                "cardNumber": "183",
                                "name": ["Los Angeles Dodgers®"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "184": {
                                "cardNumber": "184",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "185": {
                                "cardNumber": "185",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "186": {
                                "cardNumber": "186",
                                "name": ["Brent Rooker"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "187": {
                                "cardNumber": "187",
                                "name": ["Joe Jacques"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "188": {
                                "cardNumber": "188",
                                "name": ["Dane Myers"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "189": {
                                "cardNumber": "189",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "190": {
                                "cardNumber": "190",
                                "name": ["Geraldo Perdomo"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Combo Card/Checklist"]
                            },
                            "191": {
                                "cardNumber": "191",
                                "name": ["Bo Naylor"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "192": {
                                "cardNumber": "192",
                                "name": ["Baltimore Orioles®"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "193": {
                                "cardNumber": "193",
                                "name": ["Brandon Walter"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "194": {
                                "cardNumber": "194",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "195": {
                                "cardNumber": "195",
                                "name": ["Dominic Canzone"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "196": {
                                "cardNumber": "196",
                                "name": ["Walker Buehler"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "197": {
                                "cardNumber": "197",
                                "name": ["Eury Pérez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "198": {
                                "cardNumber": "198",
                                "name": ["Kyle Freeland"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "199": {
                                "cardNumber": "199",
                                "name": ["Leody Taveras"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "200": {
                                "cardNumber": "200",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "201": {
                                "cardNumber": "201",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "202": {
                                "cardNumber": "202",
                                "name": ["David Hamilton"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Card"]
                            },
                            "203": {
                                "cardNumber": "203",
                                "name": ["Chas McCormick"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "204": {
                                "cardNumber": "204",
                                "name": ["Nico Hoerner"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "205": {
                                "cardNumber": "205",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "206": {
                                "cardNumber": "206",
                                "name": ["Luis Arraez", "Ronald Acuña Jr.", "Freddie Freeman"],
                                "team": ["Miami Marlins®", "Atlanta Braves™", "Los Angeles Dodgers®"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "207": {
                                "cardNumber": "207",
                                "name": ["Luken Baker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "208": {
                                "cardNumber": "208",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "209": {
                                "cardNumber": "209",
                                "name": ["Johan Rojas"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "210": {
                                "cardNumber": "210",
                                "name": ["Gleyber Torres"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "211": {
                                "cardNumber": "211",
                                "name": ["Tampa Bay Rays™"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "212": {
                                "cardNumber": "212",
                                "name": ["Justin Steele", "Blake Snell", "Kodai Senga"],
                                "team": ["Chicago Cubs®", "San Diego Padres™", "New York Mets®"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "213": {
                                "cardNumber": "213",
                                "name": ["Camilo Doval"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "214": {
                                "cardNumber": "214",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "215": {
                                "cardNumber": "215",
                                "name": ["Bryan De La Cruz"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "216": {
                                "cardNumber": "216",
                                "name": ["Kevin Gausman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "217": {
                                "cardNumber": "217",
                                "name": ["Luis Campusano"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "218": {
                                "cardNumber": "218",
                                "name": ["Ke'Bryan Hayes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "219": {
                                "cardNumber": "219",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "220": {
                                "cardNumber": "220",
                                "name": ["Framber Valdez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "221": {
                                "cardNumber": "221",
                                "name": ["Charlie Morton"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "222": {
                                "cardNumber": "222",
                                "name": ["Zach Remillard"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "223": {
                                "cardNumber": "223",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "224": {
                                "cardNumber": "224",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "225": {
                                "cardNumber": "225",
                                "name": ["Brusdar Graterol"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "226": {
                                "cardNumber": "226",
                                "name": ["Grae Kessinger"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "227": {
                                "cardNumber": "227",
                                "name": ["Jhoan Duran"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "228": {
                                "cardNumber": "228",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "229": {
                                "cardNumber": "229",
                                "name": ["Ranger Suárez"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "230": {
                                "cardNumber": "230",
                                "name": ["Mitch Keller"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "231": {
                                "cardNumber": "231",
                                "name": ["Eugenio Suárez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "232": {
                                "cardNumber": "232",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "233": {
                                "cardNumber": "233",
                                "name": ["Jonny Deluca"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "234": {
                                "cardNumber": "234",
                                "name": ["Wilmer Flores"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "235": {
                                "cardNumber": "235",
                                "name": ["Jonah Heim"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "236": {
                                "cardNumber": "236",
                                "name": ["Patrick Corbin"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "237": {
                                "cardNumber": "237",
                                "name": ["Jake Cronenworth"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "238": {
                                "cardNumber": "238",
                                "name": ["Cincinnati Reds®"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "239": {
                                "cardNumber": "239",
                                "name": ["Keibert Ruiz"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "240": {
                                "cardNumber": "240",
                                "name": ["Andruw Monasterio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "241": {
                                "cardNumber": "241",
                                "name": ["Seattle Mariners™"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "242": {
                                "cardNumber": "242",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "243": {
                                "cardNumber": "243",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "244": {
                                "cardNumber": "244",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "245": {
                                "cardNumber": "245",
                                "name": ["Michael Wacha"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "246": {
                                "cardNumber": "246",
                                "name": ["Chris Taylor"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "247": {
                                "cardNumber": "247",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "248": {
                                "cardNumber": "248",
                                "name": ["Tyler Anderson"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "249": {
                                "cardNumber": "249",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "250": {
                                "cardNumber": "250",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "251": {
                                "cardNumber": "251",
                                "name": ["Tyler Wells"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "252": {
                                "cardNumber": "252",
                                "name": ["Miami Marlins®"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "253": {
                                "cardNumber": "253",
                                "name": ["Josh Lowe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "254": {
                                "cardNumber": "254",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "255": {
                                "cardNumber": "255",
                                "name": ["Andrew Vaughn"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "256": {
                                "cardNumber": "256",
                                "name": ["Harold Ramírez"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "257": {
                                "cardNumber": "257",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "258": {
                                "cardNumber": "258",
                                "name": ["Nathan Eovaldi"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "259": {
                                "cardNumber": "259",
                                "name": ["Justin Lawrence"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "260": {
                                "cardNumber": "260",
                                "name": ["Matt Olson", "Pete Alonso", "Kyle Schwarber"],
                                "team": ["Atlanta Braves™", "New York Mets®", "Philadelphia Phillies®"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "261": {
                                "cardNumber": "261",
                                "name": ["Jake Fraley"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "262": {
                                "cardNumber": "262",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "263": {
                                "cardNumber": "263",
                                "name": ["Jordan Balazovic"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "264": {
                                "cardNumber": "264",
                                "name": ["Clarke Schmidt"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "265": {
                                "cardNumber": "265",
                                "name": ["Alika Williams"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "266": {
                                "cardNumber": "266",
                                "name": ["Alec Bohm"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "267": {
                                "cardNumber": "267",
                                "name": ["Kenley Jansen"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "268": {
                                "cardNumber": "268",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "269": {
                                "cardNumber": "269",
                                "name": ["Cristian Javier"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "270": {
                                "cardNumber": "270",
                                "name": ["Victor Mederos"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "271": {
                                "cardNumber": "271",
                                "name": ["Paul Sewald"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "272": {
                                "cardNumber": "272",
                                "name": ["José Berríos"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "273": {
                                "cardNumber": "273",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "274": {
                                "cardNumber": "274",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "275": {
                                "cardNumber": "275",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Combo Card", "Checklist"]
                            },
                            "276": {
                                "cardNumber": "276",
                                "name": ["Gregory Santos"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "277": {
                                "cardNumber": "277",
                                "name": ["Matt Manning"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "278": {
                                "cardNumber": "278",
                                "name": ["Shea Langeliers"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "279": {
                                "cardNumber": "279",
                                "name": ["Angels®"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "280": {
                                "cardNumber": "280",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "281": {
                                "cardNumber": "281",
                                "name": ["Jared Triolo"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "282": {
                                "cardNumber": "282",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "283": {
                                "cardNumber": "283",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "284": {
                                "cardNumber": "284",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "285": {
                                "cardNumber": "285",
                                "name": ["Willi Castro"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "286": {
                                "cardNumber": "286",
                                "name": ["Cade Marlowe"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "287": {
                                "cardNumber": "287",
                                "name": ["Miles Mikolas"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "288": {
                                "cardNumber": "288",
                                "name": ["Boston Red Sox®"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "289": {
                                "cardNumber": "289",
                                "name": ["Andrés Giménez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "290": {
                                "cardNumber": "290",
                                "name": ["Thairo Estrada"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "291": {
                                "cardNumber": "291",
                                "name": ["Seth Lugo"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "292": {
                                "cardNumber": "292",
                                "name": ["Kodai Senga"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "293": {
                                "cardNumber": "293",
                                "name": ["Ozzie Albies", "Pete Alonso", "Matt Olson"],
                                "team": ["Atlanta Braves™", "New York Mets®", "Atlanta Braves™"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "294": {
                                "cardNumber": "294",
                                "name": ["Tarik Skubal"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "295": {
                                "cardNumber": "295",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "296": {
                                "cardNumber": "296",
                                "name": ["Willson Contreras"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "297": {
                                "cardNumber": "297",
                                "name": ["Alexis Díaz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "298": {
                                "cardNumber": "298",
                                "name": ["Logan O'Hoppe"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Future Stars"]
                            },
                            "299": {
                                "cardNumber": "299",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "300": {
                                "cardNumber": "300",
                                "name": ["Enmanuel Valdez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "301": {
                                "cardNumber": "301",
                                "name": ["New York Yankees®"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "302": {
                                "cardNumber": "302",
                                "name": ["Jake Rogers"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "303": {
                                "cardNumber": "303",
                                "name": ["Connor Joe"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "304": {
                                "cardNumber": "304",
                                "name": ["Evan Longoria"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "305": {
                                "cardNumber": "305",
                                "name": ["José Fermín"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "306": {
                                "cardNumber": "306",
                                "name": ["Andrew Benintendi"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "307": {
                                "cardNumber": "307",
                                "name": ["Austin Hays"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "308": {
                                "cardNumber": "308",
                                "name": ["Reese Olson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "309": {
                                "cardNumber": "309",
                                "name": ["Justin Steele", "Zac Gallen", "Spencer Strider"],
                                "team": ["Chicago Cubs®", "Arizona Diamondbacks®", "Atlanta Braves™"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "310": {
                                "cardNumber": "310",
                                "name": ["Abner Uribe"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "311": {
                                "cardNumber": "311",
                                "name": ["Will Brennan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "312": {
                                "cardNumber": "312",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "313": {
                                "cardNumber": "313",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "314": {
                                "cardNumber": "314",
                                "name": ["Max Muncy"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "315": {
                                "cardNumber": "315",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Combo Card", "Checklist"]
                            },
                            "316": {
                                "cardNumber": "316",
                                "name": ["Kutter Crawford"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "317": {
                                "cardNumber": "317",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "318": {
                                "cardNumber": "318",
                                "name": ["Justin Steele"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "319": {
                                "cardNumber": "319",
                                "name": ["Miguel Amaya"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "320": {
                                "cardNumber": "320",
                                "name": ["Daniel Bard"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "321": {
                                "cardNumber": "321",
                                "name": ["Trey Cabbage"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "322": {
                                "cardNumber": "322",
                                "name": ["Brendan Rodgers"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "323": {
                                "cardNumber": "323",
                                "name": ["Braxton Garrett"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "324": {
                                "cardNumber": "324",
                                "name": ["Sonny Gray", "Gerrit Cole", "Kyle Bradish"],
                                "team": ["Minnesota Twins®", "New York Yankees®", "Baltimore Orioles®"],
                                "cardAttributes": ["League Leaders"]
                            },
                            "325": {
                                "cardNumber": "325",
                                "name": ["Justin Martinez"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "326": {
                                "cardNumber": "326",
                                "name": ["Mauricio Dubón"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "327": {
                                "cardNumber": "327",
                                "name": ["Matt McLain"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "328": {
                                "cardNumber": "328",
                                "name": ["Patrick Wisdom"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "329": {
                                "cardNumber": "329",
                                "name": ["Framber Valdez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Combo Card", "Checklist"]
                            },
                            "330": {
                                "cardNumber": "330",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "331": {
                                "cardNumber": "331",
                                "name": ["Ryan Pressly"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "332": {
                                "cardNumber": "332",
                                "name": ["Taylor Ward"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "333": {
                                "cardNumber": "333",
                                "name": ["Jo Adell"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "334": {
                                "cardNumber": "334",
                                "name": ["Ryne Nelson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "335": {
                                "cardNumber": "335",
                                "name": ["Ryan O'Hearn"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "336": {
                                "cardNumber": "336",
                                "name": ["Dean Kremer"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "337": {
                                "cardNumber": "337",
                                "name": ["Hayden Wesneski"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "338": {
                                "cardNumber": "338",
                                "name": ["Nick Senzel"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "339": {
                                "cardNumber": "339",
                                "name": ["Logan Allen"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "340": {
                                "cardNumber": "340",
                                "name": ["Austin Gomber"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "341": {
                                "cardNumber": "341",
                                "name": ["Elehuris Montero"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "342": {
                                "cardNumber": "342",
                                "name": ["Maikel Garcia"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "343": {
                                "cardNumber": "343",
                                "name": ["Rowdy Tellez"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "344": {
                                "cardNumber": "344",
                                "name": ["Ryan Jeffers"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "345": {
                                "cardNumber": "345",
                                "name": ["Daniel Vogelbach"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "346": {
                                "cardNumber": "346",
                                "name": ["Patrick Bailey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "347": {
                                "cardNumber": "347",
                                "name": ["Manuel Margot"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "348": {
                                "cardNumber": "348",
                                "name": ["Dane Dunning"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "349": {
                                "cardNumber": "349",
                                "name": ["Danny Jansen"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "350": {
                                "cardNumber": "350",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        'Golden Mirror Image Variation': {
                            "1": {
                                "cardNumber": "1",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2": {
                                "cardNumber": "2",
                                "name": ["San Diego Padres™"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Team Card"]
                            },
                            "3": {
                                "cardNumber": "3",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "4": {
                                "cardNumber": "4",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "5": {
                                "cardNumber": "5",
                                "name": ["Owen White"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "6": {
                                "cardNumber": "6",
                                "name": ["Detroit Tigers®"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Team Card"]
                            },
                            "7": {
                                "cardNumber": "7",
                                "name": ["Brendan Donovan"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "8": {
                                "cardNumber": "8",
                                "name": ["Brendan White"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "9": {
                                "cardNumber": "9",
                                "name": ["Joey Wiemer"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "10": {
                                "cardNumber": "10",
                                "name": ["Josh Naylor"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            }
                        },
                        'Team Color Border Variation': {
                            "1": {
                                "cardNumber": "1",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "3": {
                                "cardNumber": "3",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "4": {
                                "cardNumber": "4",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "5": {
                                "cardNumber": "5",
                                "name": ["Owen White"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "7": {
                                "cardNumber": "7",
                                "name": ["Brendan Donovan"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "8": {
                                "cardNumber": "8",
                                "name": ["Brendan White"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "9": {
                                "cardNumber": "9",
                                "name": ["Joey Wiemer"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "10": {
                                "cardNumber": "10",
                                "name": ["Josh Naylor"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "11": {
                                "cardNumber": "11",
                                "name": ["Spencer Strider"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "12": {
                                "cardNumber": "12",
                                "name": ["Bryan Woo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            }
                        }
                    },
                    'Insert': {
                        'Superstar Blueprint': {
                            "SB-1": {
                                "cardNumber": "SB-1",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SB-2": {
                                "cardNumber": "SB-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SB-3": {
                                "cardNumber": "SB-3",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SB-4": {
                                "cardNumber": "SB-4",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SB-5": {
                                "cardNumber": "SB-5",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "SB-6": {
                                "cardNumber": "SB-6",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "SB-7": {
                                "cardNumber": "SB-7",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "SB-8": {
                                "cardNumber": "SB-8",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SB-9": {
                                "cardNumber": "SB-9",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "SB-10": {
                                "cardNumber": "SB-10",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            }
                        },
                        'Grand Gamers': {
                            "GOG-1": {
                                "cardNumber": "GOG-1",
                                "name": ["Nolan Ryan"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "GOG-2": {
                                "cardNumber": "GOG-2",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "GOG-3": {
                                "cardNumber": "GOG-3",
                                "name": ["Willie Mays"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "GOG-4": {
                                "cardNumber": "GOG-4",
                                "name": ["Carl Yastrzemski"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "GOG-5": {
                                "cardNumber": "GOG-5",
                                "name": ["Mike Schmidt"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "GOG-6": {
                                "cardNumber": "GOG-6",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "GOG-7": {
                                "cardNumber": "GOG-7",
                                "name": ["Greg Maddux"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "GOG-8": {
                                "cardNumber": "GOG-8",
                                "name": ["Frank Thomas"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "GOG-9": {
                                "cardNumber": "GOG-9",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "GOG-10": {
                                "cardNumber": "GOG-10",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            }
                        },
                        '2023 Greatest Hits': {
                            "23GH-1": {
                                "cardNumber": "23GH-1",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "23GH-2": {
                                "cardNumber": "23GH-2",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "23GH-3": {
                                "cardNumber": "23GH-3",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "23GH-4": {
                                "cardNumber": "23GH-4",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "23GH-5": {
                                "cardNumber": "23GH-5",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "23GH-6": {
                                "cardNumber": "23GH-6",
                                "name": ["Luis Arraez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "23GH-7": {
                                "cardNumber": "23GH-7",
                                "name": ["Chas McCormick"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "23GH-8": {
                                "cardNumber": "23GH-8",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "23GH-9": {
                                "cardNumber": "23GH-9",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "23GH-10": {
                                "cardNumber": "23GH-10",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    'Relic': {
                        'Major League Material': {
                            "MLM-AB": {
                                "cardNumber": "MLM-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLM-AJ": {
                                "cardNumber": "MLM-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-AN": {
                                "cardNumber": "MLM-AN",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLM-AR": {
                                "cardNumber": "MLM-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-ARI": {
                                "cardNumber": "MLM-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLM-AV": {
                                "cardNumber": "MLM-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-BC": {
                                "cardNumber": "MLM-BC",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLM-BH": {
                                "cardNumber": "MLM-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLM-BR": {
                                "cardNumber": "MLM-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLM-BW": {
                                "cardNumber": "MLM-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            }
                        },
                        '1989 Topps Baseball Relic': {
                            "89BR-AB": {
                                "cardNumber": "89BR-AB",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "89BR-ABR": {
                                "cardNumber": "89BR-ABR",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89BR-AJ": {
                                "cardNumber": "89BR-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BR-AM": {
                                "cardNumber": "89BR-AM",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "89BR-AN": {
                                "cardNumber": "89BR-AN",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89BR-APE": {
                                "cardNumber": "89BR-APE",
                                "name": ["Andy Pettitte"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BR-APU": {
                                "cardNumber": "89BR-APU",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89BR-AR": {
                                "cardNumber": "89BR-AR",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BR-ARU": {
                                "cardNumber": "89BR-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89BR-ARZ": {
                                "cardNumber": "89BR-ARZ",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        'City Connect Swatch Collection': {
                            "CC-AB": {
                                "cardNumber": "CC-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CC-AS": {
                                "cardNumber": "CC-AS",
                                "name": ["Anthony Santander"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CC-BC": {
                                "cardNumber": "CC-BC",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "CC-CBL": {
                                "cardNumber": "CC-CBL",
                                "name": ["Charlie Blackmon"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC-CY": {
                                "cardNumber": "CC-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "CC-EM": {
                                "cardNumber": "CC-EM",
                                "name": ["Elehuris Montero"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC-EP": {
                                "cardNumber": "CC-EP",
                                "name": ["Eury Pérez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "CC-ET": {
                                "cardNumber": "CC-ET",
                                "name": ["Ezequiel Tovar"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CC-GH": {
                                "cardNumber": "CC-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CC-GM": {
                                "cardNumber": "CC-GM",
                                "name": ["Garrett Mitchell"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            }
                        },
                        "Super Box Enamel Pin": {
                            "1 of 10": {
                                "cardNumber": "1 of 10",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "2 of 10": {
                                "cardNumber": "2 of 10",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "3 of 10": {
                                "cardNumber": "3 of 10",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "4 of 10": {
                                "cardNumber": "4 of 10",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "5 of 10": {
                                "cardNumber": "5 of 10",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "6 of 10": {
                                "cardNumber": "6 of 10",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "7 of 10": {
                                "cardNumber": "7 of 10",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "8 of 10": {
                                "cardNumber": "8 of 10",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "9 of 10": {
                                "cardNumber": "9 of 10",
                                "name": ["Adley Rutschman"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "10 of 10": {
                                "cardNumber": "10 of 10",
                                "name": ["Juan Soto"],
                                "team": [],
                                "cardAttributes": []
                            }
                        }
                    },
                    'Autograph Relic': {
                        'Topps Reverence Autograph Patch': {
                            "TRAP-AB": {
                                "cardNumber": "TRAP-AB",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "TRAP-AJ": {
                                "cardNumber": "TRAP-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP-AR": {
                                "cardNumber": "TRAP-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP-ARI": {
                                "cardNumber": "TRAP-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAP-ARU": {
                                "cardNumber": "TRAP-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TRAP-AV": {
                                "cardNumber": "TRAP-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP-BB": {
                                "cardNumber": "TRAP-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "TRAP-BH": {
                                "cardNumber": "TRAP-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TRAP-CB": {
                                "cardNumber": "TRAP-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "TRAP-CBI": {
                                "cardNumber": "TRAP-CBI",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        'Major League Material Autograph': {
                            "MLMA-AG": {
                                "cardNumber": "MLMA-AG",
                                "name": ["Adolis García"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MLMA-AN": {
                                "cardNumber": "MLMA-AN",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLMA-AR": {
                                "cardNumber": "MLMA-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMA-ARU": {
                                "cardNumber": "MLMA-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MLMA-BB": {
                                "cardNumber": "MLMA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLMA-BS": {
                                "cardNumber": "MLMA-BS",
                                "name": ["Blake Snell"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLMA-BWI": {
                                "cardNumber": "MLMA-BWI",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MLMA-CB": {
                                "cardNumber": "MLMA-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "MLMA-CM": {
                                "cardNumber": "MLMA-CM",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "MLMA-CS": {
                                "cardNumber": "MLMA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            }
                        },
                        'Heavy Lumber Autograph': {
                            "HLA-AJ": {
                                "cardNumber": "HLA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HLA-BB": {
                                "cardNumber": "HLA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "HLA-JC": {
                                "cardNumber": "HLA-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "HLA-JS": {
                                "cardNumber": "HLA-JS",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "HLA-MT": {
                                "cardNumber": "HLA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "HLA-PG": {
                                "cardNumber": "HLA-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HLA-RA": {
                                "cardNumber": "HLA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HLA-SO": {
                                "cardNumber": "HLA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "HLA-YA": {
                                "cardNumber": "HLA-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    'Autograph': {
                        'Baseball Stars Autograph': {
                            "BSA-AA": {
                                "cardNumber": "BSA-AA",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-AK": {
                                "cardNumber": "BSA-AK",
                                "name": ["Alex Kirilloff"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSA-AS": {
                                "cardNumber": "BSA-AS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-AT": {
                                "cardNumber": "BSA-AT",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BSA-BA": {
                                "cardNumber": "BSA-BA",
                                "name": ["Bryan Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSA-BAS": {
                                "cardNumber": "BSA-BAS",
                                "name": ["Osleivis Basabe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "BSA-BB": {
                                "cardNumber": "BSA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSA-BD": {
                                "cardNumber": "BSA-BD",
                                "name": ["Brendan Donovan"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSA-BDO": {
                                "cardNumber": "BSA-BDO",
                                "name": ["Brenton Doyle"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "BSA-BE": {
                                "cardNumber": "BSA-BE",
                                "name": ["Bryce Elder"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        'Baseball Stars Dual Autograph': {
                            "BSDA-BAB": {
                                "cardNumber": "BSDA-BAB",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSDA-BAB-2": {
                                "cardNumber": "BSDA-BAB",
                                "name": ["Jeff Bagwell"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSDA-BEV": {
                                "cardNumber": "BSDA-BEV",
                                "name": ["Johnny Bench"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BSDA-BEV-2": {
                                "cardNumber": "BSDA-BEV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BSDA-BOC": {
                                "cardNumber": "BSDA-BOC",
                                "name": ["Wade Boggs"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA-BOC-2": {
                                "cardNumber": "BSDA-BOC",
                                "name": ["Roger Clemens"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA-BRJ": {
                                "cardNumber": "BSDA-BRJ",
                                "name": ["Bo Jackson"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSDA-BRJ-2": {
                                "cardNumber": "BSDA-BRJ",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSDA-CLP": {
                                "cardNumber": "BSDA-CLP",
                                "name": ["Will Clark"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BSDA-CLP-2": {
                                "cardNumber": "BSDA-CLP",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            }
                        },
                        '1989 Topps Baseball Autograph': {
                            "89BA-AD": {
                                "cardNumber": "89BA-AD",
                                "name": ["Andre Dawson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89BA-AJ": {
                                "cardNumber": "89BA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BA-AJO": {
                                "cardNumber": "89BA-AJO",
                                "name": ["Andruw Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BA-AR": {
                                "cardNumber": "89BA-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BA-ARU": {
                                "cardNumber": "89BA-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89BA-AV": {
                                "cardNumber": "89BA-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BA-BBX": {
                                "cardNumber": "89BA-BBX",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "89BA-BH": {
                                "cardNumber": "89BA-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89BA-BJ": {
                                "cardNumber": "89BA-BJ",
                                "name": ["Bo Jackson"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89BA-BLA": {
                                "cardNumber": "89BA-BLA",
                                "name": ["Barry Larkin"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            }
                        }
                    }
                }
            }
        }
    }
};

export const MLB_2024_TOPPS_SERIES1_PARALLELS = {
    "MLB": {
        "2024": {
            "Topps": {
                "series 1": {
                    'Base Card Set': {
                        'Base': [
                            ["AQUA", "#00FFFF"],
                            ["GOLD FOIL", "#FFD700"],
                            ["EGGS", "#FFD700"],
                            ["PURPLE", "#800080"],
                            ["RAINBOW FOIL", "#9A9A9A"],
                            ["RABBIT", "#8B4513"],
                            ["ROYAL BLUE", "#4169E1"],
                            ["SILVER CRACKLE FOIL BOARD", "#C0C0C0"],
                            ["TRUE PHOTO", "#D3D3D3"],
                            ["YELLOW", "#EBD834"],
                            ["YELLOW FOIL", "#EBD834"],
                            ["GOLD", "#FFD700", 2024],
                            ["BLUE HOLOFOIL", "#1E90FF", 999],
                            ["PURPLE HOLOFOIL", "#800080", 799],
                            ["GREEN CRACKLE FOIL BOARD", "#008000", 499],
                            ["ORANGE CRACKLE FOIL BOARD", "#FFA500", 299],
                            ["RED CRACKLE FOIL BOARD", "#FF0000", 199],
                            ["VINTAGE STOCK", "#DEB887", 99],
                            ["INDEPENDENCE DAY", "#B22222", 76],
                            ["BLACK", "#000000", 73],
                            ["KEVIN HART", "#FF0000", 52],
                            ["MOTHER'S DAY HOT PINK", "#FF69B4", 50],
                            ["FATHER'S DAY POWDER BLUE", "#B0E0E6", 50],
                            ["FLOWERS", "#8B4513", 50],
                            ["YELLOW CRACKLE FOIL BOARD", "#EBD834", 50],
                            ["MEMORIAL DAY CAMO", "#4B5320", 25],
                            ["CLEAR", "transparent", 10],
                            ["UMBRELLA", "#B0C4DE", 10],
                            ["WATERING CAN", "#4682B4", 5],
                            ["BIRDS", "#87CEEB", 1],
                            ["FIRST CARD", "#FFFFFF", 1],
                            ["PLATINUM", "#E5E4E2", 1],
                            ["PRINTING PLATES", "#808080", 1] // R, G, B variations
                        ]
                    },
                    'Autograph': {
                        '1989 Topps Baseball Autograph': [
                            ["BLACK", "#000000", 199],
                            ["GOLD", "#FFD700", 50],
                            ["RED", "#FF0000", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        "2023 All Topps Team Autograph": [
                            ['/25', "#C0C0C0", 25]
                        ],
                        "2023 Greatest Hits Autograph": [
                            ['/25', "#C0C0C0", 25]
                        ],
                        "Baseball Stars Autograph": [
                            ["BLACK", "#000000", 199],
                            ["GOLD", "#FFD700", 50],
                            ["RED", "#FF0000", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        "Baseball Stars Dual Autograph": [
                            ['/10', "#C0C0C0", 10]
                        ],
                        "Grand Gamers Autograph": [
                            ['/25', "#C0C0C0", 25]
                        ],
                        "Postseason Performance Autograph": [
                            ['/50', "#C0C0C0", 50],
                            ["GOLD", "#FFD700", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        "Superstar Blueprint Autograph": [
                            ['/25', "#C0C0C0", 25]
                        ],
                        "World Champion Autograph": [
                            ['/50', "#C0C0C0", 50],
                            ["GOLD", "#FFD700", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ]
                    },
                    'Autograph Relic': {
                        'Autographed Home Sweet Home Stadium Medallion': [
                            ['/25', "#C0C0C0", 25],
                            ["RED", "#FF0000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Major League Material Autograph': [
                            ['/50', "#C0C0C0", 50],
                            ["RED", "#FF0000", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Postseason Performance Autograph Relic': [
                            ['/50', "#C0C0C0", 50],
                            ["GOLD", "#FFD700", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Topps Reverence Autograph Patch': [
                            ["RED", "#FF0000", 5],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'World Champion Autograph Relic': [
                            ["GOLD", "#FFD700", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ]
                    },
                    'Relic': {
                        '1989 Topps Baseball Relic': [
                            ["BLACK", "#000000", 199],
                            ["GOLD", "#FFD700", 50],
                            ["RED", "#FF0000", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'City Connect Swatch Collection': [
                            ["BLACK", "#000000", 199],
                            ["GOLD", "#FFD700", 50],
                            ["RED", "#FF0000", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Major League Material': [
                            ["BLACK", "#000000", 199],
                            ["GOLD", "#FFD700", 50],
                            ["RED", "#FF0000", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Postseason Performance Relic Card': [
                            ['/99', "#C0C0C0", 99],
                            ["GOLD", "#FFD700", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'World Champion Relic Card': [
                            ['/99', "#C0C0C0", 99],
                            ["GOLD", "#FFD700", 25],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Home Sweet Home Stadium Medallion': [
                            ["/299", "#C0C0C0", 299],
                            ["GOLD", "#FFD700", 75],
                            ["RED", "#FF0000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ]
                    },
                    'Insert': {
                        '1989 Topps Baseball': [
                            ["BLUE", "#1E90FF"],
                            ["BLACK", "#000000", 299],
                            ['THE REAL ONE', "#C0C0C0", 89],
                            ["GOLD", "#FFD700", 75],
                            ["RED", "#FF0000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        '1989 Topps Baseball Foil Mega Box': [
                            ["GOLD", "#FFD700", 50],
                            ["ORANGE", "#FFA500", 25],
                            ["RED", "#FF0000", 5],
                            ["BLACK", "#000000", 1]
                        ],
                        '2023 All Topps Team': [
                            ["BLUE", "#1E90FF"],
                            ["BLACK", "#000000", 299],
                            ["GOLD", "#FFD700", 75],
                            ["RED", "#FF0000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        '2023 Greatest Hits': [
                            ["BLUE", "#1E90FF"],
                            ["BLACK", "#000000", 299],
                            ["GOLD", "#FFD700", 75],
                            ["RED", "#FF0000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Grand Gamers': [
                            ["BLUE", "#1E90FF"],
                            ["BLACK", "#000000", 299],
                            ["GOLD", "#FFD700", 75],
                            ["RED", "#FF0000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Stars of MLB': [
                            ["RED", "#FF0000", 70],
                            ["BLACK", "#000000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Stars of MLB Chrome': [
                            ["RED REFRACTOR", "#FF0000", 70],
                            ["BLACK REFRACTOR", "#000000", 10],
                            ["SUPERFRACTOR", "#E5E4E2", 1]
                        ],
                        'Superstar Blueprint': [
                            ["BLUE", "#1E90FF"],
                            ["BLACK", "#000000", 299],
                            ["GOLD", "#FFD700", 75],
                            ["RED", "#FF0000", 10],
                            ["PLATINUM", "#E5E4E2", 1]
                        ],
                        'Topps Mega Stars': [
                            ["GREEN FOIL", "#008000", 99],
                            ["PURPLE FOIL", "#800080", 75],
                            ["GOLD FOIL", "#FFD700", 50],
                            ["ORANGE FOIL", "#FFA500", 25],
                            ["RED FOIL", "#FF0000", 5],
                            ["FOILFRACTOR", "#E5E4E2", 1]
                        ]
                    }
                }
            }
        }
    }

}