import styled from "styled-components";

export const PrimaryBtnContainer = styled.button`
    background: rgba(255,255,255,1.0);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    height: 32px;
    width: 100%;

    &:hover {
        background: rgba(220,220,220,1.0);
        filter: drop-shadow(2px 2px rgba(0,0,0,0.15));
    }
`