import { useNavigate } from 'react-router-dom';

// styles
import {
    CardDisplayContainer,
    LeftCardContent,
    CardNumber,
    CardDetails,
    CardHeader,
    AttributesContainer,
    AddBtn
} from './CardDisplay.styles';

// components
import CardAttribute from '../../../../../../components/card-attribute/CardAttribute';

export default function CardDisplay({ wasRemoved, card, path = null }) {
    const navigate = useNavigate();

    console.log("card: ", card)

    const handleNavigateToCard = () => {
        navigate(path);
    }

    return (
        <CardDisplayContainer>
            <LeftCardContent>
                <CardNumber>
                    <h4>{card.cardNumber}</h4>
                </CardNumber>
                <CardDetails>
                    <CardHeader>
                        {card.name.map((name, index) => (
                            <span key={index}>
                                <h3 style={{ display: 'inline' }}>{name}</h3>
                                {index < card.name.length - 1 && ' • '}
                            </span>
                        ))}
                    </CardHeader>
                    <AttributesContainer>
                        {card.cardAttributes && card.cardAttributes.length !== 0 &&
                            <div className='card-attributes-container'>
                                {card.cardAttributes.map(attribute => (
                                    <CardAttribute key={attribute} text={attribute} />
                                ))}
                            </div>
                        }
                        {card.cardAttributes && card.cardAttributes.length !== 0 && card.collectedCards && card.collectedCards.length !== 0 &&
                            <h4>•</h4>
                        }
                        {card.collectedCards &&
                            <div className='card-attributes-container'>
                                {card.collectedCards.map(parallel => (
                                    <CardAttribute
                                        key={parallel.cardParallelName}
                                        color={parallel.cardColor}
                                        text={`${parallel.cardParallelName} ${((parallel.cardPrintNumber !== null) && (parallel.cardSupply !== null)) ? `(${parallel.cardPrintNumber}/${parallel.cardSupply})` : ''} (QTY: ${parallel.cardQty})`} />
                                ))}
                            </div>
                        }
                    </AttributesContainer>
                </CardDetails>
            </LeftCardContent>
            <AddBtn $isActive={true} onClick={handleNavigateToCard}>
                {wasRemoved ?
                    <h4>VIEW IN CHECKLIST</h4>
                    :
                    <h4>VIEW IN COLLECTION</h4>
                }
            </AddBtn>
        </CardDisplayContainer>
    )
}
