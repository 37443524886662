import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from 'framer-motion';
import { selectDisplayedGradient, selectGradientA, selectGradientB, selectGradientData } from '../../store/system/system.selector.js';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils.js";

// styles
import {
    GradientBGContainer,
    DynamicGradientDiv
} from "./GradientBG.styles.js";

import { setTeamIndex } from "../../store/user/user.action.js";
import { selectUser } from "../../store/user/user.selector.js";
import { setSystemNotification } from "../../store/system/system.action.js";

export default function GradientBG({ setGradient = null }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const displayedGradient = useSelector(selectDisplayedGradient);
    const gradientA = useSelector(selectGradientA);
    const gradientB = useSelector(selectGradientB);
    const gradientData = useSelector(selectGradientData);

    // FETCH teamIndex
    useEffect(() => {
        if (!user) return;

        const userDocRef = doc(db, `users/${user.uid}`);

        const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
            if (docSnap.exists()) {
                const { teamIndex } = docSnap.data();
                dispatch(setSystemNotification("GradientBG: FETCHING teamIndex: ", teamIndex));
                console.log("GradientBG: FETCHING teamIndex: ", teamIndex);

                if (teamIndex !== undefined && teamIndex !== null) {
                    dispatch(setTeamIndex(teamIndex));
                } else {
                    dispatch(setSystemNotification("GradientBG: teamIndex is undefined or null"));
                }

            } else {
                dispatch(setSystemNotification("GradientBG: Could not fetch teamIndex, document does not exist"));
            }
        }, (error) => {
            dispatch(setSystemNotification("GradientBG: Error fetching teamIndex ->", error));
        });

        return () => unsubscribe();
    }, [user, dispatch]);

    return (
        <>
            {setGradient === null ?
                <>
                    {gradientA && gradientB &&
                        <GradientBGContainer $bg={displayedGradient === 'A' ? gradientData[gradientA] : gradientData[gradientB]} >
                            <motion.div
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: displayedGradient === 'A' ? 1 : 0 }}
                                transition={{ duration: 1.0 }}
                            >
                                <DynamicGradientDiv $gradient={gradientData[gradientA]} />
                            </motion.div>
                            <motion.div
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: displayedGradient === 'B' ? 1 : 0 }}
                                transition={{ duration: 1.0 }}
                            >
                                <DynamicGradientDiv $gradient={gradientData[gradientB]} />
                            </motion.div>
                        </GradientBGContainer>
                    }
                </>
                :
                <GradientBGContainer $bg={setGradient} >
                    <motion.div
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: displayedGradient === 'A' ? 1 : 0 }}
                        transition={{ duration: 1.0 }}
                    >
                        <DynamicGradientDiv $gradient={setGradient} />
                    </motion.div>
                    <motion.div
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: displayedGradient === 'B' ? 1 : 0 }}
                        transition={{ duration: 1.0 }}
                    >
                        <DynamicGradientDiv $gradient={setGradient} />
                    </motion.div>
                </GradientBGContainer>
            }
        </>
    );
}