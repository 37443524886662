import { useState } from 'react';
import { useDispatch } from 'react-redux';

// firebase
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebase/firebase.utils';

// actions
import { setUser } from '../store/user/user.action';

export const useSignIn = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const signIn = async (email, password) => {
        setError(null)
        setIsPending(true)

        try {
            const res = await signInWithEmailAndPassword(auth, email, password)

            const user = res.user;
            const userData = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
            };

            dispatch(setUser(userData))

            setError(null)
            setIsPending(false)
        } catch (err) {
            setError(err.message)
            setIsPending(false)
        }
    }

    return { error, isPending, signIn }
}