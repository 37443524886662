// CHECKLIST: COMPLETE
// PARALLELS: COMPLETE

export const MLB_2022_TOPPS_US = {
    "MLB": {
        "2022": {
            "Topps": {
                "Update Series": {
                    "Base Card Set": {
                        "Base": {
                            "US1": {
                                "cardNumber": "US1",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US2": {
                                "cardNumber": "US2",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US3": {
                                "cardNumber": "US3",
                                "name": ["Jesse Winker"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US4": {
                                "cardNumber": "US4",
                                "name": ["Freddie Freeman", "Kris Bryant"],
                                "team": ["Los Angeles Dodgers®", "Colorado Rockies™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US5": {
                                "cardNumber": "US5",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US6": {
                                "cardNumber": "US6",
                                "name": ["Josh Winder"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US7": {
                                "cardNumber": "US7",
                                "name": ["Dustin May"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US8": {
                                "cardNumber": "US8",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US9": {
                                "cardNumber": "US9",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US10": {
                                "cardNumber": "US10",
                                "name": ["Jose Trevino"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US11": {
                                "cardNumber": "US11",
                                "name": ["Rafael Devers", "Enrique Hernandez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US12": {
                                "cardNumber": "US12",
                                "name": ["Jace Peterson"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US13": {
                                "cardNumber": "US13",
                                "name": ["Reese McGuire"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US14": {
                                "cardNumber": "US14",
                                "name": ["Brad Hand"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US15": {
                                "cardNumber": "US15",
                                "name": ["Corey Dickerson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US16": {
                                "cardNumber": "US16",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US17": {
                                "cardNumber": "US17",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US18": {
                                "cardNumber": "US18",
                                "name": ["Dinelson Lamet"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US19": {
                                "cardNumber": "US19",
                                "name": ["Simon Muzziotti"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US20": {
                                "cardNumber": "US20",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US21": {
                                "cardNumber": "US21",
                                "name": ["Tyler Mahle"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US22": {
                                "cardNumber": "US22",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US23": {
                                "cardNumber": "US23",
                                "name": ["Beau Brieske"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US24": {
                                "cardNumber": "US24",
                                "name": ["Luke Raley"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US25": {
                                "cardNumber": "US25",
                                "name": ["Dillon Tate"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US26": {
                                "cardNumber": "US26",
                                "name": ["Tommy Pham"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US27": {
                                "cardNumber": "US27",
                                "name": ["Randal Grichuk"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US28": {
                                "cardNumber": "US28",
                                "name": ["Colin Moran"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US29": {
                                "cardNumber": "US29",
                                "name": ["Alex De Goti"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US30": {
                                "cardNumber": "US30",
                                "name": ["Camilo Doval"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US31": {
                                "cardNumber": "US31",
                                "name": ["Jose Cisnero"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US32": {
                                "cardNumber": "US32",
                                "name": ["Nick Pivetta"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US33": {
                                "cardNumber": "US33",
                                "name": ["Jose Quintana"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US34": {
                                "cardNumber": "US34",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US35": {
                                "cardNumber": "US35",
                                "name": ["Brandon Marsh"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US36": {
                                "cardNumber": "US36",
                                "name": ["Luke Voit"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US37": {
                                "cardNumber": "US37",
                                "name": ["Corey Kluber"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US38": {
                                "cardNumber": "US38",
                                "name": ["Lucius Fox"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US39": {
                                "cardNumber": "US39",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US40": {
                                "cardNumber": "US40",
                                "name": ["Jhoulys Chacín"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US41": {
                                "cardNumber": "US41",
                                "name": ["Gosuke Katoh"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US42": {
                                "cardNumber": "US42",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US43": {
                                "cardNumber": "US43",
                                "name": ["Jake Fraley"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US44": {
                                "cardNumber": "US44",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US45": {
                                "cardNumber": "US45",
                                "name": ["Aaron Judge", "Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US46": {
                                "cardNumber": "US46",
                                "name": ["Yoshi Tsutsugo"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US47": {
                                "cardNumber": "US47",
                                "name": ["Will Smith"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US48": {
                                "cardNumber": "US48",
                                "name": ["Sean Manaea"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US49": {
                                "cardNumber": "US49",
                                "name": ["Aaron Civale"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US50": {
                                "cardNumber": "US50",
                                "name": ["Marcus Stroman"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US51": {
                                "cardNumber": "US51",
                                "name": ["Erick Fedde"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US52": {
                                "cardNumber": "US52",
                                "name": ["Bryce Elder"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US53": {
                                "cardNumber": "US53",
                                "name": ["Josh Staumont"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US54": {
                                "cardNumber": "US54",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US55": {
                                "cardNumber": "US55",
                                "name": ["Joe Smith"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US56": {
                                "cardNumber": "US56",
                                "name": ["Jonathan Loaisiga"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US57": {
                                "cardNumber": "US57",
                                "name": ["Jake Lamb"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US58": {
                                "cardNumber": "US58",
                                "name": ["Hunter Renfroe"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US59": {
                                "cardNumber": "US59",
                                "name": ["Andre Pallante"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US60": {
                                "cardNumber": "US60",
                                "name": ["Adam Frazier"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US61": {
                                "cardNumber": "US61",
                                "name": ["Brad Boxberger"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US62": {
                                "cardNumber": "US62",
                                "name": ["Reid Detmers"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US63": {
                                "cardNumber": "US63",
                                "name": ["Jake McGee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US64": {
                                "cardNumber": "US64",
                                "name": ["Alex Faedo"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US65": {
                                "cardNumber": "US65",
                                "name": ["Jason Krizan"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US66": {
                                "cardNumber": "US66",
                                "name": ["Kyle Bradish"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US67": {
                                "cardNumber": "US67",
                                "name": ["Harold Ramirez"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US68": {
                                "cardNumber": "US68",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US69": {
                                "cardNumber": "US69",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US70": {
                                "cardNumber": "US70",
                                "name": ["Rylan Bannon"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US71": {
                                "cardNumber": "US71",
                                "name": ["Chad Kuhl"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US72": {
                                "cardNumber": "US72",
                                "name": ["Derek Hill"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US73": {
                                "cardNumber": "US73",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US74": {
                                "cardNumber": "US74",
                                "name": ["Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US75": {
                                "cardNumber": "US75",
                                "name": ["Chris Paddack"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US76": {
                                "cardNumber": "US76",
                                "name": ["Tommy Romero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US77": {
                                "cardNumber": "US77",
                                "name": ["Adrian Martinez"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US78": {
                                "cardNumber": "US78",
                                "name": ["Tanner Tully", "Kirk McCarty"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US79": {
                                "cardNumber": "US79",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US80": {
                                "cardNumber": "US80",
                                "name": ["Tyler Naquin"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US81": {
                                "cardNumber": "US81",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US82": {
                                "cardNumber": "US82",
                                "name": ["Taylor Hearn"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US83": {
                                "cardNumber": "US83",
                                "name": ["Manny Machado", "Jurickson Profar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US84": {
                                "cardNumber": "US84",
                                "name": ["Michael Lorenzen"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US85": {
                                "cardNumber": "US85",
                                "name": ["Diego Castillo"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US86": {
                                "cardNumber": "US86",
                                "name": ["Carlos Rodón"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US87": {
                                "cardNumber": "US87",
                                "name": ["Andrelton Simmons"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US88": {
                                "cardNumber": "US88",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US89": {
                                "cardNumber": "US89",
                                "name": ["Javier Báez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US90": {
                                "cardNumber": "US90",
                                "name": ["Mike Brosseau"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US91": {
                                "cardNumber": "US91",
                                "name": ["Brad Miller"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US92": {
                                "cardNumber": "US92",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US93": {
                                "cardNumber": "US93",
                                "name": ["Bradley Zimmer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US94": {
                                "cardNumber": "US94",
                                "name": ["Curt Casali"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US95": {
                                "cardNumber": "US95",
                                "name": ["Danny Mendick"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US96": {
                                "cardNumber": "US96",
                                "name": ["Kyle Cody"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US97": {
                                "cardNumber": "US97",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US98": {
                                "cardNumber": "US98",
                                "name": ["Archie Bradley"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US99": {
                                "cardNumber": "US99",
                                "name": ["Tyrone Taylor"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US101": {
                                "cardNumber": "US101",
                                "name": ["Albert Abreu"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US102": {
                                "cardNumber": "US102",
                                "name": ["Craig Kimbrel"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US103": {
                                "cardNumber": "US103",
                                "name": ["Cole Sulser"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US104": {
                                "cardNumber": "US104",
                                "name": ["Luke Williams"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US105": {
                                "cardNumber": "US105",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US106": {
                                "cardNumber": "US106",
                                "name": ["Darin Ruf"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US107": {
                                "cardNumber": "US107",
                                "name": ["Luis Guillorme"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US108": {
                                "cardNumber": "US108",
                                "name": ["Taylor Walls"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US109": {
                                "cardNumber": "US109",
                                "name": ["Ryan Pepiot"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US110": {
                                "cardNumber": "US110",
                                "name": ["Elehuris Montero"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US111": {
                                "cardNumber": "US111",
                                "name": ["Matt Duffy"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US112": {
                                "cardNumber": "US112",
                                "name": ["Rhys Hoskins", "Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US113": {
                                "cardNumber": "US113",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US114": {
                                "cardNumber": "US114",
                                "name": ["Rowdy Tellez"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US115": {
                                "cardNumber": "US115",
                                "name": ["Shohei Ohtani", "Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US116": {
                                "cardNumber": "US116",
                                "name": ["Jeurys Familia"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US117": {
                                "cardNumber": "US117",
                                "name": ["Nick Allen"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US118": {
                                "cardNumber": "US118",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US119": {
                                "cardNumber": "US119",
                                "name": ["Isiah Kiner-Falefa"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US120": {
                                "cardNumber": "US120",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US121": {
                                "cardNumber": "US121",
                                "name": ["Eric Thames"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US122": {
                                "cardNumber": "US122",
                                "name": ["Kolby Allard"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US123": {
                                "cardNumber": "US123",
                                "name": ["Isaac Paredes"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US124": {
                                "cardNumber": "US124",
                                "name": ["Nick Senzel"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US125": {
                                "cardNumber": "US125",
                                "name": ["Travis Jankowski"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US126": {
                                "cardNumber": "US126",
                                "name": ["Joe Dunand"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US127": {
                                "cardNumber": "US127",
                                "name": ["Zack Greinke"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US128": {
                                "cardNumber": "US128",
                                "name": ["Noah Syndergaard"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US129": {
                                "cardNumber": "US129",
                                "name": ["Rob Refsnyder"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US130": {
                                "cardNumber": "US130",
                                "name": ["Gio Urshela"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US131": {
                                "cardNumber": "US131",
                                "name": ["Martin Perez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US132": {
                                "cardNumber": "US132",
                                "name": ["Joe Ross"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US133": {
                                "cardNumber": "US133",
                                "name": ["Austin Nola"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US134": {
                                "cardNumber": "US134",
                                "name": ["Ian Kennedy"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US135": {
                                "cardNumber": "US135",
                                "name": ["Daniel Duarte", "Alexis Diaz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US136": {
                                "cardNumber": "US136",
                                "name": ["Aaron Bummer"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US137": {
                                "cardNumber": "US137",
                                "name": ["Eugenio Suarez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US138": {
                                "cardNumber": "US138",
                                "name": ["Drew Smyly"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US139": {
                                "cardNumber": "US139",
                                "name": ["Chad Green"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US140": {
                                "cardNumber": "US140",
                                "name": ["Mychal Givens"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US141": {
                                "cardNumber": "US141",
                                "name": ["Joc Pederson"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US142": {
                                "cardNumber": "US142",
                                "name": ["Matt Strahm"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US143": {
                                "cardNumber": "US143",
                                "name": ["Daniel Norris"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US144": {
                                "cardNumber": "US144",
                                "name": ["Jake Marisnick"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US145": {
                                "cardNumber": "US145",
                                "name": ["Miguel Castro"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US146": {
                                "cardNumber": "US146",
                                "name": ["Bennett Sousa", "Tanner Banks"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US147": {
                                "cardNumber": "US147",
                                "name": ["Collin Snider", "Dylan Coleman"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US148": {
                                "cardNumber": "US148",
                                "name": ["Ross Stripling"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US149": {
                                "cardNumber": "US149",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US150": {
                                "cardNumber": "US150",
                                "name": ["Brendan Donovan"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US151": {
                                "cardNumber": "US151",
                                "name": ["Kyle Isbel"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US152": {
                                "cardNumber": "US152",
                                "name": ["Rougned Odor"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US153": {
                                "cardNumber": "US153",
                                "name": ["Rene Pinto"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US154": {
                                "cardNumber": "US154",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US155": {
                                "cardNumber": "US155",
                                "name": ["Jonathan Villar"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US156": {
                                "cardNumber": "US156",
                                "name": ["Tim Locastro"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US157": {
                                "cardNumber": "US157",
                                "name": ["Niko Goodrum"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US158": {
                                "cardNumber": "US158",
                                "name": ["Robinson Cano"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US159": {
                                "cardNumber": "US159",
                                "name": ["Hunter Strickland"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US160": {
                                "cardNumber": "US160",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US161": {
                                "cardNumber": "US161",
                                "name": ["Lucas Sims"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "US162": {
                                "cardNumber": "US162",
                                "name": ["Elvin Rodriguez", "Angel De Jesus"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US163": {
                                "cardNumber": "US163",
                                "name": ["Jon Gray"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US164": {
                                "cardNumber": "US164",
                                "name": ["Blake Treinen"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US165": {
                                "cardNumber": "US165",
                                "name": ["Ron Marinaccio"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US166": {
                                "cardNumber": "US166",
                                "name": ["Seth Brown"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US167": {
                                "cardNumber": "US167",
                                "name": ["Daniel Vogelbach"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US168": {
                                "cardNumber": "US168",
                                "name": ["Yadier Molina", "Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US169": {
                                "cardNumber": "US169",
                                "name": ["Orlando Arcia"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US170": {
                                "cardNumber": "US170",
                                "name": ["Luke Jackson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US171": {
                                "cardNumber": "US171",
                                "name": ["Manny Pina"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US172": {
                                "cardNumber": "US172",
                                "name": ["Dusten Knight", "Phoenix Sanders"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US173": {
                                "cardNumber": "US173",
                                "name": ["Andrew Stevenson"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US174": {
                                "cardNumber": "US174",
                                "name": ["Alex Dickerson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Jacob Stallings"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US176": {
                                "cardNumber": "US176",
                                "name": ["Ryan Yarbrough"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US177": {
                                "cardNumber": "US177",
                                "name": ["Gary Sanchez"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US178": {
                                "cardNumber": "US178",
                                "name": ["Dakota Hudson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US179": {
                                "cardNumber": "US179",
                                "name": ["Maikel Franco"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US180": {
                                "cardNumber": "US180",
                                "name": ["Josh Rojas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US181": {
                                "cardNumber": "US181",
                                "name": ["Adam Ottavino"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US182": {
                                "cardNumber": "US182",
                                "name": ["Rich Hill"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US183": {
                                "cardNumber": "US183",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US184": {
                                "cardNumber": "US184",
                                "name": ["Hunter Dozier"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US185": {
                                "cardNumber": "US185",
                                "name": ["Seth Lugo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US186": {
                                "cardNumber": "US186",
                                "name": ["Mike Clevinger"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US187": {
                                "cardNumber": "US187",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US188": {
                                "cardNumber": "US188",
                                "name": ["Connor Joe"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US189": {
                                "cardNumber": "US189",
                                "name": ["Jose Iglesias"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US190": {
                                "cardNumber": "US190",
                                "name": ["Tyler Anderson"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US191": {
                                "cardNumber": "US191",
                                "name": ["Caleb Thielbar"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US192": {
                                "cardNumber": "US192",
                                "name": ["Chase Silseth"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US193": {
                                "cardNumber": "US193",
                                "name": ["James Norwood"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US194": {
                                "cardNumber": "US194",
                                "name": ["Nelson Cruz"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US195": {
                                "cardNumber": "US195",
                                "name": ["William Woods"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US196": {
                                "cardNumber": "US196",
                                "name": ["Hanser Alberto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US197": {
                                "cardNumber": "US197",
                                "name": ["Jesus Sanchez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US198": {
                                "cardNumber": "US198",
                                "name": ["Garrett Richards"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US199": {
                                "cardNumber": "US199",
                                "name": ["Austin Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US200": {
                                "cardNumber": "US200",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US201": {
                                "cardNumber": "US201",
                                "name": ["Jorge Alcala"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US202": {
                                "cardNumber": "US202",
                                "name": ["Nick Wittgren"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US203": {
                                "cardNumber": "US203",
                                "name": ["Yu Chang"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US204": {
                                "cardNumber": "US204",
                                "name": ["A.J. Pollock"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US205": {
                                "cardNumber": "US205",
                                "name": ["Drew Steckenrider"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US206": {
                                "cardNumber": "US206",
                                "name": ["Sam Hilliard"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "US207": {
                                "cardNumber": "US207",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US208": {
                                "cardNumber": "US208",
                                "name": ["Cole Sands"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US209": {
                                "cardNumber": "US209",
                                "name": ["Steven Matz"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US210": {
                                "cardNumber": "US210",
                                "name": ["JP Sears"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US211": {
                                "cardNumber": "US211",
                                "name": ["Amir Garrett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "US212": {
                                "cardNumber": "US212",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US213": {
                                "cardNumber": "US213",
                                "name": ["Owen Miller"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US214": {
                                "cardNumber": "US214",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US215": {
                                "cardNumber": "US215",
                                "name": ["Robinson Chirinos"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US216": {
                                "cardNumber": "US216",
                                "name": ["Michael Fulmer"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US217": {
                                "cardNumber": "US217",
                                "name": ["Kevin Smith"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US218": {
                                "cardNumber": "US218",
                                "name": ["Seiya Suzuki", "Yoshi Tsutsugo"],
                                "team": ["Chicago Cubs®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US219": {
                                "cardNumber": "US219",
                                "name": ["Ramon Urias"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US220": {
                                "cardNumber": "US220",
                                "name": ["Danny Young", "Penn Murfee"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US221": {
                                "cardNumber": "US221",
                                "name": ["Christian Walker", "Ketel Marte"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US222": {
                                "cardNumber": "US222",
                                "name": ["Joe Ryan"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US223": {
                                "cardNumber": "US223",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US224": {
                                "cardNumber": "US224",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US225": {
                                "cardNumber": "US225",
                                "name": ["Felix Bautista"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US226": {
                                "cardNumber": "US226",
                                "name": ["Sean Doolittle"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US227": {
                                "cardNumber": "US227",
                                "name": ["Anthony Bass"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US228": {
                                "cardNumber": "US228",
                                "name": ["J.P. Feyereisen"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "US229": {
                                "cardNumber": "US229",
                                "name": ["Jake Odorizzi"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US230": {
                                "cardNumber": "US230",
                                "name": ["Byron Buxton", "Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US231": {
                                "cardNumber": "US231",
                                "name": ["Edward Cabrera"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US232": {
                                "cardNumber": "US232",
                                "name": ["Tyler Wade"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US233": {
                                "cardNumber": "US233",
                                "name": ["Chris Archer"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US234": {
                                "cardNumber": "US234",
                                "name": ["Joe Perez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US235": {
                                "cardNumber": "US235",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US236": {
                                "cardNumber": "US236",
                                "name": ["Jack Suwinski"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US237": {
                                "cardNumber": "US237",
                                "name": ["Adam Haseley"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US238": {
                                "cardNumber": "US238",
                                "name": ["Javier Baez", "Robbie Grossman"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US239": {
                                "cardNumber": "US239",
                                "name": ["Robert Suarez", "Steven Wilson"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US240": {
                                "cardNumber": "US240",
                                "name": ["Shane Baz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US241": {
                                "cardNumber": "US241",
                                "name": ["Ryan Tepera"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US242": {
                                "cardNumber": "US242",
                                "name": ["Donovan Walton"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US243": {
                                "cardNumber": "US243",
                                "name": ["Roberto Perez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "US244": {
                                "cardNumber": "US244",
                                "name": ["Clint Frazier"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US245": {
                                "cardNumber": "US245",
                                "name": ["Yan Gomes"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US246": {
                                "cardNumber": "US246",
                                "name": ["Yunior Marte", "Sean Hjelle"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US247": {
                                "cardNumber": "US247",
                                "name": ["Nick Plummer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US248": {
                                "cardNumber": "US248",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US249": {
                                "cardNumber": "US249",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US251": {
                                "cardNumber": "US251",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "US252": {
                                "cardNumber": "US252",
                                "name": ["Tony Gonsolin"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "US253": {
                                "cardNumber": "US253",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US254": {
                                "cardNumber": "US254",
                                "name": ["Zach Jackson", "Adam Oller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US255": {
                                "cardNumber": "US255",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US256": {
                                "cardNumber": "US256",
                                "name": ["Ethan Roberts"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US257": {
                                "cardNumber": "US257",
                                "name": ["Jorge Soler"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US258": {
                                "cardNumber": "US258",
                                "name": ["Ryan Pepiot"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US259": {
                                "cardNumber": "US259",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US260": {
                                "cardNumber": "US260",
                                "name": ["Stephen Vogt"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US261": {
                                "cardNumber": "US261",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US262": {
                                "cardNumber": "US262",
                                "name": ["Josh Harrison"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US263": {
                                "cardNumber": "US263",
                                "name": ["Matt Beaty"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US264": {
                                "cardNumber": "US264",
                                "name": ["Ryan Pressly"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US265": {
                                "cardNumber": "US265",
                                "name": ["Anderson Severino"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US266": {
                                "cardNumber": "US266",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US267": {
                                "cardNumber": "US267",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US268": {
                                "cardNumber": "US268",
                                "name": ["Joe Kelly"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US269": {
                                "cardNumber": "US269",
                                "name": ["Ehire Adrianza"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US270": {
                                "cardNumber": "US270",
                                "name": ["Raimel Tapia"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US271": {
                                "cardNumber": "US271",
                                "name": ["Garrett Cooper"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US272": {
                                "cardNumber": "US272",
                                "name": ["Eduardo Rodriguez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US273": {
                                "cardNumber": "US273",
                                "name": ["Thairo Estrada"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US274": {
                                "cardNumber": "US274",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US275": {
                                "cardNumber": "US275",
                                "name": ["Oscar Mercado"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US276": {
                                "cardNumber": "US276",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US277": {
                                "cardNumber": "US277",
                                "name": ["Jhoan Duran"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US278": {
                                "cardNumber": "US278",
                                "name": ["Yusei Kikuchi"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US279": {
                                "cardNumber": "US279",
                                "name": ["Sergio Alcantara"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US280": {
                                "cardNumber": "US280",
                                "name": ["Jose Azocar"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US281": {
                                "cardNumber": "US281",
                                "name": ["Steven Duggar"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US282": {
                                "cardNumber": "US282",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US283": {
                                "cardNumber": "US283",
                                "name": ["Trent Giambrone", "Scott Effross"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "Combo Card"]
                            },
                            "US284": {
                                "cardNumber": "US284",
                                "name": ["Michael Pineda"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US285": {
                                "cardNumber": "US285",
                                "name": ["Nick Tropeano"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US286": {
                                "cardNumber": "US286",
                                "name": ["Jordan Luplow"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US287": {
                                "cardNumber": "US287",
                                "name": ["Josh Lowe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US288": {
                                "cardNumber": "US288",
                                "name": ["Jacob deGrom", "Max Scherzer"],
                                "team": ["New York Mets®", "New York Mets®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US289": {
                                "cardNumber": "US289",
                                "name": ["Aledmys Diaz"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "US290": {
                                "cardNumber": "US290",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US291": {
                                "cardNumber": "US291",
                                "name": ["Luis Torrens"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US292": {
                                "cardNumber": "US292",
                                "name": ["Cole Irvin"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US293": {
                                "cardNumber": "US293",
                                "name": ["Yadiel Hernandez"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US294": {
                                "cardNumber": "US294",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US295": {
                                "cardNumber": "US295",
                                "name": ["Joey Wentz"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US296": {
                                "cardNumber": "US296",
                                "name": ["Steve Cishek"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US297": {
                                "cardNumber": "US297",
                                "name": ["J.J. Matijevic"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US298": {
                                "cardNumber": "US298",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US299": {
                                "cardNumber": "US299",
                                "name": ["Cesar Hernandez"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US301": {
                                "cardNumber": "US301",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "US302": {
                                "cardNumber": "US302",
                                "name": ["Amed Rosario", "Jose Ramirez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US303": {
                                "cardNumber": "US303",
                                "name": ["Taylor Rogers"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US304": {
                                "cardNumber": "US304",
                                "name": ["Konnor Pilkington"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US305": {
                                "cardNumber": "US305",
                                "name": ["Chris Bassitt"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US306": {
                                "cardNumber": "US306",
                                "name": ["Jake Walsh"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US307": {
                                "cardNumber": "US307",
                                "name": ["Zach Davies"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US308": {
                                "cardNumber": "US308",
                                "name": ["Richie Palacios"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US309": {
                                "cardNumber": "US309",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Short Print"]
                            },
                            "US310": {
                                "cardNumber": "US310",
                                "name": ["Juan Soto", "Ozzie Albies"],
                                "team": ["Washington Nationals®", "Atlanta Braves™"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US311": {
                                "cardNumber": "US311",
                                "name": ["Kenley Jansen"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US312": {
                                "cardNumber": "US312",
                                "name": ["Jorge Mateo", "Rougned Odor"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Combo Card"]
                            },
                            "US313": {
                                "cardNumber": "US313",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Season Highlights"]
                            },
                            "US314": {
                                "cardNumber": "US314",
                                "name": ["Cooper Hummel"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US315": {
                                "cardNumber": "US315",
                                "name": ["Garrett Stubbs"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US316": {
                                "cardNumber": "US316",
                                "name": ["Geraldo Perdomo"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "US317": {
                                "cardNumber": "US317",
                                "name": ["Bryan Lavastida"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US318": {
                                "cardNumber": "US318",
                                "name": ["Francisco Morales"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US319": {
                                "cardNumber": "US319",
                                "name": ["Kelvin Gutierrez"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "US320": {
                                "cardNumber": "US320",
                                "name": ["Nestor Cortes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US321": {
                                "cardNumber": "US321",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US322": {
                                "cardNumber": "US322",
                                "name": ["Sergio Romo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US323": {
                                "cardNumber": "US323",
                                "name": ["Adam Engel"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "US324": {
                                "cardNumber": "US324",
                                "name": ["Avisail Garcia"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "US325": {
                                "cardNumber": "US325",
                                "name": ["Alex Colome"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "US326": {
                                "cardNumber": "US326",
                                "name": ["Luke Maile"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "US327": {
                                "cardNumber": "US327",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US328": {
                                "cardNumber": "US328",
                                "name": ["Cristian Pache"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "US329": {
                                "cardNumber": "US329",
                                "name": ["Andrew Knizner"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US330": {
                                "cardNumber": "US330",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            }
                        },
                        "Base Cards Clear Variation": {
                            "US1": {
                                "cardNumber": "US1",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US2": {
                                "cardNumber": "US2",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US3": {
                                "cardNumber": "US3",
                                "name": ["Jesse Winker"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "US5": {
                                "cardNumber": "US5",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US6": {
                                "cardNumber": "US6",
                                "name": ["Josh Winder"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US8": {
                                "cardNumber": "US8",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US9": {
                                "cardNumber": "US9",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US16": {
                                "cardNumber": "US16",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US17": {
                                "cardNumber": "US17",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US19": {
                                "cardNumber": "US19",
                                "name": ["Simon Muzziotti"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US20": {
                                "cardNumber": "US20",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US22": {
                                "cardNumber": "US22",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "US34": {
                                "cardNumber": "US34",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US35": {
                                "cardNumber": "US35",
                                "name": ["Brandon Marsh"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US38": {
                                "cardNumber": "US38",
                                "name": ["Lucius Fox"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US39": {
                                "cardNumber": "US39",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "US41": {
                                "cardNumber": "US41",
                                "name": ["Gosuke Katoh"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US42": {
                                "cardNumber": "US42",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US44": {
                                "cardNumber": "US44",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US48": {
                                "cardNumber": "US48",
                                "name": ["Sean Manaea"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "US50": {
                                "cardNumber": "US50",
                                "name": ["Marcus Stroman"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "US52": {
                                "cardNumber": "US52",
                                "name": ["Bryce Elder"],
                                "team": ["Atlanta Braves®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US54": {
                                "cardNumber": "US54",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US59": {
                                "cardNumber": "US59",
                                "name": ["Andre Pallante"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US62": {
                                "cardNumber": "US62",
                                "name": ["Reid Detmers"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US64": {
                                "cardNumber": "US64",
                                "name": ["Alex Faedo"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US66": {
                                "cardNumber": "US66",
                                "name": ["Kyle Bradish"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US73": {
                                "cardNumber": "US73",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US74": {
                                "cardNumber": "US74",
                                "name": ["Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "US76": {
                                "cardNumber": "US76",
                                "name": ["Tommy Romero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US79": {
                                "cardNumber": "US79",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US81": {
                                "cardNumber": "US81",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US85": {
                                "cardNumber": "US85",
                                "name": ["Diego Castillo"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US86": {
                                "cardNumber": "US86",
                                "name": ["Carlos Rodon"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US88": {
                                "cardNumber": "US88",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US89": {
                                "cardNumber": "US89",
                                "name": ["Javier Baez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "US97": {
                                "cardNumber": "US97",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US105": {
                                "cardNumber": "US105",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US109": {
                                "cardNumber": "US109",
                                "name": ["Ryan Pepiot"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US110": {
                                "cardNumber": "US110",
                                "name": ["Elehuris Montero"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US113": {
                                "cardNumber": "US113",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US117": {
                                "cardNumber": "US117",
                                "name": ["Nick Allen"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US118": {
                                "cardNumber": "US118",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US120": {
                                "cardNumber": "US120",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US126": {


                                "cardNumber": "US126",
                                "name": ["Joe Dunand"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US128": {
                                "cardNumber": "US128",
                                "name": ["Noah Syndergaard"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US141": {
                                "cardNumber": "US141",
                                "name": ["Joc Pederson"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "US149": {
                                "cardNumber": "US149",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US150": {
                                "cardNumber": "US150",
                                "name": ["Brendan Donovan"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US154": {
                                "cardNumber": "US154",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US160": {
                                "cardNumber": "US160",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US165": {
                                "cardNumber": "US165",
                                "name": ["Ron Marinaccio"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US187": {
                                "cardNumber": "US187",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US194": {
                                "cardNumber": "US194",
                                "name": ["Nelson Cruz"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "US200": {
                                "cardNumber": "US200",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "US207": {
                                "cardNumber": "US207",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US208": {
                                "cardNumber": "US208",
                                "name": ["Cole Sands"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US210": {
                                "cardNumber": "US210",
                                "name": ["JP Sears"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US212": {
                                "cardNumber": "US212",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US214": {
                                "cardNumber": "US214",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US222": {
                                "cardNumber": "US222",
                                "name": ["Joe Ryan"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US223": {
                                "cardNumber": "US223",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US224": {
                                "cardNumber": "US224",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US231": {
                                "cardNumber": "US231",
                                "name": ["Edward Cabrera"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US234": {
                                "cardNumber": "US234",
                                "name": ["Joe Perez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US235": {
                                "cardNumber": "US235",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "US240": {
                                "cardNumber": "US240",
                                "name": ["Shane Baz"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US247": {
                                "cardNumber": "US247",
                                "name": ["Nick Plummer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US248": {
                                "cardNumber": "US248",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US249": {
                                "cardNumber": "US249",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US251": {
                                "cardNumber": "US251",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "US253": {
                                "cardNumber": "US253",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US255": {
                                "cardNumber": "US255",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US256": {
                                "cardNumber": "US256",
                                "name": ["Ethan Roberts"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US258": {
                                "cardNumber": "US258",
                                "name": ["Ryan Pepiot"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US259": {
                                "cardNumber": "US259",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US261": {
                                "cardNumber": "US261",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US265": {
                                "cardNumber": "US265",
                                "name": ["Anderson Severino"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US266": {
                                "cardNumber": "US266",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "US267": {
                                "cardNumber": "US267",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US274": {
                                "cardNumber": "US274",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US276": {
                                "cardNumber": "US276",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US277": {
                                "cardNumber": "US277",
                                "name": ["Jhoan Duran"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US282": {
                                "cardNumber": "US282",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US287": {
                                "cardNumber": "US287",
                                "name": ["Josh Lowe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US290": {
                                "cardNumber": "US290",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US294": {
                                "cardNumber": "US294",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US298": {
                                "cardNumber": "US298",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "US301": {
                                "cardNumber": "US301",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "US305": {
                                "cardNumber": "US305",
                                "name": ["Chris Bassitt"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "US308": {
                                "cardNumber": "US308",
                                "name": ["Richie Palacios"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US314": {
                                "cardNumber": "US314",
                                "name": ["Cooper Hummel"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US317": {
                                "cardNumber": "US317",
                                "name": ["Bryan Lavastida"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US318": {
                                "cardNumber": "US318",
                                "name": ["Francisco Morales"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "US321": {
                                "cardNumber": "US321",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US327": {
                                "cardNumber": "US327",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "US330": {
                                "cardNumber": "US330",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            }
                        },
                        "SP Variation CMP056259": {
                            "US1": {
                                "cardNumber": "US1",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US2": {
                                "cardNumber": "US2",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US2_B": {
                                "cardNumber": "US2",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US3": {
                                "cardNumber": "US3",
                                "name": ["Babe Ruth"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US4": {
                                "cardNumber": "US4",
                                "name": ["Lou Gehrig"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US6": {
                                "cardNumber": "US6",
                                "name": ["Stan Musial"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US7": {
                                "cardNumber": "US7",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US8": {
                                "cardNumber": "US8",
                                "name": ["Yogi Berra"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US9": {
                                "cardNumber": "US9",
                                "name": ["Reggie Jackson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US9_B": {
                                "cardNumber": "US9",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US16": {
                                "cardNumber": "US16",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US16_B": {
                                "cardNumber": "US16",
                                "name": ["Bo Jackson"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US19": {
                                "cardNumber": "US19",
                                "name": ["Robin Yount"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US20": {
                                "cardNumber": "US20",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US21": {
                                "cardNumber": "US21",
                                "name": ["Roberto Clemente"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US22": {
                                "cardNumber": "US22",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US22_B": {
                                "cardNumber": "US22",
                                "name": ["Juan Soto"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US22_C": {
                                "cardNumber": "US22",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US23": {
                                "cardNumber": "US23",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US24": {
                                "cardNumber": "US24",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US24_B": {
                                "cardNumber": "US24",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US24_C": {
                                "cardNumber": "US24",
                                "name": ["Willie Mays"],
                                "team": ["New York Giants™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US25": {
                                "cardNumber": "US25",
                                "name": ["Mark McGwire"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US29": {
                                "cardNumber": "US29",
                                "name": ["Satchel Paige"],
                                "team": ["St. Louis Browns™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US31": {
                                "cardNumber": "US31",
                                "name": ["Greg Maddux"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US34": {
                                "cardNumber": "US34",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US34_B": {
                                "cardNumber": "US34",
                                "name": ["Nolan Ryan"],
                                "team": ["California Angels™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US39": {
                                "cardNumber": "US39",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US41": {
                                "cardNumber": "US41",
                                "name": ["Tom Seaver"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US42": {
                                "cardNumber": "US42",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US44": {
                                "cardNumber": "US44",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US44_B": {
                                "cardNumber": "US44",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US50": {
                                "cardNumber": "US50",
                                "name": ["Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US50_B": {
                                "cardNumber": "US50",
                                "name": ["Marcus Stroman"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US51": {
                                "cardNumber": "US51",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US60": {
                                "cardNumber": "US60",
                                "name": ["Rogers Hornsby"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US74": {
                                "cardNumber": "US74",
                                "name": ["Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US81": {
                                "cardNumber": "US81",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US86": {
                                "cardNumber": "US86",
                                "name": ["Carlos Rodón"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US88": {
                                "cardNumber": "US88",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US89": {
                                "cardNumber": "US89",
                                "name": ["Javier Báez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US102": {
                                "cardNumber": "US102",
                                "name": ["Christy Mathewson"],
                                "team": ["New York Giants™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US105": {
                                "cardNumber": "US105",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US117": {
                                "cardNumber": "US117",
                                "name": ["Nick Allen"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US120": {
                                "cardNumber": "US120",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US128": {
                                "cardNumber": "US128",
                                "name": ["Noah Syndergaard"],
                                "team": ["Angels®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US134": {
                                "cardNumber": "US134",
                                "name": ["Randy Johnson"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US144": {
                                "cardNumber": "US144",
                                "name": ["Mel Ott"],
                                "team": ["New York Giants™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US154": {
                                "cardNumber": "US154",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US160": {
                                "cardNumber": "US160",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US200": {
                                "cardNumber": "US200",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US204": {
                                "cardNumber": "US204",
                                "name": ["Ty Cobb"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US207": {
                                "cardNumber": "US207",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US212": {
                                "cardNumber": "US212",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US214": {
                                "cardNumber": "US214",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US214_B": {
                                "cardNumber": "US214",
                                "name": ["Manny Ramirez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US219": {
                                "cardNumber": "US219",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US223": {
                                "cardNumber": "US223",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US224": {
                                "cardNumber": "US224",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US235": {
                                "cardNumber": "US235",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US235_B": {
                                "cardNumber": "US235",
                                "name": ["Brooks Robinson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US245": {
                                "cardNumber": "US245",
                                "name": ["Roy Campanella"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US248": {
                                "cardNumber": "US248",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US251": {
                                "cardNumber": "US251",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US253": {
                                "cardNumber": "US253",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US253_B": {
                                "cardNumber": "US253",
                                "name": ["Vladimir Guerrero"],
                                "team": ["Montréal Expos™"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US261": {
                                "cardNumber": "US261",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US266": {
                                "cardNumber": "US266",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US298": {
                                "cardNumber": "US298",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "SP Variation"]
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US301": {
                                "cardNumber": "US301",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US305": {
                                "cardNumber": "US305",
                                "name": ["Roger Clemens"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US311": {
                                "cardNumber": "US311",
                                "name": ["Bob Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US320": {
                                "cardNumber": "US320",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["SP Variation"]
                            },
                            "US330": {
                                "cardNumber": "US330",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["SP Variation"]
                            }
                        },
                        "SSP Variation CMP056260": {
                            "US2": {
                                "cardNumber": "US2",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US20": {
                                "cardNumber": "US20",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US24": {
                                "cardNumber": "US24",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US24_B": {
                                "cardNumber": "US24",
                                "name": ["Willie Mays"],
                                "team": ["New York Giants™"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US34": {
                                "cardNumber": "US34",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US39": {
                                "cardNumber": "US39",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US42": {
                                "cardNumber": "US42",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US44": {
                                "cardNumber": "US44",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US81": {
                                "cardNumber": "US81",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US84": {
                                "cardNumber": "US84",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US89": {
                                "cardNumber": "US89",
                                "name": ["Javier Báez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US99": {
                                "cardNumber": "US99",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees™"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US100": {
                                "cardNumber": "US100",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US105": {
                                "cardNumber": "US105",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US160": {
                                "cardNumber": "US160",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US174": {
                                "cardNumber": "US174",
                                "name": ["Hank Aaron"],
                                "team": ["Milwaukee Braves™"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US212": {
                                "cardNumber": "US212",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US224": {
                                "cardNumber": "US224",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US250_B": {
                                "cardNumber": "US250",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "SSP Variation"]
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["SSP Variation"]
                            },
                            "US330": {
                                "cardNumber": "US330",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["SSP Variation"]
                            }
                        },
                        "Ultra SP Variation CMP056261": {
                            "150": {
                                "cardNumber": "150",
                                "name": ["Screech"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Ultra SP Variation"]
                            },
                            "200": {
                                "cardNumber": "200",
                                "name": ["Blooper"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Ultra SP Variation"]
                            },
                            "205": {
                                "cardNumber": "205",
                                "name": ["Fredbird"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Ultra SP Variation"]
                            },
                            "278": {
                                "cardNumber": "278",
                                "name": ["Orbit"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Ultra SP Variation"]
                            },
                            "288": {
                                "cardNumber": "288",
                                "name": ["Bernie Brewer"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Ultra SP Variation"]
                            }
                        }
                    },
                    "Insert": {
                        "Generation Now": {
                            "GN-61": {
                                "cardNumber": "GN-61",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "GN-62": {
                                "cardNumber": "GN-62",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "GN-63": {
                                "cardNumber": "GN-63",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "GN-64": {
                                "cardNumber": "GN-64",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "GN-65": {
                                "cardNumber": "GN-65",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "GN-66": {
                                "cardNumber": "GN-66",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "GN-67": {
                                "cardNumber": "GN-67",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "GN-68": {
                                "cardNumber": "GN-68",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "GN-69": {
                                "cardNumber": "GN-69",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "GN-70": {
                                "cardNumber": "GN-70",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "GN-71": {
                                "cardNumber": "GN-71",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "GN-72": {
                                "cardNumber": "GN-72",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "GN-73": {
                                "cardNumber": "GN-73",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "GN-74": {
                                "cardNumber": "GN-74",
                                "name": ["Tarik Skubal"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "GN-75": {
                                "cardNumber": "GN-75",
                                "name": ["Matt Manning"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "GN-76": {
                                "cardNumber": "GN-76",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "GN-77": {
                                "cardNumber": "GN-77",
                                "name": ["Gavin Lux"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "GN-78": {
                                "cardNumber": "GN-78",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "GN-79": {
                                "cardNumber": "GN-79",
                                "name": ["Jesus Luzardo"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "GN-80": {
                                "cardNumber": "GN-80",
                                "name": ["Edward Cabrera"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "GN-81": {
                                "cardNumber": "GN-81",
                                "name": ["Joe Ryan"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "GN-82": {
                                "cardNumber": "GN-82",
                                "name": ["Logan Gilbert"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "GN-83": {
                                "cardNumber": "GN-83",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "GN-84": {
                                "cardNumber": "GN-84",
                                "name": ["Lars Nootbaar"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "GN-85": {
                                "cardNumber": "GN-85",
                                "name": ["Josh Lowe"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "GN-86": {
                                "cardNumber": "GN-86",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "GN-87": {
                                "cardNumber": "GN-87",
                                "name": ["Shane McClanahan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "GN-88": {
                                "cardNumber": "GN-88",
                                "name": ["Roansy Contreras"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "GN-89": {
                                "cardNumber": "GN-89",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "GN-90": {
                                "cardNumber": "GN-90",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            }
                        },
                        "Paragons of the Postseason": {
                            "PP-1": {
                                "cardNumber": "PP-1",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-2": {
                                "cardNumber": "PP-2",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "PP-3": {
                                "cardNumber": "PP-3",
                                "name": ["Reggie Jackson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-4": {
                                "cardNumber": "PP-4",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "PP-5": {
                                "cardNumber": "PP-5",
                                "name": ["Yogi Berra"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-6": {
                                "cardNumber": "PP-6",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "PP-7": {
                                "cardNumber": "PP-7",
                                "name": ["Lou Brock"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "PP-8": {
                                "cardNumber": "PP-8",
                                "name": ["Lou Gehrig"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-9": {
                                "cardNumber": "PP-9",
                                "name": ["Bob Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "PP-10": {
                                "cardNumber": "PP-10",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "PP-11": {
                                "cardNumber": "PP-11",
                                "name": ["Manny Ramirez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "PP-12": {
                                "cardNumber": "PP-12",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-13": {
                                "cardNumber": "PP-13",
                                "name": ["Babe Ruth"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-14": {
                                "cardNumber": "PP-14",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "PP-15": {
                                "cardNumber": "PP-15",
                                "name": ["John Smoltz"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "PP-16": {
                                "cardNumber": "PP-16",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "PP-17": {
                                "cardNumber": "PP-17",
                                "name": ["Roger Clemens"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-18": {
                                "cardNumber": "PP-18",
                                "name": ["Andy Pettitte"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-19": {
                                "cardNumber": "PP-19",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "PP-20": {
                                "cardNumber": "PP-20",
                                "name": ["Brooks Robinson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "PP-21": {
                                "cardNumber": "PP-21",
                                "name": ["Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "PP-22": {
                                "cardNumber": "PP-22",
                                "name": ["Stephen Strasburg"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "PP-23": {
                                "cardNumber": "PP-23",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "PP-24": {
                                "cardNumber": "PP-24",
                                "name": ["Kirby Puckett"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "PP-25": {
                                "cardNumber": "PP-25",
                                "name": ["Jack Morris"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Black Gold": {
                            "BG-1": {
                                "cardNumber": "BG-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BG-2": {
                                "cardNumber": "BG-2",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "BG-3": {
                                "cardNumber": "BG-3",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BG-4": {
                                "cardNumber": "BG-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BG-5": {
                                "cardNumber": "BG-5",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox™"],
                                "cardAttributes": []
                            },
                            "BG-6": {
                                "cardNumber": "BG-6",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins™"],
                                "cardAttributes": []
                            },
                            "BG-7": {
                                "cardNumber": "BG-7",
                                "name": ["Luis Robert"],
                                "team": ["Chicago White Sox™"],
                                "cardAttributes": []
                            },
                            "BG-8": {
                                "cardNumber": "BG-8",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds™"],
                                "cardAttributes": []
                            },
                            "BG-9": {
                                "cardNumber": "BG-9",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers™"],
                                "cardAttributes": []
                            },
                            "BG-10": {
                                "cardNumber": "BG-10",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros™"],
                                "cardAttributes": []
                            },
                            "BG-11": {
                                "cardNumber": "BG-11",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals™"],
                                "cardAttributes": []
                            },
                            "BG-12": {
                                "cardNumber": "BG-12",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "BG-13": {
                                "cardNumber": "BG-13",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BG-14": {
                                "cardNumber": "BG-14",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "BG-15": {
                                "cardNumber": "BG-15",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs™"],
                                "cardAttributes": []
                            },
                            "BG-16": {
                                "cardNumber": "BG-16",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets™"],
                                "cardAttributes": []
                            },
                            "BG-17": {
                                "cardNumber": "BG-17",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds™"],
                                "cardAttributes": []
                            },
                            "BG-18": {
                                "cardNumber": "BG-18",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees™"],
                                "cardAttributes": []
                            },
                            "BG-19": {
                                "cardNumber": "BG-19",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies™"],
                                "cardAttributes": []
                            },
                            "BG-20": {
                                "cardNumber": "BG-20",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates™"],
                                "cardAttributes": []
                            },
                            "BG-21": {
                                "cardNumber": "BG-21",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BG-22": {
                                "cardNumber": "BG-22",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals™"],
                                "cardAttributes": []
                            },
                            "BG-23": {
                                "cardNumber": "BG-23",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays™"],
                                "cardAttributes": []
                            },
                            "BG-24": {
                                "cardNumber": "BG-24",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays™"],
                                "cardAttributes": []
                            },
                            "BG-25": {
                                "cardNumber": "BG-25",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals™"],
                                "cardAttributes": []
                            }
                        },
                        "Diamond Greats Die Cuts": {
                            "DGDC-51": {
                                "cardNumber": "DGDC-51",
                                "name": ["Lou Gehrig"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "DGDC-52": {
                                "cardNumber": "DGDC-52",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "DGDC-53": {
                                "cardNumber": "DGDC-53",
                                "name": ["Hank Aaron"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "DGDC-54": {
                                "cardNumber": "DGDC-54",
                                "name": ["Roger Clemens"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "DGDC-55": {
                                "cardNumber": "DGDC-55",
                                "name": ["Manny Ramirez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "DGDC-56": {
                                "cardNumber": "DGDC-56",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "DGDC-57": {
                                "cardNumber": "DGDC-57",
                                "name": ["Roy Campanella"],
                                "team": ["Brooklyn Dodgers®"],
                                "cardAttributes": []
                            },
                            "DGDC-58": {
                                "cardNumber": "DGDC-58",
                                "name": ["Ryne Sandberg"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "DGDC-59": {
                                "cardNumber": "DGDC-59",
                                "name": ["Bob Feller"],
                                "team": ["Cleveland®"],
                                "cardAttributes": []
                            },
                            "DGDC-60": {
                                "cardNumber": "DGDC-60",
                                "name": ["Al Kaline"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "DGDC-61": {
                                "cardNumber": "DGDC-61",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "DGDC-62": {
                                "cardNumber": "DGDC-62",
                                "name": ["Eddie Mathews"],
                                "team": ["Milwaukee Braves®"],
                                "cardAttributes": []
                            },
                            "DGDC-63": {
                                "cardNumber": "DGDC-63",
                                "name": ["Warren Spahn"],
                                "team": ["Milwaukee Braves®"],
                                "cardAttributes": []
                            },
                            "DGDC-64": {
                                "cardNumber": "DGDC-64",
                                "name": ["Christy Mathewson"],
                                "team": ["New York Giants®"],
                                "cardAttributes": []
                            },
                            "DGDC-65": {
                                "cardNumber": "DGDC-65",
                                "name": ["Mel Ott"],
                                "team": ["New York Giants®"],
                                "cardAttributes": []
                            },
                            "DGDC-66": {
                                "cardNumber": "DGDC-66",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "DGDC-67": {
                                "cardNumber": "DGDC-67",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "DGDC-68": {
                                "cardNumber": "DGDC-68",
                                "name": ["Jimmie Foxx"],
                                "team": ["Philadelphia Athletics®"],
                                "cardAttributes": []
                            },
                            "DGDC-69": {
                                "cardNumber": "DGDC-69",
                                "name": ["Carlton Fisk"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "DGDC-70": {
                                "cardNumber": "DGDC-70",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "DGDC-71": {
                                "cardNumber": "DGDC-71",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "DGDC-72": {
                                "cardNumber": "DGDC-72",
                                "name": ["Rogers Hornsby"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "DGDC-73": {
                                "cardNumber": "DGDC-73",
                                "name": ["Josh Gibson"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "DGDC-74": {
                                "cardNumber": "DGDC-74",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "DGDC-75": {
                                "cardNumber": "DGDC-75",
                                "name": ["Johnny Mize"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            }
                        },
                        "2022 All Star Game": {
                            "ASG-1": {
                                "cardNumber": "ASG-1",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASG-2": {
                                "cardNumber": "ASG-2",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-3": {
                                "cardNumber": "ASG-3",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASG-4": {
                                "cardNumber": "ASG-4",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASG-5": {
                                "cardNumber": "ASG-5",
                                "name": ["Willson Contreras"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ASG-6": {
                                "cardNumber": "ASG-6",
                                "name": ["Alejandro Kirk"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASG-7": {
                                "cardNumber": "ASG-7",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASG-8": {
                                "cardNumber": "ASG-8",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASG-9": {
                                "cardNumber": "ASG-9",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASG-10": {
                                "cardNumber": "ASG-10",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASG-11": {
                                "cardNumber": "ASG-11",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASG-12": {
                                "cardNumber": "ASG-12",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASG-13": {
                                "cardNumber": "ASG-13",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASG-14": {
                                "cardNumber": "ASG-14",
                                "name": ["Trea Turner"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-15": {
                                "cardNumber": "ASG-15",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASG-16": {
                                "cardNumber": "ASG-16",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASG-17": {
                                "cardNumber": "ASG-17",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASG-18": {
                                "cardNumber": "ASG-18",
                                "name": ["Joc Pederson"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASG-19": {
                                "cardNumber": "ASG-19",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASG-20": {
                                "cardNumber": "ASG-20",
                                "name": ["Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASG-21": {
                                "cardNumber": "ASG-21",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASG-22": {
                                "cardNumber": "ASG-22",
                                "name": ["Jose Ramirez"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "ASG-23": {
                                "cardNumber": "ASG-23",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASG-24": {
                                "cardNumber": "ASG-24",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASG-25": {
                                "cardNumber": "ASG-25",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASG-26": {
                                "cardNumber": "ASG-26",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "ASG-27": {
                                "cardNumber": "ASG-27",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASG-28": {
                                "cardNumber": "ASG-28",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-29": {
                                "cardNumber": "ASG-29",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASG-30": {
                                "cardNumber": "ASG-30",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASG-31": {
                                "cardNumber": "ASG-31",
                                "name": ["Dansby Swanson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASG-32": {
                                "cardNumber": "ASG-32",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASG-33": {
                                "cardNumber": "ASG-33",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASG-34": {
                                "cardNumber": "ASG-34",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASG-35": {
                                "cardNumber": "ASG-35",
                                "name": ["Shane McClanahan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "ASG-36": {
                                "cardNumber": "ASG-36",
                                "name": ["Nestor Cortes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASG-37": {
                                "cardNumber": "ASG-37",
                                "name": ["Alek Manoah"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASG-38": {
                                "cardNumber": "ASG-38",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-39": {
                                "cardNumber": "ASG-39",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASG-40": {
                                "cardNumber": "ASG-40",
                                "name": ["Corbin Burnes"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "ASG-41": {
                                "cardNumber": "ASG-41",
                                "name": ["Luis Castillo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASG-42": {
                                "cardNumber": "ASG-42",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASG-43": {
                                "cardNumber": "ASG-43",
                                "name": ["Tony Gonsolin"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASG-44": {
                                "cardNumber": "ASG-44",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASG-45": {
                                "cardNumber": "ASG-45",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASG-46": {
                                "cardNumber": "ASG-46",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASG-47": {
                                "cardNumber": "ASG-47",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASG-48": {
                                "cardNumber": "ASG-48",
                                "name": ["Ian Happ"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ASG-49": {
                                "cardNumber": "ASG-49",
                                "name": ["William Contreras"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASG-50": {
                                "cardNumber": "ASG-50",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        "1987 Topps Baseball": {
                            "87TBU-1": {
                                "cardNumber": "87TBU-1",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "87TBU-2": {
                                "cardNumber": "87TBU-2",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "87TBU-3": {
                                "cardNumber": "87TBU-3",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "87TBU-4": {
                                "cardNumber": "87TBU-4",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "87TBU-5": {
                                "cardNumber": "87TBU-5",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "87TBU-6": {
                                "cardNumber": "87TBU-6",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "87TBU-7": {
                                "cardNumber": "87TBU-7",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "87TBU-8": {
                                "cardNumber": "87TBU-8",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "87TBU-9": {
                                "cardNumber": "87TBU-9",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "87TBU-10": {
                                "cardNumber": "87TBU-10",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87TBU-11": {
                                "cardNumber": "87TBU-11",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "87TBU-12": {
                                "cardNumber": "87TBU-12",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "87TBU-13": {
                                "cardNumber": "87TBU-13",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "87TBU-14": {
                                "cardNumber": "87TBU-14",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "87TBU-15": {
                                "cardNumber": "87TBU-15",
                                "name": ["Gary Carter"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "87TBU-16": {
                                "cardNumber": "87TBU-16",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "87TBU-17": {
                                "cardNumber": "87TBU-17",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "87TBU-18": {
                                "cardNumber": "87TBU-18",
                                "name": ["Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "87TBU-19": {
                                "cardNumber": "87TBU-19",
                                "name": ["Javier Baez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "87TBU-20": {
                                "cardNumber": "87TBU-20",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "87TBU-21": {
                                "cardNumber": "87TBU-21",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "87TBU-22": {
                                "cardNumber": "87TBU-22",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "87TBU-23": {
                                "cardNumber": "87TBU-23",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "87TBU-24": {
                                "cardNumber": "87TBU-24",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "87TBU-25": {
                                "cardNumber": "87TBU-25",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "87TBU-26": {
                                "cardNumber": "87TBU-26",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "87TBU-27": {
                                "cardNumber": "87TBU-27",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "87TBU-28": {
                                "cardNumber": "87TBU-28",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "87TBU-29": {
                                "cardNumber": "87TBU-29",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "87TBU-30": {
                                "cardNumber": "87TBU-30",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "87TBU-31": {
                                "cardNumber": "87TBU-31",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "87TBU-32": {
                                "cardNumber": "87TBU-32",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "87TBU-33": {
                                "cardNumber": "87TBU-33",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "87TBU-34": {
                                "cardNumber": "87TBU-34",
                                "name": ["Nolan Ryan"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "87TBU-35": {
                                "cardNumber": "87TBU-35",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "87TBU-36": {
                                "cardNumber": "87TBU-36",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "87TBU-37": {
                                "cardNumber": "87TBU-37",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "87TBU-38": {
                                "cardNumber": "87TBU-38",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87TBU-39": {
                                "cardNumber": "87TBU-39",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87TBU-40": {
                                "cardNumber": "87TBU-40",
                                "name": ["Carlos Rodon"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "87TBU-41": {
                                "cardNumber": "87TBU-41",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "87TBU-42": {
                                "cardNumber": "87TBU-42",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "87TBU-43": {
                                "cardNumber": "87TBU-43",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "87TBU-44": {
                                "cardNumber": "87TBU-44",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "87TBU-45": {
                                "cardNumber": "87TBU-45",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "87TBU-46": {
                                "cardNumber": "87TBU-46",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "87TBU-47": {
                                "cardNumber": "87TBU-47",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "87TBU-48": {
                                "cardNumber": "87TBU-48",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "87TBU-49": {
                                "cardNumber": "87TBU-49",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "87TBU-50": {
                                "cardNumber": "87TBU-50",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            }
                        },
                        "1987 Topps Baseball Chrome": {
                            "T87CU-1": {
                                "cardNumber": "T87CU-1",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-2": {
                                "cardNumber": "T87CU-2",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-3": {
                                "cardNumber": "T87CU-3",
                                "name": ["Carlos Rodon"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "T87CU-4": {
                                "cardNumber": "T87CU-4",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T87CU-5": {
                                "cardNumber": "T87CU-5",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-6": {
                                "cardNumber": "T87CU-6",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T87CU-7": {
                                "cardNumber": "T87CU-7",
                                "name": ["Andrew Benintendi"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "T87CU-8": {
                                "cardNumber": "T87CU-8",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "T87CU-9": {
                                "cardNumber": "T87CU-9",
                                "name": ["Andrew Vaughn"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "T87CU-10": {
                                "cardNumber": "T87CU-10",
                                "name": ["Tom Glavine"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T87CU-11": {
                                "cardNumber": "T87CU-11",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "T87CU-12": {
                                "cardNumber": "T87CU-12",
                                "name": ["Brandon Woodruff"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "T87CU-13": {
                                "cardNumber": "T87CU-13",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "T87CU-14": {
                                "cardNumber": "T87CU-14",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "T87CU-15": {
                                "cardNumber": "T87CU-15",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "T87CU-16": {
                                "cardNumber": "T87CU-16",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T87CU-17": {
                                "cardNumber": "T87CU-17",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "T87CU-18": {
                                "cardNumber": "T87CU-18",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-19": {
                                "cardNumber": "T87CU-19",
                                "name": ["Javier Baez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "T87CU-20": {
                                "cardNumber": "T87CU-20",
                                "name": ["Bryan De La Cruz"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-21": {
                                "cardNumber": "T87CU-21",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-22": {
                                "cardNumber": "T87CU-22",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-23": {
                                "cardNumber": "T87CU-23",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "T87CU-24": {
                                "cardNumber": "T87CU-24",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "T87CU-25": {
                                "cardNumber": "T87CU-25",
                                "name": ["Nolan Ryan"],
                                "team": ["California Angels™"],
                                "cardAttributes": []
                            },
                            "T87CU-26": {
                                "cardNumber": "T87CU-26",
                                "name": ["Joe Mauer"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "T87CU-27": {
                                "cardNumber": "T87CU-27",
                                "name": ["Edgar Martinez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "T87CU-28": {
                                "cardNumber": "T87CU-28",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-29": {
                                "cardNumber": "T87CU-29",
                                "name": ["Elehuris Montero"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-30": {
                                "cardNumber": "T87CU-30",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-31": {
                                "cardNumber": "T87CU-31",
                                "name": ["Mike Piazza"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "T87CU-32": {
                                "cardNumber": "T87CU-32",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-33": {
                                "cardNumber": "T87CU-33",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-34": {
                                "cardNumber": "T87CU-34",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-35": {
                                "cardNumber": "T87CU-35",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T87CU-36": {
                                "cardNumber": "T87CU-36",
                                "name": ["Greg Maddux"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T87CU-37": {
                                "cardNumber": "T87CU-37",
                                "name": ["David Wright"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T87CU-38": {
                                "cardNumber": "T87CU-38",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-39": {
                                "cardNumber": "T87CU-39",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-40": {
                                "cardNumber": "T87CU-40",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T87CU-41": {
                                "cardNumber": "T87CU-41",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-42": {
                                "cardNumber": "T87CU-42",
                                "name": ["Dustin Pedroia"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "T87CU-43": {
                                "cardNumber": "T87CU-43",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "T87CU-44": {
                                "cardNumber": "T87CU-44",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "T87CU-45": {
                                "cardNumber": "T87CU-45",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-46": {
                                "cardNumber": "T87CU-46",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "T87CU-47": {
                                "cardNumber": "T87CU-47",
                                "name": ["Mark McGwire"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "T87CU-48": {
                                "cardNumber": "T87CU-48",
                                "name": ["Josh Winder"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-49": {
                                "cardNumber": "T87CU-49",
                                "name": ["Trey Mancini"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "T87CU-50": {
                                "cardNumber": "T87CU-50",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "T87CU-51": {
                                "cardNumber": "T87CU-51",
                                "name": ["Nick Castellanos"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "T87CU-52": {
                                "cardNumber": "T87CU-52",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-53": {
                                "cardNumber": "T87CU-53",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "T87CU-54": {
                                "cardNumber": "T87CU-54",
                                "name": ["Carlton Fisk"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "T87CU-55": {
                                "cardNumber": "T87CU-55",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "T87CU-56": {
                                "cardNumber": "T87CU-56",
                                "name": ["Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T87CU-57": {
                                "cardNumber": "T87CU-57",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "T87CU-58": {
                                "cardNumber": "T87CU-58",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-59": {
                                "cardNumber": "T87CU-59",
                                "name": ["Chris Sale"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "T87CU-60": {
                                "cardNumber": "T87CU-60",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-61": {
                                "cardNumber": "T87CU-61",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "T87CU-62": {
                                "cardNumber": "T87CU-62",
                                "name": ["Jhoan Duran"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-63": {
                                "cardNumber": "T87CU-63",
                                "name": ["Reggie Jackson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "T87CU-64": {
                                "cardNumber": "T87CU-64",
                                "name": ["Patrick Wisdom"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "T87CU-65": {
                                "cardNumber": "T87CU-65",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "T87CU-66": {
                                "cardNumber": "T87CU-66",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "T87CU-67": {
                                "cardNumber": "T87CU-67",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-68": {
                                "cardNumber": "T87CU-68",
                                "name": ["Kevin Smith"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-69": {
                                "cardNumber": "T87CU-69",
                                "name": ["Larry Walker"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "T87CU-70": {
                                "cardNumber": "T87CU-70",
                                "name": ["Lars Nootbaar"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-71": {
                                "cardNumber": "T87CU-71",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "T87CU-72": {
                                "cardNumber": "T87CU-72",
                                "name": ["Joe Morgan"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "T87CU-73": {
                                "cardNumber": "T87CU-73",
                                "name": ["Colton Welker"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "T87CU-74": {
                                "cardNumber": "T87CU-74",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "T87CU-75": {
                                "cardNumber": "T87CU-75",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "Salute to the Mick": {
                            "STM-7": {
                                "cardNumber": "STM-7",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "STM-8": {
                                "cardNumber": "STM-8",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "STM-9": {
                                "cardNumber": "STM-9",
                                "name": ["Mickey Mantle"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "Stars of MLB": {
                            "SMLB-61": {
                                "cardNumber": "SMLB-61",
                                "name": ["Reid Detmers"],
                                "team": ["Angels"],
                                "cardAttributes": []
                            },
                            "SMLB-62": {
                                "cardNumber": "SMLB-62",
                                "name": ["Brandon Marsh"],
                                "team": ["Angels"],
                                "cardAttributes": []
                            },
                            "SMLB-63": {
                                "cardNumber": "SMLB-63",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "SMLB-64": {
                                "cardNumber": "SMLB-64",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "SMLB-65": {
                                "cardNumber": "SMLB-65",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "SMLB-66": {
                                "cardNumber": "SMLB-66",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox"],
                                "cardAttributes": []
                            },
                            "SMLB-67": {
                                "cardNumber": "SMLB-67",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": []
                            },
                            "SMLB-68": {
                                "cardNumber": "SMLB-68",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": []
                            },
                            "SMLB-69": {
                                "cardNumber": "SMLB-69",
                                "name": ["Javier Baez"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": []
                            },
                            "SMLB-70": {
                                "cardNumber": "SMLB-70",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": []
                            },
                            "SMLB-71": {
                                "cardNumber": "SMLB-71",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros"],
                                "cardAttributes": []
                            },
                            "SMLB-72": {
                                "cardNumber": "SMLB-72",
                                "name": ["Julio Urias"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "SMLB-73": {
                                "cardNumber": "SMLB-73",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": []
                            },
                            "SMLB-74": {
                                "cardNumber": "SMLB-74",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "SMLB-75": {
                                "cardNumber": "SMLB-75",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "SMLB-76": {
                                "cardNumber": "SMLB-76",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": []
                            },
                            "SMLB-77": {
                                "cardNumber": "SMLB-77",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": []
                            },
                            "SMLB-78": {
                                "cardNumber": "SMLB-78",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": []
                            },
                            "SMLB-79": {
                                "cardNumber": "SMLB-79",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": []
                            },
                            "SMLB-80": {
                                "cardNumber": "SMLB-80",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "SMLB-81": {
                                "cardNumber": "SMLB-81",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": []
                            },
                            "SMLB-82": {
                                "cardNumber": "SMLB-82",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "SMLB-83": {
                                "cardNumber": "SMLB-83",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "SMLB-84": {
                                "cardNumber": "SMLB-84",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": []
                            },
                            "SMLB-85": {
                                "cardNumber": "SMLB-85",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": []
                            },
                            "SMLB-86": {
                                "cardNumber": "SMLB-86",
                                "name": ["Shane Baz"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": []
                            },
                            "SMLB-87": {
                                "cardNumber": "SMLB-87",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners"],
                                "cardAttributes": []
                            },
                            "SMLB-88": {
                                "cardNumber": "SMLB-88",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": []
                            },
                            "SMLB-89": {
                                "cardNumber": "SMLB-89",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "SMLB-90": {
                                "cardNumber": "SMLB-90",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome Stars of MLB": {
                            "SMLBC-61": {
                                "cardNumber": "SMLBC-61",
                                "name": ["Reid Detmers"],
                                "team": ["Angels"],
                                "cardAttributes": []
                            },
                            "SMLBC-62": {
                                "cardNumber": "SMLBC-62",
                                "name": ["Brandon Marsh"],
                                "team": ["Angels"],
                                "cardAttributes": []
                            },
                            "SMLBC-63": {
                                "cardNumber": "SMLBC-63",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "SMLBC-64": {
                                "cardNumber": "SMLBC-64",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "SMLBC-65": {
                                "cardNumber": "SMLBC-65",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "SMLBC-66": {
                                "cardNumber": "SMLBC-66",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox"],
                                "cardAttributes": []
                            },
                            "SMLBC-67": {
                                "cardNumber": "SMLBC-67",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": []
                            },
                            "SMLBC-68": {
                                "cardNumber": "SMLBC-68",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": []
                            },
                            "SMLBC-69": {
                                "cardNumber": "SMLBC-69",
                                "name": ["Javier Baez"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": []
                            },
                            "SMLBC-70": {
                                "cardNumber": "SMLBC-70",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": []
                            },
                            "SMLBC-71": {
                                "cardNumber": "SMLBC-71",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros"],
                                "cardAttributes": []
                            },
                            "SMLBC-72": {
                                "cardNumber": "SMLBC-72",
                                "name": ["Julio Urias"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "SMLBC-73": {
                                "cardNumber": "SMLBC-73",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": []
                            },
                            "SMLBC-74": {
                                "cardNumber": "SMLBC-74",
                                "name": ["Max Scherzer"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "SMLBC-75": {
                                "cardNumber": "SMLBC-75",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "SMLBC-76": {
                                "cardNumber": "SMLBC-76",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": []
                            },
                            "SMLBC-77": {
                                "cardNumber": "SMLBC-77",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": []
                            },
                            "SMLBC-78": {
                                "cardNumber": "SMLBC-78",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": []
                            },
                            "SMLBC-79": {
                                "cardNumber": "SMLBC-79",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": []
                            },
                            "SMLBC-80": {
                                "cardNumber": "SMLBC-80",
                                "name": ["Yu Darvish"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "SMLBC-81": {
                                "cardNumber": "SMLBC-81",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": []
                            },
                            "SMLBC-82": {
                                "cardNumber": "SMLBC-82",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "SMLBC-83": {
                                "cardNumber": "SMLBC-83",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "SMLBC-84": {
                                "cardNumber": "SMLBC-84",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": []
                            },
                            "SMLBC-85": {
                                "cardNumber": "SMLBC-85",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": []
                            },
                            "SMLBC-86": {
                                "cardNumber": "SMLBC-86",
                                "name": ["Shane Baz"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": []
                            },
                            "SMLBC-87": {
                                "cardNumber": "SMLBC-87",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners"],
                                "cardAttributes": []
                            },
                            "SMLBC-88": {
                                "cardNumber": "SMLBC-88",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": []
                            },
                            "SMLBC-89": {
                                "cardNumber": "SMLBC-89",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "SMLBC-90": {
                                "cardNumber": "SMLBC-90",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            }
                        },
                        "Oversized Rookie Reprint Boxloader": {
                            "1": {
                                "cardNumber": "1",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox™"],
                                "cardAttributes": []
                            },
                            "2": {
                                "cardNumber": "2",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants™"],
                                "cardAttributes": []
                            },
                            "8": {
                                "cardNumber": "8",
                                "name": ["Don Mattingly"],
                                "team": ["New York Yankees™"],
                                "cardAttributes": []
                            },
                            "98": {
                                "cardNumber": "98",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees™"],
                                "cardAttributes": []
                            },
                            "164": {
                                "cardNumber": "164",
                                "name": ["Roberto Clemente"],
                                "team": ["Pittsburgh Pirates™"],
                                "cardAttributes": []
                            },
                            "228": {
                                "cardNumber": "228",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals™"],
                                "cardAttributes": []
                            },
                            "260": {
                                "cardNumber": "260",
                                "name": ["Reggie Jackson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "261": {
                                "cardNumber": "261",
                                "name": ["Willie Mays"],
                                "team": ["New York Giants™"],
                                "cardAttributes": []
                            },
                            "287": {
                                "cardNumber": "287",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees™"],
                                "cardAttributes": []
                            },
                            "312": {
                                "cardNumber": "312",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers™"],
                                "cardAttributes": []
                            },
                            "333": {
                                "cardNumber": "333",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "410": {
                                "cardNumber": "410",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "482": {
                                "cardNumber": "482",
                                "name": ["Tony Gwynn"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "482 (Alternate)": {
                                "cardNumber": "482",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "661": {
                                "cardNumber": "661",
                                "name": ["Bryce Harper"],
                                "team": ["Washington Nationals™"],
                                "cardAttributes": []
                            },
                            "700": {
                                "cardNumber": "700",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "726": {
                                "cardNumber": "726",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "41T": {
                                "cardNumber": "41T",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "98T": {
                                "cardNumber": "98T",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles™"],
                                "cardAttributes": []
                            },
                            "T247": {
                                "cardNumber": "T247",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals™"],
                                "cardAttributes": []
                            },
                            "UH240": {
                                "cardNumber": "UH240",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "US300": {
                                "cardNumber": "US300",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals™"],
                                "cardAttributes": []
                            }
                        },
                        "Homefield Advantage": {
                            "HA-21": {
                                "cardNumber": "HA-21",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "HA-22": {
                                "cardNumber": "HA-22",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "HA-23": {
                                "cardNumber": "HA-23",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "HA-24": {
                                "cardNumber": "HA-24",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "HA-25": {
                                "cardNumber": "HA-25",
                                "name": ["Jazz Chisholm"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "HA-26": {
                                "cardNumber": "HA-26",
                                "name": ["Sean Murphy"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": []
                            },
                            "HA-27": {
                                "cardNumber": "HA-27",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "HA-28": {
                                "cardNumber": "HA-28",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "HA-29": {
                                "cardNumber": "HA-29",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "HA-30": {
                                "cardNumber": "HA-30",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            }
                        },
                        "Legendary Homefield Advantage": {
                            "LHA-11": {
                                "cardNumber": "LHA-11",
                                "name": ["Tom Seaver"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "LHA-12": {
                                "cardNumber": "LHA-12",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "LHA-13": {
                                "cardNumber": "LHA-13",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": []
                            },
                            "LHA-14": {
                                "cardNumber": "LHA-14",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "LHA-15": {
                                "cardNumber": "LHA-15",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            }
                        },
                    },
                    "Relic": {
                        "Commemorative Batting Helmet Cards": {
                            "BH-AM": {
                                "cardNumber": "BH-AM",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "BH-AR": {
                                "cardNumber": "BH-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BH-ARI": {
                                "cardNumber": "BH-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BH-AT": {
                                "cardNumber": "BH-AT",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BH-BM": {
                                "cardNumber": "BH-BM",
                                "name": ["Brandon Marsh"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BH-BR": {
                                "cardNumber": "BH-BR",
                                "name": ["Brendan Rodgers"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "BH-BW": {
                                "cardNumber": "BH-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BH-CA": {
                                "cardNumber": "BH-CA",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "BH-CC": {
                                "cardNumber": "BH-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BH-CM": {
                                "cardNumber": "BH-CM",
                                "name": ["Cedric Mullins"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "BH-CS": {
                                "cardNumber": "BH-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BH-DC": {
                                "cardNumber": "BH-DC",
                                "name": ["Dylan Carlson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BH-EJ": {
                                "cardNumber": "BH-EJ",
                                "name": ["Eloy Jimenez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "BH-FF": {
                                "cardNumber": "BH-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BH-GS": {
                                "cardNumber": "BH-GS",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BH-GT": {
                                "cardNumber": "BH-GT",
                                "name": ["Gleyber Torres"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BH-JA": {
                                "cardNumber": "BH-JA",
                                "name": ["Jo Adell"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BH-JB": {
                                "cardNumber": "BH-JB",
                                "name": ["Javier Baez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "BH-JD": {
                                "cardNumber": "BH-JD",
                                "name": ["Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BH-JDU": {
                                "cardNumber": "BH-JDU",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BH-JG": {
                                "cardNumber": "BH-JG",
                                "name": ["Joey Gallo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BH-JI": {
                                "cardNumber": "BH-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BH-JM": {
                                "cardNumber": "BH-JM",
                                "name": ["J.D. Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BH-JPE": {
                                "cardNumber": "BH-JPE",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BH-JR": {
                                "cardNumber": "BH-JR",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "BH-JTR": {
                                "cardNumber": "BH-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BH-JV": {
                                "cardNumber": "BH-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BH-KB": {
                                "cardNumber": "BH-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "BH-KS": {
                                "cardNumber": "BH-KS",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BH-KT": {
                                "cardNumber": "BH-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BH-MC": {
                                "cardNumber": "BH-MC",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BH-MH": {
                                "cardNumber": "BH-MH",
                                "name": ["Mitch Haniger"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "BH-MO": {
                                "cardNumber": "BH-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BH-MS": {
                                "cardNumber": "BH-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BH-MY": {
                                "cardNumber": "BH-MY",
                                "name": ["Mike Yastrzemski"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "BH-OA": {
                                "cardNumber": "BH-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BH-OC": {
                                "cardNumber": "BH-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BH-PW": {
                                "cardNumber": "BH-PW",
                                "name": ["Patrick Wisdom"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BH-RA": {
                                "cardNumber": "BH-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "BH-RL": {
                                "cardNumber": "BH-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BH-SM": {
                                "cardNumber": "BH-SM",
                                "name": ["Sean Murphy"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": []
                            },
                            "BH-SMA": {
                                "cardNumber": "BH-SMA",
                                "name": ["Starling Marte"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "BH-SS": {
                                "cardNumber": "BH-SS",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BH-ST": {
                                "cardNumber": "BH-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "BH-TA": {
                                "cardNumber": "BH-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "BH-TO": {
                                "cardNumber": "BH-TO",
                                "name": ["Tyler O'Neill"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BH-TS": {
                                "cardNumber": "BH-TS",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BH-TT": {
                                "cardNumber": "BH-TT",
                                "name": ["Trea Turner"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BH-VB": {
                                "cardNumber": "BH-VB",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "BH-WA": {
                                "cardNumber": "BH-WA",
                                "name": ["Willy Adames"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            }
                        },
                        "2022 Special Event Patch Cards": {
                            "SEP-AJ": {
                                "cardNumber": "SEP-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SEP-AJU": {
                                "cardNumber": "SEP-AJU",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SEP-AM": {
                                "cardNumber": "SEP-AM",
                                "name": ["Andrew McCutchen"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "SEP-BBX": {
                                "cardNumber": "SEP-BBX",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SEP-BH": {
                                "cardNumber": "SEP-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SEP-BHA": {
                                "cardNumber": "SEP-BHA",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SEP-BWJ": {
                                "cardNumber": "SEP-BWJ",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "SEP-CC": {
                                "cardNumber": "SEP-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SEP-CJA": {
                                "cardNumber": "SEP-CJA",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-CM": {
                                "cardNumber": "SEP-CM",
                                "name": ["Cedric Mullins"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SEP-FL": {
                                "cardNumber": "SEP-FL",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "SEP-FT": {
                                "cardNumber": "SEP-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-FTJ": {
                                "cardNumber": "SEP-FTJ",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-GC": {
                                "cardNumber": "SEP-GC",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SEP-GST": {
                                "cardNumber": "SEP-GST",
                                "name": ["Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SEP-JA": {
                                "cardNumber": "SEP-JA",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SEP-JAZ": {
                                "cardNumber": "SEP-JAZ",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "SEP-JB": {
                                "cardNumber": "SEP-JB",
                                "name": ["Javier Baez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "SEP-JROD": {
                                "cardNumber": "SEP-JROD",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "SEP-JS": {
                                "cardNumber": "SEP-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "SEP-JSO": {
                                "cardNumber": "SEP-JSO",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "SEP-LR": {
                                "cardNumber": "SEP-LR",
                                "name": ["Luis Robert"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "SEP-MB": {
                                "cardNumber": "SEP-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SEP-MBE": {
                                "cardNumber": "SEP-MBE",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SEP-MC": {
                                "cardNumber": "SEP-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "SEP-MM": {
                                "cardNumber": "SEP-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-MT": {
                                "cardNumber": "SEP-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-MTR": {
                                "cardNumber": "SEP-MTR",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-PA": {
                                "cardNumber": "SEP-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "SEP-RA": {
                                "cardNumber": "SEP-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SEP-RAJ": {
                                "cardNumber": "SEP-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SEP-RD": {
                                "cardNumber": "SEP-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "SEP-RM": {
                                "cardNumber": "SEP-RM",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SEP-ROY": {
                                "cardNumber": "SEP-ROY",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SEP-SEI": {
                                "cardNumber": "SEP-SEI",
                                "name": ["Seiya Suzuki"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "SEP-SO": {
                                "cardNumber": "SEP-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-SOH": {
                                "cardNumber": "SEP-SOH",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-TA": {
                                "cardNumber": "SEP-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "SEP-TM": {
                                "cardNumber": "SEP-TM",
                                "name": ["Trey Mancini"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SEP-TS": {
                                "cardNumber": "SEP-TS",
                                "name": ["Trevor Story"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "SEP-VG": {
                                "cardNumber": "SEP-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "SEP-VGJ": {
                                "cardNumber": "SEP-VGJ",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "SEP-WF": {
                                "cardNumber": "SEP-WF",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "SEP-WFR": {
                                "cardNumber": "SEP-WFR",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "SEP-XB": {
                                "cardNumber": "SEP-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "SEP-YM": {
                                "cardNumber": "SEP-YM",
                                "name": ["Yadier Molina"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            }
                        },
                        "Major League Material Cards": {
                            "MLM-AB": {
                                "cardNumber": "MLM-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLM-AJ": {
                                "cardNumber": "MLM-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-AP": {
                                "cardNumber": "MLM-AP",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MLM-AR": {
                                "cardNumber": "MLM-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-BB": {
                                "cardNumber": "MLM-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLM-BC": {
                                "cardNumber": "MLM-BC",
                                "name": ["Brandon Crawford"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLM-BH": {
                                "cardNumber": "MLM-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLM-BM": {
                                "cardNumber": "MLM-BM",
                                "name": ["Brandon Marsh"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLM-BP": {
                                "cardNumber": "MLM-BP",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "MLM-BW": {
                                "cardNumber": "MLM-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MLM-CC": {
                                "cardNumber": "MLM-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLM-CS": {
                                "cardNumber": "MLM-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "MLM-CY": {
                                "cardNumber": "MLM-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "MLM-DJ": {
                                "cardNumber": "MLM-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-DO": {
                                "cardNumber": "MLM-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "MLM-ELO": {
                                "cardNumber": "MLM-ELO",
                                "name": ["Eloy Jimenez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLM-FF": {
                                "cardNumber": "MLM-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLM-GC": {
                                "cardNumber": "MLM-GC",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-GS": {
                                "cardNumber": "MLM-GS",
                                "name": ["Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLM-JC": {
                                "cardNumber": "MLM-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "MLM-JV": {
                                "cardNumber": "MLM-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "MLM-KB": {
                                "cardNumber": "MLM-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "MLM-LR": {
                                "cardNumber": "MLM-LR",
                                "name": ["Luis Robert"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLM-MB": {
                                "cardNumber": "MLM-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLM-MT": {
                                "cardNumber": "MLM-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLM-OA": {
                                "cardNumber": "MLM-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLM-OC": {
                                "cardNumber": "MLM-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLM-PA": {
                                "cardNumber": "MLM-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "MLM-PGO": {
                                "cardNumber": "MLM-PGO",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "MLM-RA": {
                                "cardNumber": "MLM-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLM-RDE": {
                                "cardNumber": "MLM-RDE",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "MLM-RMO": {
                                "cardNumber": "MLM-RMO",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MLM-SB": {
                                "cardNumber": "MLM-SB",
                                "name": ["Shane Baz"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "MLM-VG": {
                                "cardNumber": "MLM-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLM-WF": {
                                "cardNumber": "MLM-WF",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "MLM-XB": {
                                "cardNumber": "MLM-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitches Cards": {
                            "ASSC-AB": {
                                "cardNumber": "ASSC-AB",
                                "name": ["Andrew Benintendi"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASSC-AG": {
                                "cardNumber": "ASSC-AG",
                                "name": ["Andres Gimenez"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "ASSC-AJ": {
                                "cardNumber": "ASSC-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSC-AK": {
                                "cardNumber": "ASSC-AK",
                                "name": ["Alejandro Kirk"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSC-AM": {
                                "cardNumber": "ASSC-AM",
                                "name": ["Alek Manoah"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSC-AR": {
                                "cardNumber": "ASSC-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSC-BB": {
                                "cardNumber": "ASSC-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASSC-CC": {
                                "cardNumber": "ASSC-CC",
                                "name": ["C.J. Cron"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "ASSC-CH": {
                                "cardNumber": "ASSC-CH",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSC-CS": {
                                "cardNumber": "ASSC-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASSC-DS": {
                                "cardNumber": "ASSC-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSC-DW": {
                                "cardNumber": "ASSC-DW",
                                "name": ["Devin Williams"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "ASSC-EC": {
                                "cardNumber": "ASSC-EC",
                                "name": ["Emmanuel Clase"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "ASSC-FF": {
                                "cardNumber": "ASSC-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSC-FV": {
                                "cardNumber": "ASSC-FV",
                                "name": ["Framber Valdez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASSC-GC": {
                                "cardNumber": "ASSC-GC",
                                "name": ["Garrett Cooper"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASSC-GST": {
                                "cardNumber": "ASSC-GST",
                                "name": ["Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSC-IH": {
                                "cardNumber": "ASSC-IH",
                                "name": ["Ian Happ"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ASSC-JC": {
                                "cardNumber": "ASSC-JC",
                                "name": ["Jake Cronenworth"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASSC-JCJ": {
                                "cardNumber": "ASSC-JCJ",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASSC-JDM": {
                                "cardNumber": "ASSC-JDM",
                                "name": ["J.D. Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASSC-JMC": {
                                "cardNumber": "ASSC-JMC",
                                "name": ["Jeff McNeil"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASSC-JMU": {
                                "cardNumber": "ASSC-JMU",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASSC-JP": {
                                "cardNumber": "ASSC-JP",
                                "name": ["Joc Pederson"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASSC-JRA": {
                                "cardNumber": "ASSC-JRA",
                                "name": ["Jose Ramirez"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "ASSC-JS": {
                                "cardNumber": "ASSC-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASSC-JT": {
                                "cardNumber": "ASSC-JT",
                                "name": ["Jose Trevino"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSC-JV": {
                                "cardNumber": "ASSC-JV",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASSC-KS": {
                                "cardNumber": "ASSC-KS",
                                "name": ["Kyle Schwarber"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "ASSC-KT": {
                                "cardNumber": "ASSC-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASSC-LA": {
                                "cardNumber": "ASSC-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASSC-LC": {
                                "cardNumber": "ASSC-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASSC-LH": {
                                "cardNumber": "ASSC-LH",
                                "name": ["Liam Hendriks"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASSC-MB": {
                                "cardNumber": "ASSC-MB",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSC-MC": {
                                "cardNumber": "ASSC-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASSC-MF": {
                                "cardNumber": "ASSC-MF",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSC-MMA": {
                                "cardNumber": "ASSC-MMA",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASSC-MT": {
                                "cardNumber": "ASSC-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASSC-NC": {
                                "cardNumber": "ASSC-NC",
                                "name": ["Nestor Cortes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSC-PA": {
                                "cardNumber": "ASSC-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASSC-PB": {
                                "cardNumber": "ASSC-PB",
                                "name": ["Paul Blackburn"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": []
                            },
                            "ASSC-PG": {
                                "cardNumber": "ASSC-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASSC-RA": {
                                "cardNumber": "ASSC-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSC-RD": {
                                "cardNumber": "ASSC-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASSC-RH": {
                                "cardNumber": "ASSC-RH",
                                "name": ["Ryan Helsley"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASSC-SA": {
                                "cardNumber": "ASSC-SA",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASSC-SE": {
                                "cardNumber": "ASSC-SE",
                                "name": ["Santiago Espinal"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSC-SM": {
                                "cardNumber": "ASSC-SM",
                                "name": ["Shane McClanahan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "ASSC-SO": {
                                "cardNumber": "ASSC-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASSC-SO2": {
                                "cardNumber": "ASSC-SO2",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASSC-TAN": {
                                "cardNumber": "ASSC-TAN",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASSC-TF": {
                                "cardNumber": "ASSC-TF",
                                "name": ["Ty France"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "ASSC-TG": {
                                "cardNumber": "ASSC-TG",
                                "name": ["Tony Gonsolin"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSC-TT": {
                                "cardNumber": "ASSC-TT",
                                "name": ["Trea Turner"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSC-Td": {
                                "cardNumber": "ASSC-Td",
                                "name": ["Travis d'Arnaud"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSC-VG": {
                                "cardNumber": "ASSC-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSC-WC": {
                                "cardNumber": "ASSC-WC",
                                "name": ["William Contreras"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSC-WCO": {
                                "cardNumber": "ASSC-WCO",
                                "name": ["Willson Contreras"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ASSC-XB": {
                                "cardNumber": "ASSC-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Jumbo Patch Cards": {
                            "ASJC-AB": {
                                "cardNumber": "ASJC-AB",
                                "name": ["Andrew Benintendi"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASJC-AG": {
                                "cardNumber": "ASJC-AG",
                                "name": ["Andres Gimenez"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "ASJC-AJ": {
                                "cardNumber": "ASJC-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJC-AK": {
                                "cardNumber": "ASJC-AK",
                                "name": ["Alejandro Kirk"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASJC-AM": {
                                "cardNumber": "ASJC-AM",
                                "name": ["Alek Manoah"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASJC-AR": {
                                "cardNumber": "ASJC-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJC-BB": {
                                "cardNumber": "ASJC-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASJC-CC": {
                                "cardNumber": "ASJC-CC",
                                "name": ["C.J. Cron"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "ASJC-CH": {
                                "cardNumber": "ASJC-CH",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJC-CR": {
                                "cardNumber": "ASJC-CR",
                                "name": ["Carlos Rodon"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASJC-CS": {
                                "cardNumber": "ASJC-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASJC-DS": {
                                "cardNumber": "ASJC-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJC-DW": {
                                "cardNumber": "ASJC-DW",
                                "name": ["Devin Williams"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "ASJC-GC": {
                                "cardNumber": "ASJC-GC",
                                "name": ["Garrett Cooper"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASJC-GCO": {
                                "cardNumber": "ASJC-GCO",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJC-GST": {
                                "cardNumber": "ASJC-GST",
                                "name": ["Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJC-IH": {
                                "cardNumber": "ASJC-IH",
                                "name": ["Ian Happ"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ASJC-JC": {
                                "cardNumber": "ASJC-JC",
                                "name": ["Jake Cronenworth"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASJC-JCJ": {
                                "cardNumber": "ASJC-JCJ",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASJC-JM": {
                                "cardNumber": "ASJC-JM",
                                "name": ["J.D. Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASJC-JMC": {
                                "cardNumber": "ASJC-JMC",
                                "name": ["Jeff McNeil"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASJC-JMU": {
                                "cardNumber": "ASJC-JMU",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASJC-JP": {
                                "cardNumber": "ASJC-JP",
                                "name": ["Joc Pederson"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASJC-JRA": {
                                "cardNumber": "ASJC-JRA",
                                "name": ["Jose Ramirez"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "ASJC-JS": {
                                "cardNumber": "ASJC-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASJC-JT": {
                                "cardNumber": "ASJC-JT",
                                "name": ["Jose Trevino"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJC-JV": {
                                "cardNumber": "ASJC-JV",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASJC-KT": {
                                "cardNumber": "ASJC-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASJC-LA": {
                                "cardNumber": "ASJC-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASJC-LC": {
                                "cardNumber": "ASJC-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASJC-LH": {
                                "cardNumber": "ASJC-LH",
                                "name": ["Liam Hendriks"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASJC-MC": {
                                "cardNumber": "ASJC-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASJC-MMA": {
                                "cardNumber": "ASJC-MMA",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASJC-MT": {
                                "cardNumber": "ASJC-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASJC-NC": {
                                "cardNumber": "ASJC-NC",
                                "name": ["Nestor Cortes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJC-PA": {
                                "cardNumber": "ASJC-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASJC-PG": {
                                "cardNumber": "ASJC-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASJC-RA": {
                                "cardNumber": "ASJC-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJC-RD": {
                                "cardNumber": "ASJC-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASJC-RH": {
                                "cardNumber": "ASJC-RH",
                                "name": ["Ryan Helsley"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASJC-SA": {
                                "cardNumber": "ASJC-SA",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASJC-SM": {
                                "cardNumber": "ASJC-SM",
                                "name": ["Shane McClanahan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "ASJC-TAN": {
                                "cardNumber": "ASJC-TAN",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASJC-TF": {
                                "cardNumber": "ASJC-TF",
                                "name": ["Ty France"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "ASJC-TG": {
                                "cardNumber": "ASJC-TG",
                                "name": ["Tony Gonsolin"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASJC-Td": {
                                "cardNumber": "ASJC-Td",
                                "name": ["Travis d'Arnaud"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJC-VG": {
                                "cardNumber": "ASJC-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASJC-WC": {
                                "cardNumber": "ASJC-WC",
                                "name": ["William Contreras"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJC-WCO": {
                                "cardNumber": "ASJC-WCO",
                                "name": ["Willson Contreras"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ASJC-XB": {
                                "cardNumber": "ASJC-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitches Dual Cards": {
                            "ASSD-AS": {
                                "cardNumber": "ASSD-AS",
                                "name": ["Dansby Swanson", "Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSD-BT": {
                                "cardNumber": "ASSD-BT",
                                "name": ["Trea Turner", "Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSD-CJA": {
                                "cardNumber": "ASSD-CJA",
                                "name": ["Sandy Alcantara", "Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASSD-DBO": {
                                "cardNumber": "ASSD-DBO",
                                "name": ["Rafael Devers", "Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASSD-GM": {
                                "cardNumber": "ASSD-GM",
                                "name": ["Alek Manoah", "Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSD-JCO": {
                                "cardNumber": "ASSD-JCO",
                                "name": ["Gerrit Cole", "Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSD-JS": {
                                "cardNumber": "ASSD-JS",
                                "name": ["Aaron Judge", "Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSD-JTR": {
                                "cardNumber": "ASSD-JTR",
                                "name": ["Aaron Judge", "Mike Trout"],
                                "team": ["New York Yankees®", "Angels®"],
                                "cardAttributes": []
                            },
                            "ASSD-MM": {
                                "cardNumber": "ASSD-MM",
                                "name": ["Manny Machado", "Joe Musgrove"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASSD-SAJ": {
                                "cardNumber": "ASSD-SAJ",
                                "name": ["Ronald Acuña Jr.", "Juan Soto"],
                                "team": ["Atlanta Braves™", "Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASSD-TRO": {
                                "cardNumber": "ASSD-TRO",
                                "name": ["Mike Trout", "Shohei Ohtani"],
                                "team": ["Angels®", "Angels®"],
                                "cardAttributes": []
                            },
                            "ASSD-VT": {
                                "cardNumber": "ASSD-VT",
                                "name": ["Kyle Tucker", "Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitches Triple Cards": {
                            "ASST-ASR": {
                                "cardNumber": "ASST-ASR",
                                "name": ["Austin Riley", "Ronald Acuña Jr.", "Dansby Swanson"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASST-BSA": {
                                "cardNumber": "ASST-BSA",
                                "name": ["Mookie Betts", "Juan Soto", "Ronald Acuña Jr."],
                                "team": ["Los Angeles Dodgers®", "Washington Nationals®", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASST-GKM": {
                                "cardNumber": "ASST-GKM",
                                "name": ["Alejandro Kirk", "Alek Manoah", "Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASST-JSC": {
                                "cardNumber": "ASST-JSC",
                                "name": ["Gerrit Cole", "Giancarlo Stanton", "Aaron Judge"],
                                "team": ["New York Yankees®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASST-JTS": {
                                "cardNumber": "ASST-JTS",
                                "name": ["Mike Trout", "Giancarlo Stanton", "Aaron Judge"],
                                "team": ["Angels®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASST-OTO": {
                                "cardNumber": "ASST-OTO",
                                "name": ["Shohei Ohtani", "Mike Trout", "TBA"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "Own The Name Relics": {
                            "OTN-AB": {
                                "cardNumber": "OTN-AB",
                                "name": ["Andrew Benintendi"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-ABR": {
                                "cardNumber": "OTN-ABR",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-AMC": {
                                "cardNumber": "OTN-AMC",
                                "name": ["Andrew McCutchen"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-AN": {
                                "cardNumber": "OTN-AN",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-AR": {
                                "cardNumber": "OTN-AR",
                                "name": ["Anthony Rendon"],
                                "team": ["Angels®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-ARI": {
                                "cardNumber": "OTN-ARI",
                                "name": ["Anthony Rizzo"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-BB": {
                                "cardNumber": "OTN-BB",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-BBX": {
                                "cardNumber": "OTN-BBX",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-BH": {
                                "cardNumber": "OTN-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-BN": {
                                "cardNumber": "OTN-BN",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-BS": {
                                "cardNumber": "OTN-BS",
                                "name": ["Blake Snell"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-CB": {
                                "cardNumber": "OTN-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-CBE": {
                                "cardNumber": "OTN-CBE",
                                "name": ["Cody Bellinger"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-CC": {
                                "cardNumber": "OTN-CC",
                                "name": ["Carlos Correa"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-CK": {
                                "cardNumber": "OTN-CK",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-CS": {
                                "cardNumber": "OTN-CS",
                                "name": ["Corey Seager"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-CY": {
                                "cardNumber": "OTN-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-CYE": {
                                "cardNumber": "OTN-CYE",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-DP": {
                                "cardNumber": "OTN-DP",
                                "name": ["Dustin Pedroia"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-DS": {
                                "cardNumber": "OTN-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-EH": {
                                "cardNumber": "OTN-EH",
                                "name": ["Eric Hosmer"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-FF": {
                                "cardNumber": "OTN-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-FT": {
                                "cardNumber": "OTN-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-GL": {
                                "cardNumber": "OTN-GL",
                                "name": ["Gavin Lux"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-GSP": {
                                "cardNumber": "OTN-GSP",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JA": {
                                "cardNumber": "OTN-JA",
                                "name": ["Jose Abreu"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JAL": {
                                "cardNumber": "OTN-JAL",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JB": {
                                "cardNumber": "OTN-JB",
                                "name": ["Josh Bell"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JBA": {
                                "cardNumber": "OTN-JBA",
                                "name": ["Javier Baez"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JBE": {
                                "cardNumber": "OTN-JBE",
                                "name": ["Jose Berrios"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JC": {
                                "cardNumber": "OTN-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JDM": {
                                "cardNumber": "OTN-JDM",
                                "name": ["J.D. Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JH": {
                                "cardNumber": "OTN-JH",
                                "name": ["Josh Hader"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JHE": {
                                "cardNumber": "OTN-JHE",
                                "name": ["Jason Heyward"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JMC": {
                                "cardNumber": "OTN-JMC",
                                "name": ["Jeff McNeil"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JP": {
                                "cardNumber": "OTN-JP",
                                "name": ["Jorge Polanco"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JR": {
                                "cardNumber": "OTN-JR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JS": {
                                "cardNumber": "OTN-JS",
                                "name": ["Jean Segura"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JU": {
                                "cardNumber": "OTN-JU",
                                "name": ["Julio Urias"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JV": {
                                "cardNumber": "OTN-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JW": {
                                "cardNumber": "OTN-JW",
                                "name": ["Jesse Winker"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-JD": {
                                "cardNumber": "OTN-JD",
                                "name": ["Jacob deGrom"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-KB": {
                                "cardNumber": "OTN-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-KBR": {
                                "cardNumber": "OTN-KBR",
                                "name": ["Kris Bryant"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-KT": {
                                "cardNumber": "OTN-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-LA": {
                                "cardNumber": "OTN-LA",
                                "name": ["Luis Arraez"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-LC": {
                                "cardNumber": "OTN-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-LCA": {
                                "cardNumber": "OTN-LCA",
                                "name": ["Lorenzo Cain"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-LG": {
                                "cardNumber": "OTN-LG",
                                "name": ["Lourdes Gurriel Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MBE": {
                                "cardNumber": "OTN-MBE",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MC": {
                                "cardNumber": "OTN-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MCH": {
                                "cardNumber": "OTN-MCH",
                                "name": ["Matt Chapman"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MF": {
                                "cardNumber": "OTN-MF",
                                "name": ["Max Fried"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MK": {
                                "cardNumber": "OTN-MK",
                                "name": ["Max Kepler"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MM": {
                                "cardNumber": "OTN-MM",
                                "name": ["Mike Moustakas"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MMA": {
                                "cardNumber": "OTN-MMA",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MMU": {
                                "cardNumber": "OTN-MMU",
                                "name": ["Max Muncy"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MS": {
                                "cardNumber": "OTN-MS",
                                "name": ["Miguel Sano"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MSC": {
                                "cardNumber": "OTN-MSC",
                                "name": ["Max Scherzer"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MT": {
                                "cardNumber": "OTN-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-MY": {
                                "cardNumber": "OTN-MY",
                                "name": ["Mike Yastrzemski"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-OA": {
                                "cardNumber": "OTN-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-PG": {
                                "cardNumber": "OTN-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-RA": {
                                "cardNumber": "OTN-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-RD": {
                                "cardNumber": "OTN-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-RH": {
                                "cardNumber": "OTN-RH",
                                "name": ["Rhys Hoskins"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-SP": {
                                "cardNumber": "OTN-SP",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-SS": {
                                "cardNumber": "OTN-SS",
                                "name": ["Stephen Strasburg"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-TH": {
                                "cardNumber": "OTN-TH",
                                "name": ["Teoscar Hernandez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-TS": {
                                "cardNumber": "OTN-TS",
                                "name": ["Trevor Story"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-VG": {
                                "cardNumber": "OTN-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-WB": {
                                "cardNumber": "OTN-WB",
                                "name": ["Walker Buehler"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-WC": {
                                "cardNumber": "OTN-WC",
                                "name": ["Willson Contreras"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-WM": {
                                "cardNumber": "OTN-WM",
                                "name": ["Whit Merrifield"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-WS": {
                                "cardNumber": "OTN-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-XB": {
                                "cardNumber": "OTN-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-YA": {
                                "cardNumber": "OTN-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-YG": {
                                "cardNumber": "OTN-YG",
                                "name": ["Yuli Gurriel"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-ZG": {
                                "cardNumber": "OTN-ZG",
                                "name": ["Zack Greinke"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["1/1"]
                            },
                            "OTN-ZW": {
                                "cardNumber": "OTN-ZW",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["1/1"]
                            }
                        },
                        "Cut Signatures": {
                            "CS-AK": {
                                "cardNumber": "CS-AK",
                                "name": ["Al Kaline"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-BG": {
                                "cardNumber": "CS-BG",
                                "name": ["Bob Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-BH": {
                                "cardNumber": "CS-BH",
                                "name": ["Billy Herman"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-DDR": {
                                "cardNumber": "CS-DDR",
                                "name": ["Don Drysdale"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-DS": {
                                "cardNumber": "CS-DS",
                                "name": ["Duke Snider"],
                                "team": ["Brooklyn Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-EB": {
                                "cardNumber": "CS-EB",
                                "name": ["Ernie Banks"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-EM": {
                                "cardNumber": "CS-EM",
                                "name": ["Eddie Mathews"],
                                "team": ["Milwaukee Braves®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-GK": {
                                "cardNumber": "CS-GK",
                                "name": ["George Kell"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-GKY": {
                                "cardNumber": "CS-GKY",
                                "name": ["George 'High Pockets' Kelly"],
                                "team": ["New York Giants®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-HA": {
                                "cardNumber": "CS-HA",
                                "name": ["Hank Aaron"],
                                "team": ["Milwaukee Braves®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-HG": {
                                "cardNumber": "CS-HG",
                                "name": ["Hank Greenberg"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-HK": {
                                "cardNumber": "CS-HK",
                                "name": ["Harmon Killebrew"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-JD": {
                                "cardNumber": "CS-JD",
                                "name": ["Joe DiMaggio"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-JM": {
                                "cardNumber": "CS-JM",
                                "name": ["Johnny Mize"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-JR": {
                                "cardNumber": "CS-JR",
                                "name": ["Jackie Robinson"],
                                "team": ["Brooklyn Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-MI": {
                                "cardNumber": "CS-MI",
                                "name": ["Monte Irvin"],
                                "team": ["New York Giants®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-PR": {
                                "cardNumber": "CS-PR",
                                "name": ["Pee Wee Reese"],
                                "team": ["Brooklyn Dodgers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-RM": {
                                "cardNumber": "CS-RM",
                                "name": ["Roger Maris"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-SM": {
                                "cardNumber": "CS-SM",
                                "name": ["Stan Musial"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-SP": {
                                "cardNumber": "CS-SP",
                                "name": ["Satchel Paige"],
                                "team": ["St. Louis Browns®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-TC": {
                                "cardNumber": "CS-TC",
                                "name": ["Ty Cobb"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-TG": {
                                "cardNumber": "CS-TG",
                                "name": ["Tony Gwynn"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-TW": {
                                "cardNumber": "CS-TW",
                                "name": ["Ted Williams"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-WM": {
                                "cardNumber": "CS-WM",
                                "name": ["Willie McCovey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["1/1"]
                            },
                            "CS-WS": {
                                "cardNumber": "CS-WS",
                                "name": ["Warren Spahn"],
                                "team": ["Milwaukee Braves®"],
                                "cardAttributes": ["1/1"]
                            }
                        }
                    },
                    "Autograph Relic": {
                        "Commemorative Batting Helmet Cards Autograph Parallel": {
                            "BH-AR": {
                                "cardNumber": "BH-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BH-ARI": {
                                "cardNumber": "BH-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BH-AT": {
                                "cardNumber": "BH-AT",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BH-BR": {
                                "cardNumber": "BH-BR",
                                "name": ["Brendan Rodgers"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "BH-BW": {
                                "cardNumber": "BH-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BH-CA": {
                                "cardNumber": "BH-CA",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "BH-CS": {
                                "cardNumber": "BH-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BH-DC": {
                                "cardNumber": "BH-DC",
                                "name": ["Dylan Carlson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BH-EJ": {
                                "cardNumber": "BH-EJ",
                                "name": ["Eloy Jimenez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "BH-GS": {
                                "cardNumber": "BH-GS",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BH-GT": {
                                "cardNumber": "BH-GT",
                                "name": ["Gleyber Torres"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BH-JD": {
                                "cardNumber": "BH-JD",
                                "name": ["Josh Donaldson"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BH-JDU": {
                                "cardNumber": "BH-JDU",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BH-JI": {
                                "cardNumber": "BH-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BH-JPE": {
                                "cardNumber": "BH-JPE",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BH-JR": {
                                "cardNumber": "BH-JR",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "BH-JTR": {
                                "cardNumber": "BH-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BH-JV": {
                                "cardNumber": "BH-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BH-MC": {
                                "cardNumber": "BH-MC",
                                "name": ["Matt Chapman"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BH-MH": {
                                "cardNumber": "BH-MH",
                                "name": ["Mitch Haniger"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "BH-MO": {
                                "cardNumber": "BH-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BH-MS": {
                                "cardNumber": "BH-MS",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BH-OA": {
                                "cardNumber": "BH-OA",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BH-OC": {
                                "cardNumber": "BH-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BH-PW": {
                                "cardNumber": "BH-PW",
                                "name": ["Patrick Wisdom"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "BH-RA": {
                                "cardNumber": "BH-RA",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "BH-RL": {
                                "cardNumber": "BH-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BH-SM": {
                                "cardNumber": "BH-SM",
                                "name": ["Sean Murphy"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": []
                            },
                            "BH-ST": {
                                "cardNumber": "BH-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "BH-TA": {
                                "cardNumber": "BH-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "BH-VB": {
                                "cardNumber": "BH-VB",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            }
                        },
                        "2022 Special Event Patch Cards Autograph Parallel": {
                            "SEP-AJ": {
                                "cardNumber": "SEP-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SEP-AJU": {
                                "cardNumber": "SEP-AJU",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SEP-BWJ": {
                                "cardNumber": "SEP-BWJ",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "SEP-BH": {
                                "cardNumber": "SEP-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "SEP-CJA": {
                                "cardNumber": "SEP-CJA",
                                "name": ["C.J. Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-FTJ": {
                                "cardNumber": "SEP-FTJ",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-FT": {
                                "cardNumber": "SEP-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-JA": {
                                "cardNumber": "SEP-JA",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "SEP-JSO": {
                                "cardNumber": "SEP-JSO",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "SEP-JS": {
                                "cardNumber": "SEP-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "SEP-JROD": {
                                "cardNumber": "SEP-JROD",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "SEP-MM": {
                                "cardNumber": "SEP-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "SEP-MC": {
                                "cardNumber": "SEP-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "SEP-MT": {
                                "cardNumber": "SEP-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-MTR": {
                                "cardNumber": "SEP-MTR",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-PA": {
                                "cardNumber": "SEP-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "SEP-RA": {
                                "cardNumber": "SEP-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SEP-RAJ": {
                                "cardNumber": "SEP-RAJ",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SEP-ROY": {
                                "cardNumber": "SEP-ROY",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "SEP-SO": {
                                "cardNumber": "SEP-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-SOH": {
                                "cardNumber": "SEP-SOH",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SEP-TA": {
                                "cardNumber": "SEP-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "SEP-TM": {
                                "cardNumber": "SEP-TM",
                                "name": ["Trey Mancini"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "SEP-VG": {
                                "cardNumber": "SEP-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "SEP-WFR": {
                                "cardNumber": "SEP-WFR",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "SEP-WF": {
                                "cardNumber": "SEP-WF",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "SEP-XB": {
                                "cardNumber": "SEP-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Reverence Autograph Patch Cards": {
                            "TRAP-ABE": {
                                "cardNumber": "TRAP-ABE",
                                "name": ["Adrian Beltre"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "TRAP-ADB": {
                                "cardNumber": "TRAP-ADB",
                                "name": ["Adrian Beltre"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TRAP-AJ": {
                                "cardNumber": "TRAP-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP-AM": {
                                "cardNumber": "TRAP-AM",
                                "name": ["Austin Meadows"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "TRAP-AN": {
                                "cardNumber": "TRAP-AN",
                                "name": ["Aaron Nola"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TRAP-BW": {
                                "cardNumber": "TRAP-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TRAP-CY": {
                                "cardNumber": "TRAP-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "TRAP-DJ": {
                                "cardNumber": "TRAP-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP-DWI": {
                                "cardNumber": "TRAP-DWI",
                                "name": ["Dave Winfield"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "TRAP-FT": {
                                "cardNumber": "TRAP-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "TRAP-JI": {
                                "cardNumber": "TRAP-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "TRAP-JMC": {
                                "cardNumber": "TRAP-JMC",
                                "name": ["Jeff McNeil"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TRAP-JR": {
                                "cardNumber": "TRAP-JR",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "TRAP-JS": {
                                "cardNumber": "TRAP-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "TRAP-JSM": {
                                "cardNumber": "TRAP-JSM",
                                "name": ["John Smoltz"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAP-JT": {
                                "cardNumber": "TRAP-JT",
                                "name": ["Jim Thome"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "TRAP-JTR": {
                                "cardNumber": "TRAP-JTR",
                                "name": ["J.T. Realmuto"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TRAP-JU": {
                                "cardNumber": "TRAP-JU",
                                "name": ["Julio Urias"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TRAP-KG": {
                                "cardNumber": "TRAP-KG",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "TRAP-LC": {
                                "cardNumber": "TRAP-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "TRAP-LV": {
                                "cardNumber": "TRAP-LV",
                                "name": ["Luke Voit"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TRAP-LW": {
                                "cardNumber": "TRAP-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "TRAP-MM": {
                                "cardNumber": "TRAP-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "TRAP-MMU": {
                                "cardNumber": "TRAP-MMU",
                                "name": ["Max Muncy"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TRAP-MT": {
                                "cardNumber": "TRAP-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "TRAP-PA": {
                                "cardNumber": "TRAP-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TRAP-PG": {
                                "cardNumber": "TRAP-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "TRAP-RA": {
                                "cardNumber": "TRAP-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TRAP-RC": {
                                "cardNumber": "TRAP-RC",
                                "name": ["Rod Carew"],
                                "team": ["California Angels®"],
                                "cardAttributes": []
                            },
                            "TRAP-RHO": {
                                "cardNumber": "TRAP-RHO",
                                "name": ["Rhys Hoskins"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TRAP-RM": {
                                "cardNumber": "TRAP-RM",
                                "name": ["Ryan Mountcastle"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TRAP-SO": {
                                "cardNumber": "TRAP-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "TRAP-ST": {
                                "cardNumber": "TRAP-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "TRAP-TGL": {
                                "cardNumber": "TRAP-TGL",
                                "name": ["Tom Glavine"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TRAP-VG": {
                                "cardNumber": "TRAP-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "TRAP-WB": {
                                "cardNumber": "TRAP-WB",
                                "name": ["Wade Boggs"],
                                "team": ["Tampa Bay Devil Rays®"],
                                "cardAttributes": []
                            },
                            "TRAP-WC": {
                                "cardNumber": "TRAP-WC",
                                "name": ["Willson Contreras"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "TRAP-WF": {
                                "cardNumber": "TRAP-WF",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "TRAP-WM": {
                                "cardNumber": "TRAP-WM",
                                "name": ["Whit Merrifield"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TRAP-XB": {
                                "cardNumber": "TRAP-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "TRAP-YA": {
                                "cardNumber": "TRAP-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "Major League Material Autograph Cards": {
                            "MLMA-AME": {
                                "cardNumber": "MLMA-AME",
                                "name": ["Austin Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "MLMA-AR": {
                                "cardNumber": "MLMA-AR",
                                "name": ["Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLMA-AT": {
                                "cardNumber": "MLMA-AT",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "MLMA-AV": {
                                "cardNumber": "MLMA-AV",
                                "name": ["Andrew Vaughn"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA-BR": {
                                "cardNumber": "MLMA-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLMA-BS": {
                                "cardNumber": "MLMA-BS",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLMA-BW": {
                                "cardNumber": "MLMA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "MLMA-DC": {
                                "cardNumber": "MLMA-DC",
                                "name": ["Dylan Cease"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA-EJ": {
                                "cardNumber": "MLMA-EJ",
                                "name": ["Eloy Jimenez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA-FT": {
                                "cardNumber": "MLMA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "MLMA-JA": {
                                "cardNumber": "MLMA-JA",
                                "name": ["Jose Abreu"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "MLMA-JBE": {
                                "cardNumber": "MLMA-JBE",
                                "name": ["Josh Bell"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "MLMA-JP": {
                                "cardNumber": "MLMA-JP",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "MLMA-JU": {
                                "cardNumber": "MLMA-JU",
                                "name": ["Julio Urias"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLMA-JV": {
                                "cardNumber": "MLMA-JV",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "MLMA-KH": {
                                "cardNumber": "MLMA-KH",
                                "name": ["Ke'Bryan Hayes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "MLMA-MM": {
                                "cardNumber": "MLMA-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "MLMA-MT": {
                                "cardNumber": "MLMA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLMA-NL": {
                                "cardNumber": "MLMA-NL",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "MLMA-RH": {
                                "cardNumber": "MLMA-RH",
                                "name": ["Rhys Hoskins"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "MLMA-RL": {
                                "cardNumber": "MLMA-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLMA-SG": {
                                "cardNumber": "MLMA-SG",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "MLMA-SO": {
                                "cardNumber": "MLMA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLMA-ST": {
                                "cardNumber": "MLMA-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "MLMA-VG": {
                                "cardNumber": "MLMA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MLMA-WB": {
                                "cardNumber": "MLMA-WB",
                                "name": ["Walker Buehler"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLMA-YG": {
                                "cardNumber": "MLMA-YG",
                                "name": ["Yuli Gurriel"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitch Autograph Cards": {
                            "ASSA-AG": {
                                "cardNumber": "ASSA-AG",
                                "name": ["Andres Gimenez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASSA-AJ": {
                                "cardNumber": "ASSA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSA-AM": {
                                "cardNumber": "ASSA-AM",
                                "name": ["Alek Manoah"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSA-AR": {
                                "cardNumber": "ASSA-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSA-BB": {
                                "cardNumber": "ASSA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASSA-CS": {
                                "cardNumber": "ASSA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASSA-DS": {
                                "cardNumber": "ASSA-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSA-FF": {
                                "cardNumber": "ASSA-FF",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASSA-IH": {
                                "cardNumber": "ASSA-IH",
                                "name": ["Ian Happ"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "ASSA-JC": {
                                "cardNumber": "ASSA-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASSA-JMU": {
                                "cardNumber": "ASSA-JMU",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASSA-JRA": {
                                "cardNumber": "ASSA-JRA",
                                "name": ["Jose Ramirez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASSA-JS": {
                                "cardNumber": "ASSA-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASSA-KT": {
                                "cardNumber": "ASSA-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASSA-LC": {
                                "cardNumber": "ASSA-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASSA-MC": {
                                "cardNumber": "ASSA-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASSA-MM": {
                                "cardNumber": "ASSA-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASSA-MT": {
                                "cardNumber": "ASSA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASSA-NC": {
                                "cardNumber": "ASSA-NC",
                                "name": ["Nestor Cortes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASSA-PA": {
                                "cardNumber": "ASSA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASSA-PG": {
                                "cardNumber": "ASSA-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASSA-RA": {
                                "cardNumber": "ASSA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASSA-RD": {
                                "cardNumber": "ASSA-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASSA-SA": {
                                "cardNumber": "ASSA-SA",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASSA-SMC": {
                                "cardNumber": "ASSA-SMC",
                                "name": ["Shane McClanahan"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "ASSA-SO": {
                                "cardNumber": "ASSA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASSA-SO2": {
                                "cardNumber": "ASSA-SO2",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASSA-TA": {
                                "cardNumber": "ASSA-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASSA-VG": {
                                "cardNumber": "ASSA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASSA-XB": {
                                "cardNumber": "ASSA-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Stitch Dual Autograph Cards": {
                            "ASDA-AAJ": {
                                "cardNumber": "ASDA-AAJ",
                                "name": ["Austin Riley", "Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASDA-AG": {
                                "cardNumber": "ASDA-AG",
                                "name": ["Pete Alonso", "Paul Goldschmidt"],
                                "team": ["New York Mets®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASDA-AGJ": {
                                "cardNumber": "ASDA-AGJ",
                                "name": ["Vladimir Guerrero Jr.", "Pete Alonso"],
                                "team": ["Toronto Blue Jays®", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASDA-AS": {
                                "cardNumber": "ASDA-AS",
                                "name": ["Juan Soto", "Ronald Acuña Jr."],
                                "team": ["Washington Nationals®", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASDA-DB": {
                                "cardNumber": "ASDA-DB",
                                "name": ["Xander Bogaerts", "Rafael Devers"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASDA-DR": {
                                "cardNumber": "ASDA-DR",
                                "name": ["Jose Ramirez", "Rafael Devers"],
                                "team": ["Cleveland Guardians®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASDA-GM": {
                                "cardNumber": "ASDA-GM",
                                "name": ["Vladimir Guerrero Jr.", "Alek Manoah"],
                                "team": ["Toronto Blue Jays®", "Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASDA-JTR": {
                                "cardNumber": "ASDA-JTR",
                                "name": ["Mike Trout", "Aaron Judge"],
                                "team": ["Angels®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASDA-MM": {
                                "cardNumber": "ASDA-MM",
                                "name": ["Manny Machado", "Joe Musgrove"],
                                "team": ["San Diego Padres®", "San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASDA-TO": {
                                "cardNumber": "ASDA-TO",
                                "name": ["Shohei Ohtani", "Mike Trout"],
                                "team": ["Angels®", "Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "All Star Autographed Jumbo Patch Cards": {
                            "ASJA-AJ": {
                                "cardNumber": "ASJA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJA-AM": {
                                "cardNumber": "ASJA-AM",
                                "name": ["Alek Manoah"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASJA-AR": {
                                "cardNumber": "ASJA-AR",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJA-BB": {
                                "cardNumber": "ASJA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "ASJA-CS": {
                                "cardNumber": "ASJA-CS",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "ASJA-DS": {
                                "cardNumber": "ASJA-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJA-JC": {
                                "cardNumber": "ASJA-JC",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "ASJA-JM": {
                                "cardNumber": "ASJA-JM",
                                "name": ["Joe Musgrove"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASJA-JRA": {
                                "cardNumber": "ASJA-JRA",
                                "name": ["Jose Ramirez"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "ASJA-JS": {
                                "cardNumber": "ASJA-JS",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASJA-KT": {
                                "cardNumber": "ASJA-KT",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASJA-LC": {
                                "cardNumber": "ASJA-LC",
                                "name": ["Luis Castillo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "ASJA-MC": {
                                "cardNumber": "ASJA-MC",
                                "name": ["Miguel Cabrera"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "ASJA-MM": {
                                "cardNumber": "ASJA-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "ASJA-MT": {
                                "cardNumber": "ASJA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASJA-NC": {
                                "cardNumber": "ASJA-NC",
                                "name": ["Nestor Cortes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASJA-PA": {
                                "cardNumber": "ASJA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASJA-PG": {
                                "cardNumber": "ASJA-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "ASJA-RA": {
                                "cardNumber": "ASJA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "ASJA-RD": {
                                "cardNumber": "ASJA-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASJA-SM": {
                                "cardNumber": "ASJA-SM",
                                "name": ["Shane McClanahan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "ASJA-SO": {
                                "cardNumber": "ASJA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASJA-SO2": {
                                "cardNumber": "ASJA-SO2",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "ASJA-TA": {
                                "cardNumber": "ASJA-TA",
                                "name": ["Tim Anderson"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASJA-VG": {
                                "cardNumber": "ASJA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASJA-XB": {
                                "cardNumber": "ASJA-XB",
                                "name": ["Xander Bogaerts"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Autograph": {
                        "Generation Now Autograph Parallel": {
                            "GN-61": {
                                "cardNumber": "GN-61",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "GN-62": {
                                "cardNumber": "GN-62",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "GN-63": {
                                "cardNumber": "GN-63",
                                "name": ["C.J. Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "GN-64": {
                                "cardNumber": "GN-64",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "GN-65": {
                                "cardNumber": "GN-65",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "GN-66": {
                                "cardNumber": "GN-66",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "GN-67": {
                                "cardNumber": "GN-67",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "GN-68": {
                                "cardNumber": "GN-68",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Indians®"],
                                "cardAttributes": []
                            },
                            "GN-69": {
                                "cardNumber": "GN-69",
                                "name": ["Jeremy Peña"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "GN-71": {
                                "cardNumber": "GN-71",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "GN-74": {
                                "cardNumber": "GN-74",
                                "name": ["Tarik Skubal"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "GN-75": {
                                "cardNumber": "GN-75",
                                "name": ["Matt Manning"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "GN-76": {
                                "cardNumber": "GN-76",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "GN-77": {
                                "cardNumber": "GN-77",
                                "name": ["Gavin Lux"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "GN-78": {
                                "cardNumber": "GN-78",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "GN-79": {
                                "cardNumber": "GN-79",
                                "name": ["Jesus Luzardo"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "GN-82": {
                                "cardNumber": "GN-82",
                                "name": ["Logan Gilbert"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "GN-83": {
                                "cardNumber": "GN-83",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "GN-85": {
                                "cardNumber": "GN-85",
                                "name": ["Josh Lowe"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "GN-86": {
                                "cardNumber": "GN-86",
                                "name": ["Vidal Brujan"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "GN-88": {
                                "cardNumber": "GN-88",
                                "name": ["Roansy Contreras"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "GN-89": {
                                "cardNumber": "GN-89",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "GN-90": {
                                "cardNumber": "GN-90",
                                "name": ["M.J. Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            }
                        },
                        "Paragons of the Postseason Autograph Parallel": {
                            "PP-1": {
                                "cardNumber": "PP-1",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-2": {
                                "cardNumber": "PP-2",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "PP-4": {
                                "cardNumber": "PP-4",
                                "name": ["Buster Posey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "PP-7": {
                                "cardNumber": "PP-7",
                                "name": ["Lou Brock"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "PP-9": {
                                "cardNumber": "PP-9",
                                "name": ["Bob Gibson"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "PP-10": {
                                "cardNumber": "PP-10",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "PP-12": {
                                "cardNumber": "PP-12",
                                "name": ["Mariano Rivera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-15": {
                                "cardNumber": "PP-15",
                                "name": ["John Smoltz"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "PP-16": {
                                "cardNumber": "PP-16",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "PP-17": {
                                "cardNumber": "PP-17",
                                "name": ["Roger Clemens"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-18": {
                                "cardNumber": "PP-18",
                                "name": ["Andy Pettitte"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "PP-19": {
                                "cardNumber": "PP-19",
                                "name": ["Randy Arozarena"],
                                "team": ["Tampa Bay Rays®"],
                                "cardAttributes": []
                            },
                            "PP-20": {
                                "cardNumber": "PP-20",
                                "name": ["Brooks Robinson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "PP-21": {
                                "cardNumber": "PP-21",
                                "name": ["Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "PP-22": {
                                "cardNumber": "PP-22",
                                "name": ["Stephen Strasburg"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "PP-23": {
                                "cardNumber": "PP-23",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Black Gold Autograph Parallel": {
                            "BG-18": {
                                "cardNumber": "BG-18",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BG-10": {
                                "cardNumber": "BG-10",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BG-23": {
                                "cardNumber": "BG-23",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BG-11": {
                                "cardNumber": "BG-11",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals® Rookie"],
                                "cardAttributes": []
                            },
                            "BG-19": {
                                "cardNumber": "BG-19",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BG-13": {
                                "cardNumber": "BG-13",
                                "name": ["C.J. Abrams"],
                                "team": ["San Diego Padres™ Rookie"],
                                "cardAttributes": []
                            },
                            "BG-21": {
                                "cardNumber": "BG-21",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "BG-17": {
                                "cardNumber": "BG-17",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds® Rookie"],
                                "cardAttributes": []
                            },
                            "BG-8": {
                                "cardNumber": "BG-8",
                                "name": ["Joey Votto"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BG-25": {
                                "cardNumber": "BG-25",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "BG-12": {
                                "cardNumber": "BG-12",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners™ Rookie"],
                                "cardAttributes": []
                            },
                            "BG-3": {
                                "cardNumber": "BG-3",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BG-22": {
                                "cardNumber": "BG-22",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BG-20": {
                                "cardNumber": "BG-20",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates® Rookie"],
                                "cardAttributes": []
                            },
                            "BG-16": {
                                "cardNumber": "BG-16",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "BG-5": {
                                "cardNumber": "BG-5",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BG-4": {
                                "cardNumber": "BG-4",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BG-6": {
                                "cardNumber": "BG-6",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins® Rookie"],
                                "cardAttributes": []
                            },
                            "BG-1": {
                                "cardNumber": "BG-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BG-9": {
                                "cardNumber": "BG-9",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers® Rookie"],
                                "cardAttributes": []
                            },
                            "BG-24": {
                                "cardNumber": "BG-24",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BG-2": {
                                "cardNumber": "BG-2",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™ Rookie"],
                                "cardAttributes": []
                            }
                        },
                        '1987 Topps Baseball Autograph': {
                            "87ASA-AME": {
                                "cardNumber": "87ASA-AME",
                                "name": ["Austin Meadows"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "87ASA-BRE": {
                                "cardNumber": "87ASA-BRE",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "87ASA-BZ": {
                                "cardNumber": "87ASA-BZ",
                                "name": ["Barry Zito"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "87ASA-CY": {
                                "cardNumber": "87ASA-CY",
                                "name": ["Carl Yastrzemski"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "87ASA-DG": {
                                "cardNumber": "87ASA-DG",
                                "name": ["Dwight Gooden"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "87ASA-JG": {
                                "cardNumber": "87ASA-JG",
                                "name": ["Juan Gonzalez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "87ASA-JM": {
                                "cardNumber": "87ASA-JM",
                                "name": ["Justin Morneau"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "87ASA-JT": {
                                "cardNumber": "87ASA-JT",
                                "name": ["Jim Thome"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "87ASA-MS": {
                                "cardNumber": "87ASA-MS",
                                "name": ["Mike Schmidt"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "87ASA-PG": {
                                "cardNumber": "87ASA-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "87ASA-RA": {
                                "cardNumber": "87ASA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "87ASA-TG": {
                                "cardNumber": "87ASA-TG",
                                "name": ["Tom Glavine"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "87ASA-WB": {
                                "cardNumber": "87ASA-WB",
                                "name": ["Wade Boggs"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "87ASA-WCO": {
                                "cardNumber": "87ASA-WCO",
                                "name": ["Willson Contreras"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "87ASA-WM": {
                                "cardNumber": "87ASA-WM",
                                "name": ["Whit Merrifield"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "87BA-AJ": {
                                "cardNumber": "87BA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "87BA-AJA": {
                                "cardNumber": "87BA-AJA",
                                "name": ["A.J. Alexy"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "87BA-AT": {
                                "cardNumber": "87BA-AT",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "87BA-AW": {
                                "cardNumber": "87BA-AW",
                                "name": ["Alex Wells"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "87BA-AZ": {
                                "cardNumber": "87BA-AZ",
                                "name": ["Angel Zerpa"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "87BA-BD": {
                                "cardNumber": "87BA-BD",
                                "name": ["Bobby Dalbec"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "87BA-BW": {
                                "cardNumber": "87BA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "87BA-CA": {
                                "cardNumber": "87BA-CA",
                                "name": ["C.J. Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87BA-CB": {
                                "cardNumber": "87BA-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "87BA-CW": {
                                "cardNumber": "87BA-CW",
                                "name": ["Colton Welker"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "87BA-DE": {
                                "cardNumber": "87BA-DE",
                                "name": ["Drew Ellis"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "87BA-DJ": {
                                "cardNumber": "87BA-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "87BA-FT": {
                                "cardNumber": "87BA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87BA-GD": {
                                "cardNumber": "87BA-GD",
                                "name": ["Greg Deichmann"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "87BA-GK": {
                                "cardNumber": "87BA-GK",
                                "name": ["George Kirby"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "87BA-HG": {
                                "cardNumber": "87BA-HG",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "87BA-HRA": {
                                "cardNumber": "87BA-HRA",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "87BA-IC": {
                                "cardNumber": "87BA-IC",
                                "name": ["Ivan Castillo"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87BA-JA": {
                                "cardNumber": "87BA-JA",
                                "name": ["Joan Adon"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "87BA-JB": {
                                "cardNumber": "87BA-JB",
                                "name": ["Jacob Brentz"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "87BA-JBU": {
                                "cardNumber": "87BA-JBU",
                                "name": ["Jay Buhner"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "87BA-JC": {
                                "cardNumber": "87BA-JC",
                                "name": ["Jake Cronenworth"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87BA-JD": {
                                "cardNumber": "87BA-JD",
                                "name": ["Jacob deGrom"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "87BA-JH": {
                                "cardNumber": "87BA-JH",
                                "name": ["Jon Heasley"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "87BA-JI": {
                                "cardNumber": "87BA-JI",
                                "name": ["Jonathan India"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "87BA-JMO": {
                                "cardNumber": "87BA-JMO",
                                "name": ["Jovani Moran"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "87BA-JR": {
                                "cardNumber": "87BA-JR",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "87BA-JSO": {
                                "cardNumber": "87BA-JSO",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "87BA-KC": {
                                "cardNumber": "87BA-KC",
                                "name": ["Kervin Castro"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "87BA-LF": {
                                "cardNumber": "87BA-LF",
                                "name": ["Luis Frias"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "87BA-LGI": {
                                "cardNumber": "87BA-LGI",
                                "name": ["Lucas Giolito"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "87BA-LW": {
                                "cardNumber": "87BA-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "87BA-MB": {
                                "cardNumber": "87BA-MB",
                                "name": ["Mike Baumann"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "87BA-MG": {
                                "cardNumber": "87BA-MG",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "87BA-MR": {
                                "cardNumber": "87BA-MR",
                                "name": ["Manny Ramirez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "87BA-MT": {
                                "cardNumber": "87BA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "87BA-NF": {
                                "cardNumber": "87BA-NF",
                                "name": ["Nick Fortes"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "87BA-NL": {
                                "cardNumber": "87BA-NL",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "87BA-OCR": {
                                "cardNumber": "87BA-OCR",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "87BA-OL": {
                                "cardNumber": "87BA-OL",
                                "name": ["Otto Lopez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "87BA-OO": {
                                "cardNumber": "87BA-OO",
                                "name": ["Oliver Ortega"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "87BA-PM": {
                                "cardNumber": "87BA-PM",
                                "name": ["Patrick Mazeika"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "87BA-RA": {
                                "cardNumber": "87BA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "87BA-RDE": {
                                "cardNumber": "87BA-RDE",
                                "name": ["Reid Detmers"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "87BA-RD": {
                                "cardNumber": "87BA-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "87BA-RDA": {
                                "cardNumber": "87BA-RDA",
                                "name": ["Ronnie Dawson"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "87BA-RL": {
                                "cardNumber": "87BA-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "87BA-RS": {
                                "cardNumber": "87BA-RS",
                                "name": ["Reiver Sanmartin"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "87BA-SG": {
                                "cardNumber": "87BA-SG",
                                "name": ["Shawn Green"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "87BA-SGR": {
                                "cardNumber": "87BA-SGR",
                                "name": ["Sonny Gray"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "87BA-SO": {
                                "cardNumber": "87BA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "87BA-ST": {
                                "cardNumber": "87BA-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "87BA-STOT": {
                                "cardNumber": "87BA-STOT",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "87BA-TG": {
                                "cardNumber": "87BA-TG",
                                "name": ["Tyler Gilbert"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "87BA-TS": {
                                "cardNumber": "87BA-TS",
                                "name": ["Tony Santillan"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "87BA-VG": {
                                "cardNumber": "87BA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "87BA-WF": {
                                "cardNumber": "87BA-WF",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "87BA-YH": {
                                "cardNumber": "87BA-YH",
                                "name": ["Yonny Hernandez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "87BA-ZPO": {
                                "cardNumber": "87BA-ZPO",
                                "name": ["Zach Pop"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "87BA-ZW": {
                                "cardNumber": "87BA-ZW",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            }
                        },
                        "Baseball Stars Autographs": {
                            "BSA-AP": {
                                "cardNumber": "BSA-AP",
                                "name": ["Andre Pallante"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSA-AT": {
                                "cardNumber": "BSA-AT",
                                "name": ["Alek Thomas"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "BSA-BB": {
                                "cardNumber": "BSA-BB",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BSA-BD": {
                                "cardNumber": "BSA-BD",
                                "name": ["Brendan Donovan"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSA-BE": {
                                "cardNumber": "BSA-BE",
                                "name": ["Bryce Elder"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "BSA-BS": {
                                "cardNumber": "BSA-BS",
                                "name": ["Bryson Stott"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSA-BW": {
                                "cardNumber": "BSA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSA-CA": {
                                "cardNumber": "BSA-CA",
                                "name": ["CJ Abrams"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "BSA-CJ": {
                                "cardNumber": "BSA-CJ",
                                "name": ["Connor Joe"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": []
                            },
                            "BSA-FM": {
                                "cardNumber": "BSA-FM",
                                "name": ["Francisco Morales"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSA-FT": {
                                "cardNumber": "BSA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "BSA-GA": {
                                "cardNumber": "BSA-GA",
                                "name": ["Gabriel Arias"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "BSA-HG": {
                                "cardNumber": "BSA-HG",
                                "name": ["Hunter Greene"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BSA-JDU": {
                                "cardNumber": "BSA-JDU",
                                "name": ["Jhoan Duran"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSA-JMI": {
                                "cardNumber": "BSA-JMI",
                                "name": ["Jose Miranda"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSA-JP": {
                                "cardNumber": "BSA-JP",
                                "name": ["Joe Perez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSA-JPE": {
                                "cardNumber": "BSA-JPE",
                                "name": ["Jeremy Pena"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSA-JRO": {
                                "cardNumber": "BSA-JRO",
                                "name": ["Julio Rodriguez"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "BSA-JSO": {
                                "cardNumber": "BSA-JSO",
                                "name": ["Juan Soto"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "BSA-JSU": {
                                "cardNumber": "BSA-JSU",
                                "name": ["Jack Suwinski"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BSA-JWI": {
                                "cardNumber": "BSA-JWI",
                                "name": ["Josh Winder"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSA-LF": {
                                "cardNumber": "BSA-LF",
                                "name": ["Lucius Fox"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "BSA-MB": {
                                "cardNumber": "BSA-MB",
                                "name": ["Matt Brash"],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            },
                            "BSA-MG": {
                                "cardNumber": "BSA-MG",
                                "name": ["MacKenzie Gore"],
                                "team": ["San Diego Padres®"],
                                "cardAttributes": []
                            },
                            "BSA-MM": {
                                "cardNumber": "BSA-MM",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSA-MT": {
                                "cardNumber": "BSA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BSA-NL": {
                                "cardNumber": "BSA-NL",
                                "name": ["Nick Lodolo"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "BSA-OC": {
                                "cardNumber": "BSA-OC",
                                "name": ["Oneil Cruz"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BSA-PN": {
                                "cardNumber": "BSA-PN",
                                "name": ["Packy Naughton"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "BSA-RC": {
                                "cardNumber": "BSA-RC",
                                "name": ["Roansy Contreras"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "BSA-RL": {
                                "cardNumber": "BSA-RL",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "BSA-RP": {
                                "cardNumber": "BSA-RP",
                                "name": ["Richie Palacios"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "BSA-RPE": {
                                "cardNumber": "BSA-RPE",
                                "name": ["Ryan Pepiot"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSA-SK": {
                                "cardNumber": "BSA-SK",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": []
                            },
                            "BSA-SMU": {
                                "cardNumber": "BSA-SMU",
                                "name": ["Simon Muzziotti"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "BSA-SO": {
                                "cardNumber": "BSA-SO",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "BSA-ST": {
                                "cardNumber": "BSA-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "BSA-VG": {
                                "cardNumber": "BSA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BSA-WF": {
                                "cardNumber": "BSA-WF",
                                "name": ["Wander Franco"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            }
                        },
                        "Baseball Stars Dual Autographs": {
                            "BSDA-BB": {
                                "cardNumber": "BSDA-BB",
                                "name": ["Alex Bregman", "Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "BSDA-BS": {
                                "cardNumber": "BSDA-BS",
                                "name": ["Adrian Beltre", "Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "BSDA-CT": {
                                "cardNumber": "BSDA-CT",
                                "name": ["Miguel Cabrera", "Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "BSDA-FB": {
                                "cardNumber": "BSDA-FB",
                                "name": ["Cody Bellinger", "Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "BSDA-FW": {
                                "cardNumber": "BSDA-FW",
                                "name": ["Wander Franco", "Bobby Witt Jr."],
                                "team": ["Tampa Bay Rays™", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "BSDA-GB": {
                                "cardNumber": "BSDA-GB",
                                "name": ["Vladimir Guerrero Jr.", "Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "BSDA-GO": {
                                "cardNumber": "BSDA-GO",
                                "name": ["Ken Griffey Jr.", "Shohei Ohtani"],
                                "team": ["Seattle Mariners®", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSDA-JR": {
                                "cardNumber": "BSDA-JR",
                                "name": ["Aaron Judge", "Anthony Rizzo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA-JT": {
                                "cardNumber": "BSDA-JT",
                                "name": ["Mike Trout", "Derek Jeter"],
                                "team": ["Angels®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "BSDA-OM": {
                                "cardNumber": "BSDA-OM",
                                "name": ["David Ortiz", "Pedro Martinez"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "BSDA-TO": {
                                "cardNumber": "BSDA-TO",
                                "name": ["Mike Trout", "Shohei Ohtani"],
                                "team": ["Angels®", "Angels®"],
                                "cardAttributes": []
                            },
                            "BSDA-TT": {
                                "cardNumber": "BSDA-TT",
                                "name": ["Fernando Tatis Jr.", "Mike Trout"],
                                "team": ["San Diego Padres®", "Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "Diamond Greats Die Cuts Autograph Parallel": {
                            "DGDC-52": {
                                "cardNumber": "DGDC-52",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "DGDC-54": {
                                "cardNumber": "DGDC-54",
                                "name": ["Roger Clemens"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "DGDC-56": {
                                "cardNumber": "DGDC-56",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "DGDC-58": {
                                "cardNumber": "DGDC-58",
                                "name": ["Ryne Sandberg"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "DGDC-67": {
                                "cardNumber": "DGDC-67",
                                "name": ["Alex Rodriguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "DGDC-69": {
                                "cardNumber": "DGDC-69",
                                "name": ["Carlton Fisk"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "DGDC-70": {
                                "cardNumber": "DGDC-70",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "DGDC-71": {
                                "cardNumber": "DGDC-71",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners®"],
                                "cardAttributes": []
                            }
                        },
                        "Oversized Rookie Reprint Boxloader Autograph Variation": {
                            "98": {
                                "cardNumber": "98",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees™"],
                                "cardAttributes": []
                            },
                            "287": {
                                "cardNumber": "287",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees™"],
                                "cardAttributes": []
                            },
                            "333": {
                                "cardNumber": "333",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "410": {
                                "cardNumber": "410",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "700": {
                                "cardNumber": "700",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "98T": {
                                "cardNumber": "98T",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles™"],
                                "cardAttributes": []
                            },
                            "US175": {
                                "cardNumber": "US175",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "US250": {
                                "cardNumber": "US250",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        }
                    }
                }
            }
        }
    }
}

export const MLB_2022_TOPPS_US_PARALLELS = {
    "MLB": {
        "2022": {
            "Topps": {
                "Update Series": {
                    "Base Card Set": {
                        'Base': [
                            { "name": "#CMP056259", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "#CMP056260", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "#CMP056261", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "GOLD FOIL", "color": "#FFD700", "limitedTo": null },
                            { "name": "RAINBOW FOIL", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 2022 },
                            { "name": "GREEN CRACKLE FOILBOARD", "color": "#008000", "limitedTo": 499 },
                            { "name": "ADVANCED STATS", "color": "#FFFFFF", "limitedTo": 300 },
                            { "name": "ORANGE CRACKLE FOILBOARD", "color": "#FFA500", "limitedTo": 299 },
                            { "name": "RED CRACKLE FOILBOARD", "color": "#FF0000", "limitedTo": 199 },
                            { "name": "VINTAGE STOCK", "color": "#DEB887", "limitedTo": 99 },
                            { "name": "INDEPENDENCE DAY", "color": "#B22222", "limitedTo": 76 },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 71 },
                            { "name": "MOTHER'S DAY HOT PINK", "color": "#FF69B4", "limitedTo": 50 },
                            { "name": "FATHER'S DAY POWDER BLUE", "color": "#B0E0E6", "limitedTo": 50 },
                            { "name": "MEMORIAL DAY CAMO", "color": "#4B5320", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (BLACK)", "color": "#000000", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (CYAN)", "color": "#00FFFF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (MAGENTA)", "color": "#FF00FF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (YELLOW)", "color": "#EBD834", "limitedTo": 1 }
                        ],
                        "Base Cards Clear Variation": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                        ]
                    },
                    "Insert": {
                        "1987 Topps Baseball": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 },
                            { "name": "WOOD", "color": "#A1662F", "limitedTo": 1 }
                        ],
                        "2022 All Star Game": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Diamond Greats Die Cuts": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Generation Now": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Paragons of the Postseason": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Stars of MLB": [
                            { "name": "RED", "color": "#FF0000", "limitedTo": 75 },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Stars of MLB Chrome": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 75 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 25 },
                            { "name": "SUPERFRACTOR", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Topps Black Gold": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Relic": {
                        "All Star Jumbo Patch Cards": [
                            { "name": "# to 5", "color": "#FFFFFF", "limitedTo": 5 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 1 }
                        ],
                        "All Star Stitches Cards": [
                            { "name": "SILVER", "color": "#C0C0C0", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 1 }
                        ],
                        "All Star Stitches Dual Cards": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 }
                        ],
                        "All Star Stitches Triple Cards": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 }
                        ],
                        "Major League Material Cards": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Commemorative Batting Helmet Cards": [
                            { "name": "BLUE", "color": "#1E90FF", "limitedTo": null },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 299 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 75 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Commemorative Special Event Patch Manufactured Relic": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Autograph Relic": {
                        "All Star Autographed Jumbo Patch Cards": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 1 }
                        ],
                        "All Star Stitch Autograph Cards": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 1 }
                        ],
                        "All Star Stitch Dual Autograph Cards": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 10 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 1 }
                        ],
                        "Commemorative Batting Helmet Cards Autograph Parallel": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                        "Major League Material Autograph Cards": [
                            { "name": "# to 50", "color": "#FFFFFF", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "2022 Special Event Patch Cards Autograph Parallel": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                        "Topps Reverence Autograph Patch Cards": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ]
                    },
                    "Autograph": {
                        '1987 Topps Baseball Autograph': [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Baseball Stars Autographs": [
                            { "name": "BLACK", "color": "#000000", "limitedTo": 199 },
                            { "name": "GOLD", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "RED", "color": "#FF0000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                        "Baseball Stars Dual Autographs": [
                            { "name": "# to 25", "color": "#FFFFFF", "limitedTo": 25 }
                        ],
                        "Diamond Greats Die Cuts Autograph Parallel": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                        "Generation Now Autograph Parallel": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                        "Oversized Rookie Reprint Boxloader Autograph Variation": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                        "Paragons of the Postseason Autograph Parallel": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                        "Topps Black Gold Autograph Parallel": [
                            { "name": "# to 10", "color": "#FFFFFF", "limitedTo": 10 }
                        ],
                    }
                }
            }
        }
    }
}