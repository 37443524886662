// styles
import { CardAttributeContainer } from "./CardAttribute.styles"

export default function CardAttribute({ color = null, text, size = "S" }) {
    return (
        <CardAttributeContainer size={size} color={color}>
            {text}
        </CardAttributeContainer>
    )
}
