import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAvailableSeries } from '../../../utils/firebase/firebase.utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/user/user.selector';

// components
import SelectionPanel from '../../selection-panel/SelectionPanel';

export default function SeriesSelection({ type }) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { sport, year, manufacturer } = useParams();
  const [availableSeries, setAvailableSeries] = useState([]);

  useEffect(() => {
    const fetchSeries = async () => {
      const series = type === "add" ? await getAvailableSeries(null, sport, year, manufacturer) : await getAvailableSeries(user.uid, sport, year, manufacturer);
      setAvailableSeries(series);
    };

    fetchSeries();
  }, [type, user.uid, sport, year, manufacturer]);

  const addBasePath = `/add/${sport}/${year}/${manufacturer}`;
  const viewBasePath = `/collection/${sport}/${year}/${manufacturer}`;

  const displayData = Object.keys(availableSeries)
    .sort()
    .map(seriesKey => ({
      name: seriesKey,
      count: availableSeries[seriesKey].cardCount || 0
  }));

  const handleItemClick = (series) => {
    navigate(`${type === "add" ? addBasePath : viewBasePath}/${series}`);
  };
  
  return <SelectionPanel data={displayData} onItemClick={handleItemClick} basePath={type === "add" ? addBasePath : viewBasePath} />;
}