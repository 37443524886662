// styles
import {
    AccountUpdatedEntryContainer,
    LeftContent,
    DateDisplay,
    ActionDisplay
} from "./AccountUpdatedEntry.styles";

export default function AccountUpdatedEntry({ content }) {
    const formattedTimestamp = content.timestamp?.toDate().toLocaleString();

    return (
        <AccountUpdatedEntryContainer className='panel-bg'>
            <LeftContent>
                <DateDisplay>{formattedTimestamp}</DateDisplay>
                {content.details.accountActionType === "username" &&
                    <ActionDisplay>
                        Username updated from{' '}{' '}
                        <span style={{ textDecoration: "underline" }}>@{content.details.oldUsername}</span>
                        {' '}to{' '}
                        <span style={{ textDecoration: "underline" }}>@{content.details.newUsername}</span>
                    </ActionDisplay>
                }
                {content.details.accountActionType === "email" &&
                    <ActionDisplay>
                        Email updated from{' '}
                        <span style={{ textDecoration: "underline" }}>{content.details.oldEmail}</span>
                        {' '}to{' '}
                        <span style={{ textDecoration: "underline" }}>{content.details.newEmail}</span>
                    </ActionDisplay>
                }
                {content.details.accountActionType === "newAccount" &&
                    <ActionDisplay>
                        Welcome to Pull Vault @{content.details.displayName}! Your account has been successfully created.
                    </ActionDisplay>
                }
            </LeftContent>
        </AccountUpdatedEntryContainer>
    )
}
