import { createSelector } from "reselect";

const selectSystemReducer = state => state.system;

export const selectGradientData = createSelector(
    [selectSystemReducer],
    (system) => system.gradientData
); 
export const selectGradient = createSelector(
    [selectSystemReducer],
    (system) => system.gradient
);
export const selectBgIsChanging = createSelector(
    [selectSystemReducer],
    (system) => system.bgIsChanging
);
export const selectGradientA = createSelector(
    [selectSystemReducer],
    (system) => system.gradientA
);
export const selectGradientB = createSelector(
    [selectSystemReducer],
    (system) => system.gradientB
);
export const selectDisplayedGradient = createSelector(
    [selectSystemReducer],
    (system) => system.displayedGradient
);
export const selectSystemNotification = createSelector(
    [selectSystemReducer],
    (system) => system.systemNotification
);