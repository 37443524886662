import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../utils/firebase/firebase.utils.js';
import { selectUser } from '../../../../store/user/user.selector.js';
import { setCardCount } from '../../../../store/user/user.action.js';

// styles
import { HeaderDataPillContainer } from './HeaderDataPill.styles.js';

// assets
import cardIcon from '../../../../assets/system/icons/card-icon.png';
import { setCardData } from '../../../../store/card/card.action.js';

export default function HeaderDataPill() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [collectionCardCount, setCollectionCardCount] = useState(null);
  const userUid = useMemo(() => user?.uid, [user]);

  const handleSnapshot = useCallback((docSnap) => {
    if (docSnap.exists()) {
      const { collectionCardCount: count } = docSnap.data();
      
      if (count !== collectionCardCount) {  // Only update state if count is different
        dispatch(setCardCount(count));
        if (count === 0) {
          dispatch(setCardData({}));
        }
        setCollectionCardCount(count);
      }
    } else {
      setCollectionCardCount('Err');
    }
  }, [dispatch, collectionCardCount]);

  // Firestore listener setup
  useEffect(() => {
    if (!userUid) return;  // Guard against undefined user

    
    console.log("HeaderDataPill: Fetching collectionCardCount...");
    const userDocRef = doc(db, `users/${userUid}`);

    const unsubscribe = onSnapshot(
      userDocRef,
      handleSnapshot,
      (error) => {
        console.error("HeaderDataPill: Error fetching collectionCardCount ->", error);
        setCollectionCardCount("Err");
      }
    );

    return () => unsubscribe();  // Cleanup listener on unmount
  }, [userUid, handleSnapshot]);

  // Return null to prevent rendering when data is still loading
  if (collectionCardCount === null) {
    return null;
  }

  return (
    <HeaderDataPillContainer>
      <h5>{collectionCardCount}</h5>
      <img src={cardIcon} alt='card count icon' />
    </HeaderDataPillContainer>
  );
}