import styled from "styled-components";

export const CardPanelContainer = styled.div`
    margin: 24px;

    h2 {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        left: 24px;
        top: 25px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
`
export const CardCountContainer = styled.div`
    align-items: center;
    bottom: 24px;
    display: flex;
    gap: 8px;
    height: 40px;
    right: 24px;
    width: fit-content;

    h5 {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    img {
        height: 16px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
`