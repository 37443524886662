import styled from "styled-components";

export const CardDisplayContainer = styled.div`
    align-items: center;
    background-color: rgba(255,255,255,0.25);
    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 8px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    padding: 8px;
    margin: 8px 0px;
`
export const LeftCardContent = styled.div`
    align-items: center;
    display: flex;
`;
export const CardNumber = styled.div`
    box-sizing: border-box;
    align-items: center;
    display: flex;
    height: 40px;
    width: 80px;

    h4 {
        font-size: 12px;
        padding: 4px 12px;
    };
`;
export const CardDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const CardHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 4px;

    span {
        color: rgba(255,255,255,0.4 );
    }

    h3 {
        color: white;
        font-size: 12px;
        font-weight: 600;
    };
`;
export const AttributesContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

    .card-attributes-container, .card-variations-container {
        display: flex;
        gap: 4px;
    }

    h4 {
        font-size: 10px;
    }
`;
export const AddBtn = styled.div`
    border: ${(props) => `1px solid ${props.$isActive ? '#FFFFFF' : '#FFFFFF66'}`};
    border-radius: 4px;
    cursor: ${(props) => props.$isActive ? 'pointer' : 'default'};
    opacity: ${(props) => props.$isActive ? '1.0' : '0.25'};
    padding: 4px 12px;
    width: fit-content;
    white-space: nowrap;

    h4 {
        width: 100%;
        margin: 0;
        white-space: nowrap;
    };

    &:hover {
        background: ${(props) => props.$isActive ? 'rgba(255,255,255,0.2)' : ''};
    };
`;