import styled from "styled-components";

export const ChecklistManagerContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`
export const UploadDetails = styled.div`
    display: flex;
    gap: 16px;

    h4 {
        font-weight: 500;
    }

    select {
        border-radius: 4px;
        height: 32px;
        width: 320px;
    }
`
export const BtnContainer = styled.div`
    width: 80px;
`
export const JsonDisplays = styled.div`
    display: flex;
    gap: 24px;
`
export const JsonDisplayContainer = styled.div`
  flex: 1;
  background-color: rgba(255,255,255,0.25);
  padding: 10px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: scroll;

  pre {
    font-size: 12px;
    color: #FFFFFF;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;