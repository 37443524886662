import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../store/user/user.action";

// firebase
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db, logUserAction } from '../utils/firebase/firebase.utils';

export const useCreateAccount = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const createAccount = async (email, password, username, teamIndex) => {
        setError(null);
        setIsPending(true);

        try {
            const res = await createUserWithEmailAndPassword(auth, email, password)

            if (!res) {
                setError("Error creating account, try again later.");
                throw new Error('Could not complete signup');
            }

            await updateProfile(auth.currentUser, {
                displayName: username
            })

            await setDoc(doc(db, 'users', res.user.uid), {
                displayName: username,
                teamIndex: teamIndex,
                collectionCardCount: 0
            })

            const user = res.user;
            const userData = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
            };

            dispatch(setUser(userData));
            logUserAction(res.user.uid, "updatedAccount", { accountActionType: "newAccount", displayName: username });
            setError(null);
        }
        catch (err) {
            switch (err.message) {
                case 'Firebase: Error (auth/email-already-in-use).':
                    setError("An account already exists with that email.");
                    break;
                case 'Firebase: Error (auth/invalid-email).':
                    setError("An error occurred, please try again later.");
                    break;
                case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
                    setError("Password should be at least 6 characters.");
                    break;
                default:
                    setError(err.message);
            }
        }

        setIsPending(false)
    }
    return { error, isPending, createAccount }
}