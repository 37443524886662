export const SINGULAR_UPLOAD_CHECKLIST = {

}

export const SINGULAR_UPLOAD_PARALLELS = {
    "MLB": {
        "2024": {
            "Topps": {
                "Series 2": {
                    "Insert": {
                        "Chrome Stars of MLB": [
                            { "name": "RED", "color": "#FF0000", "limitedTo": 75 },
                            { "name": "BLACK", "color": "#000000", "limitedTo": 25 },
                            { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        ],
                    }
                }
            }
        }
    }
}