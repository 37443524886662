import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeamIndex } from '../../../../../../utils/firebase/firebase.utils';
import { setTeamIndex } from '../../../../../../store/user/user.action';
import { selectTeamIndex, selectUser } from '../../../../../../store/user/user.selector';

// styles
import {
    TeamSelectionContainer,
    TeamBar,
    ExpandedContent,
    TeamDisplay,
    ButtonsContainer,
    MinimizedContent
} from './TeamSelection.styles';

// data
import { TEAM_LOGOS } from '../../../../../../data/theme-data/team-data';

// components
import ActionField from '../../../../../../components/action-field/ActionField';
import SecondaryBtn from '../../../../../../components/btns/secondary-btn/SecondaryBtn';

export default function TeamSelection() {
    const dispatch = useDispatch();
    const teamIndex = useSelector(selectTeamIndex);  // Redux state for current team
    const { uid } = useSelector(selectUser);  // User ID from Redux

    const [isChoosingNewTeam, setIsChoosingNewTeam] = useState(false); // Controls Expanded/Minimized views
    const [activeTeamIndex, setActiveTeamIndex] = useState(teamIndex);  // Tracks the active/current teamIndex
    const [choseNewTeam, setChoseNewTeam] = useState(null);  // Track the newly selected team

    // Change team display logic
    const changeTeamDisplay = (selectedTeamIndex) => {
        if (selectedTeamIndex !== activeTeamIndex) {
            setChoseNewTeam(selectedTeamIndex);  // Only set a new team if it's different from the current team
        } else {
            setChoseNewTeam(null);  // Reset if the user clicks the already active team
        }
    }

    // Effect to monitor changes to choseNewTeam and activeTeamIndex
    useEffect(() => {
        console.log("choseNewTeam: ", choseNewTeam, " - activeTeamIndex: ", activeTeamIndex);
    }, [choseNewTeam, activeTeamIndex]);

    // Handle team change confirmation
    const handleTeamChange = async () => {
        console.log("handling team change...")
        if (isChoosingNewTeam) {
            console.log("Pass: isChoosingNewTeam is: true")
            if (choseNewTeam !== null && choseNewTeam !== activeTeamIndex) {
                // Update Firestore and local state
                console.log("Pass: choseNewTeam !== null (", choseNewTeam, ")", "&& choseNewTeam !== activeTeamIndex (", choseNewTeam, ")")
                await updateTeamIndex(uid, choseNewTeam, activeTeamIndex);
                setActiveTeamIndex(choseNewTeam);  // Set the newly chosen team a active
                dispatch(setTeamIndex(choseNewTeam));  // Update Redux state
            }
            setIsChoosingNewTeam(false);  // Close the selection UI
            setChoseNewTeam(null);  // Reset the selected team
        } else {
            setIsChoosingNewTeam(true);  // Open the selection UI
        }
    }

    // Handle canceling team change
    const handleCancelTeamChange = () => {
        setIsChoosingNewTeam(false);  // Close the selection UI
        setChoseNewTeam(null);  // Reset the selected team
        dispatch(setTeamIndex(activeTeamIndex));  // Restore the previously active team
    }

    return (
        <TeamSelectionContainer>
            <ActionField>
                <TeamBar className='panel-bg'>
                    {isChoosingNewTeam ? (
                        <ExpandedContent>
                            {Object.keys(TEAM_LOGOS).map((key, index) => (
                                <TeamDisplay 
                                    key={index} 
                                    onClick={() => changeTeamDisplay(index)}
                                    className={index === (choseNewTeam ?? activeTeamIndex) ? 'active' : ''}
                                >
                                    <img
                                        src={TEAM_LOGOS[key].logo}
                                        alt={TEAM_LOGOS[key].name}
                                        className={index === (choseNewTeam ?? activeTeamIndex) ? 'active' : ''}
                                    />
                                </TeamDisplay>
                            ))}
                        </ExpandedContent>
                    ) : (
                        <MinimizedContent>
                            <img
                                className="circle-img-left"
                                src={TEAM_LOGOS[activeTeamIndex].logo}
                                alt={TEAM_LOGOS[activeTeamIndex].name}
                            />
                            <h3>{TEAM_LOGOS[activeTeamIndex].name}</h3>
                        </MinimizedContent>
                    )}
                </TeamBar>

                <ButtonsContainer>
                    <div onClick={handleTeamChange}>
                        <SecondaryBtn
                            isDisabled={isChoosingNewTeam && (choseNewTeam === null || choseNewTeam === activeTeamIndex)}
                            title={isChoosingNewTeam ? 'SAVE' : 'CHANGE TEAM'}
                        />
                    </div>
                    {isChoosingNewTeam && (
                        <div onClick={handleCancelTeamChange}>
                            <SecondaryBtn title="CANCEL" />
                        </div>
                    )}
                </ButtonsContainer>
            </ActionField>
        </TeamSelectionContainer>
    );
}