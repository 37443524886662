import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setupAuthListener } from "./utils/firebase/firebase.utils.js";
import { selectAuthIsReady, selectUser } from "./store/user/user.selector.js";

// components
import SportSelection from "./components/selections/sport-selection/SportSelection.js";
import YearSelection from "./components/selections/year-selection/YearSelection.js";
import ManufacturerSelection from "./components/selections/manufacturer-selection/ManufacturerSelection.js";
import SeriesSelection from "./components/selections/series-selection/SeriesSelection.js";
import CardTypeSelection from "./components/selections/card-type-selection/CardTypeSelection.js";
import CardTypeCategorySelection from "./components/selections/card-type-category-selection/CardTypeCategorySelection.js";

// pages
import AccountPage from "./pages/account-page/AccountPage";
import ActivityLog from "./pages/activity-log/ActivityLog.js";
import AddCardPage from "./pages/add-card-page/AddCardPage.js";
import AuthPage from "./pages/auth-page/AuthPage";
import CardShop from "./pages/card-shop/CardShop.js";
import ChecklistManager from "./pages/checklist-manager/ChecklistManager.js";
import ChecklistView from './pages/add-card-page/components/checklist-view/ChecklistView.js';
import CollectionPage from "./pages/collection-page/CollectionPage.js";
import CreateSetPage from "./pages/create-set-page/CreateSetPage.js";
import HomePage from "./pages/home-page/HomePage";
// import LoadingPage from "./pages/loading-page/LoadingPage.js";
import Showcase from "./components/widgets/showcase/Showcase.js";
import SiteContainer from "./pages/site-container/SiteContainer.js";
import SeriesDisplay from "./components/series-display/SeriesDisplay.js";

function App() {
  const dispatch = useDispatch();
  const authIsReady = useSelector(selectAuthIsReady);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(setupAuthListener());
  }, [dispatch]);

  return (
    <div>
      {authIsReady &&
        <BrowserRouter>
          <Routes>
            <Route path='/' element={user ? <SiteContainer /> : <AuthPage />}>
              {/* <Route index element={<LoadingPage />} /> */}
              <Route path='' element={<HomePage />}>
                <Route path='' element={<Showcase />} />
                <Route path='collection' element={<CollectionPage />}>
                  <Route path='' element={<SportSelection type={"view"} />} />
                  <Route path=':sport' element={<YearSelection type={"view"} />} />
                  <Route path=':sport/:year' element={<ManufacturerSelection type={"view"} />} />
                  <Route path=':sport/:year/:manufacturer' element={<SeriesSelection type={"view"} />} />
                  <Route path=':sport/:year/:manufacturer/:series' element={<CardTypeSelection type={"view"} />} />
                  <Route path=':sport/:year/:manufacturer/:series/:cardType' element={<CardTypeCategorySelection type={"view"} />} />
                  <Route path=':sport/:year/:manufacturer/:series/:cardType/:cardTypeCategory' element={<SeriesDisplay />} />
                </Route>
                <Route path='card-shop' element={<CardShop />} />
                <Route path='checklist-manager' element={<ChecklistManager />} />
                <Route path='activity-log' element={<ActivityLog />} />
                <Route path='add' element={<AddCardPage />}>
                  <Route path='' element={<SportSelection type={"add"} />} />
                  <Route path=':sport' element={<YearSelection type={"add"} />} />
                  <Route path=':sport/:year' element={<ManufacturerSelection type={"add"} />} />
                  <Route path=':sport/:year/:manufacturer' element={<SeriesSelection type={"add"} />} />
                  <Route path=':sport/:year/:manufacturer/:series' element={<CardTypeSelection type={"add"} />} />
                  <Route path=':sport/:year/:manufacturer/:series/:cardType' element={<CardTypeCategorySelection type={"add"} />} />
                  <Route path=':sport/:year/:manufacturer/:series/:cardType/:cardTypeCategory' element={<ChecklistView />} />
                </Route>
              </Route>

              <Route path='create-set' element={<CreateSetPage />} />
              <Route path='account' element={<AccountPage />} />


            </Route>
          </Routes>
        </BrowserRouter>
      }
    </div>
  )
}

export default App;
