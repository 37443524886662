import styled from "styled-components";

export const ChecklistItemDisplayContainer = styled.div`
    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 8px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: ${(props) => props.$isCollected ? 'pointer' : 'default'};
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;
    transition: 0.3s ease-in-out; 

    &:hover {
        transform: ${(props) => `scale(${props.$isCollected ? '1.02' : '1.01'})`};
        transition: 0.3s ease-in-out;
    }
`;
export const TopCardContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
export const LeftCardContent = styled.div`
    align-items: center;
    display: flex;
`;
export const Spacer = styled.div`
    width: 80px;
`
export const CardNumber = styled.div`
    box-sizing: border-box;
    align-items: center;
    display: flex;
    height: 40px;
    width: 80px;

    h4 {
        font-size: 12px;
        padding: 4px 12px;
    };
`;
export const CardDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const CardHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 4px;

    span {
        color: rgba(255,255,255,0.4 );
    }

    h3 {
        color: #FFFFFF;
        display: inline;
        font-size: 12px;
        font-weight: 600;
    };
`;
export const QuantityControlsContainer = styled.div`
    justify-content: center;
    display: flex;
    height: 100%;
`;
export const QtyContent = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
`;
export const AttributesContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

    .card-attributes-container, .card-variations-container {
        display: flex;
        gap: 4px;
    }

    h4 {
        font-size: 10px;
    }
`;
export const AddBtn = styled.div`
    border: ${(props) => `1px solid ${props.$isActive ? '#FFFFFF' : '#FFFFFF66'}`};
    border-radius: 4px;
    cursor: ${(props) => props.$isActive ? 'pointer' : 'default'};
    opacity: ${(props) => props.$isActive ? '1.0' : '0.25'};
    padding: 4px 12px;
    text-align: center;
    width: 96px;
    white-space: nowrap;

    h4 {
        width: 100%;
        margin: 0;
        white-space: nowrap;
    };

    &:hover {
        background: ${(props) => props.$isActive ? 'rgba(255,255,255,0.2)' : ''};
    };
`;
export const ParallelConfiguration = styled.div`
    border-top: 1px solid rgba(255,255,255,0.25);
    margin-left: 32px;
    padding-top: 16px;
    padding-bottom: 8px;
`;
export const Parallels = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;
export const Parallel = styled.h5`
    border: ${(props) => `2px solid ${props.$parallelColor}`};
    border-radius: 150px;
    box-sizing: border-box;
    cursor: pointer;
    height: 30px;
    padding: 4px 12px;
    width: fit-content;

    &:hover {
        background: ${(props) => `${props.$parallelColor}80`}; // Adding 66 to the hex value for 40% opacity
    };

    &.active {
        background: ${(props) => `${props.$parallelColor}80`}; // Adding 66 to the hex value for 40% opacity
    };
`;
export const SelectedParallelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export const ParallelDetailsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
export const ActionContainer = styled.div`
    display: flex;
    gap: 8px;
`;
export const DeselectButton = styled.div`
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    width: 28px;

    &:hover {
        background: rgba(255,255,255,0.25);
    };

    img {
        height: 28px;
        width: 28px;
    };
`;
export const InputContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`;
export const InputField = styled.input`
    padding: 5px;
    background: none;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    text-align: center;
    width: 64px;

    &::placeholder {
        color: rgba(255,255,255,0.4);
    };
`;
export const ExpandVariations = styled.h3`
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    cursor: pointer;
    font-size: 10px;
    font-weight: 500;
    padding: 0px 8px;
    width: fit-content;

    &:hover {
        background: rgba(255,255,255,0.4);
    }
`
export const VariationRows = styled.div`
    display: flex;
    flex-direction: column;
`
export const VariationRow = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 48px;
    justify-content: space-between;
    width: 100%;
`