// CHECKLIST: INCOMPLETE (first 10 cards each category)
// PARALLELS: COMPLETE

export const MLB_2024_TOPPS_CHROME = {
    "MLB": {
        "2024": {
            "Topps": {
                "Topps Chrome® Baseball": {
                    "Base Card Set": {
                        "Base": {
                            "1": {
                                "cardNumber": "1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "2": {
                                "cardNumber": "2",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "3": {
                                "cardNumber": "3",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "4": {
                                "cardNumber": "4",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "5": {
                                "cardNumber": "5",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "6": {
                                "cardNumber": "6",
                                "name": ["Eury Pérez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "7": {
                                "cardNumber": "7",
                                "name": ["MJ Melendez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "8": {
                                "cardNumber": "8",
                                "name": ["Tristan Gray"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "9": {
                                "cardNumber": "9",
                                "name": ["Ozzie Albies"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "10": {
                                "cardNumber": "10",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    'Insert': {
                        'Topps Chrome Radiating Rookies': {
                            "RR-1": {
                                "cardNumber": "RR-1",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-2": {
                                "cardNumber": "RR-2",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-3": {
                                "cardNumber": "RR-3",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-4": {
                                "cardNumber": "RR-4",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-5": {
                                "cardNumber": "RR-5",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-6": {
                                "cardNumber": "RR-6",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-7": {
                                "cardNumber": "RR-7",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-8": {
                                "cardNumber": "RR-8",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-9": {
                                "cardNumber": "RR-9",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-10": {
                                "cardNumber": "RR-10",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "1989 Topps Baseball": {
                            "89CB-1": {
                                "cardNumber": "89CB-1",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89CB-2": {
                                "cardNumber": "89CB-2",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89CB-3": {
                                "cardNumber": "89CB-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89CB-4": {
                                "cardNumber": "89CB-4",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89CB-5": {
                                "cardNumber": "89CB-5",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89CB-6": {
                                "cardNumber": "89CB-6",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89CB-7": {
                                "cardNumber": "89CB-7",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89CB-8": {
                                "cardNumber": "89CB-8",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89CB-9": {
                                "cardNumber": "89CB-9",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89CB-10": {
                                "cardNumber": "89CB-10",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Chrome Layer Variation": {
                            "189BCL-1": {
                                "cardNumber": "189BCL-1",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "89BCL-2": {
                                "cardNumber": "89BCL-2",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BCL-3": {
                                "cardNumber": "89BCL-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89BCL-4": {
                                "cardNumber": "89BCL-4",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BCL-5": {
                                "cardNumber": "89BCL-5",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89BCL-6": {
                                "cardNumber": "89BCL-6",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89BCL-7": {
                                "cardNumber": "89BCL-7",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89BCL-8": {
                                "cardNumber": "89BCL-8",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89BCL-9": {
                                "cardNumber": "89BCL-9",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89BCL-10": {
                                "cardNumber": "89BCL-10",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            }
                        },
                        "Future Stars": {
                            "FS-1": {
                                "cardNumber": "FS-1",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FS-2": {
                                "cardNumber": "FS-2",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FS-3": {
                                "cardNumber": "FS-3",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "FS-4": {
                                "cardNumber": "FS-4",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "FS-5": {
                                "cardNumber": "FS-5",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FS-6": {
                                "cardNumber": "FS-6",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "FS-7": {
                                "cardNumber": "FS-7",
                                "name": ["Francisco Alvarez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "FS-8": {
                                "cardNumber": "FS-8",
                                "name": ["Justin Steele"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "FS-9": {
                                "cardNumber": "FS-9",
                                "name": ["Kodai Senga"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "FS-10": {
                                "cardNumber": "FS-10",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        "Ultra Violet All Stars": {
                            "UV-1": {
                                "cardNumber": "UV-1",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "UV-2": {
                                "cardNumber": "UV-2",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "UV-3": {
                                "cardNumber": "UV-3",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "UV-4": {
                                "cardNumber": "UV-4",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "UV-5": {
                                "cardNumber": "UV-5",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "UV-6": {
                                "cardNumber": "UV-6",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "UV-7": {
                                "cardNumber": "UV-7",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "UV-8": {
                                "cardNumber": "UV-8",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "UV-9": {
                                "cardNumber": "UV-9",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "UV-10": {
                                "cardNumber": "UV-10",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            }
                        },
                        "Summertime in the Park": {
                            "SITP-1": {
                                "cardNumber": "SITP-1",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SITP-2": {
                                "cardNumber": "SITP-2",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "SITP-3": {
                                "cardNumber": "SITP-3",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SITP-4": {
                                "cardNumber": "SITP-4",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SITP-5": {
                                "cardNumber": "SITP-5",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "SITP-6": {
                                "cardNumber": "SITP-6",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "SITP-7": {
                                "cardNumber": "SITP-7",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "SITP-8": {
                                "cardNumber": "SITP-8",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SITP-9": {
                                "cardNumber": "SITP-9",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "SITP-10": {
                                "cardNumber": "SITP-10",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "Strokes": {
                            "S-1": {
                                "cardNumber": "S-1",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "S-2": {
                                "cardNumber": "S-2",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "S-3": {
                                "cardNumber": "S-3",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "S-4": {
                                "cardNumber": "S-4",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "S-5": {
                                "cardNumber": "S-5",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "S-6": {
                                "cardNumber": "S-6",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "S-7": {
                                "cardNumber": "S-7",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "S-8": {
                                "cardNumber": "S-8",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "S-9": {
                                "cardNumber": "S-9",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "S-10": {
                                "cardNumber": "S-10",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome All Etch": {
                            "CAE-1": {
                                "cardNumber": "CAE-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CAE-2": {
                                "cardNumber": "CAE-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CAE-3": {
                                "cardNumber": "CAE-3",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "CAE-4": {
                                "cardNumber": "CAE-4",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CAE-5": {
                                "cardNumber": "CAE-5",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CAE-6": {
                                "cardNumber": "CAE-6",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CAE-7": {
                                "cardNumber": "CAE-7",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CAE-8": {
                                "cardNumber": "CAE-8",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CAE-9": {
                                "cardNumber": "CAE-9",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CAE-10": {
                                "cardNumber": "CAE-10",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome All Etch Rookie Rush": {
                            "CERR-1": {
                                "cardNumber": "CERR-1",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-2": {
                                "cardNumber": "CERR-2",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-3": {
                                "cardNumber": "CERR-3",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-4": {
                                "cardNumber": "CERR-4",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-5": {
                                "cardNumber": "CERR-5",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-6": {
                                "cardNumber": "CERR-6",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-7": {
                                "cardNumber": "CERR-7",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-8": {
                                "cardNumber": "CERR-8",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-9": {
                                "cardNumber": "CERR-9",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERR-10": {
                                "cardNumber": "CERR-10",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Topps Chrome Exposé": {
                            "TCE-1": {
                                "cardNumber": "TCE-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TCE-2": {
                                "cardNumber": "TCE-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "TCE-3": {
                                "cardNumber": "TCE-3",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TCE-4": {
                                "cardNumber": "TCE-4",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "TCE-5": {
                                "cardNumber": "TCE-5",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "TCE-6": {
                                "cardNumber": "TCE-6",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "TCE-7": {
                                "cardNumber": "TCE-7",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TCE-8": {
                                "cardNumber": "TCE-8",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "TCE-9": {
                                "cardNumber": "TCE-9",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "TCE-10": {
                                "cardNumber": "TCE-10",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            }
                        },
                        "Fanatical": {
                            "FAN-1": {
                                "cardNumber": "FAN-1",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "FAN-2": {
                                "cardNumber": "FAN-2",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "FAN-3": {
                                "cardNumber": "FAN-3",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "FAN-4": {
                                "cardNumber": "FAN-4",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FAN-5": {
                                "cardNumber": "FAN-5",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "FAN-6": {
                                "cardNumber": "FAN-6",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "FAN-7": {
                                "cardNumber": "FAN-7",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "FAN-8": {
                                "cardNumber": "FAN-8",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "FAN-9": {
                                "cardNumber": "FAN-9",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FAN-10": {
                                "cardNumber": "FAN-10",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Let's Go!": {
                            "LGC-26": {
                                "cardNumber": "LGC-26",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "LGC-27": {
                                "cardNumber": "LGC-27",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "LGC-28": {
                                "cardNumber": "LGC-28",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "LGC-29": {
                                "cardNumber": "LGC-29",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "LGC-30": {
                                "cardNumber": "LGC-30",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "LGC-31": {
                                "cardNumber": "LGC-31",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "LGC-32": {
                                "cardNumber": "LGC-32",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "LGC-33": {
                                "cardNumber": "LGC-33",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "LGC-34": {
                                "cardNumber": "LGC-34",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "LGC-35": {
                                "cardNumber": "LGC-35",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            }
                        },
                        "Youthquake": {
                            "YQ-1": {
                                "cardNumber": "YQ-1",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-2": {
                                "cardNumber": "YQ-2",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-3": {
                                "cardNumber": "YQ-3",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-4": {
                                "cardNumber": "YQ-4",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-5": {
                                "cardNumber": "YQ-5",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-6": {
                                "cardNumber": "YQ-6",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-7": {
                                "cardNumber": "YQ-7",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-8": {
                                "cardNumber": "YQ-8",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-9": {
                                "cardNumber": "YQ-9",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-10": {
                                "cardNumber": "YQ-10",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Helix": {
                            "HX-1": {
                                "cardNumber": "HX-1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HX-2": {
                                "cardNumber": "HX-2",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "HX-3": {
                                "cardNumber": "HX-3",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "HX-4": {
                                "cardNumber": "HX-4",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "HX-5": {
                                "cardNumber": "HX-5",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HX-6": {
                                "cardNumber": "HX-6",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "HX-7": {
                                "cardNumber": "HX-7",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HX-8": {
                                "cardNumber": "HX-8",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HX-9": {
                                "cardNumber": "HX-9",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "HX-10": {
                                "cardNumber": "HX-10",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "Award Winners Variation": {
                            "1": {
                                "cardNumber": "1",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "36": {
                                "cardNumber": "36",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "164": {
                                "cardNumber": "164",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "218": {
                                "cardNumber": "218",
                                "name": ["Blake Snell"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "250": {
                                "cardNumber": "250",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "267": {
                                "cardNumber": "267",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            }
                        },
                        "Swing for the Stars": {
                            "STS-1": {
                                "cardNumber": "STS-1",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "STS-2": {
                                "cardNumber": "STS-2",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "STS-3": {
                                "cardNumber": "STS-3",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "STS-4": {
                                "cardNumber": "STS-4",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "STS-5": {
                                "cardNumber": "STS-5",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "STS-6": {
                                "cardNumber": "STS-6",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "STS-7": {
                                "cardNumber": "STS-7",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "STS-8": {
                                "cardNumber": "STS-8",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "STS-9": {
                                "cardNumber": "STS-9",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "STS-10": {
                                "cardNumber": "STS-10",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "STS-11": {
                                "cardNumber": "STS-11",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers™"],
                                "cardAttributes": []
                            },
                            "STS-12": {
                                "cardNumber": "STS-12",
                                "name": ["Adolis García"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "STS-13": {
                                "cardNumber": "STS-13",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "STS-14": {
                                "cardNumber": "STS-14",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "STS-15": {
                                "cardNumber": "STS-15",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "STS-16": {
                                "cardNumber": "STS-16",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "STS-17": {
                                "cardNumber": "STS-17",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "STS-18": {
                                "cardNumber": "STS-18",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "STS-19": {
                                "cardNumber": "STS-19",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "STS-20": {
                                "cardNumber": "STS-20",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "STS-21": {
                                "cardNumber": "STS-21",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "STS-22": {
                                "cardNumber": "STS-22",
                                "name": ["Sammy Sosa"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "STS-23": {
                                "cardNumber": "STS-23",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "STS-24": {
                                "cardNumber": "STS-24",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "STS-25": {
                                "cardNumber": "STS-25",
                                "name": ["Alex Rodriguez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            }
                        },
                        "2023 Chrome MVP Buybacks": {
                            "17": {
                                "cardNumber": "17",
                                "name": ["Shohei Ohtani"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "39": {
                                "cardNumber": "39",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        }
                    },
                    'Relic': {
                        'Topps Chrome Authentics': {
                            "TCA-AB": {
                                "cardNumber": "TCA-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "TCA-AJ": {
                                "cardNumber": "TCA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TCA-ARI": {
                                "cardNumber": "TCA-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TCA-ARU": {
                                "cardNumber": "TCA-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TCA-BB": {
                                "cardNumber": "TCA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "TCA-BN": {
                                "cardNumber": "TCA-BN",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TCA-BR": {
                                "cardNumber": "TCA-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "TCA-BS": {
                                "cardNumber": "TCA-BS",
                                "name": ["Blake Snell"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "TCA-BW": {
                                "cardNumber": "TCA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TCA-CCO": {
                                "cardNumber": "TCA-CCO",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            }
                        },
                    },
                    'Autograph Relic': {
                        'Topps Chrome Authentics Relic Autograph Variation': {
                            "TCAA-AB": {
                                "cardNumber": "TCAA-AB",
                                "name": ["Alex Bregman"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "TCAA-AJ": {
                                "cardNumber": "TCAA-AJ",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TCAA-ARI": {
                                "cardNumber": "TCAA-ARI",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TCAA-ARU": {
                                "cardNumber": "TCAA-ARU",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "TCAA-BB": {
                                "cardNumber": "TCAA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "TCAA-BN": {
                                "cardNumber": "TCAA-BN",
                                "name": ["Brandon Nimmo"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TCAA-BW": {
                                "cardNumber": "TCAA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TCAA-DO": {
                                "cardNumber": "TCAA-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "TCAA-GC": {
                                "cardNumber": "TCAA-GC",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TCAA-GM": {
                                "cardNumber": "TCAA-GM",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    'Autograph': {
                        'Rookie Autograph': {
                            "RA-AA": {
                                "cardNumber": "RA-AA",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AC": {
                                "cardNumber": "RA-AC",
                                "name": ["Alexander Canario"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AF": {
                                "cardNumber": "RA-AF",
                                "name": ["Angel Felipe"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AJ": {
                                "cardNumber": "RA-AJ",
                                "name": ["Alek Jacob"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AJS": {
                                "cardNumber": "RA-AJS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AL": {
                                "cardNumber": "RA-AL",
                                "name": ["Andre Lipcius"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AMA": {
                                "cardNumber": "RA-AMA",
                                "name": ["Alec Marsh"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AMW": {
                                "cardNumber": "RA-AMW",
                                "name": ["Amos Willingham"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-ASP": {
                                "cardNumber": "RA-ASP",
                                "name": ["Alex Speas"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AV": {
                                "cardNumber": "RA-AV",
                                "name": ["Anthony Veneziano"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        'Chrome Legend Autograph': {
                            "CLA-AB": {
                                "cardNumber": "CLA-AB",
                                "name": ["Adrián Beltré"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CLA-AD": {
                                "cardNumber": "CLA-AD",
                                "name": ["Andre Dawson"],
                                "team": ["Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "CLA-AP": {
                                "cardNumber": "CLA-AP",
                                "name": ["Albert Pujols"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CLA-APE": {
                                "cardNumber": "CLA-APE",
                                "name": ["Andy Pettitte"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CLA-BJ": {
                                "cardNumber": "CLA-BJ",
                                "name": ["Bo Jackson"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "CLA-BW": {
                                "cardNumber": "CLA-BW",
                                "name": ["Billy Williams"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CLA-CBI": {
                                "cardNumber": "CLA-CBI",
                                "name": ["Craig Biggio"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CLA-CU": {
                                "cardNumber": "CLA-CU",
                                "name": ["Chase Utley"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CLA-DJ": {
                                "cardNumber": "CLA-DJ",
                                "name": ["Derek Jeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CLA-DO": {
                                "cardNumber": "CLA-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        'Seamhead Signature': {
                            "SSA-BO": {
                                "cardNumber": "SSA-BO",
                                "name": ["Buster Olney"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "SSA-DO": {
                                "cardNumber": "SSA-DO",
                                "name": ["Don Orsillo"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "SSA-GC": {
                                "cardNumber": "SSA-GC",
                                "name": ["Gary Cohen"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "SSA-JB": {
                                "cardNumber": "SSA-JB",
                                "name": ["Joe Buck"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "SSA-KR": {
                                "cardNumber": "SSA-KR",
                                "name": ["Ken Rosenthal"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "SSA-MK": {
                                "cardNumber": "SSA-MK",
                                "name": ["Michael Kay"],
                                "team": [],
                                "cardAttributes": []
                            },
                            "SSA-RR": {
                                "cardNumber": "SSA-RR",
                                "name": ["Ryan Ruocco"],
                                "team": [],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Autograph": {
                            "89CBA-AV": {
                                "cardNumber": "89CBA-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "89CBA-BB": {
                                "cardNumber": "89CBA-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "89CBA-JROD": {
                                "cardNumber": "89CBA-JROD",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89CBA-MM": {
                                "cardNumber": "89CBA-MM",
                                "name": ["Max Muncy"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89CBA-MO": {
                                "cardNumber": "89CBA-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89CBA-MY": {
                                "cardNumber": "89CBA-MY",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89CBA-TT": {
                                "cardNumber": "89CBA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89CBA-VG": {
                                "cardNumber": "89CBA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Chrome Radiating Rookies Autograph": {
                            "RRA-CC": {
                                "cardNumber": "RRA-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-CE": {
                                "cardNumber": "RRA-CE",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-CK": {
                                "cardNumber": "RRA-CK",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-CM": {
                                "cardNumber": "RRA-CM",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-CR": {
                                "cardNumber": "RRA-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-ED": {
                                "cardNumber": "RRA-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-ER": {
                                "cardNumber": "RRA-ER",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-EVA": {
                                "cardNumber": "RRA-EVA",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-HEK": {
                                "cardNumber": "RRA-HEK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RRA-JAD": {
                                "cardNumber": "RRA-JAD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Topps Chrome Ultraviolet All Stars Autograph": {
                            "UVA-BH": {
                                "cardNumber": "UVA-BH",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "UVA-DO": {
                                "cardNumber": "UVA-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "UVA-GB": {
                                "cardNumber": "UVA-GB",
                                "name": ["George Brett"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "UVA-I": {
                                "cardNumber": "UVA-I",
                                "name": ["Ichiro"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "UVA-JAD": {
                                "cardNumber": "UVA-JAD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "UVA-JS": {
                                "cardNumber": "UVA-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "UVA-MT": {
                                "cardNumber": "UVA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            }
                        },
                        "Future Stars Autograph": {
                            "FSA-AR": {
                                "cardNumber": "FSA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FSA-AV": {
                                "cardNumber": "FSA-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FSA-FA": {
                                "cardNumber": "FSA-FA",
                                "name": ["Francisco Alvarez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "FSA-JW": {
                                "cardNumber": "FSA-JW",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "FSA-MY": {
                                "cardNumber": "FSA-MY",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "FSA-TC": {
                                "cardNumber": "FSA-TC",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome All Etch Autograph": {
                            "CEA-DO": {
                                "cardNumber": "CEA-DO",
                                "name": ["David Ortiz"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CEA-JR": {
                                "cardNumber": "CEA-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CEA-MT": {
                                "cardNumber": "CEA-MT",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CEA-TT": {
                                "cardNumber": "CEA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CEA-WB": {
                                "cardNumber": "CEA-WB",
                                "name": ["Wade Boggs"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome All Etch Rush Autograph": {
                            "CERA-CC": {
                                "cardNumber": "CERA-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERA-CM": {
                                "cardNumber": "CERA-CM",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERA-EVA": {
                                "cardNumber": "CERA-EVA",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERA-JDO": {
                                "cardNumber": "CERA-JDO",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERA-JOL": {
                                "cardNumber": "CERA-JOL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERA-JUC": {
                                "cardNumber": "CERA-JUC",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CERA-SF": {
                                "cardNumber": "CERA-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "On The Spot Die Cut Autograph": {
                            "OSA-AD": {
                                "cardNumber": "OSA-AD",
                                "name": ["Andre Dawson"],
                                "team": ["Montréal Expos™"],
                                "cardAttributes": []
                            },
                            "OSA-AH": {
                                "cardNumber": "OSA-AH",
                                "name": ["Austin Hays"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "OSA-AR": {
                                "cardNumber": "OSA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "OSA-AV": {
                                "cardNumber": "OSA-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "OSA-BBX": {
                                "cardNumber": "OSA-BBX",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "OSA-BW": {
                                "cardNumber": "OSA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "OSA-CK": {
                                "cardNumber": "OSA-CK",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "OSA-CM": {
                                "cardNumber": "OSA-CM",
                                "name": ["Christopher Morel"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "OSA-DP": {
                                "cardNumber": "OSA-DP",
                                "name": ["Dustin Pedroia"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "OSA-GH": {
                                "cardNumber": "OSA-GH",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            }
                        },
                        "Dual Autograph": {
                            "DA-BWJ": {
                                "cardNumber": "DA-BWJ",
                                "name": ["George Brett", "Bobby Witt Jr."],
                                "team": ["Kansas City Royals®", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "DA-FRS": {
                                "cardNumber": "DA-FRS",
                                "name": ["Christian Yelich", "Sal Frelick"],
                                "team": ["Milwaukee Brewers®", "Milwaukee Brewers®"],
                                "cardAttributes": []
                            },
                            "DA-KV": {
                                "cardNumber": "DA-KV",
                                "name": ["Clayton Kershaw", "Fernando Valenzuela"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "DA-RH": {
                                "cardNumber": "DA-RH",
                                "name": ["Ryan Howard", "Jimmy Rollins"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "DA-RIO": {
                                "cardNumber": "DA-RIO",
                                "name": ["Austin Riley", "Matt Olson"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "DA-TM": {
                                "cardNumber": "DA-TM",
                                "name": ["Fernando Tatis Jr.", "Manny Machado"],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "DA-TRG": {
                                "cardNumber": "DA-TRG",
                                "name": ["Vladimir Guerrero", "Mike Trout"],
                                "team": ["Angels®", "Angels®"],
                                "cardAttributes": []
                            }
                        }
                    }
                }
            }
        }
    }
}

export const MLB_2024_TOPPS_CHROME_PARALLELS = {
    "MLB": {
        "2024": {
            "Topps": {
                "Topps Chrome® Baseball": {
                    "Base Card Set": {
                        'Base': [
                            { "name": "REFRACTOR", "color": "#C0C0C0", "limitedTo": null},
                            { "name": "PINK REFRACTOR", "color": "#FF69B4", "limitedTo": null},
                            { "name": "PRISM REFRACTOR", "color": "#C0C0C0", "limitedTo": null},
                            { "name": "RAYWAVE REFRACTOR", "color": "#C0C0C0", "limitedTo": null},
                            { "name": "SEPIA REFRACTOR", "color": "#D4AA7D", "limitedTo": null},
                            { "name": "X-FRACTOR", "color": "#C0C0C0", "limitedTo": null},
                            { "name": "NEGATIVE REFRACTOR", "color": "#5c5c5c", "limitedTo": null},
                            { "name": "MAGENTA SPECKLE REFRACTOR", "color": "#FF00FF", "limitedTo": 350},
                            { "name": "PURPLE SPECKLE REFRACTOR", "color": "#800080", "limitedTo": 299},
                            { "name": "SONAR PURPLE REFRACTOR", "color": "#800080", "limitedTo": 275},
                            { "name": "PURPLE REFRACTOR", "color": "#800080", "limitedTo": 250},
                            { "name": "AQUA REFRACTOR", "color": "#00FFFF", "limitedTo": 199},
                            { "name": "AQUA LAVA REFRACTOR", "color": "#00FFFF", "limitedTo": 199},
                            { "name": "AQUA RAYWAVE REFRACTOR", "color": "#00FFFF", "limitedTo": 199},
                            { "name": "BLUE REFRACTOR", "color": "#1E90FF", "limitedTo": 150},
                            { "name": "BLUE RAYWAVE REFRACTOR", "color": "#1E90FF", "limitedTo": 150},
                            { "name": "SONAR BLUE REFRACTOR", "color": "#1E90FF", "limitedTo": 125},
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GREEN WAVE REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GREEN RAYWAVE REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "SONAR GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "BLUE WAVE REFRACTOR", "color": "#1E90FF", "limitedTo": 75},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "GOLD WAVE REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "GOLD RAYWAVE REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "ORANGE WAVE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "ORANGE RAYWAVE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "BLACK RAYWAVE REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "FROZENFRACTOR", "color": "#a2d2df", "limitedTo": 5},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "RED WAVE REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "RED RAYWAVE REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "PRINTING PLATE", "color": "#808080", "limitedTo": 1},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1},
                            { "name": "BIG APPLE REFRACTOR", "color": "#a1c7b6", "limitedTo": null},
                            { "name": "LIGHTBOARD LOGO", "color": "#C0C0C0", "limitedTo": null}
                        ]
                    },
                    'Insert': {
                        "Award Winners Variation": [
                            { "name": "/23", "color": "#C0C0C0", "limitedTo": 23 }
                        ],
                        "1989 Topps Baseball": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "1989 Topps Baseball Chrome Layer Variation": [
                            { "name": "/25", "color": "#C0C0C0", "limitedTo": 25},
                            { "name": "BLACK WAVE REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED RAYWAVE REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Chrome All Etch": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Chrome All Etch Rookie Rush": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Topps Chrome Exposé": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Fanatical": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Future Stars": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Let's Go!": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Topps Chrome Radiating Rookies": [
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Strokes": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Summertime in the Park": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Swing for the Stars": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Ultra Violet All Stars": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Youthquake": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ]
                    },
                    'Relic': {
                        'Topps Chrome Authentics': [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ]
                    },
                    'Autograph Relic': {
                    },
                    'Autograph': {
                        "1989 Topps Baseball Autograph": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "PRINTING PLATE", "color": "#808080", "limitedTo": 1},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "2023 Chrome MVP Buybacks": [
                            { "name": "/23", "color": "#C0C0C0", "limitedTo": 23}
                        ],
                        "Chrome All Etch Autograph": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "PRINTING PLATE", "color": "#808080", "limitedTo": 1},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Chrome All Etch Rookie Rush": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "PRINTING PLATE", "color": "#808080", "limitedTo": 1},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        'Chrome Legend Autograph': [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "PRINTING PLATE", "color": "#808080", "limitedTo": 1},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Dual Autograph": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Future Stars Autograph": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "PRINTING PLATE", "color": "#808080", "limitedTo": 1},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "On The Spot Die Cut Autograph": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Topps Chrome Radiating Rookies Autograph": [
                            { "name": "/24", "color": "#C0C0C0", "limitedTo": 24},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Rookie Autograph": [
                            { "name": "BLACK & WHITE MINI DIAMOND", "color": "#C0C0C0", "limitedTo": null},
                            { "name": "REFRACTOR", "color": "#C0C0C0", "limitedTo": null},
                            { "name": "PURPLE SPECKLE REFRACTOR", "color": "#800080", "limitedTo": 299},
                            { "name": "PURPLE REFRACTOR", "color": "#800080", "limitedTo": 250},
                            { "name": "AQUA WAVE", "color": "#00FFFF", "limitedTo": 199},
                            { "name": "BLUE REFRACTOR", "color": "#1E90FF", "limitedTo": 150},
                            { "name": "BLUE RAYWAVE REFRACTOR", "color": "#1E90FF", "limitedTo": 150},
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99},
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "GOLD WAVE REFRACTOR", "color": "#FFD700", "limitedTo": 50},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "ORANGE WAVE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "RED WAVE REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "PRINTING PLATE", "color": "#808080", "limitedTo": 1},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1},
                        ],
                        'Seamhead Signature': [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ],
                        "Topps Chrome Ultraviolet All Stars Autograph": [
                            { "name": "/99", "color": "#C0C0C0", "limitedTo": 99},
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25},
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10},
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5},
                            { "name": "SUPERFRACTOR", "color": "#C0C0C0", "limitedTo": 1}
                        ]
                    }
                }
            }
        }
    }
}