import { useDispatch, useSelector } from 'react-redux';
import { addCardToQueue, removeCardFromQueue, addCardToQueueDisplay, removeCardFromQueueDisplay } from '../../../../store/card/card.action';
import { selectQueuedCards, selectQueuedCardsDisplay } from '../../../../store/card/card.selector';

// styles
import {
    QueueCardContainer,
    CardDetails,
    CardHeader,
    CardNumber,
    AttributesContainer,
    AddBtn,
    CardContent,
    LeftCardContent,
    QuantityControlsContainer,
    QtyContent,
    QtyBtns,
    QtyButton
} from './QueueCard.styles';

// assets
import addIcon from '../../../../assets/system/icons/add-icon.png';
import minusIcon from '../../../../assets/system/icons/minus-icon.png';
import CardAttribute from '../../../card-attribute/CardAttribute';

export default function QueueCard({ card, index, parallel }) {
    const dispatch = useDispatch();
    const queuedCards = useSelector(selectQueuedCards);
    const queuedCardsDisplay = useSelector(selectQueuedCardsDisplay);

    const handleRemoveCard = () => {
        dispatch(removeCardFromQueueDisplay(queuedCardsDisplay, index, parallel.cardParallelName));
        dispatch(removeCardFromQueue(queuedCards, card, parallel.cardParallelName));
    };

    const handleQtyChangeFromQueue = (inc) => {
        if (card.card.collectedCards[0].cardSupply !== null) {
            return;
        }
        dispatch(
            addCardToQueue(
                queuedCards,
                card.sport,
                card.year,
                card.manufacturer,
                card.series,
                card.cardType,
                card.cardTypeCategory,
                card.card,
                inc
            )
        );
        dispatch(
            addCardToQueueDisplay(
                queuedCardsDisplay,
                card.sport,
                card.year,
                card.manufacturer,
                card.series,
                card.cardType,
                card.cardTypeCategory,
                card.card,
                inc
            )
        );
    };

    return (
        <QueueCardContainer className='panel-bg dropshadow'>
            <CardContent>
                <LeftCardContent>
                    <CardNumber>
                        <h4>{card.card.cardNumber}</h4>
                    </CardNumber>
                    <CardDetails>
                        <CardHeader>
                            {card.card.name.map((name, index) => (
                                <span key={index}>
                                    <h3>{name}</h3>
                                    {index < card.card.name.length - 1 && ' • '}
                                </span>
                            ))}
                        </CardHeader>
                        {(card.card.cardAttributes?.length > 0 || card.card.collectedCards?.length > 0) &&
                            <AttributesContainer>
                                {card.card.cardAttributes?.length > 0 &&
                                    card.card.cardAttributes.map(attribute => (
                                        <CardAttribute key={attribute} text={attribute} />
                                    ))
                                }

                                {card.card.cardAttributes?.length > 0 && card.card.collectedCards?.length > 0 &&
                                    <h4>•</h4>
                                }

                                {card.card.collectedCards.map((parallel) => {
                                    const borderColor = parallel.cardColor ? parallel.cardColor : '#FFFFFF';
                                    return (
                                        <CardAttribute
                                            key={parallel.cardParallelName}
                                            color={borderColor}
                                            text={
                                                parallel.cardSupply ?
                                                    `${parallel.cardParallelName} (${parallel.cardPrintNumber}/${parallel.cardSupply})`
                                                    :
                                                    parallel.cardParallelName
                                            }
                                        />
                                    );
                                })}
                            </AttributesContainer>
                        }
                    </CardDetails>
                </LeftCardContent>
                <QuantityControlsContainer>
                    <QtyContent>
                        <QtyBtns>
                            <QtyButton $isActive={card.card.collectedCards[0].cardSupply === null} onClick={() => handleQtyChangeFromQueue(-1)}>
                                <img src={minusIcon} alt='subtract from checklist button' />
                            </QtyButton>
                            <h4>{card.quantity}</h4>
                            <QtyButton $isActive={card.card.collectedCards[0].cardSupply === null} onClick={() => handleQtyChangeFromQueue(1)}>
                                <img src={addIcon} alt='add from checklist button' />
                            </QtyButton>
                        </QtyBtns>
                        <AddBtn onClick={() => handleRemoveCard(index)}>
                            <h4>REMOVE</h4>
                        </AddBtn>
                    </QtyContent>
                </QuantityControlsContainer>
            </CardContent>
        </QueueCardContainer>
    );
}