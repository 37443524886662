import styled from "styled-components";

export const SignUpContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .agreement {
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
        max-width: 300px;
        text-align: center;
    }

    .error {
        color: #FFFFFF;
        font-weight: 600;
        text-align: center;
    }
`
export const FavoriteTeam = styled.select`
    appearance: none;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    height: 44px;
    font-size: 12px;
    font-weight: 700;
    padding: 0px 16px;

    &:focus {
        outline: none;
    }
`