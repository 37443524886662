import styled from "styled-components";

export const AccountPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 48px;

    h1 {
        color: #FFFFFF;
        font-size: 48px;
        opacity: 0.2;
    }
`
export const AccountContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`