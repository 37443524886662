import _ from 'lodash';
import { clearCardsFromQueue, setCardData, setChecklistData } from '../../store/card/card.action';
import { authIsReady } from '../../store/user/user.action';
import { setSystemNotification } from '../../store/system/system.action';

// firebase
import { initializeApp } from "firebase/app";
import {
  getAuth
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  writeBatch,
  getDocs,
  increment,
  updateDoc,
  Timestamp,
  addDoc
} from 'firebase/firestore';

// import { getAnalytics } from 'firebase/analytics';
// import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCsA6mPYWudr4ff9BLYz52YfTj13K0S7vs",
  authDomain: "pull-vault.firebaseapp.com",
  projectId: "pull-vault",
  storageBucket: "pull-vault.appspot.com",
  messagingSenderId: "486497881333",
  appId: "1:486497881333:web:05304745cdb18aba8a09bc",
  measurementId: "G-T1TCDY6GBJ"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
// const analytics = getAnalytics(app);
// export const storage = getStorage(app);
// const storageRef = ref(storage);

export const setupAuthListener = () => {
  return (dispatch) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        };
        dispatch(authIsReady(userData));
      } else {
        dispatch(authIsReady());
      }
    });
  };
};

// CHECKLIST & PARALLEL UPLOAD FUNCTIONS
export const addChecklistToFirestore = async (checklistData) => {
  const batch = writeBatch(db);
  let newAvailableYears = {};
  let newAvailableSeries = {};
  let newAvailableCardTypeCategories = {};

  let totalNewCards = 0; // Total new cards added across all sports (master total)

  // Helper Function: Calculate totalCardCount by summing all cardCount fields within availableYear/Series/CardTypeCategories map
  const calculateTotalCardCount = (object) => {
    return Object.values(object).reduce((total, item) => total + (item.cardCount || 0), 0);
  };

  for (const sport of Object.keys(checklistData)) {
    const sportData = checklistData[sport];
    if (!sportData) continue;

    // fetch availableYears for each Sport
    const sportDocRef = doc(db, `checklists/${sport}`);
    try {
      const sportDocSnap = await getDoc(sportDocRef);
      if (sportDocSnap.exists()) {
        const data = sportDocSnap.data();
        newAvailableYears = _.cloneDeep(data.availableYears) || {};
        console.log(`Fetched availableYears for ${sport}: ${newAvailableYears}`);
      } else {
        console.log(`availableYears does not yet exist for ${sport}.`);
      }
    } catch (error) {
      console.error("Error fetching availableYears:", error);
    }

    let sportNewCardsCount = 0; // Track new cards for the sport

    for (const year of Object.keys(sportData)) {
      const yearData = sportData[year];
      if (!yearData) continue;

      let yearNewCardsCount = 0; // Track new cards for the year

      for (const manufacturer of Object.keys(yearData)) {
        const manufacturerData = yearData[manufacturer];
        if (!manufacturerData) continue;

        // fetch availableSeries
        const manufacturerDocRef = doc(db, `checklists/${sport}/${year}/${manufacturer}`);
        try {
          const manufacturerDocSnap = await getDoc(manufacturerDocRef);
          if (manufacturerDocSnap.exists()) {
            const data = manufacturerDocSnap.data();
            newAvailableSeries = _.cloneDeep(data.availableSeries) || {};
            console.log(`Fetched availableSeries for ${manufacturer}: ${newAvailableSeries}`);
          } else {
            console.log(`availableSeries does not yet exist for ${manufacturer}.`);
          }
        } catch (error) {
          console.error("Error fetching availableSeries:", error);
        }

        let manufacturerNewCardsCount = 0; // Track new cards for the manufacturer

        for (const series of Object.keys(manufacturerData)) {
          const seriesData = manufacturerData[series];
          if (!seriesData) continue;

          let seriesNewCardsCount = 0; // Track new cards for the series

          for (const cardType of Object.keys(seriesData)) {
            const cardTypeData = seriesData[cardType];
            if (!cardTypeData) continue;

            // fetch availableCardTypeCategories
            const cardTypeDocRef = doc(db, `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}`);
            try {
              const cardTypeDocSnap = await getDoc(cardTypeDocRef);
              if (cardTypeDocSnap.exists()) {
                const data = cardTypeDocSnap.data();
                newAvailableCardTypeCategories = _.cloneDeep(data.availableCardTypeCategories) || {};
                console.log(`Fetched availableCardTypeCategories for ${cardType}: ${newAvailableCardTypeCategories}`);
              } else {
                console.log(`availableCardTypeCategories does not yet exist for ${cardType}.`);
              }
            } catch (error) {
              console.error("Error fetching availableCardTypeCategories:", error);
            }

            let cardTypeNewCardsCount = 0; // Track new cards for the card type

            for (const cardTypeCategory of Object.keys(cardTypeData)) {
              const cardTypeCategoryData = cardTypeData[cardTypeCategory];
              if (!cardTypeCategoryData) continue;

              let cardTypeCategoryNewCardsCount = 0;

              const cardTypeCategoryCollectionRef = collection(db, `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}`);
              const snapshot = await getDocs(cardTypeCategoryCollectionRef);
              const existingCardIds = snapshot.docs.map(doc => doc.id);
              const cardTypeCategoryKeys = Object.keys(cardTypeCategoryData);

              let newCardTypeCardsAdded = 0;

              cardTypeCategoryKeys.forEach((key) => {
                if (!existingCardIds.includes(key)) {
                  newCardTypeCardsAdded++;
                }
              });

              cardTypeCategoryNewCardsCount += newCardTypeCardsAdded;
              cardTypeNewCardsCount += newCardTypeCardsAdded;
              seriesNewCardsCount += newCardTypeCardsAdded;
              manufacturerNewCardsCount += newCardTypeCardsAdded;
              yearNewCardsCount += newCardTypeCardsAdded;
              sportNewCardsCount += newCardTypeCardsAdded;
              totalNewCards += newCardTypeCardsAdded;

              console.log(`New cards added for ${cardTypeCategory}: ${cardTypeCategoryNewCardsCount}`);

              // Add each new card to Firestore in a batch
              for (const cardNumber of Object.keys(cardTypeCategoryData)) {
                const cardData = cardTypeCategoryData[cardNumber];
                const cardDocRef = doc(
                  db,
                  `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}/${cardNumber}`
                );

                batch.set(cardDocRef, {
                  cardNumber: cardData.cardNumber,
                  name: cardData.name,
                  team: cardData.team,
                  cardAttributes: cardData.cardAttributes || [],
                });
              }

              if (newAvailableCardTypeCategories[cardTypeCategory]) {
                // If the year already exists, add the existing cardCount to manufacturerNewCardsCount
                newAvailableCardTypeCategories[cardTypeCategory].cardCount += cardTypeCategoryNewCardsCount;
              } else {
                // If the year does not exist, initialize it with manufacturerNewCardsCount
                newAvailableCardTypeCategories[cardTypeCategory] = {
                  cardCount: cardTypeCategoryNewCardsCount,
                  title: cardTypeCategory,
                };
              }
            }

            const newTotalCardCount = calculateTotalCardCount(newAvailableCardTypeCategories);
            batch.set(cardTypeDocRef, {
              availableCardTypeCategories: newAvailableCardTypeCategories,
              totalCardCount: newTotalCardCount,
            }, { merge: true });
            console.log(`New cards added for ${cardType}: ${cardTypeNewCardsCount}`);
            newAvailableCardTypeCategories = {}
          }
          console.log(`New cards added for ${series}: ${seriesNewCardsCount}`);

          if (newAvailableSeries[series]) {
            // If the year already exists, add the existing cardCount to manufacturerNewCardsCount
            newAvailableSeries[series].cardCount += seriesNewCardsCount;
          } else {
            // If the year does not exist, initialize it with manufacturerNewCardsCount
            newAvailableSeries[series] = {
              cardCount: seriesNewCardsCount,
              title: series,
            };
          }
        }
        const newTotalCardCount = calculateTotalCardCount(newAvailableSeries);
        batch.set(manufacturerDocRef, {
          availableSeries: newAvailableSeries,
          totalCardCount: newTotalCardCount,
        }, { merge: true });
        console.log(`New cards added for ${manufacturer}: ${manufacturerNewCardsCount}`);

        if (newAvailableYears[year]) {
          newAvailableYears[year].cardCount += manufacturerNewCardsCount;
        } else {
          newAvailableYears[year] = {
            cardCount: manufacturerNewCardsCount,
            title: year,
          };
        }
      }
      console.log(`New cards added for ${year}: ${yearNewCardsCount}`);
    }
    const newTotalCardCount = calculateTotalCardCount(newAvailableYears);
    batch.set(sportDocRef, {
      availableYears: newAvailableYears,
      totalCardCount: newTotalCardCount,
    }, { merge: true });
    console.log(`New cards added for ${sport}: ${sportNewCardsCount}`);
  }
  await batch.commit();
  console.log(`Total new cards added: ${totalNewCards}`);
  console.log("Checklist uploaded successfully!");
};
export const addParallelsToFirestore = async (parallelData) => {
  const batch = writeBatch(db);

  // Traverse through the structure of parallelData
  for (const sport of Object.keys(parallelData)) {
    const sportParallelData = parallelData[sport];
    for (const year of Object.keys(sportParallelData)) {
      const yearParallelData = sportParallelData[year];
      for (const manufacturer of Object.keys(yearParallelData)) {
        const manufacturerParallelData = yearParallelData[manufacturer];
        for (const series of Object.keys(manufacturerParallelData)) {
          const seriesParallelData = manufacturerParallelData[series];
          for (const cardType of Object.keys(seriesParallelData)) {
            const cardTypeParallelData = seriesParallelData[cardType];
            for (const cardTypeCategory of Object.keys(cardTypeParallelData)) {
              const cardTypeCategoryParallelData = cardTypeParallelData[cardTypeCategory];

              // Fetch the existing cardNumber documents
              const cardTypeCategoryRef = collection(
                db,
                `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}`
              );

              // Await the fetching of all cardNumber documents for this cardTypeCategory
              const cardDocumentsSnapshot = await getDocs(cardTypeCategoryRef);

              cardDocumentsSnapshot.forEach((cardDoc) => {
                const cardDocRef = cardDoc.ref;
                const availableParallels = cardTypeCategoryParallelData;

                // Add the availableParallels field to the card document
                batch.set(cardDocRef, {
                  availableParallels: availableParallels // Replace the existing availableParallels field
                }, { merge: true }); // Merge without overwriting other fields
              });
            }
          }
        }
      }
    }
  }
  await batch.commit();
  console.log("Parallel data added successfully!");
};
////////////////////////////////////////

// CHECKLIST & COLLECTION FETCH FUNCTIONS
export const getAvailableSports = async (userID = null) => {
  try {
    const collectionRef = userID
      ? collection(db, `users/${userID}/collection`)
      : collection(db, 'checklists');

    const querySnapshot = await getDocs(collectionRef)

    if (querySnapshot.empty) return [];

    const sportsData = querySnapshot.docs.map((doc) => {
      const { totalCardCount = 0 } = doc.data();
      return { sport: doc.id, totalCardCount };
    });

    return sportsData;

  } catch (error) {
    console.error("Error fetching sports data:", error);
    return [];
  }
};
export const getAvailableYears = async (userID = null, sport) => {
  try {
    let sportDocRef;
    let yearsField = 'availableYears';

    if (userID) {
      sportDocRef = doc(db, `users/${userID}/collection`, sport);
      yearsField = 'collectedYears';
    } else {
      sportDocRef = doc(db, 'checklists', sport);
    }
    /// XXX
    const sportDocSnap = await getDoc(sportDocRef);

    if (sportDocSnap.exists()) {
      const data = sportDocSnap.data();
      const yearsData = data[yearsField];
      // console.log("yearsDataa: ", yearsData);
      return yearsData || [];
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
export const getAvailableManufacturers = async (userID = null, sport, year) => {
  try {
    let manufacturerRef;

    if (userID) {
      manufacturerRef = collection(db, `users/${userID}/collection/${sport}/${year}`);
    } else {
      manufacturerRef = collection(db, `checklists/${sport}/${year}`);
    }

    const manufacturerSnap = await getDocs(manufacturerRef);

    if (!manufacturerSnap.empty) {
      const manufacturersData = await Promise.all(
        manufacturerSnap.docs.map(async (doc) => {
          const manufacturer = doc.id;
          const manufacturerDocRef = doc.ref;
          const manufacturerSnapshot = await getDoc(manufacturerDocRef);

          if (manufacturerSnapshot.exists()) {
            const { totalCardCount } = manufacturerSnapshot.data();
            return { manufacturer, totalCardCount: totalCardCount || 0 };
          } else {
            return { manufacturer, totalCardCount: 0 };
          }
        })
      );
      // console.log("manufacturersData: ", manufacturersData);
      return manufacturersData;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
export const getAvailableSeries = async (userID = null, sport, year, manufacturer) => {
  try {
    let manufacturerDocRef;
    let seriesField = 'availableSeries';

    if (userID) {
      manufacturerDocRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}`);
      seriesField = 'collectedSeries';
    } else {
      manufacturerDocRef = doc(db, `checklists/${sport}/${year}/${manufacturer}`);
    }

    const manufacturerDocSnap = await getDoc(manufacturerDocRef);

    if (manufacturerDocSnap.exists()) {
      const data = manufacturerDocSnap.data();
      const seriesData = data[seriesField];
      // console.log("seriessData: ", seriesData);
      return seriesData || [];
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
export const getAvailableCardTypes = async (userID = null, sport, year, manufacturer, series) => {
  try {
    let cardTypesRef;

    if (userID) {
      cardTypesRef = collection(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}/${series}`);
    } else {
      cardTypesRef = collection(db, `checklists/${sport}/${year}/${manufacturer}/${series}`);
    }

    const cardTypeSnap = await getDocs(cardTypesRef);

    if (!cardTypeSnap.empty) {
      const cardTypesData = await Promise.all(
        cardTypeSnap.docs.map(async (doc) => {
          const cardType = doc.id;
          const cardTypeDocRef = doc.ref;
          const cardTypeSnapshot = await getDoc(cardTypeDocRef);

          if (cardTypeSnapshot.exists()) {
            const { totalCardCount } = cardTypeSnapshot.data();
            return { cardType, totalCardCount: totalCardCount || 0 };
          } else {
            return { cardType, totalCardCount: 0 };
          }
        })
      );
      // console.log("cardTypesData: ", cardTypesData);
      return cardTypesData;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
export const getAvailableCardTypeCategories = async (userID = null, sport, year, manufacturer, series, cardType) => {
  try {
    let cardTypeRef;
    let cardTypeCategoryField = 'availableCardTypeCategories';

    if (userID) {
      cardTypeRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}/${series}/${cardType}`);
      cardTypeCategoryField = 'collectedCardTypeCategories';
    } else {
      cardTypeRef = doc(db, `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}`);
    }

    const cardTypeCategoriesSnap = await getDoc(cardTypeRef);

    if (cardTypeCategoriesSnap.exists()) {
      const data = cardTypeCategoriesSnap.data();
      const cardTypeCategories = data[cardTypeCategoryField];
      // console.log("cardTypeCategoriesData: ", cardTypeCategories);
      return cardTypeCategories || [];
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
export const fetchCollectionData = (collectionPath) => async (dispatch) => {
  try {
    const collectionRef = collection(db, collectionPath);
    const collectionSnapshot = await getDocs(collectionRef);
    const collectionData = {};

    collectionSnapshot.forEach((doc) => {
      collectionData[doc.id] = doc.data();
    });

    dispatch(setCardData(collectionData));

  } catch (error) {
    alert('Error fetching checklist data: ', error);
  }
};
export const fetchChecklistData = (checklistPath) => async (dispatch) => {
  try {
    const checklistRef = collection(db, checklistPath);
    const checklistSnapshot = await getDocs(checklistRef);
    const checklistData = {};

    checklistSnapshot.forEach((doc) => {
      checklistData[doc.id] = doc.data();
    });

    dispatch(setChecklistData(checklistData));

  } catch (error) {
    alert('Error fetching checklist data: ', error);
  }
};

// ADD CARD(S) TO COLLECTION
export const mergeQueuedCardsWithFirestore = (userID, queuedCards, prevCount) => {
  return async (dispatch) => {
    try {
      await addCardsToFirestore(userID, queuedCards);
      logUserAction(userID, "addedToCollection", { cards: queuedCards, prevCount: prevCount, increment: queuedCards.totalCardCount })
      dispatch(setSystemNotification(`Successfully added ${increment} ${increment === 1 ? "cards" : "card"}`))
      dispatch(clearCardsFromQueue("removed"));

    } catch (error) {
      console.error('mergeQueuedCardsWithFirestore: Error adding cards to Firestore: ', error);
      dispatch(setSystemNotification('mergeQueuedCardsWithFirestore: Error adding cards to Firestore: ', error))
    }
  };
};
export const addCardsToFirestore = async (userID, queuedCards) => {
  const batch = writeBatch(db);

  let newCollectedYears = {};
  let newCollectedSeries = {};
  let newCollectedCardTypeCategories = {};

  let totalNewCardCount = 0;
  let sportNewCardsCount = 0;
  let yearNewCardsCount = 0;
  let manufacturerNewCardsCount = 0;
  let seriesNewCardsCount = 0;
  let cardTypeNewCardsCount = 0;
  let cardTypeCategoryNewCardsCount = 0;

  const calculateTotalCardCount = (object) => {
    return Object.values(object).reduce((total, item) => total + (item.cardCount || 0), 0);
  };

  for (const sport of Object.keys(queuedCards)) {
    if (sport === "totalCardCount") {
      totalNewCardCount = queuedCards[sport];
      continue;
    };
    const sportData = queuedCards[sport];

    // fetch collectedYears for each Sport
    const sportDocRef = doc(db, `users/${userID}/collection/${sport}`);
    try {
      const sportDocSnap = await getDoc(sportDocRef);
      if (sportDocSnap.exists()) {
        const data = sportDocSnap.data();
        newCollectedYears = _.cloneDeep(data.collectedYears) || {};
        console.log(`Fetched collectedYears for ${sport}: ${newCollectedYears}`);
      } else {
        console.log(`collectedYears does not yet exist for ${sport}.`);
      }
    } catch (error) {
      console.error("Error fetching collectedYears:", error);
    }

    for (const year of Object.keys(sportData)) {
      if (year === "totalCardCount") {
        sportNewCardsCount = sportData[year];
        continue;
      };
      const yearData = sportData[year];

      for (const manufacturer of Object.keys(yearData)) {
        if (manufacturer === "totalCardCount") {
          yearNewCardsCount = yearData[manufacturer];
          continue;
        };
        const manufacturerData = yearData[manufacturer];

        // fetch collectedSeries for each manufacturer
        const manufacturerDocRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}`);
        try {
          const manufacturerDocSnap = await getDoc(manufacturerDocRef);
          if (manufacturerDocSnap.exists()) {
            const data = manufacturerDocSnap.data();
            newCollectedSeries = _.cloneDeep(data.collectedSeries) || {};
            console.log(`Fetched collectedSeries for ${manufacturer}: ${newCollectedSeries}`);
          } else {
            console.log(`collectedSeries does not yet exist for ${manufacturer}.`);
          }
        } catch (error) {
          console.error("Error fetching collectedSeries:", error);
        }

        for (const series of Object.keys(manufacturerData)) {
          if (series === "totalCardCount") {
            manufacturerNewCardsCount = manufacturerData[series];
            continue;
          };
          const seriesData = manufacturerData[series];

          for (const cardType of Object.keys(seriesData)) {
            if (cardType === "totalCardCount") {
              seriesNewCardsCount = seriesData[cardType];
              continue;
            };
            const cardTypeData = seriesData[cardType];

            // fetch collectedCardTypeCategories
            const cardTypeDocRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}/${series}/${cardType}`);
            try {
              const cardTypeDocSnap = await getDoc(cardTypeDocRef);
              if (cardTypeDocSnap.exists()) {
                const data = cardTypeDocSnap.data();
                newCollectedCardTypeCategories = _.cloneDeep(data.collectedCardTypeCategories) || {};
                console.log(`Fetched collectedCardTypeCategories for ${cardType}: ${newCollectedCardTypeCategories}`);
              } else {
                console.log(`collectedCardTypeCategories does not yet exist for ${cardType}.`);
              }
            } catch (error) {
              console.error("Error fetching collectedCardTypeCategories:", error);
            }

            for (const cardTypeCategory of Object.keys(cardTypeData)) {
              if (cardTypeCategory === "totalCardCount") {
                cardTypeNewCardsCount = cardTypeData[cardTypeCategory];
                continue;
              };
              const cardTypeCategoryData = cardTypeData[cardTypeCategory];

              for (const cardNumber of Object.keys(cardTypeCategoryData)) {
                if (cardNumber === "totalCardCount") {
                  cardTypeCategoryNewCardsCount = cardTypeCategoryData[cardNumber];
                  continue;
                };
                const cardData = cardTypeCategoryData[cardNumber];
                const cardDocRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}/${cardNumber}`);

                // Fetch existing card to determine if it's new or duplicate
                const existingCardSnap = await getDoc(cardDocRef);
                let existingCollectedCards = [];

                // If the card exists, check the variations
                if (existingCardSnap.exists()) {
                  const existingCardData = existingCardSnap.data();
                  existingCollectedCards = existingCardData.collectedCards || [];

                  // Check each variation in cardData.collectedParallels
                  cardData.collectedCards.forEach((parallel) => {
                    const existingVariation = existingCollectedCards.find(
                      (p) =>
                        p.cardParallelName === parallel.cardParallelName &&
                        ((p.cardPrintNumber === parallel.cardPrintNumber) || p.cardPrintNumber === null)
                    );

                    if (existingVariation) {
                      // Increment the quantity for this variation
                      existingVariation.cardQty += parallel.cardQty;
                    } else {
                      // Add a new variation if it doesn't exist
                      existingCollectedCards.push({
                        cardParallelName: `${parallel.cardParallelName}`,
                        cardColor: `${parallel.cardColor}`,
                        cardQty: parallel.cardQty,
                        cardPrintNumber: parallel.cardPrintNumber,
                        cardSupply: parallel.cardSupply
                      });
                    }
                  });
                } else {
                  // If the card doesn't exist, initialize collectedCards from the queued data
                  existingCollectedCards = cardData.collectedCards;
                }

                // Set or merge card data in Firestore with updated variations
                batch.set(cardDocRef, {
                  cardNumber: cardData.cardNumber,
                  name: cardData.name || [],
                  team: cardData.team || [],
                  cardAttributes: cardData.cardAttributes || [],
                  collectedCards: existingCollectedCards,  // Updated collectedCards
                  quantity: increment(cardData.quantity), // Increment total quantity
                  checklistPath: cardData.checklistPath
                }, { merge: true });
              }

              if (newCollectedCardTypeCategories[cardTypeCategory]) {
                // If the year already exists, add the existing cardCount to manufacturerNewCardsCount
                newCollectedCardTypeCategories[cardTypeCategory].cardCount += cardTypeCategoryNewCardsCount;
              } else {
                // If the year does not exist, initialize it with manufacturerNewCardsCount
                newCollectedCardTypeCategories[cardTypeCategory] = {
                  cardCount: cardTypeCategoryNewCardsCount,
                  title: cardTypeCategory,
                };
              }
            }

            const newTotalCount = calculateTotalCardCount(newCollectedCardTypeCategories);
            batch.set(cardTypeDocRef, {
              collectedCardTypeCategories: newCollectedCardTypeCategories,
              totalCardCount: newTotalCount,
            }, { merge: true });
            console.log(`New cards added for ${cardType}: ${cardTypeNewCardsCount}`);
            newCollectedCardTypeCategories = {}
          }
          console.log(`New cards added for ${series}: ${seriesNewCardsCount}`);

          if (newCollectedSeries[series]) {
            // If the year already exists, add the existing cardCount to manufacturerNewCardsCount
            newCollectedSeries[series].cardCount += seriesNewCardsCount;
          } else {
            // If the year does not exist, initialize it with manufacturerNewCardsCount
            newCollectedSeries[series] = {
              cardCount: seriesNewCardsCount,
              title: series,
            };
          }
        }
        const newTotalManufactuereCardCount = calculateTotalCardCount(newCollectedSeries);
        batch.set(manufacturerDocRef, {
          collectedSeries: newCollectedSeries,
          totalCardCount: newTotalManufactuereCardCount,
        }, { merge: true });
        console.log(`New cards added for ${manufacturer}: ${manufacturerNewCardsCount}`);
      }
      console.log(`New cards added for ${year}: ${yearNewCardsCount}`);

      if (newCollectedYears[year]) {
        newCollectedYears[year].cardCount += manufacturerNewCardsCount;
      } else {
        newCollectedYears[year] = {
          cardCount: manufacturerNewCardsCount,
          title: year,
        };
      }
    }
    const newTotalCardCount = calculateTotalCardCount(newCollectedYears);
    batch.set(sportDocRef, {
      collectedYears: newCollectedYears,
      totalCardCount: newTotalCardCount,
    }, { merge: true });
    console.log(`New cards added for ${sport}: ${sportNewCardsCount}`);
  }

  // Update user's total collection counts (in user document)
  const userDocRef = doc(db, `users/${userID}`);
  const userSnap = await getDoc(userDocRef);
  const existingUserData = userSnap.exists() ? userSnap.data() : {};

  // Accumulate the current total counts with the new values
  const updatedCollectionCardCount = (existingUserData.collectionCardCount || 0) + totalNewCardCount;

  batch.set(userDocRef, {
    collectionCardCount: updatedCollectionCardCount
  }, { merge: true });

  // Commit batch operations
  await batch.commit();
  console.log(`Total new cards added: ${totalNewCardCount}`);
  console.log("Cards added successfully!");
};

// REMOVE CARD(S) FROM COLLECTION
export const removeCardFromFirestore = async (
  userID,
  parallelCardName,
  inc,
  sport,
  year,
  manufacturer,
  series,
  cardType,
  cardTypeCategory,
  cardNumber,
  prevCount,
  card
) => {

  // e.g. "1"
  const cardDocRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}/${cardNumber}`);

  try {
    const cardSnap = await getDoc(cardDocRef);
    if (!cardSnap.exists()) throw new Error("Card not found!");

    const cardData = cardSnap.data();
    const { collectedCards } = cardData;
    const parallelIndex = collectedCards.findIndex(v => v.cardParallelName === parallelCardName);

    if (parallelIndex === -1) throw new Error(`Parallel (${parallelCardName}) not found!`);

    const newQuantity = collectedCards[parallelIndex].cardQty - inc;
    const removeAll = (newQuantity <= 0);

    if (removeAll) {
      collectedCards.splice(parallelIndex, 1);
    } else {
      collectedCards[parallelIndex].cardQty = newQuantity;
    }

    const newTotalQuantity = collectedCards.reduce((sum, parallel) => sum + parallel.cardQty, 0);

    const batch = writeBatch(db);

    const handleUpdate = (data, field, incValue) => {
      if (data[field]) {
        data[field].cardCount -= incValue;
        if (removeAll) data[field].uniqueCardCount -= 1;
        if (data[field].cardCount <= 0) delete data[field];
      }
    };

    // last collectedCard parallel was removed, so delete the cardDocRef (e.g. "1")
    if (newTotalQuantity <= 0) {
      batch.delete(cardDocRef);
    } else {
      batch.update(cardDocRef, {
        collectedCards: collectedCards,
        quantity: newTotalQuantity
      });
    }

    // update collectedCardTypeCategories values
    const cardTypeDocRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}/${series}/${cardType}`);
    const cardTypeSnap = await getDoc(cardTypeDocRef);

    if (cardTypeSnap.exists()) {
      const cardTypeData = cardTypeSnap.data();
      const updatedCollectedCardTypeCategories = { ...cardTypeData.collectedCardTypeCategories };

      handleUpdate(updatedCollectedCardTypeCategories, cardTypeCategory, inc);

      const newCardTypeCount = Object.values(updatedCollectedCardTypeCategories).reduce((sum, category) => sum + category.cardCount, 0);

      if (newCardTypeCount <= 0) {
        batch.delete(cardTypeDocRef);
      } else {
        batch.update(cardTypeDocRef, {
          totalCardCount: newCardTypeCount,
          collectedCardTypeCategories: updatedCollectedCardTypeCategories
        });
      }
    }

    // update collectedSeries values
    const manufacturerDocRef = doc(db, `users/${userID}/collection/${sport}/${year}/${manufacturer}`);
    const manufacturerSnap = await getDoc(manufacturerDocRef);

    if (manufacturerSnap.exists()) {
      const manufacturerData = manufacturerSnap.data();
      const updatedCollectedSeries = { ...manufacturerData.collectedSeries };

      handleUpdate(updatedCollectedSeries, series, inc);

      const newSeriesCardCount = Object.values(updatedCollectedSeries).reduce((sum, series) => sum + series.cardCount, 0);

      if (newSeriesCardCount <= 0) {
        batch.delete(manufacturerDocRef);
      } else {
        batch.update(manufacturerDocRef, {
          totalCardCount: newSeriesCardCount,
          collectedSeries: updatedCollectedSeries
        });
      }
    }

    // update collectedYears values
    const yearDocRef = doc(db, `users/${userID}/collection/${sport}`);
    const yearSnap = await getDoc(yearDocRef);

    if (yearSnap.exists()) {
      const yearData = yearSnap.data();
      const updatedCollectedYears = { ...yearData.collectedYears };

      handleUpdate(updatedCollectedYears, year, inc);

      const newYearCardCount = Object.values(updatedCollectedYears).reduce((sum, year) => sum + year.cardCount, 0);

      if (newYearCardCount <= 0) {
        batch.delete(yearDocRef);
      } else {
        batch.update(yearDocRef, {
          totalCardCount: newYearCardCount,
          collectedYears: updatedCollectedYears
        });
      }
    }

    // update user top-level values
    const userDocRef = doc(db, `users/${userID}`);
    const userSnap = await getDoc(userDocRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();

      let newUserCardCount = userData.collectionCardCount - inc;
      let newUserUniqueCardCount = removeAll ? userData.uniqueCardCount - 1 : userData.uniqueCardCount

      newUserCardCount = Math.max(0, newUserCardCount);
      newUserUniqueCardCount = Math.max(0, newUserUniqueCardCount);

      batch.update(userDocRef, {
        collectionCardCount: newUserCardCount,
        uniqueCardCount: newUserUniqueCardCount
      });
    }

    await batch.commit();

    const increment = removeAll ? card.collectedCards[parallelIndex].cardQty * -1 : -1;

    const removedCards = card.collectedCards[parallelIndex]

    removedCards.cardQty = inc * -1

    const cardObject = {
      [sport]: {
        [year]: {
          [manufacturer]: {
            [series]: {
              [cardType]: {
                [cardTypeCategory]: {
                  [cardNumber]: {
                    ...card,
                    collectedCards: [
                      removedCards
                    ]
                  }
                }
              }
            }
          }
        }
      }
    }

    logUserAction(userID, "removedFromCollection", { cards: cardObject, prevCount: prevCount, increment });

  } catch (error) {
    console.error("Error removing card: ", error);
  }
};

// UPDATE FAVORITE TEAM
export const updateTeamIndex = async (userID, newTeamIndex, currentTeamIndex) => {
  try {
    const userDocRef = doc(db, `users/${userID}`);
    await updateDoc(userDocRef, { teamIndex: newTeamIndex });
    logUserAction(userID, 'updatedTeam', { oldTeam: currentTeamIndex, newTeam: newTeamIndex })
  } catch (error) {
    console.error('Error updating favorite team:', error);
  }
};

// LOG USER ACTION
export const logUserAction = async (userID, actionType, details = {}) => {
  try {
    const logRef = collection(db, `users/${userID}/activityLog`);
    const logEntry = {
      actionType,
      timestamp: Timestamp.now(),
      details,
    };
    await addDoc(logRef, logEntry);
    console.log('logUserAction: User action logged -> ', logEntry);
  } catch (error) {
    console.error('Error logging user action:', error);
  }
};