// styles
import {
    CreateSetPageContainer
} from './CreateSetPage.styles';


export default function CreateSetPage() {
  return (
    <CreateSetPageContainer>
        <h1>CREATE SET</h1>
    </CreateSetPageContainer>
  )
}
