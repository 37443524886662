import {
  TeamUpdatedEntryContainer,
  LeftContent,
  DateDisplay,
  ActionDisplay,
  TeamChangeDisplay
} from './TeamUpdatedEntry.styles.js';

// data
import { TEAM_LOGOS } from '../../../../data/theme-data/team-data.js';

export default function TeamUpdatedEntry({ content }) {
  const formattedTimestamp = content.timestamp?.toDate().toLocaleString();

  return (
    <TeamUpdatedEntryContainer className='panel-bg'>
      <LeftContent>
        <DateDisplay>{formattedTimestamp}</DateDisplay>
        <ActionDisplay>Team updated from {TEAM_LOGOS[content.details.oldTeam].name} to {TEAM_LOGOS[content.details.newTeam].name}</ActionDisplay>
      </LeftContent>
      <TeamChangeDisplay>
        <img src={TEAM_LOGOS[content.details.oldTeam].logo} alt={TEAM_LOGOS[content.details.oldTeam].name} />
        <span>&#x279F;</span>
        <img src={TEAM_LOGOS[content.details.newTeam].logo} alt={TEAM_LOGOS[content.details.newTeam].name}/>
      </TeamChangeDisplay>
    </TeamUpdatedEntryContainer>
  )
}
