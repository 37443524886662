import { useEffect, useState } from 'react';
import { logUserAction } from '../../../../utils/firebase/firebase.utils.js';
import { OAuthProvider, reauthenticateWithPopup, updateEmail, updateProfile, getAuth } from 'firebase/auth';

// styles
import {
  AccountDetailsContainer,
  InputContainer,
} from './AccountDetails.styles.js';

// components
import ActionField from '../../../../components/action-field/ActionField';
import InputBar from '../../../../components/input-bar/InputBar';
import SecondaryBtn from '../../../../components/btns/secondary-btn/SecondaryBtn';

// data
import TeamSelection from './components/team-selection/TeamSelection.js';

export default function AccountDetails() {
  const signInProvider = new OAuthProvider();
  const auth = getAuth();
  const [savedDisplayName, setSavedDisplayName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [savedEmail, setSavedEmail] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const user = auth.currentUser;
    if (user !== null) {
      setSavedDisplayName(user.displayName)
      setDisplayName(user.displayName)
      setSavedEmail(user.email)
      setEmail(user.email)
    } else {
      setSavedDisplayName("ERR")
      setDisplayName("ERR")
      setSavedEmail("ERR")
      setEmail("ERR")
    }
  }, [auth])

  // HANDLE UPDATING USERNAME
  const updateDisplayName = (newDisplayName) => {
    if (newDisplayName === savedDisplayName) {
      return
    } else {
      updateProfile(auth.currentUser, {
        displayName: newDisplayName
      }).then(() => {
        logUserAction(auth.currentUser.uid, "updatedAccount", { accountActionType: "username", oldUsername: savedDisplayName, newUsername: newDisplayName })
        setSavedDisplayName(newDisplayName);
      }).catch((error) => {
        alert(error.message);
      });
    }
  }

  // HANDLE UPDATING EMAIL
  const updateUserEmail = async (newEmailStr) => {
    if (newEmailStr === savedEmail) {
      return;
    } else {
      try {
        await updateEmail(auth.currentUser, newEmailStr);
        logUserAction(auth.currentUser.uid, "updatedAccount", {
          accountActionType: "email",
          oldEmail: savedEmail,
          newEmail: newEmailStr
        });
        setSavedEmail(newEmailStr);
      } catch (err) {
        if (err.code === "auth/requires-recent-login") {
          reAuth();
        } else {
          alert(err.message);
        }
      }
    }
  };
  async function reAuth() {
    try {
      // const result = await reauthenticateWithPopup(user, signInProvider);
      await reauthenticateWithPopup(auth.currentUser, signInProvider);
      // const credential = OAuthProvider.credentialFromResult(result);

      // After successful reauthentication, retry the email update
      await updateUserEmail(email);
    } catch (err) {
      alert(err.message);
    }
  }

  return (
    <AccountDetailsContainer>
      <TeamSelection />
      <ActionField>
        <InputContainer>
          <InputBar type={'text'} value={!displayName ? savedDisplayName : displayName} updateValue={!displayName ? setSavedDisplayName : setDisplayName} />
        </InputContainer>
        <div onClick={() => updateDisplayName(displayName)}>
          <SecondaryBtn title='CHANGE USERNAME' isDisabled={displayName === savedDisplayName} />
        </div>
      </ActionField>
      <ActionField>
        <InputContainer>
          <InputBar type={'text'} value={!email ? savedEmail : email} updateValue={!email ? setSavedEmail : setEmail} />
        </InputContainer>
        <div onClick={() => updateUserEmail(email)}>
          <SecondaryBtn title='UPDATE EMAIL' isDisabled={email === savedEmail} />
        </div>
      </ActionField>
    </AccountDetailsContainer>
  )
}