import { createSelector } from "reselect";

const selectCardReducer = state => state.card;

export const selectCardData = createSelector(
    [selectCardReducer],
    (card) => card.cardData
);
export const selectChecklistData = createSelector(
    [selectCardReducer],
    (card) => card.checklistData
);
export const selectCardParallels = createSelector(
    [selectCardReducer],
    (card) => card.cardParallels
);
export const selectQueuedCards = createSelector(
    [selectCardReducer],
    (card) => card.queuedCards
);
export const selectQueuedCardsDisplay = createSelector(
    [selectCardReducer],
    (card) => card.queuedCardsDisplay
);
export const selectCardCollection = createSelector(
    [selectCardReducer],
    (card) => card.cardCollection
);