import { useDispatch, useSelector } from 'react-redux';
import { selectQueuedCards, selectQueuedCardsDisplay } from '../../store/card/card.selector.js';
import { mergeQueuedCardsWithFirestore } from '../../utils/firebase/firebase.utils.js';
import { selectCardCount, selectUser } from '../../store/user/user.selector.js';
import { clearCardsFromQueue } from '../../store/card/card.action.js';

// styles
import {
    QueuedCardsContainer,
    QueueHeader,
    QueueBody,
    QueueFooter
} from './QueuedCards.styles.js';

// components
import QueueCard from './components/queue-card/QueueCard.js';

export default function QueuedCards() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const queuedCards = useSelector(selectQueuedCards);
    const queuedCardsDisplay = useSelector(selectQueuedCardsDisplay);
    const cardCount = useSelector(selectCardCount);

    const handleAddToCollection = () => {
        const userID = user.uid;
        if (userID) {
            dispatch(mergeQueuedCardsWithFirestore(userID, queuedCards, cardCount));
        }
    };

    const handleClearQueue = () => {
        dispatch(clearCardsFromQueue());
    }

    return (
        <>
            {queuedCards.totalCardCount !== 0 && (
                <QueuedCardsContainer>
                    <QueueHeader>
                        <h3>QUEUED CARDS</h3>
                        <h4>
                            {queuedCards.totalCardCount && queuedCards.totalCardCount !== 0
                                ? queuedCards.totalCardCount
                                : ''}
                        </h4>
                    </QueueHeader>

                    <QueueBody>
                        {queuedCardsDisplay
                            .slice() // Create a shallow copy to avoid mutating the original array
                            .reverse() // Reverse the queuedCardsDisplay array
                            .map((card, cardIndex) =>
                                card.card.collectedCards
                                    .slice() // Create a shallow copy of collectedCards
                                    .reverse() // Reverse the collectedCards array
                                    .map((parallel, parallelIndex) => (
                                        <QueueCard
                                            key={`${cardIndex}-${parallelIndex}`}
                                            card={card}
                                            parallel={parallel}
                                            index={cardIndex}
                                        />
                                    ))
                            )}
                    </QueueBody>

                    <QueueFooter>
                        <button onClick={handleClearQueue}>CLEAR QUEUE</button>
                        <button onClick={handleAddToCollection}>ADD TO COLLECTION</button>
                    </QueueFooter>
                </QueuedCardsContainer>
            )}
        </>
    );
}