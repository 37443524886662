import styled from "styled-components";
import { motion } from "framer-motion";

export const SeriesDisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 48px;
`
export const CollectionStats = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;

    div {
        flex-grow: 1;
    }
`
export const DisplayHeader = styled.div`
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
    padding: 8px;

    h3 {
        font-size: 16px;
        opacity: 0.4;
        padding: 8px 0px;
    }
`
export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`
export const CardsDisplayContainer = styled.div`
    display: flex;
    gap: 24px;
    justify-content: space-between;
`
export const ChecklistControls = styled.div`
    display: flex;
    justify-content: space-between;

    label {
        font-size: 12px;
        font-weight: 600;
    }

    select {
        border: 1px solid rgba(255,255,255,0.4);
        border-radius: 8px;
        color: #FFFFFF;
        cursor: pointer;
        height: 44px;
        outline: none;
        padding: 0px 8px;
    }
`
export const ControlContent = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
`
export const InputField = styled.input`
    background: none;
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 8px;
    box-sizing: border-box;
    color: #FFFFFF;
    font-weight: 600;
    height: 44px;
    padding: 8px;
    width: 50%;
    min-width: 480px;

    &:focus {
        border: 2px solid rgba(255,255,255,0.4);
        outline: none;
    }

    &::placeholder {
        color: rgba(255,255,255,0.4);
    }
`
export const CardsDisplay = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: ${(props) => props.$width};
    transition: 0.2s ease-out;
`
export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        margin-left: 10px;
        width: fit-content;
    }
`;

export const ToggleSwitch = styled.div`
    width: 50px;
    height: 24px;
    background-color: ${props => (props.$isToggled ? "rgba(255,255,255,0.4)" : "rgba(255,255,255,0.1)")};
    border-radius: 24px;
    position: relative;
    transition: background-color 0.3s ease;
`;

export const ToggleCircle = styled.div`
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: ${props => (props.$isToggled ? '28px' : '2px')};
    transition: left 0.3s ease;
`;
export const QueuedCardsContainer = styled(motion.div)`
    position: ${(props) => (props.$fixed ? 'fixed' : 'absolute')};
    right: 48px;
    top: ${(props) => (props.$fixed ? '48px' : 'auto')};
`