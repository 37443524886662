import { USER_ACTION_TYPES } from "./user.types.js";

const USER_INITIAL_STATE = {
    user: null,
    authIsReady: false,
    teamIndex: null,
    activityLog: [],
    recentlyViewed: [],
    cardCount: null
};

export const userReducer = (state = USER_INITIAL_STATE, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case USER_ACTION_TYPES.LOG_IN:
            return { ...state, user: payload };
        case USER_ACTION_TYPES.LOG_OUT:
            return { ...state, user: null };
        case USER_ACTION_TYPES.AUTH_IS_READY:
            return { ...state, user: payload, authIsReady: true };
        case USER_ACTION_TYPES.SET_TEAM_INDEX:
            return { ...state, teamIndex: payload };
        case USER_ACTION_TYPES.UPDATE_RECENTLY_VIEWED:
            return { ...state, recentlyViewed: payload };
        case USER_ACTION_TYPES.SET_CARD_COUNT:
            return { ...state, cardCount: payload };
        default:
            return state;
    }
};