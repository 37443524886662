import styled from "styled-components";

export const TeamUpdatedEntryContainer = styled.div`
    align-items: center;
    border-radius: 32px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    padding: 8px 8px;

    h3 {
        color: #FFFFFF;
    }
`
export const LeftContent = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
`
export const DateDisplay = styled.h4`
    color: #FFFFFF;
    background: rgba(255,255,255,0.25);
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    font-size: 10px;
    padding: 8px 16px;
    padding: 8px 16px;
    text-align: center;
    width: 128px;
`
export const ActionDisplay = styled.h4`
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
`
export const TeamChangeDisplay = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    padding-right: 8px;

    img {
        border-radius: 100px;
        height: 24px;
        width: 24px;
    }

    span {
        color: #FFFFFF;
    }
`